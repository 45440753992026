import React, {useEffect, useState} from 'react';
import {LinkProps, NavLink} from 'react-router-dom'
import styles from './navbar.module.scss'
import {auth} from "../../store/redusers/authSlice";
import {useAuth} from "../../hooks/auth.hook";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {AppRoutes, routeConfig} from "../../app/routeConfig";

const Navbar = () => {

  const dispatch = useAppDispatch()

  const userIdInStore = useAppSelector(state => state.authReduser.userId)
  const tokenInStore = useAppSelector(state => state.authReduser.token)

  const [isAuth, setIsAuth] = useState(false)
  const [activeLink, setActiveLink] = useState<AppRoutes | undefined>()

  const {login, logout, token, userId, ready} = useAuth()

  useEffect(() => {
    if (token && userId && ready) {
      setIsAuth(true)
    }
  }, [token, userId, ready])

  useEffect(() => {
    if (userIdInStore && tokenInStore) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
  }, [userIdInStore, tokenInStore])

  useEffect(() => {
    if (isAuth) {
      setActiveLink(AppRoutes.MAIN)
    } else {
      setActiveLink(AppRoutes.LOGIN)
    }
  }, [isAuth])

  const onQuit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    logout()
    dispatch(auth({userId: null, token: null}))
    setIsAuth(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={[styles.main, activeLink === AppRoutes.MAIN ? styles.activeLink : ''].join(' ')}>
          <NavLink onClick={() => {
            setActiveLink(AppRoutes.MAIN)
          }}
                   to={routeConfig[AppRoutes.MAIN].routeProps.path as LinkProps['to']}>
            <h1 className={styles.header}>Trade Helper</h1>
          </NavLink>
        </div>
        <div className={styles.links}>
          {
            !isAuth &&
            <div
              className={[styles.link, activeLink === AppRoutes.LOGIN ? styles.activeLink : ''].join(' ')}>
              <NavLink onClick={() => {
                setActiveLink(AppRoutes.LOGIN)
              }}
                       to={routeConfig[AppRoutes.LOGIN].routeProps.path as LinkProps['to']}>{routeConfig.login.routeProps.children}</NavLink>
            </div>
          }
          {
            !isAuth &&
            <div
              className={[styles.link, activeLink === AppRoutes.REGISTRATION ? styles.activeLink : ''].join(' ')}>
              <NavLink onClick={() => {
                setActiveLink(AppRoutes.REGISTRATION)
              }}
                       to={routeConfig[AppRoutes.REGISTRATION].routeProps.path as LinkProps['to']}>{routeConfig.registration.routeProps.children}</NavLink>
            </div>
          }
          {
            isAuth &&
            <div
              className={[styles.link, activeLink === AppRoutes.POSITIONS ? styles.activeLink : ''].join(' ')}>
              <NavLink onClick={() => {
                setActiveLink(AppRoutes.POSITIONS)
              }}
                       to={routeConfig[AppRoutes.POSITIONS].routeProps.path as LinkProps['to']}>{routeConfig.positions.routeProps.children}</NavLink>
            </div>
          }
          {
            isAuth &&
            <div
              className={[styles.link, activeLink === AppRoutes.RATINGS ? styles.activeLink : ''].join(' ')}>
              <NavLink onClick={() => {
                setActiveLink(AppRoutes.RATINGS)
              }}
                       to={routeConfig[AppRoutes.RATINGS].routeProps.path as LinkProps['to']}>{routeConfig.ratings.routeProps.children}</NavLink>
            </div>
          }
          {
            isAuth &&
            <div
              className={[styles.link, activeLink === AppRoutes.TESTING ? styles.activeLink : ''].join(' ')}>
              <NavLink onClick={() => {
                setActiveLink(AppRoutes.TESTING)
              }}
                       to={routeConfig[AppRoutes.TESTING].routeProps.path as LinkProps['to']}>{routeConfig.testing.routeProps.children}</NavLink>
            </div>
          }
          {
            isAuth &&
            <div className={[styles.quit, styles.link].join(' ')}>
              <a href="/" onClick={onQuit}>Выйти</a></div>
          }
        </div>
      </div>
    </div>
  );
};

export default Navbar;