import React, {useEffect, useState} from 'react';
import styles from "./proTips.module.scss";
import {v4 as uuidv4} from "uuid";
import bearSvg from "../../../../../../../assets/images/bear.svg";
import bullSvg from "../../../../../../../assets/images/bull.svg";
import doubterSvg from "../../../../../../../assets/images/doubter.svg";
import {
  ColorOfProTips,
  ProTipsCssClasses
} from "../../../../../../../shared/investingComConfig";

interface Props {
  analytic: { content: string, classes: string }[]
}

const ProTips = ({analytic}: Props) => {

  const [colorOfTips, setColorOfTips] = useState<string[]>()
  const [imagesOfTips, setImagesOfTips] = useState<string[]>()

  //определение цвета для каждой подсказки
  useEffect(() => {
    const colors: string[] = []
    const images: string[] = []
    analytic.forEach((elem) => {
      if (elem.classes.includes(ProTipsCssClasses["text-positive-main"])) {
        colors.push(ColorOfProTips["text-positive-main"])
        images.push(bullSvg)
      } else if (elem.classes.includes(ProTipsCssClasses["text-negative-main"])) {
        colors.push(ColorOfProTips["text-negative-main"])
        images.push(bearSvg)
      } else {
        colors.push(ColorOfProTips["doubter"])
        images.push(doubterSvg)
      }
    })
    setColorOfTips(colors)
    setImagesOfTips(images)
  }, [analytic])

  if (!colorOfTips || !imagesOfTips) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <p className={styles.title}>ProTips</p>
          {
            analytic.map((elem, index) => {
              const color = colorOfTips[index]
              const image = imagesOfTips[index]
              return (
                <div className={styles.listElem} key={uuidv4()}>
                  <img src={image} alt=""/>
                  <p className={styles.value} style={{color: color}}>
                    {elem.content}
                  </p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default ProTips;