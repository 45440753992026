import {useHttp} from "./http.hook";
import {CandlesAnalyticsType, Countries} from "../shared/types";

interface IDataGetCandlesAnalytics {
  message: string,
  info: CandlesAnalyticsType
}

export const useCandlesAnalytics = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение аналитики по свечным моделям по тикеру
  const getCandles = async (country: Countries, tickerList: string[]) => {
    try {
      const data: IDataGetCandlesAnalytics = await request('/api/analytics/get-candles', 'POST', {
        country: country,
        tickerList: tickerList
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getCandles, error, clearError}
}