import React, {useEffect, useState} from 'react';
import styles from "./ratings.module.scss";
import Company from "./company/Company";
import {Tabs} from "antd";
import Analyst from "./analyst/Analyst";

const Ratings = () => {

  const [companyIsActive, setCompanyIsActive] = useState(true) //активность вкладки Компании
  const [analystIsActive, setAnalystIsActive] = useState(false) //активность вкладки Аналитики

  //работа табов
  const itemsTab = [
    {key: 'company', label: 'Компания',},
    {key: 'analyst', label: 'Аналитик',},
  ];
  const changeTabHandler = (key: string) => {
    if (key === 'company') {
      if (!companyIsActive) {
        setCompanyIsActive(true)
        setAnalystIsActive(false)
      }
    } else if (key === 'analyst') {
      if (!analystIsActive) {
        setAnalystIsActive(true)
        setCompanyIsActive(false)
      }
    }
  };


  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Глобальные рейтинги</p>
          <div className={styles.tabs}>
            <Tabs defaultActiveKey="1" items={itemsTab} onChange={changeTabHandler}/>
          </div>
        </div>
        <div className={styles.table}>
          {
            companyIsActive && <Company/>
          }
          {
            analystIsActive && <Analyst/>
          }
        </div>
      </div>
    </div>
  );
};

export default Ratings;