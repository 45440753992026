export const candlestick = {
  'Belt Hold Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Мин.',
    description: 'Во время восходящего тренда в восходящем гэпе возникает черное тело с открытием, которое также является максимумом для свечи. Это может привести к тому, что многие позиции будут проданы и это закрепит медвежий разворот. Чем выше свеча в модели «Захват за пояс», тем больше ее значимость.',
    image: 3
  },
  'Bullish doji Star': {
    direction: 'Бычий разворот',
    reliability: 'Средняя',
    description: 'Во время нисходящего тренда рынок усиливает «медведей» посредством длинной черной свечи и открытием гэпов на второй свече. В то же время вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о наличии потенциала для ралли, так как изменились многие позиции. Подтверждением разворота тренда послужило бы более высокое открытие на следующей свече.',
    image: 43
  },
  'Three Black Crows': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Во время восходящего тренда появляются три длинные черные свечи, каждая из которых закрывается ниже предыдущей. Эта модель говорит о том, что рынок слишком долго находился на высокой цене и инвесторы начинают это компенсировать. Модель более значима, если появляется после существенного роста.',
    image: 35
  },
  'Three Inside Up': {
    direction: 'Бычий разворот',
    reliability: 'Макс.',
    description: 'Эта модель — более надежное дополнение к стандартной модели «Харами». Бычье «Харами» возникает в первых двух свечах. Третья свеча — белая с более высоким закрытием, чем у второй, и является подтверждением бычьего разворота.',
    image: 57
  },
  'Harami Bullish': {
    direction: 'Бычий разворот',
    reliability: 'Мин.',
    description: 'Бычья модель «Харами» характеризуется небольшим белым реальным телом, которое заключено в предшествующее относительно длинное черное реальное тело. «Харами» — древнее японское слово, означающее «беременная». Длинная черная свеча — это «мать», а маленькая — «ребенок». Чем меньше вторая свеча, тем сильнее сигнал разворота. Тени второй свечи необязательно должны быть в теле первой свечи, хотя такой вариант предпочтительнее. Модели требуется подтверждение на следующей свече.',
    image: 48
  },
  'Harami Cross': {
    direction: 'Бычий разворот',
    reliability: 'Мин.',
    description: 'После длинной черной свечи на нижнем конце нисходящего тренда белая свеча открывается выше или на уровне закрытия предыдущей свечи и закрывается на своем уровне открытия или вблизи него.\n' +
      'По сравнению с базовым индикатором «Харами» индикатор «Крест Харами» отличается большей определенностью и указывает на разворот для «быков».\n' +
      'Чем меньше вторая свеча, тем сильнее сигнал разворота.\n' +
      'Тени второй свечи необязательно должны быть в теле первой свечи, хотя такой вариант предпочтительнее.',
    image: 49
  },
  'Bullish Hammer': {
    direction: 'Бычий разворот',
    reliability: 'Мин.',
    description: 'Во время нисходящего тренда после начала свечи происходит распродажа. Однако свеча закрывается на своем максимуме или вблизи него. Это сигнализирует об ослаблении прежних медвежьих настроений. Чем длиннее нижняя тень, тем меньше верхняя тень, и чем меньше реальное тело, тем более значима модель. По сравнению с черным телом, белое реальное тело имеет большую бычью окраску.',
    image: 46
  },
  'Engulfing Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Эта модель наблюдается во время восходящего тренда. Для нее характерно наличие большого черного реального тела, которое поглощает белое реальное тело (без теней). Это означает, что задет восходящий тренд и «медведи», вероятно, набирают силу. На индикатор «Поглощение» также указывают две первые свечи моделей «Три снаружи». Это главный сигнал разворота. Факторы, повышающие надежность этого сигнала :\n' +
      '1) первая свеча имеет очень маленькое, а вторая — очень большое реальное тело;\n' +
      '2) модель появляется после затяжного или очень быстрого движения;\n' +
      '3) вторая черная свеча обладает большим объемом;\n' +
      '4) вторая свеча поглощает больше одного реального тела.',
    image: 9
  },
  'Falling Three Methods': {
    direction: 'Продолжение медвежьего тренда',
    reliability: 'Макс.',
    description: 'НаправлениеПродолжение медвежьего тренда\n' +
      'НадежностьМакс.\n' +
      'ОписаниеВо время нисходящего тренда появляется длинная черная свеча, за которой следуют три свечи с небольшими реальными телами и попадают в короткий восходящий тренд. На пятой свече «медведи» усиливаются и закрываются на новом минимуме. Этот небольшой восходящий тренд между двумя длинными черными свечами соответствует паузе у инвесторов. Нисходящий тренд продолжится.',
    image: 30
  },
  'Doji Star Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда рынок укрепляется на длинной белой свече и гэпах вверх на второй свече. В то же время вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о снижении доверия к текущему тренду. Подтверждением разворота тренда послужило бы более низкое открытие на следующей свече.',
    image: 8
  },
  'Bullish Engulfing': {
    direction: 'Бычий разворот',
    reliability: 'Средняя',
    description: 'Во время нисходящего тренда бычье «Поглощение» отражает открытие на новом минимуме и закрывается на уровне или выше открытия предыдущей свечи. Это означает, что нисходящий тренд потерял моментум и «быки», вероятно, набирают силу. Факторы, повышающие эффективность модели:\n' +
      '1) первая свеча имеет небольшое, а вторая — большое реальное тело;\n' +
      '2) модель появляется после затяжного или очень быстрого движения;\n' +
      '3) второе реальное тело обладает большим объемом;\n' +
      '4) вторая свеча поглощает больше одного реального тела.',
    image: 44
  },
  'Shooting Star': {
    direction: 'Медвежий разворот',
    reliability: 'Мин.',
    description: 'Во время восходящего тренда происходит гэп вверх. Он поднимается к новому максимуму, затем ослабевает и закрывается вблизи своего минимума: медвежья смена моментума. Подтверждением разворота тренда стало бы открытие ниже тела «Падающей звезды» на следующей торговой свече. Это не такой важный сигнал разворота, как «Вечерняя звезда». В идеале, должен был произойти отрыв реального тела от предыдущего тела в виде гэпа.',
    image: 21
  },
  'Dragonfly Doji': {
    direction: 'Бычий разворот',
    reliability: 'Средняя',
    description: 'Во время нисходящего тренда после начала свечи происходит массовая распродажа. Однако свеча закрывается на своем максимуме или вблизи него и на том же уровне, что и открытие. Это сигнализирует об ослаблении прежних медвежьих настроений. Чем длиннее нижняя тень, тем меньше верхняя тень, и чем меньше реальное тело, тем более значима модель. По сравнению с черным телом, белое реальное тело имеет большую бычью окраску.',
    image: 47
  },
  'Three Outside Down': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Эта модель — более надежное дополнение к стандартной модели «Поглощение». Медвежье «Поглощение» возникает в первых двух свечах. Третья свеча — черная с более низким закрытием, чем у второй, и является подтверждением медвежьего разворота.',
    image: 24
  },
  'Deliberation Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда появляются три белые свечи, каждая из которых закрывается выше предыдущей. Эта модель образовалась от модели «Три белых солдата» и очень напоминает «Отбитое наступление». Восходящий тренд продолжается, но наличие третьего небольшого тела говорит о том, что предыдущее ралли ослабевает и готовится к развороту.',
    image: 6
  },
  'Harami Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Мин.',
    description: 'Медвежье «Харами» — это двухсвечная модель, которая состоит из относительно небольшого черного реального тела, заключенного в предшествующее относительно длинное белое реальное тело.\n' +
      '«Харами» — древнее японское слово, означающее «беременная». Длинная белая свеча — это «мать», а маленькая — «ребенок». Модель сигнализирует об ослаблении текущего восходящего тренда.\n' +
      'Тени второй свечи необязательно должны быть в теле первой свечи, хотя такой вариант предпочтительнее. Индикатор «Харами» должен быть подтвержден следующей свечой.\n' +
      'Модель «Харами» также представляет собой первые две свечи моделей «Три внутри». По сравнению с «Висельником» или «Поглощением», это не столь значимая разворотная модель.',
    image: 15
  },
  'Harami Cross Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Мин.',
    description: 'После длинной белой свечи в верхней части восходящего тренда свеча открывается ниже или на уровне закрытия предыдущей свечи, заканчивается в теле первой свечи и сигнализирует о том, что текущий восходящий тренд ослабевает. По сравнению с базовой медвежьей моделью «Харами», эта разворотная модель более значима.\n' +
      'Тени второй свечи необязательно должны быть в теле первой свечи, хотя такой вариант предпочтительнее. Тело второй свечи может быть либо белым, либо черным.',
    image: 17
  },
  'Abandoned Baby Bullish': {
    direction: 'Бычий разворот',
    reliability: 'Макс.',
    description: 'Очень редкая модель, для которой характерен нисходящий гэп «Доджи» и последующий восходящий гэп. Тени на «Доджи» должны полностью исчезнуть под тенями первой и третьей свечей.',
    image: 39
  },
  'Hanging Man': {
    direction: 'Медвежий разворот',
    reliability: 'Мин.',
    description: 'Во время восходящего тренда после гэпа вверх происходит резкая распродажа, однако свеча закрывается на своем максимуме или вблизи него. Это указывает на наличие потенциала для дальнейших распродаж. Поскольку модель «Висельник» отличается низкой определенностью, разворот тренда может быть подтвержден черной свечой или большим нисходящим гэпом на следующей свече и более низким закрытием.',
    image: 13
  },
  'Downside Gap Three Methods': {
    direction: 'Продолжение медвежьего тренда',
    reliability: 'Средняя',
    description: 'За нисходящим трендом следуют две длинные черные свечи с нисходящим гэпом между ними. Третья свеча — белая, но она закрывает гэп между первыми двумя. Такую ситуацию следует рассматривать как поддержку нисходящего тренда.',
    image: 28
  },
  'Dark Cloud Cover': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Во время восходящего тренда первой появляется длинная белая свеча. Второй — черная свеча с открытием выше максимума предыдущей свечи и закрытием в рамках, но ниже середины тела первой свечи. Модель «Завеса из темных облаков» дает «шортам» возможность заработать на открытии следующей свечи. Это предупреждение для инвесторов-«быков». Чем больше вторая свеча охватывает первую и чем больше объем на второй свече, тем значительнее эта модель. Кроме того, открытие второго тела выше уровня серьезного сопротивления может свидетельствовать о сильном развороте. «Завеса из темных облаков» является противоположностью модели «Просвет в облаках».',
    image: 5
  },
  'On Neck Bearish': {
    direction: 'Продолжение медвежьего тренда',
    reliability: 'Средняя',
    description: 'Во время нисходящего тренда первой появляется длинная черная свеча. Второй — белая свеча (не длинная), которая открывается ниже минимума предыдущей свечи и закрывается на ее минимуме. Модель «У линии шеи» типична для нисходящего тренда. Тот факт, что вторая свеча строит небольшое ралли, которое заканчивается на минимуме предыдущей черной свечи, указывает на то, что верх возьмут «медведи». Эта модель похожа на «Просвет в облаках», но медвежий, поскольку нет проникновения второй свечи.',
    image: 32
  },
  'Ladder Bottom Bullish': {
    direction: 'Бычий разворот',
    reliability: 'Средняя',
    description: 'Нет описания',
    image: 70
  },
  'Inverted Hammer': {
    direction: 'Бычий разворот',
    reliability: 'Мин.',
    description: 'Во время нисходящего тренда открытие ниже, затем оно торгуется выше, но закрывается вблизи своего открытия, поэтому выглядит как перевернутый леденец. Модели требуется бычье подтверждение на следующей свече.',
    image: 51
  },
  'Three Outside Up': {
    direction: 'Бычий разворот',
    reliability: 'Макс.',
    description: 'Эта модель — более надежное дополнение к стандартной модели «Поглощение». Бычье «Поглощение» возникает в первых двух свечах. Третья свеча является подтверждением бычьего разворота.',
    image: 58
  },
  'Thrusting Bearish': {
    direction: 'Продолжение медвежьего тренда',
    reliability: 'Мин.',
    description: 'Во время нисходящего тренда первой появляется длинная черная свеча. Второй — белая свеча, которая открывается ниже минимума предыдущей свечи и закрывается в ее теле, но ниже середины. Модель «Толчок» является более слабым родственником продолжения трендов «У линии шеи» и «На линии шеи». Поскольку закрытие второй свечи не достигает даже середины тела первой свечи, «быки», скорее всего, будут разочарованы, и нисходящий тренд продолжится.',
    image: 38
  },
  'Morning Doji Star': {
    direction: 'Бычий разворот',
    reliability: 'Макс.',
    description: 'Во время нисходящего тренда рынок усиливает медвежий тренд посредством длинной черной свечи. Вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о наличии потенциала для ралли, так как изменились многие позиции. Подтверждение развороту дает третья белая свеча. «Утренняя звезда доджи» представляет собой полностью реализованную бычью модель «Звезда доджи». Это важный сигнал разворота.',
    image: 53
  },
  'Evening Doji Star': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Во время восходящего тренда рынок укрепляется на длинной белой свече. Вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о снижении доверия к текущему тренду. Подтверждением разворота тренда служит третья черная свеча. Индикатор «Вечерняя звезда доджи» представляет собой полностью реализованную медвежью модель «Звезда доджи».',
    image: 10
  },
  'Belt Hold Bullish': {
    direction: 'Бычий разворот',
    reliability: 'Мин.',
    description: 'Во время нисходящего тренда появляется белое тело с открытием, которое также является для него минимумом. Это может сигнализировать о начале ралли для «быков». Чем больше белая свеча, тем она значительнее.',
    image: 40
  },
  'Advance Block Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда появляются три длинные свечи, каждая из которых закрывается выше предыдущей. Эта модель напоминает «Три белых солдата», однако в этом случае каждая последующая свеча слабее предыдущей. Это говорит о том, что ралли испытывает затруднения. Признаками ослабления могут быть постепенно уменьшающиеся белые реальные тела или относительно длинные верхние тени на последних двух белых свечах.',
    image: 2
  },
  'Break Away Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда первая свеча представляет собой «длинный белый день». Вторая — белая свеча, которая разрывается выше первой свечи. Третья и четвертая свечи продолжают движение в направлении второй, и каждая из них закрывается выше предыдущей. Пятая — длинная черная свеча, которая закрывается в гэпе между первой и второй свечами. Такая картина говорит о краткосрочном развороте.',
    image: 4
  },
  'Evening Star': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Во время восходящего тренда рынок укрепляется на длинной белой свече. Вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о снижении доверия к текущему тренду. Подтверждением разворота тренда служит третья черная свеча. Наличие гэпа между вторым и третьим телами необязательно.',
    image: 11
  },
  'Upside Gap Three Methods': {
    direction: 'Продолжение бычьего тренда',
    reliability: 'Средняя',
    description: 'За восходящим трендом следуют две длинные белые свечи с восходящим гэпом между ними. Третья свеча — черная, но она закрывает гэп между первыми двумя. Модель следует рассматривать как поддержку восходящего тренда, а причиной ее появления может быть временная фиксация прибыли.',
    image: 67
  },
  'Break Away Bullish': {
    direction: 'Бычий разворот',
    reliability: 'Средняя',
    description: 'Во время нисходящего тренда цены достигают дна и выравниваются. В результате возникает длинная белая свеча, которая не закрывает гэп в теле первой. Это говорит о краткосрочном развороте.',
    image: 41
  },
  'Three Inside Down': {
    direction: 'Медвежий разворот',
    reliability: 'Макс.',
    description: 'Эта модель — более надежное дополнение к стандартной модели «Харами». Медвежье «Харами» возникает в первых двух свечах. Третья свеча — черная с более низким закрытием, чем у второй, и является подтверждением медвежьего разворота.',
    image: 23
  },
  'Dragonfly Bearish': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда после гэпа вверх происходит резкая распродажа, однако свеча закрывается на своем максимуме или вблизи него. Это указывает на наличие потенциала для дальнейших распродаж. Поскольку модель «Доджи стрекоза» отличается низкой определенностью, разворот тренда может быть подтвержден черной свечой либо большим нисходящим гэпом на следующей свече и более низким закрытием. Это то же самое, что «Висельник», только открытие и закрытие идентичны.',
    image: 14
  },
  'Mat Hold Bullish': {
    direction: 'Продолжение бычьего тренда',
    reliability: 'Макс.',
    description: 'Модель «Удержание на татами» аналогична бычьей модели «Три метода». Во время восходящего тренда появляется длинная белая свеча, за которой следуют три свечи с небольшими реальными телами и попадают в короткий нисходящий тренд. На пятой свече «быки» усиливаются и закрываются на новом максимуме. Похоже, что были предприняты попытки развернуть тренд, но они потерпели неудачу. Восходящий тренд продолжится. Модель может быть представлена 2–4 черными свечами.',
    image: 62
  },
  'Gravestone Doji': {
    direction: 'Медвежий разворот',
    reliability: 'Средняя',
    description: 'Во время восходящего тренда происходит гэп вверх. Он поднимается к новому максимуму, затем ослабевает и закрывается вблизи своего минимума: медвежья смена моментума. Подтверждением разворота тренда стал бы нисходящий гэп. Открытие и закрытие идентичны. Модель «Надгробие Доджи» ассоциируется с более высокой надежностью, чем «Падающая звезда». Ее значимость намного возрастает, если она достигает нового максимума.',
    image: 22
  },
  'Morning Star': {
    direction: 'Бычий разворот',
    reliability: 'Макс.',
    description: 'Во время нисходящего тренда рынок усиливает медвежий тренд посредством длинной черной свечи. Вторая свеча торгуется в небольшом диапазоне и закрывается на уровне своего открытия или вблизи него. Такой сценарий в целом свидетельствует о наличии потенциала для ралли, так как изменились многие позиции. Подтверждение развороту дает третья белая свеча. Чем сильнее третье белое тело, тем более значима модель.',
    image: 54
  },
}