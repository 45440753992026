import {useHttp} from "./http.hook";
import {Countries, IDateExpRating, IRatingTicker, IRatingUser, Strategies, TypesOfRating} from "../shared/types";

interface IDataSaveTickerRating {
  message: string,
}

interface IDataSaveDateExpRatingRating {
  message: string,
}

interface IDataGetAll {
  message: string,
  info: IRatingTicker[]
}



interface IDataGetAllDateExpRating {
  message: string,
  info: IDateExpRating[]
}

export const useTickerRating = () => {

  const {loading, request, error, clearError} = useHttp()

  //сохранение рейтинга компании
  const saveTickerRating = async (country: Countries, ticker: string, fund: string, tech: boolean) => {
    try {
      const data: IDataSaveTickerRating = await request('/api/ratings/save-one-rating', 'POST', {
        country: country,
        ticker: ticker,
        rating: fund,
        tech: tech
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //удаление рейтинга компании
  const removeTickerRating = async (country: Countries, ticker: string) => {
    try {
      const data: IDataSaveTickerRating = await request('/api/ratings/remove-one-rating', 'POST', {
        country: country,
        ticker: ticker,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение всех рейтингов компаний
  const getAllTickerRatings = async (country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/ratings', 'POST', {
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //сохранение даты экспирации для рейтинга
  const saveDateExpRating = async (country: Countries, typeOfRating: TypesOfRating, dateExpiration: string) => {
    try {
      const data: IDataSaveDateExpRatingRating = await request('/api/ratings/save-date-expiration-rating', 'POST', {
        country: country,
        typeOfRating: typeOfRating,
        dateExpiration: dateExpiration
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение дат экспирации для всех типов рейтингов
  const getDateExpAllRatings = async (country: Countries) => {
    try {
      const data: IDataGetAllDateExpRating = await request('/api/ratings/date-expiration-rating', 'POST', {
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {
    loading,
    saveTickerRating,
    getAllTickerRatings,
    removeTickerRating,
    saveDateExpRating,
    getDateExpAllRatings,
    error,
    clearError
  }
}