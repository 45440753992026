import React from 'react';
import styles from "./fincondition.module.scss";
import {
  ColorOfFinancialState,
  FinancialState,
  PositionOfFinancialState
} from "../../../../../../../shared/investingComConfig";

interface Props {
  analytic: string
}

const Fincondition = ({analytic}: Props) => {

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <p className={styles.title}>Финансовое состояние</p>
          <div className={styles.indicatorWrap}>
            <p className={styles.value}>
              <span style={{
                backgroundColor: ColorOfFinancialState[analytic as keyof typeof FinancialState],
                left: PositionOfFinancialState[analytic as keyof typeof FinancialState]
              }}>{analytic}</span>
            </p>
            <div className={styles.slider}>
              <div className={styles.svgWrap} style={{
                left: PositionOfFinancialState[analytic as keyof typeof FinancialState]
              }}>
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.003 4.28C.917 2.978 1.843 1 3.539 1h5.73c1.696 0 2.622 1.978 1.537 3.28L7.94 7.718a2 2 0 01-3.073 0L2.003 4.28z"
                    fill="#181C21" stroke="#fff" strokeWidth="2"></path>
                </svg>
              </div>
            </div>
            <div className={styles.colorLine}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fincondition;