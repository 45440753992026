import {Candle, CandleTypePart, DirectionsOfCandle} from "../types";
import {colorOfDojiCandle, colorOfDownCandle, colorOfUpCandle} from "../configCanvas";
import {
  getDirectionOfCandle,
  getMovingAverage,
  getMovingAverageForNumArr,
  getRsi,
  getStochastic,
  getZigZag
} from "./lib";

export interface IWidthCandleOnCanvas {
  bodyWidth: number
  sideWidth: number
  distance: number
  total: number
}

const ColorOfCandle: Record<DirectionsOfCandle, string> = {
  UP: colorOfUpCandle,
  DOWN: colorOfDownCandle,
  DOJI: colorOfDojiCandle,
}

/**
 * Трансформация координат по оси времени, чтобы не слева а справа
 * @param x - координата Х для трансформации
 * @param canvasWidth - ширина canvas
 * @return координата Х после трансформации
 */
export const transformX = (x: number, canvasWidth: number): number => {
  return canvasWidth - x
}

/**
 * Трансформация координат по оси цены, чтобы не сверху а снизу
 * @param y - координата Y для трансформации
 * @param canvasHeight - высота canvas
 * @return координата Y после трансформации
 */
export const transformY = (y: number, canvasHeight: number): number => {
  return canvasHeight - y
}

/**
 * Трансформация ширины по оси времени
 * @param width -   ширина по Х для трансформации
 * @return ширина по Х после трансформации
 */
export const transformWidth = (width: number): number => {
  return 0 - width
}

/**
 * Трансформация высоты по оси цены
 * @param height -   высота по Y для трансформации
 * @return высота по Y после трансформации
 */
export const transformHeight = (height: number): number => {
  return 0 - height
}

/**
 * Определение масштаба по шкале цены
 * @param chart - график
 * @param canvasHeight - высота canvas
 * @param heightTimeScale - высота шкалы времени
 * @return сколько пикселей в единице цены
 */
export const scalePrice = (hightPrice: number, lowPrice: number, canvasHeight: number, heightTimeScale: number) => {
  let scale: number
  scale = (canvasHeight - heightTimeScale) / (hightPrice - lowPrice)
  return scale
}

/**
 * Определение начала отсчета по шкале цены, чтобы шкала начиналась с наименьшего значения на графике, а не с 0
 * @param chart - график
 * @return цена, которая является началом отсчета по шкале цены
 */
export const startOfScalePrice = (chart: Candle[]) => {
  let lowPrice = chart[0][CandleTypePart.low]
  chart.forEach((candle) => {
    if (candle[CandleTypePart.low] < lowPrice) {
      lowPrice = candle[CandleTypePart.low]
    }
  })

  return lowPrice
}

/**
 * Построение бара на canvas
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param scaleXCandle - параметр масштабирования по ширине, для того, чтобы весь график помещался на холсте
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param widthPriceScale - ширина шкалы цен
 * @param heightTimeScale - высота шкалы времени
 * @param candle - данные по свече, которую надо построить
 * @param indexOfCandle - индекс свечи, для расположения свечи по оси времени
 */
export const buildBar = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, scaleXCandle: IWidthCandleOnCanvas, scaleY: number, startOfScaleY: number, offsetLeftBorder: number, widthPriceScale: number, heightTimeScale: number, candle: Candle, indexOfCandle: number) => {
  const open = candle[CandleTypePart.open]
  const close = candle[CandleTypePart.close]
  const high = candle[CandleTypePart.high]
  const low = candle[CandleTypePart.low]
  const direction = getDirectionOfCandle(candle)
  const color = ColorOfCandle[direction]

  //пересчет значений цен свечи в нужный масштаб и с разворотом системы координат и началом системы координат не с нуля а нижней точки графика
  const openTransformed = transformY((open - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const closeTransformed = transformY((close - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const highTransformed = transformY((high - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const lowTransformed = transformY((low - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)

  //нахождение положения оси свечи на временной шкале
  const placeCandleOnTimeAxis = transformX(indexOfCandle * scaleXCandle.total + offsetLeftBorder + widthPriceScale, widthCanvas)

  //рисование тела свечи
  context.beginPath();
  context.moveTo(placeCandleOnTimeAxis, lowTransformed)
  context.lineTo(placeCandleOnTimeAxis, highTransformed)
  context.strokeStyle = color
  context.lineWidth = scaleXCandle.bodyWidth
  context.stroke()

  //рисование линии open
  context.moveTo(placeCandleOnTimeAxis, openTransformed);
  context.lineTo(placeCandleOnTimeAxis - scaleXCandle.sideWidth, openTransformed);
  context.stroke();

  //рисование линии close
  context.moveTo(placeCandleOnTimeAxis, closeTransformed);
  context.lineTo(placeCandleOnTimeAxis + scaleXCandle.sideWidth, closeTransformed);
  context.stroke();
}

/**
 * Построение свечи на canvas
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param scaleXCandle - параметр масштабирования по ширине, для того, чтобы весь график помещался на холсте
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param widthPriceScale - ширина шкалы цен
 * @param heightVolume - высота места под объемы
 * @param candle - данные по свече, которую надо построить
 * @param indexOfCandle - индекс свечи, для расположения свечи по оси времени
 * @param colorOfLineCandle - цвет контура свечи
 */
export const buildCandle = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, scaleXCandle: IWidthCandleOnCanvas, scaleY: number, startOfScaleY: number, offsetLeftBorder: number, widthPriceScale: number, heightVolume: number, candle: Candle, indexOfCandle: number, colorOfLineCandle: string) => {
  const open = candle[CandleTypePart.open]
  const close = candle[CandleTypePart.close]
  const high = candle[CandleTypePart.high]
  const low = candle[CandleTypePart.low]
  const direction = getDirectionOfCandle(candle)
  const color = ColorOfCandle[direction]

  //пересчет значений цен свечи в нужный масштаб и с разворотом системы координат и началом системы координат не с нуля а нижней точки графика
  const openTransformed = transformY((open - startOfScaleY) * scaleY + heightVolume, heightCanvas)
  const closeTransformed = transformY((close - startOfScaleY) * scaleY + heightVolume, heightCanvas)
  const highTransformed = transformY((high - startOfScaleY) * scaleY + heightVolume, heightCanvas)
  const lowTransformed = transformY((low - startOfScaleY) * scaleY + heightVolume, heightCanvas)
  let highBasis: number
  let lowBasis: number
  if (direction === DirectionsOfCandle.UP) {
    highBasis = closeTransformed
    lowBasis = openTransformed
  } else if (direction === DirectionsOfCandle.DOWN) {
    highBasis = openTransformed
    lowBasis = closeTransformed
  } else {
    highBasis = openTransformed
    lowBasis = openTransformed
  }

  //нахождение положения оси свечи на временной шкале
  const placeCandleOnTimeAxis = transformX(indexOfCandle * scaleXCandle.total + offsetLeftBorder + widthPriceScale, widthCanvas)

  //рисование тела свечи
  context.beginPath();
  context.strokeStyle = colorOfLineCandle
  context.fillStyle = color;
  context.lineWidth = scaleXCandle.bodyWidth
  context.strokeRect(placeCandleOnTimeAxis - scaleXCandle.sideWidth, highBasis, scaleXCandle.bodyWidth + scaleXCandle.sideWidth * 2 - 1, lowBasis - highBasis);
  context.fillRect(placeCandleOnTimeAxis - 1, highBasis + 1, 2, lowBasis - highBasis - 2);

  //рисование линии high
  context.moveTo(placeCandleOnTimeAxis, highBasis);
  context.lineTo(placeCandleOnTimeAxis, highTransformed);
  context.stroke();

  //рисование линии low
  context.moveTo(placeCandleOnTimeAxis, lowBasis);
  context.lineTo(placeCandleOnTimeAxis, lowTransformed);
  context.stroke();
}

/**
 * Построение линии на свечах на canvas
 * @param context - контекст canvas
 * @param indStart - индекс свечи с конца графика, которой соответствует начало линии, для расположения точки по оси времени
 * @param priceStart - цена, на которой должно быть расположено начало линии, для расположения точки по оси цен
 * @param indEnd - индекс свечи с конца графика, которой соответствует конец линии, для расположения точки по оси времени
 * @param priceEnd - цена, на которой должен быть расположен конец линии, для расположения точки по оси цен
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param color - цвет линии
 * @param lineWidth - толщина линии
 */
export const buildLine = (context: CanvasRenderingContext2D, indStart: number, priceStart: number, indEnd: number, priceEnd: number, startOfScaleY: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, color: string, lineWidth: number) => {

  //пересчет значений цен точек в нужный масштаб и с разворотом системы координат и началом системы координат не с нуля а нижней точки графика
  const priceStartTransformed = transformY((priceStart - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const priceEndTransformed = transformY((priceEnd - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)

  //нахождение положения точек на временной шкале
  const startOnTimeAxis = transformX(indStart * stepCandleOnScaleX + offsetLeftBorder + widthPriceScale, widthCanvas)
  const endOnTimeAxis = transformX(indEnd * stepCandleOnScaleX + offsetLeftBorder + widthPriceScale, widthCanvas)

  //рисование линии
  context.beginPath();
  context.moveTo(startOnTimeAxis, priceStartTransformed)
  context.lineTo(endOnTimeAxis, priceEndTransformed)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()
}

/**
 * Построение ценовой отметки на оси цен
 * @param context - контекст canvas
 * @param price - цена для отметки на оси
 * @param color - цвет ценовой метки
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте
 * @param widthScale - ширина шкалы цен
 * @param heightTimeScale - высота шкалы времени
 * @param heightCanvas - высота canvas
 * @param xOfAxis - положение оси по Х
 * @param textBaseline - выравнивание текста по вертикали
 */
export const buildPriceStrokeOnAxis = (context: CanvasRenderingContext2D, price: number, color: string, startOfScaleY: number, scaleY: number, widthScale: number, heightTimeScale: number, heightCanvas: number, xOfAxis: number, textBaseline: CanvasTextBaseline = 'middle') => {
  const priceY = transformY((price - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)

  //обрезка длинных значений, чтобы помещались в видимую часть шкалы
  const priceString = shorterNumber(price, 5)

  context.beginPath();
  context.strokeStyle = color
  context.font = "8px Arial"
  context.textAlign = 'left'
  context.textBaseline = textBaseline
  context.fillStyle = color
  context.fillText(priceString, xOfAxis + 5, priceY, widthScale - 5);
  context.moveTo(xOfAxis, priceY)
  context.lineTo(xOfAxis + 3, priceY)
  context.stroke()
}

/**
 * функция обрезки длинных значений, много знаков после запятой округляются, а просто большие числа переводятся в вид K и М
 * @param value - сокращаемое значение
 * @param count - количество знаков должно остаться
 * @return сокращенное значение
 */
const shorterNumber = (value: number, count: number): string => {
  let valueString = value.toString()
  const length = valueString.length
  if (length > 4) {
    if (valueString.includes('.')) {
      //дробные числа
      //возможно потом как-то сократить если будут проблемы
    } else {
      //целые числа
      if (length > 10) {
        let vn = Math.round((value / 1000000000) * 10) / 10
        valueString = vn.toString() + 'Y'
      } else if (length > 7) {
        let vn = Math.round((value / 1000000) * 10) / 10
        valueString = vn.toString() + 'M'
      } else if (length > 4) {
        let vn = Math.round((value / 1000) * 10) / 10
        valueString = vn.toString() + 'K'
      }
    }
  }
  return valueString
}

/**
 * Построение ценовой отметки на оси времени
 * @param context - контекст canvas
 * @param date - дата для отметки на оси
 * @param color - цвет ценовой метки
 * @param candleIndex - индекс свечи по которой строим метку
 * @param scaleXCandle - масштаб по шкале времени в виде размера свечи и расстояния
 * @param widthPriceScale - ширина шкалы цен
 * @param widthCanvas - ширина canvas
 * @param yOfAxis - положение оси по Y
 * @param textAlign - выравнивание текста по горизонтали
 * @param offsetLeftBorder - смещение первой свечи от левого края графика
 */
export const buildTimeStrokeOnAxis = (context: CanvasRenderingContext2D, date: string, color: string, candleIndex: number, scaleXCandle: IWidthCandleOnCanvas, widthPriceScale: number, widthCanvas: number, yOfAxis: number, textAlign: CanvasTextAlign, offsetLeftBorder: number) => {
  const timeX = transformX(candleIndex * scaleXCandle.total + widthPriceScale + offsetLeftBorder, widthCanvas)

  context.beginPath();
  context.strokeStyle = color
  context.font = "8px Arial"
  context.textAlign = textAlign
  context.textBaseline = 'top'
  context.fillStyle = color
  context.fillText(date, timeX, yOfAxis + 5);
  context.moveTo(timeX, yOfAxis)
  context.lineTo(timeX, yOfAxis + 3)
  context.stroke()
}

/**
 * Получение массива круглых цен для простановки на ценовой оси
 * @param highPrice - самая высокая цена на графике
 * @param lowPrice - самая низкая цена на графике
 * @return массив цен
 */
export const getRoundPricesToYAxis = (highPrice: number, lowPrice: number) => {

  const arrPrices: number[] = []
  //допустимое количество ценовых меток
  const minCount = 2
  const maxCount = 10
  const distance = highPrice - lowPrice

  //значения до которых пробуем округлить цены
  const roundNumArr = [100000, 50000, 10000, 5000, 1000, 500, 100, 50, 10, 5, 1, 0.5, 0.1, 0.05, 0.01, 0.005]
  let devider = roundNumArr[0]
  for (let i = 0; i < roundNumArr.length; i++) {
    const num = roundNumArr[i]
    const count = distance / num
    if (count >= minCount && count <= maxCount) {
      devider = num
      break
    }
  }

  //заполняем круглые цены через найденный шаг до максимальной цены на графике
  const roundLowPrice = Math.ceil(lowPrice / devider) * devider
  arrPrices.push(roundLowPrice)
  let i = 1;
  while (roundLowPrice + devider * i < highPrice) {
    const value = Math.round((roundLowPrice + devider * i) / devider) * devider
    arrPrices.push(value)
    i++
  }

  return arrPrices
}

/**
 * Построение оси цен
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas
 * @param heightCanvas - высота canvas
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param widthScale - ширина шкалы
 * @param heightTimeScale - высота шкалы времени
 * @param lineWidth - толщина линии шкалы
 * @param color - цвет линии шкалы
 * @param colorOfCurrentPrice - цвет метки текущей цены
 * @param currentPrice - текущая цена для отметки на оси
 * @param highPrice - самая высокая цена на графике
 * @param lowPrice - самая низкая цена на графике
 */
export const buildYAxis = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, scaleY: number, startOfScaleY: number, widthScale: number, heightTimeScale: number, lineWidth: number, color: string, colorOfCurrentPrice: string, currentPrice: number, highPrice: number, lowPrice: number) => {

  //положение оси по Х
  const xOfAxis = transformX(widthScale, widthCanvas)

  //рисование тела оси
  context.beginPath();
  context.moveTo(xOfAxis, heightCanvas - heightTimeScale)
  context.lineTo(xOfAxis, 0)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()

  //засечка на текущей цене
  buildPriceStrokeOnAxis(context, currentPrice, colorOfCurrentPrice, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis)

  //остальные ценовые засечки
  const arrPrices = getRoundPricesToYAxis(highPrice, lowPrice)
  arrPrices.forEach((price) => {
    const distance = Math.abs(price - currentPrice) * scaleY
    //рисуем штрих цены только если он не ближе 20 пикселей к штриху текущей цены
    if (distance > 20) {
      buildPriceStrokeOnAxis(context, price, color, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis)
    }
  })
}

/**
 * Рисование легенды на графике
 * @param context - контекст canvas
 * @param text - текст легенды
 * @param color - цвет текста
 * heightCanvas - высота canvas
 */
export const buildLegend = (context: CanvasRenderingContext2D, text: string, color: string, heightCanvas: number) => {
  context.beginPath();
  context.font = "12px Open Sans"
  context.textAlign = 'left'
  context.textBaseline = 'top'
  context.fillStyle = color
  context.fillText(text, 100, heightCanvas - 100);
}

/**
 * Рисование вертикального маркера на графике по клику мыши
 * @param context - контекст canvas
 * @param x - координата Х на canvas по которой кликнули
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param color - цвет линии
 * @param lineWidth - толщина линии
 */
export const buildVerticalMarker = (context: CanvasRenderingContext2D, x: number, heightCanvas: number, color: string, lineWidth: number) => {
  context.beginPath();
  context.moveTo(x, 0)
  context.lineTo(x, heightCanvas)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()
}

/**
 * Рисование контрольных уровней на осцилляторе
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param widthPriceScale - ширина шкалы цен
 * @param heightTimeScale - высота шкалы времени
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param colorOfLevel - цвет контрольных уровней
 * @param lineWidth - толщина линии
 * @param level - уровень который нужно нарисовать
 */
export const buildControlLevel = (context: CanvasRenderingContext2D, widthCanvas: number, widthPriceScale: number, heightTimeScale: number, heightCanvas: number, colorOfLevel: string, lineWidth: number, level: number) => {
  const yOfLine = transformY(level + heightTimeScale, heightCanvas)

  context.save();
  context.setLineDash([5, 10]);
  context.beginPath();
  context.moveTo(widthCanvas - widthPriceScale, yOfLine)
  context.lineTo(0, yOfLine)
  context.strokeStyle = colorOfLevel
  context.lineWidth = lineWidth
  context.stroke()
  context.restore();
}

/**
 * Построение оси цен на осцилляторе
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas
 * @param heightCanvas - высота canvas
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param widthScale - ширина шкалы
 * @param heightTimeScale - высота шкалы времени
 * @param lineWidth - толщина линии шкалы
 * @param color - цвет линии шкалы
 * @param colorOfCurrentPrice - цвет метки текущей цены
 * @param currentPrice - текущая цена для отметки на оси
 * @param highPrice - самая высокая цена на графике
 * @param lowPrice - самая низкая цена на графике
 * @param middlePrice - опционально, средняя цена на графике, на некоторых индикаторах нужна отметка 0 как середина, например
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены, чаще всего это 0, но для осевых осцилляторов это отрицательное значение
 */
export const buildYAxisOscillator = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, scaleY: number, widthScale: number, heightTimeScale: number, lineWidth: number, color: string, colorOfCurrentPrice: string, currentPrice: number, highPrice: number, lowPrice: number, middlePrice?: number, startOfScaleY: number = 0) => {

  //положение оси по Х
  const xOfAxis = transformX(widthScale, widthCanvas)

  //рисование тела оси
  context.beginPath();
  context.moveTo(xOfAxis, heightCanvas - heightTimeScale)
  context.lineTo(xOfAxis, 0)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()

  //верхняя и нижняя ценовые засечки
  buildPriceStrokeOnAxis(context, highPrice, color, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis, 'top')
  buildPriceStrokeOnAxis(context, lowPrice, color, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis, 'bottom')
  if (middlePrice !== undefined) {
    buildPriceStrokeOnAxis(context, middlePrice, color, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis, 'bottom')
  }

  //засечка на текущей цене
  //рисуем штрих цены только если он не ближе 15 пикселей к штриху верхней или нижней цены
  const distanceHigh = Math.abs(highPrice - currentPrice) * scaleY
  const distanceLow = Math.abs(lowPrice - currentPrice) * scaleY
  let distanceMiddle = 100
  if (middlePrice !== undefined) {
    distanceMiddle = Math.abs(middlePrice - currentPrice) * scaleY
  }
  if (distanceHigh > 15 && distanceLow > 15 && distanceMiddle > 15) {
    buildPriceStrokeOnAxis(context, currentPrice, colorOfCurrentPrice, startOfScaleY, scaleY, widthScale, heightTimeScale, heightCanvas, xOfAxis)
  }
}

/**
 * Построение оси времени на основном графике
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas
 * @param heightCanvas - высота canvas
 * @param heightScale - высота шкалы
 * @param widthPriceScale - ширина шкалы цены
 * @param lineWidth - толщина линии шкалы
 * @param color - цвет линии шкалы
 * @param colorOfCurrentPrice - цвет метки текущей цены
 * @param scaleXCandle - масштаб по шкале времени в виде размера свечи и расстояния
 * @param candles - информация по свечам графика
 * @param offsetLeftBorder - смещение первой свечи от левого края графика
 */
export const buildXAxis = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, heightScale: number, widthPriceScale: number, lineWidth: number, color: string, colorOfCurrentPrice: string, scaleXCandle: IWidthCandleOnCanvas, candles: Candle[], offsetLeftBorder: number) => {
  //положение оси по Y
  const yOfAxis = transformY(heightScale, heightCanvas)

  //рисование тела оси
  context.beginPath();
  context.moveTo(widthCanvas - widthPriceScale, yOfAxis)
  context.lineTo(0, yOfAxis)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()

  //временные засечки
  const indexOfFirstCandleInCanvas = 0
  const indexOfFirstCandleInCandles = candles.length - 1 - indexOfFirstCandleInCanvas
  let indexOfLastCandleInCanvas = Math.floor((widthCanvas - widthPriceScale - offsetLeftBorder) / scaleXCandle.total) - 1
  //Это на случай если свечей меньше чем может вместиться на canvas
  if (indexOfLastCandleInCanvas > candles.length - 1) {
    indexOfLastCandleInCanvas = candles.length - 1
  }
  const indexOfLastCandleInCandles = candles.length - 1 - indexOfLastCandleInCanvas
  const indexOfMiddleCandleInCanvas = Math.floor(indexOfLastCandleInCanvas / 2)
  const indexOfMiddleCandleInCandles = candles.length - 1 - indexOfMiddleCandleInCanvas

  //засечка на текущей дате
  buildTimeStrokeOnAxis(context, candles[indexOfFirstCandleInCandles][CandleTypePart.end], colorOfCurrentPrice, indexOfFirstCandleInCanvas, scaleXCandle, widthPriceScale, widthCanvas, yOfAxis, 'end', offsetLeftBorder)
  //засечка примерно на середине шкалы
  buildTimeStrokeOnAxis(context, candles[indexOfMiddleCandleInCandles][CandleTypePart.end], color, indexOfMiddleCandleInCanvas, scaleXCandle, widthPriceScale, widthCanvas, yOfAxis, 'center', offsetLeftBorder)
  //засечка в конце шкалы
  buildTimeStrokeOnAxis(context, candles[indexOfLastCandleInCandles][CandleTypePart.end], color, indexOfLastCandleInCanvas, scaleXCandle, widthPriceScale, widthCanvas, yOfAxis, 'start', offsetLeftBorder)
}

/**
 * Построение оси времени на осцилляторе
 * @param context - контекст canvas
 * @param widthCanvas - ширина canvas
 * @param heightCanvas - высота canvas
 * @param heightScale - высота шкалы
 * @param widthPriceScale - ширина шкалы цены
 * @param lineWidth - толщина линии шкалы
 * @param color - цвет линии шкалы
 */
export const buildXAxisOscillator = (context: CanvasRenderingContext2D, widthCanvas: number, heightCanvas: number, heightScale: number, widthPriceScale: number, lineWidth: number, color: string) => {
  //положение оси по Y
  const yOfAxis = transformY(heightScale, heightCanvas)

  //рисование тела оси
  context.beginPath();
  context.moveTo(widthCanvas - widthPriceScale, yOfAxis)
  context.lineTo(0, yOfAxis)
  context.strokeStyle = color
  context.lineWidth = lineWidth
  context.stroke()
}

/**
 * Построение индикатора зигзаг
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param delta - параметр для построения зигзага
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param color - цвет линий зигзага
 * @param lineWidth - толщина линии
 */
export const buildZigzag = (context: CanvasRenderingContext2D, candles: Candle[], delta: number, startOfScaleY: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, color: string, lineWidth: number) => {
  //расчет зигзага
  const zigzag = getZigZag(candles, delta)

  zigzag.forEach((node, index) => {
    const indOfLastCandle = candles.length - 1

    const startPointIndCandleInCanvas = indOfLastCandle - node.ind
    const startPointPrice = node.price
    let endPointIndCandleInCanvas: number
    let endPointPrice: number
    if (zigzag[index + 1]) {
      endPointIndCandleInCanvas = indOfLastCandle - zigzag[index + 1].ind
      endPointPrice = zigzag[index + 1].price
    } else {
      return
    }

    buildLine(context, startPointIndCandleInCanvas, startPointPrice, endPointIndCandleInCanvas, endPointPrice, startOfScaleY, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
  })
}

/**
 * Построение скользящей средней
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param periodMA - длинна скользящей средней
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param color - цвет линий
 * @param lineWidth - толщина линии
 */
export const buildMovingAverage = (context: CanvasRenderingContext2D, candles: Candle[], periodMA: number, startOfScaleY: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, color: string, lineWidth: number) => {
  //расчет скользящей средней
  const movingAverage = getMovingAverage(candles, periodMA)

  const indOfLastCandle = candles.length - 1
  movingAverage.forEach((value, index) => {
    const startPointIndCandleInCanvas = indOfLastCandle - index
    const startPointPrice = movingAverage[index]
    let endPointIndCandleInCanvas: number
    let endPointPrice: number
    if (movingAverage[index + 1]) {
      endPointIndCandleInCanvas = startPointIndCandleInCanvas - 1
      endPointPrice = movingAverage[index + 1]
    } else {
      return
    }

    buildLine(context, startPointIndCandleInCanvas, startPointPrice, endPointIndCandleInCanvas, endPointPrice, startOfScaleY, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
  })
}

/**
 * Построение точек разворота-пивот
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param scaleXCandle - параметр масштабирования по ширине, для того, чтобы весь график помещался на холсте
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param colorOfLevel - цвет основной линии
 * @param colorOfResist - цвет линий сопротивления
 * @param colorOfSupport - цвет линий поддержки
 * @param lineWidth - толщина линии
 */
export const buildPivot = (context: CanvasRenderingContext2D, candles: Candle[], startOfScaleY: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, scaleXCandle: IWidthCandleOnCanvas, offsetLeftBorder: number, colorOfLevel: string, colorOfResist: string, colorOfSupport: string, lineWidth: number) => {

  //свеча предыдущего периода старшего таймфрейма для расчета точек Pivot
  const candle = candles[candles.length - 2]
  const high = candle[CandleTypePart.high]
  const low = candle[CandleTypePart.low]
  const close = candle[CandleTypePart.close]

  //определение уровней
  const level = (high + low + close) / 3
  const r1 = (2 * level) - low
  const r2 = level + (high - low)
  const r3 = high + 2 * (level - low)
  const s1 = (2 * level) - high
  const s2 = level - (high - low)
  const s3 = low - 2 * (high - level)

  //положение уровней по оси Y
  const yOfLevel = transformY((level - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfR1 = transformY((r1 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfR2 = transformY((r2 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfR3 = transformY((r3 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfS1 = transformY((s1 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfS2 = transformY((s2 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)
  const yOfS3 = transformY((s3 - startOfScaleY) * scaleY + heightTimeScale, heightCanvas)

  //минимальный уроверь для рисования, выше уроня оси времени, так как некоторые урони иногда забираются сразу под ось времени
  const minLevel = transformY(heightTimeScale + 5, heightCanvas)

  //положение линий на временной шкале
  const startOnTimeAxis = transformX(30 * scaleXCandle.total + offsetLeftBorder + widthPriceScale, widthCanvas)
  const endOnTimeAxis = transformX(0 * scaleXCandle.total + offsetLeftBorder + widthPriceScale, widthCanvas)

  //рисование основного уровня
  context.beginPath();
  context.moveTo(startOnTimeAxis, yOfLevel)
  context.lineTo(endOnTimeAxis, yOfLevel)
  context.strokeStyle = colorOfLevel
  context.lineWidth = lineWidth
  context.stroke()
  //рисование уровней сопротивления
  context.beginPath();
  context.moveTo(startOnTimeAxis + 20, yOfR1)
  context.lineTo(endOnTimeAxis, yOfR1)
  context.strokeStyle = colorOfResist
  context.stroke()
  context.moveTo(startOnTimeAxis + 40, yOfR2)
  context.lineTo(endOnTimeAxis, yOfR2)
  context.stroke()
  context.moveTo(startOnTimeAxis + 60, yOfR3)
  context.lineTo(endOnTimeAxis, yOfR3)
  context.stroke()
  //рисование уровней поддержки
  context.beginPath();
  context.strokeStyle = colorOfSupport
  if (yOfS1 < minLevel) {
    context.moveTo(startOnTimeAxis + 20, yOfS1)
    context.lineTo(endOnTimeAxis, yOfS1)
    context.stroke()
  }
  if (yOfS2 < minLevel) {
    context.moveTo(startOnTimeAxis + 40, yOfS2)
    context.lineTo(endOnTimeAxis, yOfS2)
    context.stroke()
  }
  if (yOfS3 < minLevel) {
    context.moveTo(startOnTimeAxis + 60, yOfS3)
    context.lineTo(endOnTimeAxis, yOfS3)
    context.stroke()
  }
}

/**
 * Построение индикатора объема в виде баров
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param colorUpCandle - цвет линий на растущей свече
 * @param colorDownCandle - цвет линий на падающей свече
 * @param colorDojiCandle - цвет линий на doji свече
 * @param colorLegend - цвет легенды
 * @param lineWidth - толщина линии
 */
export const buildVolume = (context: CanvasRenderingContext2D, candles: Candle[], scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, colorUpCandle: string, colorDownCandle: string, colorDojiCandle: string, colorLegend: string, lineWidth: number) => {
  const indOfLastCandle = candles.length - 1
  candles.forEach((candle, index) => {
    const startPointIndCandleInCanvas = indOfLastCandle - index
    const startPointPrice = 0
    const endPointIndCandleInCanvas = indOfLastCandle - index
    const endPointPrice = candle[CandleTypePart.volume]
    let color = colorDojiCandle
    if (candle[CandleTypePart.open] < candle[CandleTypePart.close]) {
      color = colorUpCandle
    } else if (candle[CandleTypePart.open] > candle[CandleTypePart.close]) {
      color = colorDownCandle
    }

    buildLine(context, startPointIndCandleInCanvas, startPointPrice, endPointIndCandleInCanvas, endPointPrice, 0, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
  })
}

/**
 * Построение индикатора объема в виде свечей
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param colorUpCandle - цвет линий на растущей свече
 * @param colorDownCandle - цвет линий на падающей свече
 * @param colorDojiCandle - цвет линий на doji свече
 * @param colorLegend - цвет легенды
 * @param lineWidth - толщина линии
 */
export const buildVolumeCandle = (context: CanvasRenderingContext2D, candles: Candle[], scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, colorUpCandle: string, colorDownCandle: string, colorDojiCandle: string, colorLegend: string, lineWidth: number) => {
  const indOfLastCandle = candles.length - 1
  candles.forEach((candle, index) => {
    const startPointIndCandleInCanvas = indOfLastCandle - index
    const startPointPrice = 0
    const endPointIndCandleInCanvas = indOfLastCandle - index
    const endPointPrice = candle[CandleTypePart.volume]
    let color = colorDojiCandle
    if (candle[CandleTypePart.open] < candle[CandleTypePart.close]) {
      color = colorUpCandle
    } else if (candle[CandleTypePart.open] > candle[CandleTypePart.close]) {
      color = colorDownCandle
    }

    buildLine(context, startPointIndCandleInCanvas, startPointPrice, endPointIndCandleInCanvas, endPointPrice, 0, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
  })
}

/**
 * Построение меток над свечами, по которым есть свечные модели
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param countCandles - количество свечей на canvas
 * @param arrNumberOfCandlesWithModel - номера свечей по которым есть модели
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightVolume - высота места под объемы
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param widthPriceScale - ширина шкалы цен
 * @param color - цвет метки

 */
export const buildMarkUnderCandleWithModels = (context: CanvasRenderingContext2D, candles: Candle[], countCandles: number, arrNumberOfCandlesWithModel: number[], startOfScaleY: number, scaleY: number, heightVolume: number, heightCanvas: number, widthCanvas: number, stepCandleOnScaleX: number, offsetLeftBorder: number, widthPriceScale: number, color: string) => {
  const indOfLastCandle = candles.length - 1

  //проход по всем отображаемым на canvas свечам
  for (let i = indOfLastCandle - countCandles + 1; i <= indOfLastCandle; i++) {
    const newInd = indOfLastCandle - i
    //проверка, есть ли по этой свече модель
    if (arrNumberOfCandlesWithModel.includes(newInd)) {
      const priceHigh = candles[i][CandleTypePart.high]

      //пересчет значений цен точек в нужный масштаб и с разворотом системы координат и началом системы координат не с нуля а нижней точки графика
      const priceHighTransformed = transformY((priceHigh - startOfScaleY) * scaleY + heightVolume + 4, heightCanvas)

      //нахождение положения точки на временной шкале
      const pointOnTimeAxis = transformX(newInd * stepCandleOnScaleX + offsetLeftBorder + widthPriceScale, widthCanvas)

      //рисование метки
      context.beginPath();
      context.moveTo(pointOnTimeAxis - 2, priceHighTransformed - 2)
      context.lineTo(pointOnTimeAxis, priceHighTransformed)
      context.lineTo(pointOnTimeAxis + 2, priceHighTransformed - 3)
      context.strokeStyle = color
      context.lineWidth = 1
      context.stroke()
    }
  }
}

/**
 * Построение осциллятора RSI
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param periodOfRsi - период rsi
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param color - цвет линий
 * @param colorLegend - цвет легенды
 * @param colorControlLevel - цвет контрольных уровней
 * @param lineWidth - толщина линии
 */
export const buildRsi = (context: CanvasRenderingContext2D, candles: Candle[], periodOfRsi: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, color: string, colorLegend: string, colorControlLevel: string, lineWidth: number) => {
  //расчет rsi
  const rsi = getRsi(candles, periodOfRsi)

  const indOfLastCandle = candles.length - 1
  candles.forEach((candle, index) => {
    if (index !== 0) {
      const startPointIndCandleInCanvas = indOfLastCandle - index
      const startPointPrice = rsi[index - 1]
      let endPointIndCandleInCanvas: number
      let endPointPrice: number
      if (rsi[index]) {
        endPointIndCandleInCanvas = startPointIndCandleInCanvas - 1
        endPointPrice = rsi[index]
      } else {
        return
      }

      buildLine(context, startPointIndCandleInCanvas, startPointPrice, endPointIndCandleInCanvas, endPointPrice, 0, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
    }
  })

  //контрольные уровни
  buildControlLevel(context, widthCanvas, widthPriceScale, heightTimeScale, heightCanvas, colorControlLevel, lineWidth, 20)
  buildControlLevel(context, widthCanvas, widthPriceScale, heightTimeScale, heightCanvas, colorControlLevel, lineWidth, 80)
}

/**
 * Построение осциллятора Stochastic
 * @param context - контекст canvas
 * @param candles - информация по свечам графика
 * @param periodOfStochastic - период Stochastic
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param color - цвет линий
 * @param colorMoving - цвет линий сглаживания
 * @param colorLegend - цвет легенды
 * @param colorControlLevel - цвет контрольных уровней
 * @param lineWidth - толщина линии
 */
export const buildStochastic = (context: CanvasRenderingContext2D, candles: Candle[], periodOfStochastic: number, scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, color: string, colorMoving: string, colorLegend: string, colorControlLevel: string, lineWidth: number) => {
  //расчет Stochastic и линии сглаживания
  const stochastic = getStochastic(candles, periodOfStochastic)
  const stochasticMa = getMovingAverageForNumArr(stochastic, 3)

  const indOfLastCandle = candles.length - 1
  candles.forEach((candle, index) => {
    if (index !== 0) {
      const startPointIndCandleInCanvas = indOfLastCandle - index
      const startPointPriceStoch = stochastic[index - 1]
      const startPointPriceMoving = stochasticMa[index - 1]
      let endPointIndCandleInCanvas: number
      let endPointPriceStoch: number
      let endPointPriceMoving: number
      if (stochastic[index]) {
        endPointIndCandleInCanvas = startPointIndCandleInCanvas - 1
        endPointPriceStoch = stochastic[index]
        endPointPriceMoving = stochasticMa[index]
      } else {
        return
      }

      buildLine(context, startPointIndCandleInCanvas, startPointPriceStoch, endPointIndCandleInCanvas, endPointPriceStoch, 0, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, color, lineWidth)
      buildLine(context, startPointIndCandleInCanvas, startPointPriceMoving, endPointIndCandleInCanvas, endPointPriceMoving, 0, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorMoving, lineWidth)
    }
  })

  //контрольные уровни
  buildControlLevel(context, widthCanvas, widthPriceScale, heightTimeScale, heightCanvas, colorControlLevel, lineWidth, 20)
  buildControlLevel(context, widthCanvas, widthPriceScale, heightTimeScale, heightCanvas, colorControlLevel, lineWidth, 80)
}

/**
 * Построение осциллятора MACD
 * @param context - контекст canvas
 * @param macd - значения индикатора MACD
 * @param macdSignal - значения сигнальной линии индикатора MACD
 * @param macdHistogram - значения индикатора MACD Histogram
 * @param scaleY - параметр масштабирования по высоте, для того, чтобы весь график помещался на холсте
 * @param heightCanvas - высота canvas, нужна для трансформации системы координат
 * @param widthCanvas - ширина canvas, нужна для трансформации системы координат
 * @param heightTimeScale - высота шкалы времени
 * @param widthPriceScale - ширина шкалы цен
 * @param stepCandleOnScaleX - шаг свечей на графике в пикселях
 * @param offsetLeftBorder - смещение в пикселях начала графика от оси цен
 * @param colorMacd - цвет линий Macd
 * @param colorSignalMacd - цвет линий SignalMacd
 * @param colorMacdHistogram - цвет линий MacdHistogram
 * @param colorLegend - цвет легенды
 * @param lineWidth - толщина линии
 * @param startOfScaleY - цена, которая является началом отсчета по шкале цены, чаще всего это 0, но для осевых осцилляторов это отрицательное значение
 */
export const buildMacd = (context: CanvasRenderingContext2D, macd: number[], macdSignal: number[], macdHistogram: number[], scaleY: number, heightCanvas: number, widthCanvas: number, heightTimeScale: number, widthPriceScale: number, stepCandleOnScaleX: number, offsetLeftBorder: number, colorMacd: string, colorSignalMacd: string, colorMacdHistogram: string, colorLegend: string, lineWidth: number, startOfScaleY: number) => {

  const indOfLastCandle = macd.length - 1
  macd.forEach((value, index) => {
    const startPointIndCandleInCanvas = indOfLastCandle - index
    const startPointPriceMacd = value
    const startPointPriceSignal = macdSignal[index]
    const startPointPriceHistogram = 0
    let endPointIndCandleInCanvas: number
    let endPointPriceMacd: number
    let endPointPriceSignal: number
    let endPointPriceHistogram = macdHistogram[index]

    buildLine(context, startPointIndCandleInCanvas, startPointPriceHistogram, startPointIndCandleInCanvas, endPointPriceHistogram, startOfScaleY, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorMacdHistogram, lineWidth)

    if (macd[index + 1]) {
      endPointIndCandleInCanvas = startPointIndCandleInCanvas - 1
      endPointPriceMacd = macd[index + 1]
      endPointPriceSignal = macdSignal[index + 1]
    } else {
      return
    }

    buildLine(context, startPointIndCandleInCanvas, startPointPriceMacd, endPointIndCandleInCanvas, endPointPriceMacd, startOfScaleY, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorMacd, lineWidth)
    buildLine(context, startPointIndCandleInCanvas, startPointPriceSignal, endPointIndCandleInCanvas, endPointPriceSignal, startOfScaleY, scaleY, heightCanvas, widthCanvas, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorSignalMacd, lineWidth)
  })
}

