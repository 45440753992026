import {useHttp} from "./http.hook";
import {Countries, IToOrder} from "../shared/types";

interface IDataSaveEnteredRequest {
  message: string,
}

interface IDataGetAll {
  message: string,
  info: IToOrder[]
}

export const useRequest = () => {

  const {loading, request, error, clearError} = useHttp()

  //сохранение данных по выставленной заявке
  const saveEnteredRequest = async (country: Countries, requestData: IToOrder) => {
    try {
      const data: IDataSaveEnteredRequest = await request('/api/requests/save-one-request', 'POST', {
        country: country,
        enteredRequest: requestData,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //удаление выставленной заявки
  const removeEnteredRequest = async (country: Countries, requestData: IToOrder) => {
    try {
      const data: IDataSaveEnteredRequest = await request('/api/requests/remove-one-request', 'POST', {
        country: country,
        deletedRequest: requestData,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение всех выставленных заявок
  const getAllEnteredRequests = async (country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/requests', 'POST', {
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, saveEnteredRequest, getAllEnteredRequests, removeEnteredRequest, error, clearError}
}