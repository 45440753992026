import React, {useEffect, useState} from 'react';
import styles from "./analyst.module.scss";
import {Countries, IRatingUser, TypesOfRating} from "../../../shared/types";
import {v4 as uuidv4} from "uuid";
import {Button, DatePicker, DatePickerProps, message as messageAnt} from "antd";
import dayjs from "dayjs";
import {useTickerRating} from "../../../hooks/tickerRating.hook";
import {useAppSelector} from "../../../store/store";
import {useMembersRating} from "../../../hooks/membersRating.hook";

const Analyst = () => {

  const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const {loading: loadingRating, saveDateExpRating, error: errorRating, clearError: clearErrorRating} = useTickerRating()
  const {loading: loadingMembersRating, saveMembersRating, getAllMembersRating, getAllMembersRatingFromSite, error: errorMembersRating, clearError: clearErrorMembersRating} = useMembersRating()

  const country = useAppSelector(state => state.countryReduser.country)
  const tickerList = useAppSelector(state => state.countryReduser.tickerList)

  const [ratingListReceived, setRatingListReceived] = useState<IRatingUser[]>()//новые рейтинги, все без отбора эффективных, полученные с сайта investing.com
  const [ratingListForSave, setRatingListForSave] = useState<IRatingUser[]>()//новые рейтинги, отфильтрованные по эффективности, которые нужно сохранить в БД
  const [ratingList, setRatingList] = useState<IRatingUser[]>()//уже существующие в БД рейтинги
  const [dateToSetDateExp, setDateToSetDateExp] = useState<dayjs.Dayjs | null>(null)
  const [setDateExpBtnDisabled, setSetDateExpBtnDisabled] = useState(true)//блокировка кнопки настройки даты экспирации рейтинга если не все необходимые данные выбраны
  const [needUpdateRatingList, setNeedUpdateRatingList] = useState(true)
  const [btnGetRatingsIsLoading, setBtnGetRatingsIsLoading] = useState<boolean>(false);//состояние кнопки Получить
  const [btnProcessRatingsIsLoading, setBtnProcessRatingsIsLoading] = useState<boolean>(false);//состояние кнопки Обработать
  const [btnSaveRatingsIsLoading, setBtnSaveRatingsIsLoading] = useState<boolean>(false);//состояние кнопки Сохранить

  //выбор даты для установки даты экспирации
  const onChangeDateExp: DatePickerProps['onChange'] = (date, dateString) => {
    setDateToSetDateExp(date);
  };

  //функция для сохранение даты экспирации для рейтинга в БД
  const saveDateExpOfRating = async (country: Countries, typeOfRating: TypesOfRating, dateExpirationOfRating: string) => {
    try {
      const data = await saveDateExpRating(country, typeOfRating, dateExpirationOfRating)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
    }
  }

  //установка даты экспирации для списка рейтинга
  const saveDateExpHandler = async () => {
    if (dateToSetDateExp && country) {
      const dateString = dateToSetDateExp.format('YYYY-MM-DD')
      await saveDateExpOfRating(country, TypesOfRating.MEMB, dateString)
      setDateToSetDateExp(null)
    }
  }

  //управление активностью кнопки установки даты экспирации рейтинга
  useEffect(() => {
    if (dateToSetDateExp) {
      setSetDateExpBtnDisabled(false)
    } else {
      setSetDateExpBtnDisabled(true)
    }
  }, [dateToSetDateExp])

  //получить новые рейтинги пользователей с сайта investing.com
  const getRatingsHandler = async () => {
    const areYouSure = window.confirm(`Вы уверены, что хотите обновить рейтинги? Очень длительный процесс!`)
    if (!areYouSure) {
      return
    }
    if (country && tickerList) {
      try {
        setBtnGetRatingsIsLoading(true)
        const data = await getAllMembersRatingFromSite(country, tickerList)
        messageAnt.info(data.message)
        const ratings = data.info
        setRatingListReceived(ratings)
      } catch (e) {
        messageAnt.info(errorRating);
        clearErrorRating()
      } finally {
        setBtnGetRatingsIsLoading(false)
      }
    }
  }

  //обработать рейтинги - оставить только список эффективных участников рейтингов
  const processRatingsHandler = async () => {
    if (ratingListReceived && ratingListReceived.length !== 0) {
      setBtnProcessRatingsIsLoading(true)
      await delay(500)

      //сборка массива без дублей имен, где по каждому участнику будет его суммарный результат со всех тикеров по которым он голосовал
      const listWithTotalResult: IRatingUser[] = []
      const tempArrNames: string[] = []
      ratingListReceived.forEach((member) => {
        if (!tempArrNames.includes(member.name)) {
          tempArrNames.push(member.name)
          listWithTotalResult.push(member)
        } else {
          const indOldElem = tempArrNames.indexOf(member.name)
          const newTotal = Number(listWithTotalResult[indOldElem].total) + Number(member.total)
          const newClose = Number(listWithTotalResult[indOldElem].close) + Number(member.close)
          const newHappily = Number(listWithTotalResult[indOldElem].happily) + Number(member.happily)
          const newSuccess = Math.round(((newHappily / newClose) * 100) * 100) / 100
          //очищаем значение delta от лишних символов: может быть +2.52%, 0%, -29.73%
          const oldDelta = listWithTotalResult[indOldElem].delta[0] === '+' ? Number(listWithTotalResult[indOldElem].delta.slice(1, -1)) : Number(listWithTotalResult[indOldElem].delta.slice(0, -1))
          const newDelta = member.delta[0] === '+' ? Number(member.delta.slice(1, -1)) : Number(member.delta.slice(0, -1))
          const totalDeltaNumber = Math.round((oldDelta + newDelta) * 100) / 100
          const totalDelta = totalDeltaNumber > 0 ? '+' + totalDeltaNumber + '%' : '' + totalDeltaNumber + '%'

          tempArrNames.splice(indOldElem, 1)
          listWithTotalResult.splice(indOldElem, 1)
          tempArrNames.push(member.name)
          listWithTotalResult.push({
            name: member.name,
            total: newTotal.toString(),
            close: newClose.toString(),
            happily: newHappily.toString(),
            success: newSuccess.toString(),
            delta: totalDelta,
          })
        }
      })

      //отбираем только эффективных пользователей: total > 10; close > 80 % total; success > 70; delta приведенная к number > 20
      const listOnlyEffective: IRatingUser[] = []
      listWithTotalResult.forEach((member) => {
        const total = Number(member.total)
        const close = Number(member.close)
        const success = Number(member.success)
        //очищаем значение от лишних символов: может быть +2.52%, 0%, -29.73%
        const delta = member.delta[0] === '+' ? Number(member.delta.slice(1, -1)) : Number(member.delta.slice(0, -1))
        if (
          total >= 10 &&
          close >= total * 0.8 &&
          success >= 70 &&
          delta >= 7
        ) {
          listOnlyEffective.push(member)
        }
      })

      setRatingListForSave(listOnlyEffective)
      setBtnProcessRatingsIsLoading(false)
    }
  }

  //функция для сохранение списка рейтингов в БД
  const saveRatings = async (country: Countries, ratingList: IRatingUser[]) => {
    try {
      setBtnSaveRatingsIsLoading(true)
      const data = await saveMembersRating(country, ratingList)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorMembersRating);
      clearErrorMembersRating()
    } finally {
      setBtnSaveRatingsIsLoading(false)
    }
  }

  //сохранить новый список эффективных участников рейтингов
  const saveRatingsHandler = async () => {
    if (country && ratingListForSave && ratingListForSave.length !== 0) {
      await saveRatings(country, ratingListForSave)
      setNeedUpdateRatingList(true)
    }
  }

  //получение данных по рейтингам пользователей из БД
  const getAllRatings = async () => {
    try {
      const data = await getAllMembersRating()
      messageAnt.info(data.message)
      const ratings = data.info
      return ratings
    } catch (e) {
      messageAnt.info(errorMembersRating);
      clearErrorMembersRating()
      return undefined
    }
  }

  //изначальное получение данных по рейтингам пользователей из БД
  useEffect(() => {
    if (needUpdateRatingList) {
      const get = async () => {
        const allRatings = await getAllRatings()
        if (allRatings) {
          setRatingList(allRatings)
        }
        setNeedUpdateRatingList(false)
      }
      get()
    }
  }, [needUpdateRatingList])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <h5>Отбор эффективно прогнозирующих пользователей</h5>
          {
            !country &&
            <p className={styles.noCountry}>Не выбрана страна на главной странице!</p>
          }
          {
            country &&
            <div>
              <div className={[styles.dateOfExpiration, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Установка даты экспирации рейтингов</p>
                <DatePicker placeholder="Дата экспирации" onChange={onChangeDateExp} style={{width: 165}}
                            value={dateToSetDateExp}/>
                <Button title={'Установить дату экспирации рейтинга'} type={'primary'} onClick={saveDateExpHandler}
                        style={{width: 165}} disabled={setDateExpBtnDisabled}>Установить</Button>
              </div>
              <div className={[styles.addToRating, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Обновление рейтингов</p>
                <Button title={'Получить'} type={'primary'} onClick={getRatingsHandler}
                        style={{width: 165}}
                        loading={btnGetRatingsIsLoading}
                >Получить</Button>
                <Button title={'Обработать'} type={'primary'} onClick={processRatingsHandler}
                        style={{width: 165}} disabled={!ratingListReceived}
                        loading={btnProcessRatingsIsLoading}
                >Обработать</Button>
                <Button title={'Сохранить'} type={'primary'} onClick={saveRatingsHandler}
                        style={{width: 165}} disabled={!ratingListForSave}
                        loading={btnSaveRatingsIsLoading}
                >Сохранить</Button>
              </div>
              {
                ratingList ?
                  <div className={[styles.received, styles.blockWrap].join(' ')}>
                    <p className={styles.title}>Список пользователей попавших в рейтинг как наиболее эффективные</p>
                    <div className={styles.headTable}>
                      <p className={styles.name}>Имя</p>
                      <p className={styles.total}>Всего</p>
                      <p className={styles.close}>Закрыто</p>
                      <p className={styles.happily}>Удачно</p>
                      <p className={styles.success}>Успех %</p>
                      <p className={styles.delta}>Изм. %</p>
                    </div>
                    <div className={styles.bodyTable}>
                      {
                        ratingList.map((ratingElem, index) => (
                          <div className={styles.lineTable} key={uuidv4()}>
                            <p className={styles.name}>{ratingElem.name}</p>
                            <p className={styles.total}>{ratingElem.total}</p>
                            <p className={styles.close}>{ratingElem.close}</p>
                            <p className={styles.happily}>{ratingElem.happily}</p>
                            <p className={styles.success}>{ratingElem.success}</p>
                            <p className={styles.delta}>{ratingElem.delta}</p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Analyst;