import React, {useEffect, useState} from 'react';
import styles from "./sentiment.module.scss";
import {
  ColorOfDirectionValues,
  ColorOfSentiment,
} from "../../../../../../../shared/investingComConfig";

interface Props {
  analytic: { direction: string, absolute: string, relative: string }
}

const Sentiment = ({analytic}: Props) => {

  const [colorOfRelative, setColorOfRelative] = useState<string>()

  //определение цвета значения справедливой цены
  useEffect(() => {
    const valueString = analytic.relative
    if (valueString[0] === '-') {
      setColorOfRelative(ColorOfDirectionValues.negative)
    } else {
      setColorOfRelative(ColorOfDirectionValues.positive)
    }
  }, [analytic])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <p className={styles.title}>Анализ настроений</p>
          <p className={styles.value}>
            <span className={[styles.valueValue, styles.direction].join(' ')}
                  style={{
                    color: ColorOfSentiment[analytic.direction as keyof typeof Sentiment]
                  }}>
              {analytic.direction}
            </span>
          </p>
          <p className={styles.value}>
            <span className={styles.valueName}>Целевая цена:</span>
            <span className={styles.valueValue}>{analytic.absolute}</span>
          </p>
          <p className={styles.value}>
            <span className={styles.valueName}>рост:</span>
            <span className={[styles.valueValue, styles.grow].join(' ')} style={{color: colorOfRelative}}>
              {analytic.relative}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sentiment;