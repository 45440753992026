import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from "./strategiesWrap.module.scss";
import {Button, Input, message as messageAnt, Select, SelectProps} from "antd";
import {
  Candle, Countries, IElemAllStrategyTransform, ISeriesOfTransaction, StrategyLabelFlip, StrategyObject,
  TimeFrames
} from "../../../shared/types";
import {Strategies, StrategyLabel} from "../../../shared/types";
import {useHistory} from "../../../hooks/history.hook";
import {testInImitationRealTimeMode, transformAllSettings} from "../../../shared/lib/lib";
import TestReport from "../testReport/TestReport";
import {tickerNameList, tickerNameListAmerica} from "../../../shared/tickerNameList";
import {useSettings} from "../../../hooks/settings.hook";
import {useHttp} from "../../../hooks/http.hook";

interface Props {
  country: Countries
}

const StrategiesWrap = ({country}: Props) => {

  const {loading, request, error, clearError} = useHistory()
  const {request: requestHttp, clearError: clearErrorHttp} = useHttp()
  const {loading: loadingSettings, getAllSettings, saveSettingsForStrategy, error: errorSettings, clearError: clearErrorSettings} = useSettings()

  const [strategyForTesting, setStrategyForTesting] = useState<Strategies | undefined>()
  const [strategySetReadyToOpen, setStrategySetReadyToOpen] = useState<boolean>(false)
  const [tickerForTesting, setTickerForTesting] = useState<string>('')
  const [timeframeForTesting, setTimeframeForTesting] = useState<TimeFrames | undefined>()
  const [allSettings, setAllSettings] = useState<Record<Strategies, IElemAllStrategyTransform> | undefined>()
  const [optionsSelectStrategy, setOptionsSelectStrategy] = useState<SelectProps['options']>()
  const [optionsSelectTicker, setOptionsSelectTicker] = useState<SelectProps['options']>()//массив тикеров, доступных для выбора
  const [optionsSelectTimeframe, setOptionsSelectTimeframe] = useState<SelectProps['options']>()//массив таймфреймов, доступных для выбора
  const [history, setHistory] = useState('')//полученная с сервера вся история по выбранному тикеру
  const [isOpen, setIsOpen] = useState(false)//включение запуска теста стратегии
  const [openSetBtnDisabled, setOpenSetBtnDisabled] = useState(true)//блокировка кнопки открытия настроек если не все необходимые данные выбраны
  const [startTestBtnDisabled, setStartTestBtnDisabled] = useState(true)//блокировка кнопки запуска теста если не все необходимые данные выбраны
  const [isTesting, setIsTesting] = useState(false)//индикатор процесса тестирования, отображается на кнопке Старт теста

  //поля тонких настроек актуальных для выбранной стратегии
  const [setting0, setSetting0] = useState<string>('')
  const [setting1, setSetting1] = useState<string>('')
  const [setting2, setSetting2] = useState<string>('')
  const [setting3, setSetting3] = useState<string>('')
  const [setting4, setSetting4] = useState<string>('')
  const [setting5, setSetting5] = useState<string>('')
  const [setting6, setSetting6] = useState<string>('')
  const [setting7, setSetting7] = useState<string>('')
  const [setting8, setSetting8] = useState<string>('')
  const [setting9, setSetting9] = useState<string>('')
  const [setting10, setSetting10] = useState<string>('')
  const [setting11, setSetting11] = useState<string>('')
  const [setting12, setSetting12] = useState<string>('')
  const [setting13, setSetting13] = useState<string>('')
  const [setting14, setSetting14] = useState<string>('')
  const [setting15, setSetting15] = useState<string>('')
  const [setting16, setSetting16] = useState<string>('')
  const [setting17, setSetting17] = useState<string>('')
  const [setting18, setSetting18] = useState<string>('')
  const [setting19, setSetting19] = useState<string>('')
  const [settings, setSettings] = useState<string[]>([])

  //массивы исторических свечей по таймфреймам //[["open", "close", "high", "low", "value", "volume", "begin", "end"], ]
  const [infoTfM10, setInfoTfM10] = useState<Candle[]>()
  const [infoTfM60, setInfoTfM60] = useState<Candle[]>()
  const [infoTfDay, setInfoTfDay] = useState<Candle[]>()
  const [infoTfWeek, setInfoTfWeek] = useState<Candle[]>()
  const [infoTfMonth, setInfoTfMonth] = useState<Candle[]>()

  //массив тестовых сделок совершенных по алгоритму стратегии
  const [series, setSeries] = useState<Array<ISeriesOfTransaction>>()

  //получение точных настроек для всех стратегий из БД
  const getSettings = async () => {
    try {
      const data = await getAllSettings()
      messageAnt.info(data.message)
      const settings = transformAllSettings(data.info)
      return settings
    } catch (e) {
      messageAnt.info(errorSettings);
      clearErrorSettings()
      return undefined
    }
  }

  //изначальное получение настроек для стратегий с сервера
  useEffect(() => {
    const get = async () => {
      const settings = await getSettings()
      setAllSettings(settings)
    }
    get()
  }, [])

  //изначальное заполнение полей настроек выбранной для настройки стратегии
  useEffect(() => {
    if (allSettings && strategyForTesting) {
      let sett0 = ''
      let sett1 = ''
      let sett2 = ''
      let sett3 = ''
      let sett4 = ''
      let sett5 = ''
      let sett6 = ''
      let sett7 = ''
      let sett8 = ''
      let sett9 = ''
      let sett10 = ''
      let sett11 = ''
      let sett12 = ''
      let sett13 = ''
      let sett14 = ''
      let sett15 = ''
      let sett16 = ''
      let sett17 = ''
      let sett18 = ''
      let sett19 = ''
      if (allSettings[StrategyLabelFlip[strategyForTesting]].settings !== '') {
        sett0 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[0]]
        sett1 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[1]]
        sett2 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[2]]
        sett3 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[3]]
        sett4 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[4]]
        sett5 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[5]]
        sett6 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[6]]
        sett7 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[7]]
        sett8 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[8]]
        sett9 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[9]]
        sett10 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[10]]
        sett11 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[11]]
        sett12 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[12]]
        sett13 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[13]]
        sett14 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[14]]
        sett15 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[15]]
        sett16 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[16]]
        sett17 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[17]]
        sett18 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[18]]
        sett19 = JSON.parse(allSettings[StrategyLabelFlip[strategyForTesting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[19]]
      }
      setSetting0(sett0)
      setSetting1(sett1)
      setSetting2(sett2)
      setSetting3(sett3)
      setSetting4(sett4)
      setSetting5(sett5)
      setSetting6(sett6)
      setSetting7(sett7)
      setSetting8(sett8)
      setSetting9(sett9)
      setSetting10(sett10)
      setSetting11(sett11)
      setSetting12(sett12)
      setSetting13(sett13)
      setSetting14(sett14)
      setSetting15(sett15)
      setSetting16(sett16)
      setSetting17(sett17)
      setSetting18(sett18)
      setSetting19(sett19)
    }
  }, [allSettings, strategyForTesting])

  const changeSetting0Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting0(e.target.value)
  }
  const changeSetting1Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting1(e.target.value)
  }
  const changeSetting2Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting2(e.target.value)
  }
  const changeSetting3Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting3(e.target.value)
  }
  const changeSetting4Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting4(e.target.value)
  }
  const changeSetting5Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting5(e.target.value)
  }
  const changeSetting6Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting6(e.target.value)
  }
  const changeSetting7Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting7(e.target.value)
  }
  const changeSetting8Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting8(e.target.value)
  }
  const changeSetting9Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting9(e.target.value)
  }
  const changeSetting10Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting10(e.target.value)
  }
  const changeSetting11Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting11(e.target.value)
  }
  const changeSetting12Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting12(e.target.value)
  }
  const changeSetting13Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting13(e.target.value)
  }
  const changeSetting14Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting14(e.target.value)
  }
  const changeSetting15Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting15(e.target.value)
  }
  const changeSetting16Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting16(e.target.value)
  }
  const changeSetting17Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting17(e.target.value)
  }
  const changeSetting18Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting18(e.target.value)
  }
  const changeSetting19Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting19(e.target.value)
  }
  useEffect(() => {
    setSettings([setting0, setting1, setting2, setting3, setting4, setting5, setting6, setting7, setting8, setting9, setting10, setting11, setting12, setting13, setting14, setting15, setting16, setting17, setting18, setting19])
  }, [setting0, setting1, setting2, setting3, setting4, setting5, setting6, setting7, setting8, setting9, setting10, setting11, setting12, setting13, setting14, setting15, setting16, setting17, setting18, setting19])

  //формирование списков для селекторов выбора
  useEffect(() => {
    const optionsStrategy: SelectProps['options'] = []
    Object.entries(StrategyLabel).forEach(([key, value]) => {
      optionsStrategy.push({value: value, label: value})
    })
    setOptionsSelectStrategy(optionsStrategy)
    const optionsTicker: SelectProps['options'] = []
    if (country === Countries.RU) {
      Object.keys(tickerNameList).forEach((ticker) => {
        optionsTicker.push({value: ticker, label: ticker})
      })
    } else if (country === Countries.US) {
      Object.keys(tickerNameListAmerica).forEach((ticker) => {
        optionsTicker.push({value: ticker, label: ticker})
      })
    }
    setOptionsSelectTicker(optionsTicker)
    const optionsTimeframe: SelectProps['options'] = []
    Object.keys(TimeFrames).forEach((timeFrame) => {
      //пропускаем таймфрейм Month, т.к. на этом таймфрейме автоматически не работаю, он только для ручного стратегического анализа по компаниям
      if (timeFrame !== TimeFrames.month) {
        optionsTimeframe.push({value: timeFrame, label: timeFrame})
      }
    })
    setOptionsSelectTimeframe(optionsTimeframe)
  }, [StrategyLabel, tickerNameList, TimeFrames])

  //выбор стратегии для тестирования
  const onChangeStrategy = (value: string) => {
    setIsOpen(false)
    setStrategyForTesting(value as Strategies)
  };
  //выбор инструмента для тестирования
  const onChangeTicker = (value: string) => {
    setIsOpen(false)
    setInfoTfM10(undefined)
    setInfoTfM60(undefined)
    setInfoTfDay(undefined)
    setInfoTfWeek(undefined)
    setInfoTfMonth(undefined)
    setTickerForTesting(value)
  };
  //выбор таймфрейма для тестирования
  const onChangeTimeframe = (value: string) => {
    setIsOpen(false)
    setTimeframeForTesting(value as TimeFrames)
  };

  //получение истории с сервера для обработки в стратегии
  useEffect(() => {
    if (tickerForTesting && isOpen) {
      const getHistory = async () => {
        try {
          const data = await request(country, tickerForTesting)
          messageAnt.info(data.message)
          setHistory(data.info)
        } catch (e) {
          messageAnt.info(error);
          clearError()
        }
      }
      getHistory()
    }
  }, [tickerForTesting, isOpen, country])

  //разбивка истории по таймфреймам
  useEffect(() => {
    if (history) {
      const info = JSON.parse(history)
      setInfoTfM10((info[TimeFrames.m10] && info[TimeFrames.m10] !== 'нет данных') ? JSON.parse(info[TimeFrames.m10]) : [])
      setInfoTfM60((info[TimeFrames.m60] && info[TimeFrames.m60] !== 'нет данных') ? JSON.parse(info[TimeFrames.m60]) : [])
      setInfoTfDay((info[TimeFrames.day] && info[TimeFrames.day] !== 'нет данных') ? JSON.parse(info[TimeFrames.day]) : [])
      setInfoTfWeek((info[TimeFrames.week] && info[TimeFrames.week] !== 'нет данных') ? JSON.parse(info[TimeFrames.week]) : [])
      setInfoTfMonth((info[TimeFrames.month] && info[TimeFrames.month] !== 'нет данных') ? JSON.parse(info[TimeFrames.month]) : [])
    }
  }, [history])

  //управление активностью кнопки открытия настроек
  useEffect(() => {
    if (strategyForTesting && tickerForTesting && timeframeForTesting) {
      setOpenSetBtnDisabled(false)
    } else {
      setOpenSetBtnDisabled(true)
    }
  }, [strategyForTesting, tickerForTesting, timeframeForTesting])

  //управление активностью кнопки запуска теста
  useEffect(() => {
    if (strategyForTesting) {
      const settings = StrategyObject[StrategyLabelFlip[strategyForTesting]].settings
      const settingsKeys = Object.keys(settings)
      if (
        (setting0 || settings[settingsKeys[0]] === '') &&
        (setting1 || settings[settingsKeys[1]] === '') &&
        (setting2 || settings[settingsKeys[2]] === '') &&
        (setting3 || settings[settingsKeys[3]] === '') &&
        (setting4 || settings[settingsKeys[4]] === '') &&
        (setting5 || settings[settingsKeys[5]] === '') &&
        (setting6 || settings[settingsKeys[6]] === '') &&
        (setting7 || settings[settingsKeys[7]] === '') &&
        (setting8 || settings[settingsKeys[8]] === '') &&
        (setting9 || settings[settingsKeys[9]] === '') &&
        (setting10 || settings[settingsKeys[10]] === '') &&
        (setting11 || settings[settingsKeys[11]] === '') &&
        (setting12 || settings[settingsKeys[12]] === '') &&
        (setting13 || settings[settingsKeys[13]] === '') &&
        (setting14 || settings[settingsKeys[14]] === '') &&
        (setting15 || settings[settingsKeys[15]] === '') &&
        (setting16 || settings[settingsKeys[16]] === '') &&
        (setting17 || settings[settingsKeys[17]] === '') &&
        (setting18 || settings[settingsKeys[18]] === '') &&
        (setting19 || settings[settingsKeys[19]] === '')
      ) {
        setStartTestBtnDisabled(false)
      } else {
        setStartTestBtnDisabled(true)
      }
    }
  }, [setting0, setting1, setting2, setting3, setting4, setting5, setting6, setting7, setting8, setting9, setting10, setting11, setting12, setting13, setting14, setting15, setting16, setting17, setting18, setting19])

  //если все поля заполнены и нажата кнопка Открыть то разрешаем открыть выбранную стратегию
  useEffect(() => {
    if (isOpen && tickerForTesting && timeframeForTesting && strategyForTesting && (infoTfM10?.length || infoTfM60?.length || infoTfDay?.length || infoTfWeek?.length || infoTfMonth?.length)) {
      setStrategySetReadyToOpen(true)
    } else {
      setStrategySetReadyToOpen(false)
    }
  }, [isOpen, tickerForTesting, timeframeForTesting, strategyForTesting, infoTfM10, infoTfM60, infoTfDay, infoTfWeek, infoTfMonth])

  const openSetTestHandler = () => {
    setIsOpen(true)
  }

  //запуск теста выбранной стратегии c имитацией реалтайм режима
  const startTestHandler = () => {
    if (strategyForTesting && infoTfM10 && infoTfM60 && infoTfDay && infoTfWeek && infoTfMonth && timeframeForTesting && settings) {
      setIsTesting(true)
      setTimeout(async () => {
        const series = await testInImitationRealTimeMode(strategyForTesting, infoTfM10, infoTfM60, infoTfDay, infoTfWeek, infoTfMonth, timeframeForTesting, settings)
        setSeries(series)
        setIsTesting(false)
      }, 1000)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <h5>Тестирование стратегий</h5>
          <div className={[styles.mainSettings, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Настройки данных для теста</p>
            <Select showSearch allowClear placeholder="Стратегия" onChange={onChangeStrategy}
                    options={optionsSelectStrategy} style={{width: 499}}/>
            <Select showSearch allowClear placeholder="Тикер" onChange={onChangeTicker}
                    options={optionsSelectTicker} style={{width: 165}}/>
            <Select showSearch allowClear placeholder="Таймфрейм" onChange={onChangeTimeframe}
                    options={optionsSelectTimeframe} style={{width: 165}}/>
            <Button title={'Запустить тест'} type={'primary'} onClick={openSetTestHandler}
                    loading={loading} style={{width: 165}} disabled={openSetBtnDisabled}>Открыть тест</Button>
          </div>
          {
            !history ?
              null :
              isOpen && !strategySetReadyToOpen && infoTfM10 !== undefined ?
                <p className={styles.tip}>Для выбранных параметров исторические данные отсутствуют</p> :
                strategySetReadyToOpen && strategyForTesting ?
                  <div className={[styles.narrowSettings, styles.blockWrap].join(' ')}>
                    <p className={styles.title}>Точные настройки стратегии</p>
                    <div className={styles.setts}>
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[0]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[0]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[0]]}
                            value={setting0}
                            onChange={changeSetting0Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[1]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[1]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[1]]}
                            value={setting1}
                            onChange={changeSetting1Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[2]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[2]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[2]]}
                            value={setting2}
                            onChange={changeSetting2Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[3]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[3]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[3]]}
                            value={setting3}
                            onChange={changeSetting3Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[4]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[4]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[4]]}
                            value={setting4}
                            onChange={changeSetting4Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[5]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[5]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[5]]}
                            value={setting5}
                            onChange={changeSetting5Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[6]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[6]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[6]]}
                            value={setting6}
                            onChange={changeSetting6Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[7]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[7]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[7]]}
                            value={setting7}
                            onChange={changeSetting7Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[8]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[8]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[8]]}
                            value={setting8}
                            onChange={changeSetting8Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[9]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[9]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[9]]}
                            value={setting9}
                            onChange={changeSetting9Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[10]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[10]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[10]]}
                            value={setting10}
                            onChange={changeSetting10Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[11]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[11]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[11]]}
                            value={setting11}
                            onChange={changeSetting11Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[12]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[12]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[12]]}
                            value={setting12}
                            onChange={changeSetting12Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[13]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[13]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[13]]}
                            value={setting13}
                            onChange={changeSetting13Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[14]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[14]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[14]]}
                            value={setting14}
                            onChange={changeSetting14Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[15]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[15]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[15]]}
                            value={setting15}
                            onChange={changeSetting15Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[16]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[16]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[16]]}
                            value={setting16}
                            onChange={changeSetting16Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[17]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[17]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[17]]}
                            value={setting17}
                            onChange={changeSetting17Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[18]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[18]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[18]]}
                            value={setting18}
                            onChange={changeSetting18Handler}/>
                        </div>
                      }
                      {
                        StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[19]] &&
                        <div>
                          <label
                            htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[19]]}</label>
                          <Input
                            placeholder={StrategyObject[StrategyLabelFlip[strategyForTesting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForTesting]].settings)[19]]}
                            value={setting19}
                            onChange={changeSetting19Handler}/>
                        </div>
                      }
                    </div>
                    <Button title={'Запустить тест'} type={'primary'} onClick={startTestHandler}
                            disabled={startTestBtnDisabled} loading={isTesting} style={{width: 165}}>
                      {!isTesting ? 'Старт теста' : 'Тестирование'}
                    </Button>
                  </div>
                  :
                  null
          }
          {
            series && <TestReport history={infoTfM10} series={series}/>
          }
        </div>
      </div>
    </div>
  );
};

export default StrategiesWrap;