import React from "react";
import {LinkProps, RouteProps} from "react-router-dom";
import Login from "../pages/login/Login";
import Registration from "../pages/registration/Registration";
import Main from "../pages/main/Main";
import Testing from "../pages/testing/Testing";
import Positions from "../pages/positions/Positions";
import Ratings from "../pages/ratings/Ratings";

export enum AppRoutes {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  MAIN = 'main',
  TESTING = 'testing',
  POSITIONS = 'positions',
  RATINGS = 'ratings',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.LOGIN]: '/login',
  [AppRoutes.REGISTRATION]: '/registration',
  [AppRoutes.MAIN]: '/',
  [AppRoutes.TESTING]: '/testing',
  [AppRoutes.POSITIONS]: '/positions',
  [AppRoutes.RATINGS]: '/ratings',
}

export const RouteLabel: Record<AppRoutes, string> = {
  [AppRoutes.LOGIN]: 'Вход',
  [AppRoutes.REGISTRATION]: 'Регистрация',
  [AppRoutes.MAIN]: 'Главная',
  [AppRoutes.TESTING]: 'Тестинг',
  [AppRoutes.POSITIONS]: 'Позиции',
  [AppRoutes.RATINGS]: 'Рейтинги',
}

export const routeConfig: Record<AppRoutes, { routeProps: RouteProps, forAuth: boolean }> = {
  [AppRoutes.LOGIN]: {
    routeProps: {
      path: RoutePath[AppRoutes.LOGIN],
      element: <Login/>,
      children: RouteLabel[AppRoutes.LOGIN]
    },
    forAuth: false
  },
  [AppRoutes.REGISTRATION]: {
    routeProps: {
      path: RoutePath[AppRoutes.REGISTRATION],
      element: <Registration/>,
      children: RouteLabel[AppRoutes.REGISTRATION]
    },
    forAuth: false
  },
  [AppRoutes.MAIN]: {
    routeProps: {
      path: RoutePath[AppRoutes.MAIN],
      element: <Main/>,
      children: RouteLabel[AppRoutes.MAIN]
    },
    forAuth: true
  },
  [AppRoutes.TESTING]: {
    routeProps: {
      path: RoutePath[AppRoutes.TESTING],
      element: <Testing/>,
      children: RouteLabel[AppRoutes.TESTING]
    },
    forAuth: true
  },
  [AppRoutes.POSITIONS]: {
    routeProps: {
      path: RoutePath[AppRoutes.POSITIONS],
      element: <Positions/>,
      children: RouteLabel[AppRoutes.POSITIONS]
    },
    forAuth: true
  },
  [AppRoutes.RATINGS]: {
    routeProps: {
      path: RoutePath[AppRoutes.RATINGS],
      element: <Ratings/>,
      children: RouteLabel[AppRoutes.RATINGS]
    },
    forAuth: true
  },
}
