import React from 'react';
import styles from "./expert.module.scss";
import {IExpertAnalytics, IRatingUser} from "../../../../../../shared/types";
import Fincondition from "./fincondition/Fincondition";
import FairPrice from "./fairPrice/FairPrice";
import Sentiment from "./sentiment/Sentiment";
import ProTips from "./proTips/ProTips";
import UserForecasts from "./userForecasts/UserForecasts";

interface Props {
  active: boolean
  analytic: IExpertAnalytics
  membersRating: IRatingUser[]
}

const Expert = ({active, analytic, membersRating}: Props) => {

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <Fincondition analytic={analytic.financialCondition}/>
          <FairPrice analytic={analytic.fairValue}/>
          <Sentiment analytic={analytic.sentiment}/>
          <ProTips analytic={analytic.proTips}/>
          <UserForecasts analytic={analytic.userForecasts} membersRating={membersRating}/>
        </div>
      </div>
    </div>
  );
};

export default Expert;