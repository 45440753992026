import React, {useMemo, useState} from 'react';
import styles from "./charts.module.scss";
import {Candle, TimeFrames} from "../../../../../../shared/types";
import Chart from "./chart/Chart";

interface Props {
  active: boolean
  specificityOfRecomendsByStrategy: string
  quotes: Record<TimeFrames, Candle[]>
}

const Charts = ({active, specificityOfRecomendsByStrategy, quotes}: Props) => {

  const specificityOfRecomendsByStrategyHighTf: TimeFrames = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).highTf, [specificityOfRecomendsByStrategy])
  const specificityOfRecomendsByStrategyHighZz: number = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).highZz, [specificityOfRecomendsByStrategy])
  const specificityOfRecomendsByStrategyLowTf: TimeFrames = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).lowTf, [specificityOfRecomendsByStrategy])
  const specificityOfRecomendsByStrategyLowZz: number = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).lowZz, [specificityOfRecomendsByStrategy])

  const [highTfIsActive, setHighTfIsActive] = useState(true)

  const toggleScreen = () => {
    const prevState = highTfIsActive
    setHighTfIsActive(!prevState)
  }

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.buttons}>
          <span
            onClick={toggleScreen}
            style={{
              backgroundColor: highTfIsActive ? '#f0fff0' : '#fff0f0',
            }}
            onMouseOver={(event) => event.currentTarget.style.background = highTfIsActive ? '#fff0f0' : '#f0fff0'}
            onMouseOut={(event) => event.currentTarget.style.background = highTfIsActive ? '#f0fff0' : '#fff0f0'}
          >
            {
              highTfIsActive ? 'high TF' : 'low TF'
            }
          </span>
        </div>
        <div className={[styles.wrap, highTfIsActive ? styles.highTfIsActive : ''].join(' ')}>
          <Chart active={active} quotes={quotes} tfByRecomendsStrategy={specificityOfRecomendsByStrategyHighTf}
                 zzByRecomendsStrategy={specificityOfRecomendsByStrategyHighZz}/>
          <Chart active={active} quotes={quotes} tfByRecomendsStrategy={specificityOfRecomendsByStrategyLowTf}
                 zzByRecomendsStrategy={specificityOfRecomendsByStrategyLowZz}/>
        </div>
      </div>
    </div>
  );
};

export default Charts;