import React, {useEffect, useState} from 'react';
import styles from "./userForecasts.module.scss";
import {v4 as uuidv4} from "uuid";
import {IForecastUser, IRatingUser} from "../../../../../../../shared/types";
import {
  ColorOfForecast,
  ForecastCssClasses,
} from "../../../../../../../shared/investingComConfig";
import bullSvg from "../../../../../../../assets/images/bull.svg";
import bearSvg from "../../../../../../../assets/images/bear.svg";

interface Props {
  analytic: IForecastUser[]
  membersRating: IRatingUser[]
}

const UserForecasts = ({analytic, membersRating}: Props) => {

  const [forecast, setForecast] = useState<IForecastUser[]>()//голоса только рейтинговых пользователей
  const [colorOfLine, setColorOfLine] = useState<string[]>()
  const [imagesOfLine, setImagesOfLine] = useState<string[]>()


  //отбор из всех голосов пользователей, только тех, кто есть в рейтингах
  useEffect(() => {
    const arr: IForecastUser[] = []
    const namesRatingUser = membersRating.map(user => user.name)
    analytic.forEach((voice) => {
      if (namesRatingUser.includes(voice.name)) {
        arr.push(voice)
      }
    })
    setForecast(arr)
  }, [])

  //определение цвета для каждой подсказки
  useEffect(() => {
    if (forecast) {
      const colors: string[] = []
      const images: string[] = []
      forecast.forEach((elem) => {
        if (elem.direction.includes(ForecastCssClasses["newSmallBullIcon2"])) {
          colors.push(ColorOfForecast["newSmallBullIcon2"])
          images.push(bullSvg)
        } else if (elem.direction.includes(ForecastCssClasses["newSmallBearIcon2"])) {
          colors.push(ColorOfForecast["newSmallBearIcon2"])
          images.push(bearSvg)
        }
      })
      setColorOfLine(colors)
      setImagesOfLine(images)
    }
  }, [forecast])

  if (!colorOfLine || !imagesOfLine) {
    return null
  }


  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <p className={styles.title}>Прогнозы рейтинговых пользователей</p>
          <div className={styles.headTable}>
            <p className={[styles.date, styles.value].join(' ')}>Дата</p>
            <p className={[styles.name, styles.value].join(' ')}>Имя</p>
            <p className={[styles.direction, styles.value].join(' ')}>Прогноз</p>
            <p className={[styles.sourcePrice, styles.value].join(' ')}>Откр</p>
            <p className={[styles.dateClose, styles.value].join(' ')}>Закр</p>
            <p className={[styles.delta, styles.value].join(' ')}>Изм. %</p>
          </div>
          {
            forecast && forecast.length !== 0 &&
            forecast.map((elem, index) => {
              const date = elem.date
              const name = elem.name
              const direction = imagesOfLine[index]
              const sourcePrice = elem.sourcePrice
              const сlose = elem.dateClose ? elem.dateClose.slice(elem.dateClose.indexOf('@') + 1) : ''
              const delta = elem.delta
              const colorOfDelta = colorOfLine[index]
              return (
                <div className={styles.listElem} key={uuidv4()}>
                  <p className={[styles.date, styles.value].join(' ')}>{date}</p>
                  <p className={[styles.name, styles.value].join(' ')} title={name}>{name}</p>
                  <p className={[styles.direction, styles.value].join(' ')}><img src={direction} alt=""/></p>
                  <p className={[styles.sourcePrice, styles.value].join(' ')}>{sourcePrice}</p>
                  <p className={[styles.dateClose, styles.value].join(' ')}>{сlose}</p>
                  <p className={[styles.delta, styles.value].join(' ')} style={{color: colorOfDelta}}>{delta}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
};

export default UserForecasts;