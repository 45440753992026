import React, {useEffect, useState} from 'react';
import styles from "./testReport.module.scss";
import {Candle, IOrder, ISeriesOfTransaction, ITestReport} from "../../../shared/types";
import {
  getAnnualAmountOrders,
  getAverageAnnualIncome,
  getAverageDrawdownPerOrder,
  getAverageDurationTransaction,
  getAverageIncomeInOrder,
  getMaxDrawdownPerOrder,
  getMaxDurationTransaction,
  getMaxIncomeInOrder,
  getMinDurationTransaction,
  getMinIncomeInOrder,
  getRatioMaxDrawdownPerOrder,
  getRatioMaxDurationTransaction,
  getRatioRandomIncomeInOrder,
  getSchwagerCoefficient,
  getStrategyQualityFactor,
  getIncomeInTest,
  getInvolvementCoefficient, getPossibleIncome, calculateSurrogateCloseTransaction
} from "../../../shared/lib/lib";

interface Props {
  history: Array<Candle> | undefined
  series: Array<ISeriesOfTransaction> | undefined
}

const TestReport = ({history, series}: Props) => {

  const [orders, setOrders] = useState<Array<IOrder>>()

  //для упрощения расчета результатов теста массив серии сделок трансформируем в массив сублимированных сделок
  useEffect(() => {
    const orders: Array<IOrder> = []
    if (series && series?.length !== 0) {
      series.forEach((oneSeries) => {
        const oneOrder = calculateSurrogateCloseTransaction(oneSeries)
        orders?.push({
          enter: {price: oneOrder.enter.price, date: oneOrder.enter.date, value: oneOrder.enter.value},
          exit: {price: oneOrder.exit.price, date: oneOrder.exit.date, value: oneOrder.exit.value}
        })
      })
      setOrders(orders)
    }
  }, [series])

  //отчетные данные по результатам тестирования
  const [testReport, setTestReport] = useState<ITestReport | undefined>()

  //расчет показателей стратегии по массиву тестовых сделок
  useEffect(() => {
    if (orders && orders?.length !== 0 && history && history?.length !== 0) {
      let amountOrders = orders.length
      let annualAmountOrders = getAnnualAmountOrders(history, orders)
      let averageDurationTransaction = getAverageDurationTransaction(orders)
      let minDurationTransaction = getMinDurationTransaction(orders)
      let maxDurationTransaction = getMaxDurationTransaction(orders)
      let incomeInTest = getIncomeInTest(orders)
      let averageIncomeInOrder = getAverageIncomeInOrder(incomeInTest, orders)
      let minIncomeInOrder = getMinIncomeInOrder(orders)
      let maxIncomeInOrder = getMaxIncomeInOrder(orders)
      let averageAnnualIncome = getAverageAnnualIncome(annualAmountOrders, averageIncomeInOrder)
      let averageDrawdownPerOrder = getAverageDrawdownPerOrder(history, orders)
      let maxDrawdownPerOrder = getMaxDrawdownPerOrder(history, orders)
      let ratioMaxDurationTransaction = getRatioMaxDurationTransaction(averageDurationTransaction, maxDurationTransaction)
      let ratioMaxDrawdownPerOrder = getRatioMaxDrawdownPerOrder(averageIncomeInOrder, maxDrawdownPerOrder)
      let ratioRandomIncomeInOrder = getRatioRandomIncomeInOrder(averageIncomeInOrder, maxIncomeInOrder)
      let strategyQualityFactor = getStrategyQualityFactor(ratioMaxDurationTransaction, ratioMaxDrawdownPerOrder, ratioRandomIncomeInOrder)
      let schwagerCoefficient = getSchwagerCoefficient(averageAnnualIncome, maxDrawdownPerOrder)
      let involvementCoefficient = getInvolvementCoefficient(history, orders)
      let possibleIncome = getPossibleIncome(averageAnnualIncome, involvementCoefficient)

      setTestReport({
        averageAnnualIncome,
        amountOrders,
        annualAmountOrders,
        averageDrawdownPerOrder,
        averageDurationTransaction,
        averageIncomeInOrder,
        incomeInTest,
        maxDrawdownPerOrder,
        maxDurationTransaction,
        maxIncomeInOrder,
        minDurationTransaction,
        minIncomeInOrder,
        ratioMaxDrawdownPerOrder,
        ratioMaxDurationTransaction,
        ratioRandomIncomeInOrder,
        schwagerCoefficient,
        strategyQualityFactor,
        involvementCoefficient,
        possibleIncome,
      })
    } else {
      setTestReport(undefined)
    }
  }, [orders, history])

  if (!testReport) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Результаты тестирования</p>
        <p>-------------------------------</p>
        <p className={styles.line}>доходность среднегодовая (доходность) - {testReport?.averageAnnualIncome}%</p>
        <p className={styles.line}>доходность при 100% вовлеченности (возможная доходность)
          - {testReport?.possibleIncome}</p>
        <p className={styles.line}>коэффициент качества стратегии (стабильность)
          - {testReport?.strategyQualityFactor}</p>
        <p className={styles.line}>коэффициент Швагера (безопасность) - {testReport?.schwagerCoefficient}</p>
        <p className={styles.line}>коэффициент не вовлеченности (доп. возможности)
          - {testReport?.involvementCoefficient}</p>
        <p>-------------------------------</p>
        <p className={styles.line}>коэффициент максимальной продолжительности сделки
          - {testReport?.ratioMaxDurationTransaction}</p>
        <p className={styles.line}>коэффициент максимальной просадки в сделке
          - {testReport?.ratioMaxDrawdownPerOrder}</p>
        <p className={styles.line}>коэффициент случайной доходности в сделке
          - {testReport?.ratioRandomIncomeInOrder}</p>
        <p>-------------------------------</p>
        <p className={styles.line}>кол-во сделок в тесте - {testReport?.amountOrders}шт</p>
        <p className={styles.line}>кол-во сделок среднегодовое - {testReport?.annualAmountOrders}шт</p>
        <p className={styles.line}>продолжительность сделки средняя - {testReport?.averageDurationTransaction} дней</p>
        <p className={styles.line}>продолжительность сделки, минимальная - {testReport?.minDurationTransaction} дней</p>
        <p className={styles.line}>продолжительность сделки, максимальная
          - {testReport?.maxDurationTransaction} дней</p>
        <p>-------------------------------</p>
        <p className={styles.line}>доходность в тесте - {testReport?.incomeInTest}%</p>
        <p className={styles.line}>доходность среднегодовая - {testReport?.averageAnnualIncome}%</p>
        <p className={styles.line}>доходность средняя на сделку - {testReport?.averageIncomeInOrder}%</p>
        <p className={styles.line}>доходность минимальная на сделку - {testReport?.minIncomeInOrder}%</p>
        <p className={styles.line}>доходность максимальная на сделку - {testReport?.maxIncomeInOrder}%</p>
        <p>-------------------------------</p>
        <p className={styles.line}>просадка средняя на сделку - {testReport?.averageDrawdownPerOrder}%</p>
        <p className={styles.line}>просадка максимальная на сделку - {testReport?.maxDrawdownPerOrder}%</p>
      </div>
    </div>
  );
};

export default TestReport;