import {StrategyThreeScreens} from "../strategies/threeScreens";
import {StrategyPositiveContextAndDeepCorrection} from "../strategies/positiveContextAndDeepCorrection";
import {StrategyPositiveContextAndStopCorrectionByDoubleBottom} from "../strategies/positiveContextAndStopCorrectionByDoubleBottom";

export enum Strategies {
  THREE_SCREENS = 'THREE_SCREENS',
  POSITIVE_CONTEXT_AND_DEEP_CORRECTION = 'POSITIVE_CONTEXT_AND_DEEP_CORRECTION',
  POSITIVE_CONTEXT_AND_STOP_CORRECTION_BY_DOUBLE_BOTTOM = 'POSITIVE_CONTEXT_AND_STOP_CORRECTION_BY_DOUBLE_BOTTOM',

}

export const StrategyLabel: Record<Strategies, string> = {
  [Strategies.THREE_SCREENS]: 'Стратегия трех экранов',
  [Strategies.POSITIVE_CONTEXT_AND_DEEP_CORRECTION]: 'Не отрицательный контекст и глубокая коррекция',
  [Strategies.POSITIVE_CONTEXT_AND_STOP_CORRECTION_BY_DOUBLE_BOTTOM]: 'Не отрицательный контекст и остановка коррекции двойным дном',

}

export const StrategyLabelFlip: Record<string, Strategies> = {
  'Стратегия трех экранов': Strategies.THREE_SCREENS,
  'Не отрицательный контекст и глубокая коррекция': Strategies.POSITIVE_CONTEXT_AND_DEEP_CORRECTION,
  'Не отрицательный контекст и остановка коррекции двойным дном': Strategies.POSITIVE_CONTEXT_AND_STOP_CORRECTION_BY_DOUBLE_BOTTOM,

}

//сопоставление наименования стратегии с объектом самой стратегии
export const StrategyObject: Record<Strategies, IStrategyClass> = {
  [Strategies.THREE_SCREENS]: new StrategyThreeScreens(),
  [Strategies.POSITIVE_CONTEXT_AND_DEEP_CORRECTION]: new StrategyPositiveContextAndDeepCorrection(),
  [Strategies.POSITIVE_CONTEXT_AND_STOP_CORRECTION_BY_DOUBLE_BOTTOM]: new StrategyPositiveContextAndStopCorrectionByDoubleBottom(),

}

export enum TimeFrames {
  'm10' = 'm10',
  'm60' = 'm60',
  'day' = 'day',
  'week' = 'week',
  'month' = 'month'
}

export const TimeFramesLabel: Record<TimeFrames, string> = {
  [TimeFrames.m10]: 'm10',
  [TimeFrames.m60]: 'm60',
  [TimeFrames.day]: 'Day',
  [TimeFrames.week]: 'Week',
  [TimeFrames.month]: 'Month',
}

export interface IParamsStrategyFunction {
  timeframe: TimeFrames
  settings: string[]
  currentInfoTfM10: Candle[]
  currentInfoTfM60: Candle[]
  currentInfoTfDay: Candle[]
  currentInfoTfWeek: Candle[]
  currentInfoTfMonth: Candle[]
}

export interface IStrategyClass {

  /**
   * Объект настроек для стратегии (ключ настройки: человекопонятное русское название)
   */
  settings: Record<string, string>

  /**
   * Основная шаблонная корневая логика для работы всех стратегий
   * @param params - объект данных, нужных для работы стратегии
   * @param openedSeriesOfTransactions - информация об открытой серии сделок, если они есть
   * @return тип возвращаемого стратегией результата
   */
  main(params: IParamsStrategyFunction, openedSeriesOfTransactions: ISeriesOfTransaction): IResultStrategy

  /**
   * Описание стратегии
   */
  strategyDescription: string

  /**
   * Что значит единичный объем в стратегии в сопоставлении с реальным объемом сделки
   */
  singleVolumeInStrategyDescription: string

}

//интерфейс данных по заявке
export interface IToOrder {
  strategy: Strategies
  ticker: string
  timeframe: TimeFrames
  price: string
  date: string
  actionType: TradeAction
  action: StateOfTrade
  value: number
  specificity: string
}

//интерфейс данных по предлагаемой стратегией заявке передаваемых в попап с аналитикой
export interface IInfoAboutAdviceToAnalyticsPopup {
  ticker: string
  specificity: string
}

//интерфейс внешней аналитики
export interface IAnalytics {
  news: NewsAnalyticsType
  expert: ExpertAnalyticsType
  candles: CandlesAnalyticsType
}

//Объект новостей для аналитики, ключи - тикеры
export type NewsAnalyticsType = Record<string, INewsAnalytics[]>

//интерфейс новостей в аналитике
export interface INewsAnalytics {
  title: string
  link: string
  descr: string
  date: string
  img: string
}

//Объект экспертных мнений для аналитики, ключи - тикеры
export type ExpertAnalyticsType = Record<string, IExpertAnalytics>

//интерфейс экспертных мнений в аналитике
export interface IExpertAnalytics {
  financialCondition: string
  fairValue: { direction: string, absolute: string, relative: string }
  sentiment: { direction: string, absolute: string, relative: string }
  proTips: { content: string, classes: string }[]
  userForecasts: IForecastUser[]
}

//Объект аналитики по графикам, ключи - тикеры
export type CandlesAnalyticsType = Record<string, ICandlesAnalytics[]>

//интерфейс аналитики по графикам
export interface ICandlesAnalytics {
  returnDirection: string
  name: string
  timeframe: string
  reliability: string
  candlesAgo: string
}

//интерфейс возвращаемого стратегией результата если сигнал найден
export interface IResultStrategyFound {
  state: StateOfTrade.NEED_OPEN_SERIES | StateOfTrade.NEED_ADD_ENTER_IN_SERIES | StateOfTrade.NEED_ADD_EXIT_IN_SERIES | StateOfTrade.NEED_CLOSE_SERIES
  price: number
  date: string
  value: number
  specificity: string
}

//интерфейс возвращаемого стратегией результата если сигнал не найден
export interface IResultStrategyNone {
  state: StateOfTrade.NONE
  price: undefined
  date: undefined
  value: undefined
  specificity: undefined
}

//тип возвращаемого стратегией результата
export type IResultStrategy = IResultStrategyNone | IResultStrategyFound

//тип Свечи полученной с сервера
export type Candle = [number, number, number, number, number, number, string, string]

//соответствие параметров Свечи индексам значений внутри Свечи
export enum CandleTypePart {
  open = 0,
  close = 1,
  high = 2,
  low = 3,
  value = 4,
  volume = 5,
  begin = 6,
  end = 7,
}

export interface IExit {
  price: number | null
  date: string
  value: number
}

export interface IEnter {
  price: number
  date: string
  value: number
}

//параметры сделки для массива сделок для теста
export interface IOrder {
  enter: IEnter
  exit: IExit
}

export interface ITransaction {
  price: number | null
  date: string
  value: number
  specificity: string
}

//серия сделок
export interface ISeriesOfTransaction {
  enters: ITransaction[],
  exits: ITransaction[]
}

//отчет по результатам тестирования
export interface ITestReport {
  amountOrders: number //кол-во сделок в тесте
  annualAmountOrders: number //кол-во сделок среднегодовое
  averageDurationTransaction: number //средняя продолжительность сделки в днях
  minDurationTransaction: number //минимальная продолжительность сделки в днях
  maxDurationTransaction: number //максимальная продолжительность сделки в днях
  incomeInTest: number //доходность в тесте в %
  averageIncomeInOrder: number //средняя доходность на сделку в %
  minIncomeInOrder: number //минимальная доходность на сделку в %
  maxIncomeInOrder: number //максимальная доходность на сделку в %
  averageAnnualIncome: number //доходность среднегодовая, %
  averageDrawdownPerOrder: number //средняя просадка на сделку в %
  maxDrawdownPerOrder: number //максимальная просадка на сделку в %
  ratioMaxDurationTransaction: number //коэффициент максимальной продолжительность сделки - отношение средней продолжительности сделки к максимальной продолжительности
  ratioMaxDrawdownPerOrder: number // коэффициент максимальной просадки в сделке - отношение средней доходности сделки к максимальной просадке в сделке
  ratioRandomIncomeInOrder: number // коэффициент случайной доходности в сделке - отношение средней доходности сделки к максимальной доходности в сделке
  strategyQualityFactor: number // коэффициент качества стратегии - среднее арифметическое основных коэффициентов (ratioMaxDurationTransaction, ratioMaxDrawdownPerOrder, ratioRandomIncomeInOrder). Значение около 1, чем больше тем лучше
  schwagerCoefficient: number // коэффициент Швагера - отношение среднегодовой доходности к максимальной просадке в сделке
  involvementCoefficient: number // коэффициент не вовлеченности стратегии, т.е. соотношение свободного времени ко времени в сделке, это время как доп возможность, можно зарабатывать на других стратегиях
  possibleIncome: number // возможная среднегодовая доходность при 100% вовлеченности
}

//интерфейс элемента настроек при получении из БД
export interface IStrategySettings {
  strategy: Strategies
  enable: boolean
  settings: string
}

//интерфейс элемента открытых позиций при получении из БД
export interface IStrategyPositions {
  strategy: Strategies
  positions: string
}

export interface IElemAllStrategyTransform {
  enable: boolean
  settings: string
}

//состояния торговли по стратегиям
export enum StateOfTrade {
  NEED_OPEN_SERIES = 'NEED_OPEN_SERIES',
  NEED_ADD_ENTER_IN_SERIES = 'NEED_ADD_ENTER_IN_SERIES',
  NEED_ADD_EXIT_IN_SERIES = 'NEED_ADD_EXIT_IN_SERIES',
  NEED_CLOSE_SERIES = 'NEED_CLOSE_SERIES',
  NONE = 'NONE',
}

//состояния торговли по стратегиям - заголовки
export const StateOfTradeLabel: Record<StateOfTrade, string> = {
  NEED_OPEN_SERIES: 'Открыть серию сделок',
  NEED_ADD_ENTER_IN_SERIES: 'Добрать в серию сделок',
  NEED_ADD_EXIT_IN_SERIES: 'Убавить серию сделок',
  NEED_CLOSE_SERIES: 'Закрыть серию сделок',
  NONE: 'NONE'
}

//информация об открытых сделках
export const OpenedPositions: Record<Strategies, Record<string, Record<TimeFrames, ISeriesOfTransaction>>> | undefined = undefined

//возможные действия по регистрации сделки
export enum TradeAction {
  'BUY' = 'BUY',
  'SELL' = 'SELL'
}

//типы рекомендаций на сделку от стратегий
export const RecommendationLabel: Record<TradeAction, string> = {
  BUY: 'Купить',
  SELL: 'Продать'
}

//узел индикатора зигзаг
export interface IZigZagNode {
  ind: number,//индекс, соответствующий индексу свечи на графике, по которому рассчитывался зигзаг
  price: number
}

//страны
export enum Countries {
  RU = 'ru',
  US = 'us',
  ANY = 'any',
}

//рейтинг компании
export interface IRatingTicker {
  ticker: string
  rating: string
  tech: boolean
}

//рейтинг пользователей investing.com кто участвует в прогнозировании
export interface IRatingUser {
  name: string
  total: string
  close: string
  happily: string
  success: string
  delta: string
}

//прогнозы пользователей investing.com
export interface IForecastUser {
  date: string,
  name: string,
  direction: string,
  sourcePrice: string,
  dateClose: string,
  delta: string
}

//типы рейтингов
export enum TypesOfRating {
  TECH = 'TECH',//технический
  FUND = 'FUND',//фундаментальный
  MEMB = 'MEMB',//рейтинг прогнозирующих пользователей
}

export const namesOfTypesOfRating: Record<TypesOfRating, string> = {
  TECH: 'Долгосрочный технический',
  FUND: 'Фундаментальный',
  MEMB: 'Рейтинг пользователей'
}

//дата экспирации рейтинга
export interface IDateExpRating {
  country: Countries
  typeOfRating: TypesOfRating
  dateOfExpiration: string
}

//направления свечей
export enum DirectionsOfCandle {
  UP = 'UP',
  DOWN = 'DOWN',
  DOJI = 'DOJI',
}

