import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import authReduser from './redusers/authSlice'
import appReduser from './redusers/appSlice'
import countryReduser from './redusers/countrySlice'

const rootReduser = combineReducers({
  authReduser,
  appReduser,
  countryReduser
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReduser
  })
}

type RootState = ReturnType<typeof rootReduser>
type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
