import React, {useEffect, useState} from 'react';
import styles from "./positions.module.scss";
import PositionsWrap from "./positionsWrap/PositionsWrap";

const Positions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Открытые позиции</p>
        <div className={styles.table}>
          <PositionsWrap/>
        </div>
      </div>
    </div>
  );
};

export default Positions;