import {useNewsAnalytics} from "./newsAnalytics.hook";
import {useExpertAnalytics} from "./expertAnalytics.hook";
import {useCandlesAnalytics} from "./candlesAnalytics.hook";
import {Countries, IAnalytics} from "../shared/types";
import {useState} from "react";
import {useAllAnalytics} from "./allAnalytics.hook";

interface IDataGetAnalytics {
  message: string,
  info: IAnalytics
}

export const useAnalytics = () => {

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const {getNews, loading: loadingNews, error: errorNews, clearError: clearErrorNews} = useNewsAnalytics()
  const {getExpert, loading: loadingExpert, error: errorExpert, clearError: clearErrorExpert} = useExpertAnalytics()
  const {getCandles, loading: loadingCandles, error: errorCandles, clearError: clearErrorCandles} = useCandlesAnalytics()
  const {getAllAnalytics, loading: loadingAllAnalytics, error: errorAllAnalytics, clearError: clearErrorAllAnalytics} = useAllAnalytics()

  //получение всех видов внешней аналитики
  const getAnalytics = async (country: Countries, tickerList: string[]) => {
    setLoading(true)
    try {

      //получение аналитики отдельными запросами
      // const dataNews = await getNews(country, tickerList)
      // const dataExpert = await getExpert(country, tickerList)
      // const dataCandles = await getCandles(country, tickerList)
      // const data: IDataGetAnalytics = {
      //   message: dataCandles.message,
      //   info: {
      //     news: dataNews.info,
      //     expert: dataExpert.info,
      //     candles: dataCandles.info
      //   }
      // }

      //получение аналитики общим запросом как более быстрая альтернатива
      const dataAllAnalytics = await getAllAnalytics(country, tickerList)
      const data: IDataGetAnalytics = {
        message: dataAllAnalytics.message,
        info: {
          news: dataAllAnalytics.info.news,
          expert: dataAllAnalytics.info.expert,
          candles: dataAllAnalytics.info.candles
        }
      }

      return data
    } catch (e) {
      let message = e instanceof Error ? e.message : 'Что-то пошло не так'
      setError(message)
      throw e
    } finally {
      setLoading(false)
    }
  }

  const clearError = () => setError(null)

  return {getAnalytics, loading, error, clearError}
}