import React, {useEffect, useState} from 'react';
import './App.scss';
import {BrowserRouter, Routes, Route, Navigate, NavigateProps} from 'react-router-dom'
import Navbar from "./components/navbar/Navbar";
import {useAppDispatch, useAppSelector} from "./store/store";
import {useAuth} from "./hooks/auth.hook";
import {authSlice} from './store/redusers/authSlice';
import {appSlice} from './store/redusers/appSlice';
import {routeConfig} from "./app/routeConfig";

function App() {

  const dispatch = useAppDispatch()

  const userIdInStore = useAppSelector(state => state.authReduser.userId)
  const tokenInStore = useAppSelector(state => state.authReduser.token)
  const isAllowSound = useAppSelector(state => state.appReduser.allowSound)

  const {auth} = authSlice.actions
  const {allowSound} = appSlice.actions

  const [isAuth, setIsAuth] = useState<boolean>(false)

  const {token, userId, ready} = useAuth()

  //изменение статуса авторизации если в локальном хранилище обнаружен токен
  useEffect(() => {
    if (token && userId && ready) {
      dispatch(auth({userId, token}))
      setIsAuth(true)
    }
  }, [token, userId, ready])

  //изменение статуса авторизации если в сторе обнаружен токен
  useEffect(() => {
    if (userIdInStore && tokenInStore) {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
  }, [userIdInStore, tokenInStore])

  //браузеры не позволяют воспроизводить звуки (мои уведомления) до контакта пользователя со страницей, поэтому разрешаю запускать уведомления только если что-то на странице уже по нажимал иначе вываливается ошибка браузера
  const appClickHandler = () => {
    if (!isAllowSound) {
      dispatch(allowSound(true))
    }
  }

  if (!ready) {
    return null
  }

  return (
    <BrowserRouter>
      <div className="App" onClick={appClickHandler}>
        <Navbar/>
        <div className="wrap">
          {
            !isAuth ?
              <Routes>
                <Route path={routeConfig.registration.routeProps.path}
                       element={routeConfig.registration.routeProps.element}/>
                <Route path={routeConfig.login.routeProps.path} element={routeConfig.login.routeProps.element}/>
                <Route path={'*'} element={<Navigate to={routeConfig.login.routeProps.path as NavigateProps['to']}/>}/>
              </Routes>
              :
              <Routes>
                {
                  Object.values(routeConfig).map(({routeProps, forAuth}) => (
                    forAuth && <Route key={routeProps.path} path={routeProps.path} element={routeProps.element}/>
                  ))
                }
                <Route path={'*'} element={<Navigate to={routeConfig.main.routeProps.path as NavigateProps['to']}/>}/>
              </Routes>
          }
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
