import React from 'react';
import styles from "./registration.module.scss";
import {Button, Form, Input, message as messageAnt} from 'antd';
import {useHttp} from "../../hooks/http.hook";

const Registration = () => {

  const {loading, request, error, clearError} = useHttp()

  //Регистрация пользователя на сервере
  const onFinish = async (values: any) => {
    // try {
    //   const data = await request('/api/auth/register', 'POST', {...values})
    //   messageAnt.info(data.message)
    // } catch (e) {
    //   let message = e instanceof Error? e.message: 'Что-то пошло не так'
    //   messageAnt.info(message)
    //   clearError()
    // }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Регистрация (сейчас закрыта)</p>
        <div className={styles.form}>
          <Form name="registration" labelCol={{span: 8,}} initialValues={{remember: true,}}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off">
            <Form.Item label="Email" name="email"
                       rules={[{required: true, message: 'Пожалуйста введите ваш email',},]}>
              <Input/>
            </Form.Item>

            <Form.Item label="Пароль" name="password"
                       rules={[{required: true, message: 'Пожалуйста введите ваш пароль',},]}>
              <Input.Password/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>Зарегистрироваться</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Registration;