import {useHttp} from "./http.hook";

export const useHistory = () => {

  const {loading, request: requestHttp, error, clearError} = useHttp()

  const request = async (country: string, ticker: string) => {
    try {
      const data = await requestHttp('/api/history/full-info', 'POST', {
        country: country,
        ticker: ticker,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, request, error, clearError}
}