import React from 'react';
import styles from "./login.module.scss";
import {Button, Form, Input, message as messageAnt} from 'antd';
import {useDispatch} from "react-redux";
import {useHttp} from "../../hooks/http.hook";
import {useAuth} from "../../hooks/auth.hook";
import {auth} from "../../store/redusers/authSlice";

const Login = () => {

  const dispatch = useDispatch()

  const {loading, request, error, clearError} = useHttp()
  const {login, logout} = useAuth()

  //Авторизация пользователя на сервере и локальное сохранение результата авторизации
  const onFinish = async (values: any) => {
    try {
      const data = await request('/api/auth/login', 'POST', {...values})
      login(data.token, data.userId)
      dispatch(auth({userId: data.userId, token: data.token}))
      messageAnt.info(data.message)
    } catch (e) {
      let message = e instanceof Error? e.message: 'Что-то пошло не так'
      logout()
      dispatch(auth({userId: null, token: null}))
      messageAnt.info(message)
      clearError()
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Авторизация</p>
        <div className={styles.form}>
          <Form name="registration" labelCol={{span: 8,}} initialValues={{remember: true,}}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off">
            <Form.Item label="Email" name="email"
                       rules={[{required: true, message: 'Пожалуйста введите ваш email',},]}>
              <Input/>
            </Form.Item>

            <Form.Item label="Пароль" name="password"
                       rules={[{required: true, message: 'Пожалуйста введите ваш пароль',},]}>
              <Input.Password/>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>Авторизоваться</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;