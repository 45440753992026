import React, {useEffect, useRef, useState} from 'react';
import styles from "./getData.module.scss";
import Advisors from "../advisors/Advisors";
import Positions from "../positions/Positions";
import {Candle, Countries, TimeFrames, TypesOfRating} from "../../../shared/types";
import {addingActualPriceToCandlesWithDelay} from "../../../shared/lib/lib";
import {message as messageAnt, Tabs} from "antd";
import {useAppSelector} from "../../../store/store";
import {useQuotes} from "../../../hooks/quotes.hook";
import {useTickerRating} from "../../../hooks/tickerRating.hook";
import ColorIndicator, {Color as ColorOfIndicator} from "../../../components/colorIndicator/ColorIndicator";
import dayjs from "dayjs";

type IRatingIsExpired = Record<TypesOfRating, { isExpired: boolean, dateOfExpiration: string }>

//в GetData получаем список актуальных котировок и графиков по всем инструментам, которые попали в фундаментальный и технический рейтинг, и запускаем цикл периодического обновления этих данных
const GetData = () => {

  const isOnAdvisors = useAppSelector(state => state.appReduser.onOffAdvisors)
  const country = useAppSelector(state => state.countryReduser.country)
  const tickerList = useAppSelector(state => state.countryReduser.tickerList)

  const {loading: loadingTickerRating, saveTickerRating, getAllTickerRatings, removeTickerRating, getDateExpAllRatings, error: errorRating, clearError: clearErrorRating} = useTickerRating()
  const {loading, getAllLastQuotes, getAllCharts, error, clearError} = useQuotes()

  const [advisorsIsActive, setAdvisorsIsActive] = useState(true) //активность вкладки Советники
  const [positionsIsActive, setPositionsIsActive] = useState(false) //активность вкладки Позиции

  const [ratingIsExpiredObject, setRatingIsExpiredObject] = useState<IRatingIsExpired>()//информация по актуальности фунд и тех рейтингов
  const [ratingObject, setRatingObject] = useState<Record<string, { fund: string, tech: boolean }>>()//объект с информацией по тех и фунд рейтингам для каждого тикера
  const [tickerListFilteredByRating, setTickerListFilteredByRating] = useState<string[]>()//список тикеров входящих в оба положительных рейтинга
  const [colorIndicatorQuotes, setColorIndicatorQuotes] = useState<ColorOfIndicator>(ColorOfIndicator.gray)//цвет индикатора результата загрузки последних котировок по массиву инструментов
  const [colorIndicatorCharts, setColorIndicatorCharts] = useState<ColorOfIndicator>(ColorOfIndicator.gray)//цвет индикатора результата загрузки графиков с отставанием по массиву инструментов
  const [quotesIsOld, setQuotesIsOld] = useState(true)//индикатор что данные по текущим котировкам устарели
  const [chartsIsOld, setChartsIsOld] = useState(true)//индикатор что данные по графикам устарели
  const [quotes, setQuotes] = useState<Record<string, number>>()//объект с актуальными ценами на данный момент с ru.investing.com, ключ - тикер
  const [charts, setCharts] = useState<Record<string, Record<TimeFrames, Candle[]>>>()//объект с графиками с отставанием с московской биржи или yahoo.finance, ключ - тикер
  const [actualCharts, setActualCharts] = useState<Record<string, Record<TimeFrames, Candle[]>>>()//объект (ключ - тикер) с актуальными графиками, собран из графиков с отставанием и актуальными котировками

  //ссылка на timeout задержки запросов по котировкам, чтобы удалять таймаут перед созданием нового, чтобы не задваивался
  let timeoutQuotes = useRef<NodeJS.Timeout>()
  //ссылка на timeout задержки запросов по графикам, чтобы удалять таймаут перед созданием нового, чтобы не задваивался
  let timeoutCharts = useRef<NodeJS.Timeout>()

  //работа табов
  const itemsTab = [
    {key: 'advisors', label: 'Советники',},
    {key: 'positions', label: 'Позиции',},
  ];
  const changeTabHandler = (key: string) => {
    if (key === 'advisors') {
      if (!advisorsIsActive) {
        setAdvisorsIsActive(true)
        setPositionsIsActive(false)
      }
    } else if (key === 'positions') {
      if (!positionsIsActive) {
        setPositionsIsActive(true)
        setAdvisorsIsActive(false)
      }
    }
  };

  //заполнение списка тикеров, по которым будем работать только теми тикерами, которые прошли отбор по фундаментальному анализу и долгосрочному техническому, т.е. попали в рейтинги
  useEffect(() => {
    if (tickerList && ratingIsExpiredObject && ratingObject) {
      if (!ratingIsExpiredObject.TECH.isExpired && !ratingIsExpiredObject.FUND.isExpired) {
        const tickerArr: string[] = []
        tickerList.forEach((ticker) => {
          if (ratingObject[ticker] && ratingObject[ticker].fund && ratingObject[ticker].tech) {
            tickerArr.push(ticker)
          }
        })
        if (tickerArr.length !== 0) {
          setTickerListFilteredByRating(tickerArr)
        }
      }
    }
  }, [tickerList, ratingIsExpiredObject, ratingObject])

  //получение данных по рейтингам тикеров из БД
  const getAllRatings = async (country: Countries) => {
    try {
      const data = await getAllTickerRatings(country)
      messageAnt.info(data.message)
      const ratings = data.info
      return ratings
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
      return undefined
    }
  }

  //изначальное получение данных по рейтингам тикеров из БД
  useEffect(() => {
    const get = async () => {
      if (country) {
        const allTickerRatings = await getAllRatings(country)
        if (allTickerRatings) {
          const ratingObject: Record<string, { fund: string, tech: boolean }> = {}
          allTickerRatings.forEach((elem) => {
            ratingObject[elem.ticker] = {fund: elem.rating, tech: elem.tech}
          })
          setRatingObject(ratingObject)
        }
      }
    }
    get()
  }, [])

  //получение дат экспирации по рейтингам из БД
  const getDateExpAllRats = async (country: Countries) => {
    try {
      const data = await getDateExpAllRatings(country)
      messageAnt.info(data.message)
      const ratings = data.info
      return ratings
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
      return undefined
    }
  }

  //изначальное получение актуальности рейтингов по датам экспирации
  useEffect(() => {
    const get = async () => {
      if (country) {
        const dateExpAllRats = await getDateExpAllRats(country)
        if (dateExpAllRats) {
          const ratingIsExpired: Partial<IRatingIsExpired> = {}
          dateExpAllRats.forEach((elem) => {
            ratingIsExpired[elem.typeOfRating] = {
              isExpired: dayjs(elem.dateOfExpiration) < dayjs(),
              dateOfExpiration: elem.dateOfExpiration
            }
          })
          setRatingIsExpiredObject(ratingIsExpired as IRatingIsExpired)
        }
      }
    }
    get()
  }, [])

  //сборка актуальных графиков из графиков с отставанием и актуальных котировок
  useEffect(() => {
    if (quotes && charts && country) {
      let actualInfo = JSON.parse(JSON.stringify({}))
      Object.keys(charts).forEach((ticker) => {
        //графики по всем ТФ для тикера
        const chartsForTicker = charts[ticker]
        //актуальная цена по тикеру
        const actualPrice = quotes[ticker]
        Object.keys(chartsForTicker).forEach((timeframe) => {
          const tf = timeframe as TimeFrames
          //массив свечей по тикеру и ТФ
          const candles = chartsForTicker[tf]
          //комбинация массива с опозданием и актуальной цены
          const candlesWithActualPrice = addingActualPriceToCandlesWithDelay(candles, actualPrice, tf, country)
          if (actualInfo[ticker]) {
            actualInfo[ticker][tf] = candlesWithActualPrice
          } else {
            actualInfo[ticker] = {[tf]: candlesWithActualPrice}
          }
        })
      })
      setActualCharts(actualInfo)
    }
  }, [quotes, charts, country])

  //функция получения последних котировок по массиву инструментов
  const getTickersLastQuotes = async (tickerList: string[], country: Countries) => {
    try {
      setColorIndicatorQuotes(ColorOfIndicator.gray)
      const dataQuotes = await getAllLastQuotes(tickerList, country)
      messageAnt.info(dataQuotes.message)
      setQuotes(dataQuotes.info)
      setColorIndicatorQuotes(ColorOfIndicator.green)
    } catch (e) {
      messageAnt.info(error);
      clearError()
      if (!quotes) {
        setColorIndicatorQuotes(ColorOfIndicator.red)
      }
    }
  }

  //функция получения последних графиков по массиву инструментов
  const getTickersAllCharts = async (tickerList: string[], timeframes: string[], country: Countries) => {
    try {
      setColorIndicatorCharts(ColorOfIndicator.gray)
      const dataCharts = await getAllCharts(tickerList, timeframes, country)
      messageAnt.info(dataCharts.message)
      //нужно в charts заменить данные только по обновленным таймфреймам
      const newCharts = dataCharts.info
      if (!charts) {
        setCharts(newCharts)
      } else {
        const oldCharts = JSON.parse(JSON.stringify(charts))
        const updatedTickers = Object.keys(newCharts)
        updatedTickers.forEach((ticker) => {
          const updatedTimeframes = Object.keys(newCharts[ticker])
          updatedTimeframes.forEach((timeframe) => {
            oldCharts[ticker][timeframe] = [...newCharts[ticker][timeframe]]
          })
        })
        setCharts(oldCharts)
      }
      setColorIndicatorCharts(ColorOfIndicator.green)
    } catch (e) {
      messageAnt.info(error);
      clearError()
      if (!charts) {
        setColorIndicatorCharts(ColorOfIndicator.red)
      }
    }
  }

  //периодическое обновление данных по последним котировкам по тикерам попавшим в рейтинги
  useEffect(() => {
    const func = async () => {
      if (isOnAdvisors && charts) {
        if (quotesIsOld) {
          if (ratingIsExpiredObject && !ratingIsExpiredObject.FUND.isExpired && !ratingIsExpiredObject.TECH.isExpired && !ratingIsExpiredObject.MEMB.isExpired) {
            if (tickerListFilteredByRating && country) {
              setQuotesIsOld(false)
              if (timeoutQuotes.current) {
                clearTimeout(timeoutQuotes.current)
              }
              await getTickersLastQuotes(tickerListFilteredByRating, country)
              timeoutQuotes.current = setTimeout(() => {
                setQuotesIsOld(true)
              }, 60 * 1000)
            }
          }
        }
      }
    }
    func()
  }, [quotesIsOld, isOnAdvisors, tickerListFilteredByRating, country, charts, ratingIsExpiredObject])

  //периодическое обновление данных по последним графикам по тикерам попавшим в рейтинги
  useEffect(() => {
    const func = async () => {
      if (isOnAdvisors) {
        if (chartsIsOld) {
          if (ratingIsExpiredObject && !ratingIsExpiredObject.FUND.isExpired && !ratingIsExpiredObject.TECH.isExpired && !ratingIsExpiredObject.MEMB.isExpired) {
            if (tickerListFilteredByRating && country) {
              //первоначально получение графиков - поэтому для всех таймфреймов
              if (!charts) {
                setChartsIsOld(false)
                let timeframes: string[] = Object.keys(TimeFrames)
                await getTickersAllCharts(tickerListFilteredByRating, timeframes, country)
                timeoutCharts.current = setTimeout(() => {
                  setChartsIsOld(true)
                }, 60 * 1000)
              }
              //обновление данных по графикам - только нужные таймфреймы
              else {
                const minutes = dayjs().get('minutes')
                setChartsIsOld(false)
                if (timeoutCharts.current) {
                  clearTimeout(timeoutCharts.current)
                }
                //если по времени нужно обновлять, то обновляем данные по нужным таймфреймам
                if (minutes === 0 || minutes === 30 || minutes === 45) {
                  //обновляем 10 минутки
                  let timeframes: string[] = [TimeFrames.m10]
                  await getTickersAllCharts(tickerListFilteredByRating, timeframes, country)
                  timeoutCharts.current = setTimeout(() => {
                    setChartsIsOld(true)
                  }, 60 * 1000)
                } else if (minutes === 15) {
                  //обновляем часовики и 10 минутки
                  let timeframes: string[] = [TimeFrames.m10, TimeFrames.m60]
                  await getTickersAllCharts(tickerListFilteredByRating, timeframes, country)
                  timeoutCharts.current = setTimeout(() => {
                    setChartsIsOld(true)
                  }, 60 * 1000)
                }
                //если обновлять рано, просто обновляем таймер проверки
                else {
                  timeoutCharts.current = setTimeout(() => {
                    setChartsIsOld(true)
                  }, 60 * 1000)
                }
              }
            }
          }
        }
      }
    }
    func()
  }, [chartsIsOld, isOnAdvisors, tickerListFilteredByRating, country, ratingIsExpiredObject])

  //принудительная загрузка котировок на случай сбоя в автоматической загрузке
  const forcedDownloadQuotes = () => {
    if (colorIndicatorQuotes === ColorOfIndicator.red) {
      const areYouSure = window.confirm(`Принудительная загрузка котировок. Продолжить?`)
      if (!areYouSure) {
        return
      }
      setQuotesIsOld(true)
    }
  }

  //принудительная загрузка графиков на случай сбоя в автоматической загрузке
  const forcedDownloadCharts = () => {
    if (colorIndicatorCharts === ColorOfIndicator.red) {
      const areYouSure = window.confirm(`Принудительная загрузка графиков. Продолжить?`)
      if (!areYouSure) {
        return
      }
      setChartsIsOld(true)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Tabs defaultActiveKey="1" items={itemsTab} onChange={changeTabHandler}/>
        </div>
        <div className={styles.indicators}>
          <ColorIndicator onClick={forcedDownloadCharts} color={colorIndicatorCharts}
                          description={'Индикатор загрузки графиков с отставанием'}/>
          <ColorIndicator onClick={forcedDownloadQuotes} color={colorIndicatorQuotes}
                          description={'Индикатор загрузки последних котировок'}/>
        </div>
        {
          ratingIsExpiredObject && (ratingIsExpiredObject.FUND.isExpired || ratingIsExpiredObject.TECH.isExpired || ratingIsExpiredObject.MEMB.isExpired) ?
            <div className={styles.ratingAttention}>
              <p className={styles.title}>Необходимо актуализировать рейтинги</p>
              <p className={styles.term}>Дата экспирации
                фундаментального: {ratingIsExpiredObject.FUND.dateOfExpiration}</p>
              <p className={styles.term}>Дата экспирации технического: {ratingIsExpiredObject.TECH.dateOfExpiration}</p>
              <p className={styles.term}>Дата экспирации рейтинга прогнозирующих
                пользователей: {ratingIsExpiredObject.MEMB.dateOfExpiration}</p>
            </div> :
            null
        }
        {
          actualCharts && country && ratingIsExpiredObject &&
          !ratingIsExpiredObject.FUND.isExpired && !ratingIsExpiredObject.TECH.isExpired && !ratingIsExpiredObject.MEMB.isExpired &&
          <>
            <Advisors active={advisorsIsActive} quotes={actualCharts} country={country}/>
            <Positions active={positionsIsActive} quotes={actualCharts} country={country}/>
          </>
        }
      </div>
    </div>
  );
};

export default GetData;