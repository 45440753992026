import {
  Candle,
  CandleTypePart,
  Countries,
  DirectionsOfCandle,
  IElemAllStrategyTransform,
  IEnter,
  IOrder,
  IParamsStrategyFunction,
  ISeriesOfTransaction,
  IStrategyPositions,
  IStrategySettings,
  IZigZagNode,
  OpenedPositions,
  StateOfTrade,
  Strategies,
  StrategyLabelFlip,
  StrategyObject,
  TimeFrames
} from "../types";
import moment from "moment";
import {commissionOfBroker, conditionalAverageMarketIncome, minIncomeInOrderForClose} from "../config";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Удобное для восприятия округления значений для отчета о тестировании
 */
export const reportRound = (value: number): number => {
  let valueRounded

  if (value < 1) {
    valueRounded = Math.round(value * 1000) / 1000
  } else if (value < 10) {
    valueRounded = Math.round(value * 100) / 100
  } else if (value < 100) {
    valueRounded = Math.round(value * 10) / 10
  } else {
    valueRounded = Math.round(value)
  }

  return valueRounded
}

/**
 * Проверка свечи на то, что она растущая
 */
export const candleIsUp = (candle: Candle): boolean => {
  if (candle[CandleTypePart.close] > candle[CandleTypePart.open]) {
    return true
  } else {
    return false
  }
}

/**
 * Проверка свечи на то, что она убывающая
 */
export const candleIsDown = (candle: Candle): boolean => {
  if (candle[CandleTypePart.close] < candle[CandleTypePart.open]) {
    return true
  } else {
    return false
  }
}

/**
 * Расчет количества дней между двумя строковыми датами
 */
export const getQuantityDays = (start: string, end: string): number => {
  let quantityDays = (moment(end).unix() - moment(start).unix()) / (60 * 60 * 24)
  return quantityDays
}

/**
 * Расчет продолжительности сделки в днях
 */
export const getDurationTransaction = (order: IOrder): number => {
  let start = order.enter.date
  let end = order.exit.date
  if (start && end) {
    return getQuantityDays(start, end)
  } else {
    return 0
  }
}

/**
 * Расчет доходности сделки в %
 */
export const getIncomeInOrder = (order: IOrder): number => {
  let open = order.enter.price
  let close = order.exit.price
  if (open && close) {
    let openWithCommission = getMinimumClosingPrice(open, commissionOfBroker)
    return (close - openWithCommission) * 100 / open
  } else {
    return 0
  }
}

/**
 * Расчет среднегодового кол-ва сделок
 */
export const getAnnualAmountOrders = (history: Candle[], orders: IOrder[]): number => {
  let startDate = history[0][CandleTypePart.begin]
  let endDate = history[history.length - 1][CandleTypePart.end]
  let quantityDays = getQuantityDays(startDate, endDate)
  let daysPerOrder = quantityDays / orders.length
  let annualAmountOrders = reportRound(365 / daysPerOrder)
  return annualAmountOrders
}

/**
 * Расчет средней продолжительности сделки в днях
 */
export const getAverageDurationTransaction = (orders: IOrder[]): number => {
  let sumDurationAllOrders = 0
  orders.forEach(order => {
    sumDurationAllOrders += getDurationTransaction(order)
  })
  let averageDurationTransaction = reportRound(sumDurationAllOrders / orders.length)
  return averageDurationTransaction
}

/**
 * Расчет минимальной продолжительности сделки в днях
 */
export const getMinDurationTransaction = (orders: IOrder[]): number => {
  let minDurationOrder = getDurationTransaction(orders[0])
  orders.forEach(order => {
    let durationCurrentTransaction = getDurationTransaction(order)
    if (durationCurrentTransaction < minDurationOrder) {
      minDurationOrder = durationCurrentTransaction
    }
  })
  return reportRound(minDurationOrder)
}

/**
 * Расчет максимальной продолжительности сделки в днях
 */
export const getMaxDurationTransaction = (orders: IOrder[]): number => {
  let maxDurationOrder = getDurationTransaction(orders[0])
  orders.forEach(order => {
    let durationCurrentTransaction = getDurationTransaction(order)
    if (durationCurrentTransaction > maxDurationOrder) {
      maxDurationOrder = durationCurrentTransaction
    }
  })
  return reportRound(maxDurationOrder)
}

/**
 * Расчет доходности в тесте в %
 */
export const getIncomeInTest = (orders: IOrder[]): number => {
  let income = 0
  orders.forEach(order => {
    if (order.enter.price !== null && order.exit.price !== null) {//если последняя сделка не закрыта exit.price === null, то ее не берем в учет
      income += getIncomeInOrder(order)
    }
  })
  return reportRound(income)
}

/**
 * Расчет средней доходности на сделку в %
 */
export const getAverageIncomeInOrder = (sumIncome: number, orders: IOrder[]): number => {
  let averageIncome
  //если последняя сделка не закрыта, то ее не берем в учет
  if (orders[orders.length - 1].exit.price === null) {
    averageIncome = reportRound(sumIncome / (orders.length - 1))
  } else {
    averageIncome = reportRound(sumIncome / orders.length)
  }
  return averageIncome
}

/**
 * Расчет минимальной доходности на сделку в %
 */
export const getMinIncomeInOrder = (orders: IOrder[]): number => {
  let minIncome = getIncomeInOrder(orders[0])
  orders.forEach(order => {
    if (order.enter.price !== null && order.exit.price !== null) {//если последняя сделка не закрыта exit.price === null, то ее не берем в учет
      let incomeCurrentTransaction = getIncomeInOrder(order)
      if (incomeCurrentTransaction < minIncome) {
        minIncome = incomeCurrentTransaction
      }
    }
  })
  return reportRound(minIncome)
}

/**
 * Расчет максимальной доходности на сделку в %
 */
export const getMaxIncomeInOrder = (orders: IOrder[]): number => {
  let maxIncome = getIncomeInOrder(orders[0])
  orders.forEach(order => {
    if (order.enter.price !== null && order.exit.price !== null) {//если последняя сделка не закрыта exit.price === null, то ее не берем в учет
      let incomeCurrentTransaction = getIncomeInOrder(order)
      if (incomeCurrentTransaction > maxIncome) {
        maxIncome = incomeCurrentTransaction
      }
    }
  })
  return reportRound(maxIncome)
}

/**
 * Расчет среднегодовой доходности в %
 */
export const getAverageAnnualIncome = (annualAmountOrders: number, averageIncomeInOrder: number): number => {
  let averageAnnualIncome = reportRound(annualAmountOrders * averageIncomeInOrder)
  return averageAnnualIncome
}

/**
 * Расчет средней просадки на сделку в %
 */
export const getAverageDrawdownPerOrder = (history: Candle[], orders: IOrder[]): number => {
  let sumDrawdown = 0
  let indInHistory = 0
  orders.forEach((order, index) => {
    let priceOpen = order.enter.price
    let dateOpen = order.enter.date
    let dateClose = order.exit.date
    let indexOpen = 0
    let indexClose = 0
    for (let i = indInHistory; i < history.length; i++) {
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateOpen) && indexOpen === 0) {
        indexOpen = i
      } else if (indexOpen === 0) {
        continue
      }
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateClose) && indexClose === 0) {
        indexClose = i
        indInHistory = i
        break
      }
    }
    let drawdown
    if (priceOpen && indexOpen && indexClose) {
      let lowest = history[indexOpen][CandleTypePart.low]
      for (let i = indexOpen; i < indexClose + 1; i++) {
        if (history[i][CandleTypePart.low] < lowest) {
          lowest = history[i][CandleTypePart.low]
        }
      }
      drawdown = (priceOpen - lowest) * 100 / priceOpen
      sumDrawdown += drawdown
    }
  })
  let averageDrawdownPerOrder = reportRound(sumDrawdown / orders.length)
  return averageDrawdownPerOrder
}

/**
 * Расчет максимальной просадки на сделку в %
 */
export const getMaxDrawdownPerOrder = (history: Candle[], orders: IOrder[]): number => {
  let maxDrawdown = 0
  let indInHistory = 0
  orders.forEach((order, index) => {
    let priceOpen = order.enter.price
    let dateOpen = order.enter.date
    let dateClose = order.exit.date
    let indexOpen = 0
    let indexClose = 0
    for (let i = indInHistory; i < history.length; i++) {
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateOpen) && indexOpen === 0) {
        indexOpen = i
      } else if (indexOpen === 0) {
        continue
      }
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateClose) && indexClose === 0) {
        indexClose = i
        indInHistory = i
        break
      }
    }
    let drawdown
    if (priceOpen && indexOpen && indexClose) {
      let lowest = history[indexOpen][CandleTypePart.low]
      for (let i = indexOpen; i < indexClose + 1; i++) {
        if (history[i][CandleTypePart.low] < lowest) {
          lowest = history[i][CandleTypePart.low]
        }
      }
      drawdown = (priceOpen - lowest) * 100 / priceOpen
      if (drawdown > maxDrawdown) {
        maxDrawdown = drawdown
      }
    }
  })
  return reportRound(maxDrawdown)
}

/**
 * Расчет коэффициента максимальной продолжительность сделки
 * отношение средней продолжительности сделки к максимальной продолжительности
 */
export const getRatioMaxDurationTransaction = (averageDuration: number, maxDuration: number): number => {
  let ratio = reportRound(averageDuration / maxDuration)
  return ratio
}

/**
 * Расчет коэффициента максимальной просадки в сделке
 * отношение средней доходности сделки к максимальной просадке в сделке
 */
export const getRatioMaxDrawdownPerOrder = (averageIncomeInOrder: number, maxDrawdownPerOrder: number): number => {
  let ratio = reportRound(averageIncomeInOrder / maxDrawdownPerOrder)
  return ratio
}

/**
 * Расчет коэффициента случайной доходности в сделке
 * отношение средней доходности сделки к максимальной доходности в сделке
 */
export const getRatioRandomIncomeInOrder = (averageIncomeInOrder: number, maxIncomeInOrder: number): number => {
  let ratio = reportRound(averageIncomeInOrder / maxIncomeInOrder)
  return ratio
}

/**
 * Расчет коэффициента качества стратегии
 * среднее арифметическое основных коэффициентов (ratioMaxDurationTransaction, ratioMaxDrawdownPerOrder, ratioRandomIncomeInOrder)
 */
export const getStrategyQualityFactor = (ratioMaxDurationTransaction: number, ratioMaxDrawdownPerOrder: number, ratioRandomIncomeInOrder: number): number => {
  let ratio = reportRound((ratioMaxDurationTransaction + ratioMaxDrawdownPerOrder + ratioRandomIncomeInOrder) / 3)
  return ratio
}

/**
 * Расчет коэффициента Швагера
 * отношение среднегодовой доходности к максимальной просадке в сделке
 */
export const getSchwagerCoefficient = (averageAnnualIncome: number, maxDrawdownPerOrder: number): number => {
  let ratio = reportRound(averageAnnualIncome / maxDrawdownPerOrder)
  return ratio
}

/**
 * Расчет коэффициента не вовлеченности
 * показывает сколько времени стратегия находится вне сделок, это время можно использовать для торговли по другим стратегиям,
 * поэтому он показывает позволяет ли стратегия использовать доп возможности с помощью других стратегий и/или на других инструментах
 */
export const getInvolvementCoefficient = (history: Candle[], orders: IOrder[]): number => {
  let ratio
  const totalNumCandles = history.length
  let sumInvolved = 0
  let indInHistory = 0
  orders.forEach((order, index) => {
    let dateOpen = order.enter.date
    let dateClose = order.exit.date
    let indexOpen = 0
    let indexClose = 0
    for (let i = indInHistory; i < history.length; i++) {
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateOpen) && indexOpen === 0) {
        indexOpen = i
      } else if (indexOpen === 0) {
        continue
      }
      if (dayjs(history[i][CandleTypePart.end]) >= dayjs(dateClose) && indexClose === 0) {
        indexClose = i
        indInHistory = i
        break
      }
    }
    if (indexOpen && indexClose) {
      sumInvolved += (indexClose - indexOpen + 1)
    }
  })
  ratio = reportRound((totalNumCandles - sumInvolved) / totalNumCandles)
  return ratio
}

/**
 * Расчет возможной среднегодовой доходности при 100% вовлеченности стратегии
 */
export const getPossibleIncome = (averageAnnualIncome: number, involvementCoefficient: number): number => {
  let possibleIncome = reportRound(averageAnnualIncome / (1 - involvementCoefficient))
  return possibleIncome
}

/**
 * Расчет минимальной цены для закрытия сделки из расчета гарантированного перекрытия комиссии прибылью
 */
export const getMinimumClosingPrice = (priceOpen: number, commission: number): number => {
  let minimumClosingPrice = priceOpen * (1 + commission / 100)
  return minimumClosingPrice
}

/**
 * Проверка при закрытии сделки, перекрыла ли текущая цена комиссию относительно цены открытия сделки
 */
export const checkProfitOverCommission = (priceOpen: number, currentPrice: number): boolean => {
  let priceReachedMin = currentPrice > getMinimumClosingPrice(priceOpen, commissionOfBroker)
  return priceReachedMin
}

/**
 * Какая цена минимум должна быть для того, чтобы при закрытии сделки перекрыть комиссию и среднерыночную годовую доходность
 * по акциям в пересчете на длительность сделки
 */
export const priceDueForProfitOverAverageMarketIncome = (priceOpen: number, dateOpen: string, currentPrice: number, currentDate: string): number => {
  let minimumClosingPriceForCloseCommission = getMinimumClosingPrice(priceOpen, commissionOfBroker)
  let minimumDueIncomeForDay = conditionalAverageMarketIncome * minIncomeInOrderForClose / 365
  let durationOfTransaction = dayjs(currentDate).diff(dateOpen, 'day')
  if (durationOfTransaction === 0) {
    durationOfTransaction = 1
  }
  let minimumDueIncomeForDurationOfTransaction = minimumDueIncomeForDay * durationOfTransaction
  let minimumClosingPriceForCloseCommissionAndDueIncome = minimumClosingPriceForCloseCommission + priceOpen * minimumDueIncomeForDurationOfTransaction / 100
  return minimumClosingPriceForCloseCommissionAndDueIncome
}

/**
 * Трансформация массива данных с настройками по всем стратегиям, полученного из БД в удобный объект с ключами-стратегиями
 */
export const transformAllSettings = (settings: IStrategySettings[]): Record<Strategies, IElemAllStrategyTransform> | undefined => {
  let transformSettings: Partial<Record<Strategies, IElemAllStrategyTransform>> = {}
  Object.keys(Strategies).forEach(strategy => {
    const sett = settings.find(elem => (elem.strategy === strategy))
    if (sett) {
      transformSettings[strategy as keyof typeof Strategies] = {enable: sett.enable, settings: sett.settings}
    } else {
      transformSettings[strategy as keyof typeof Strategies] = {enable: false, settings: ''}
    }
  })
  return transformSettings as Record<Strategies, IElemAllStrategyTransform>
}

/**
 * Трансформация массива данных с открытыми позициями по всем стратегиям, полученного из БД в удобный объект с ключами-стратегиями
 */
export const transformAllPositions = (positions: IStrategyPositions[]): typeof OpenedPositions | undefined => {
  let transformPositions = JSON.parse(JSON.stringify({}))
  Object.keys(Strategies).forEach(strategy => {
    const pos = positions.find(elem => (elem.strategy === strategy))
    if (pos) {
      let posObj = JSON.parse(pos.positions)
      Object.keys(posObj).forEach((ticker) => {
        Object.keys(posObj[ticker]).forEach((timeframe) => {
          if (transformPositions[strategy]) {
            if (transformPositions[strategy][ticker]) {
              if (transformPositions[strategy][ticker][timeframe]) {
                transformPositions[strategy][ticker][timeframe] = posObj[ticker][timeframe]
              } else {
                transformPositions[strategy][ticker][timeframe] = posObj[ticker][timeframe]
              }
            } else {
              transformPositions[strategy][ticker] = {[timeframe]: posObj[ticker][timeframe]}
            }
          } else {
            transformPositions[strategy] = {[ticker]: {[timeframe]: posObj[ticker][timeframe]}}
          }
        })
      })
    }
  })
  return transformPositions as typeof OpenedPositions
}

/**
 * Дробление 10 минутной свечи на 10 частей
 * @return массив промежуточных цен
 */
export const candleSeparation = (currentCandle: Candle): { prices: number[], dateTimes: string[] } => {
  let innerPrices: number[] = []
  let innerDateTime: string[] = []

  // получение промежуточных DateTime
  let start = currentCandle[CandleTypePart.begin]
  let startUnix = moment(start).unix()
  let end = currentCandle[CandleTypePart.end]
  let endUnix = moment(end).unix()
  let difference = endUnix - startUnix
  let addition = Math.ceil(difference / 10)
  let currentInnerTimeUnix = startUnix
  for (let i = 0; i < 10; i++) {
    currentInnerTimeUnix += addition
    if (currentInnerTimeUnix <= endUnix) {
      innerDateTime.push(moment.unix(currentInnerTimeUnix).format('YYYY-MM-DD HH:mm:ss'))
    } else {
      innerDateTime.push(end)
    }
  }

  //получение промежуточных цен
  let totalVolatility = (currentCandle[CandleTypePart.high] - currentCandle[CandleTypePart.low]) * 2 - Math.abs(currentCandle[CandleTypePart.close] - currentCandle[CandleTypePart.open])
  let additionForOneMinute = totalVolatility / 10
  // делим текущую свечу на 10 частей
  // если свеча восходящая
  if (currentCandle[CandleTypePart.close] > currentCandle[CandleTypePart.open]) {
    let currentPhase = 1//на примере растущей свечи, движение вниз от open к low фаза 1, от low к high фаза 2, от high к close фаза 3
    let directAddition = -1
    let currentInnerPrice = currentCandle[CandleTypePart.open]
    for (let j = 0; j < 10; j++) {
      currentInnerPrice += additionForOneMinute * directAddition
      if (currentPhase === 1 && currentInnerPrice >= currentCandle[CandleTypePart.low]) {
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 1 && currentInnerPrice < currentCandle[CandleTypePart.low]) {
        currentPhase = 2
        directAddition = 1
        currentInnerPrice = currentCandle[CandleTypePart.low] + (currentCandle[CandleTypePart.low] - currentInnerPrice) * directAddition
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 2 && currentInnerPrice <= currentCandle[CandleTypePart.high]) {
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 2 && currentInnerPrice > currentCandle[CandleTypePart.high]) {
        currentPhase = 3
        directAddition = -1
        currentInnerPrice = currentCandle[CandleTypePart.high] + (currentInnerPrice - currentCandle[CandleTypePart.high]) * directAddition
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 3 && currentInnerPrice >= currentCandle[CandleTypePart.close]) {
        innerPrices.push(currentInnerPrice)
      } else {
        innerPrices.push(currentCandle[CandleTypePart.close])
      }
    }
  } else {// если свеча нисходящая
    let currentPhase = 1
    let directAddition = 1
    let currentInnerPrice = currentCandle[CandleTypePart.open]
    for (let j = 0; j < 10; j++) {
      currentInnerPrice += additionForOneMinute * directAddition
      if (currentPhase === 1 && currentInnerPrice <= currentCandle[CandleTypePart.high]) {
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 1 && currentInnerPrice > currentCandle[CandleTypePart.high]) {
        currentPhase = 2
        directAddition = -1
        currentInnerPrice = currentCandle[CandleTypePart.high] + (currentInnerPrice - currentCandle[CandleTypePart.high]) * directAddition
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 2 && currentInnerPrice >= currentCandle[CandleTypePart.low]) {
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 2 && currentInnerPrice < currentCandle[CandleTypePart.low]) {
        currentPhase = 3
        directAddition = 1
        currentInnerPrice = currentCandle[CandleTypePart.low] + (currentCandle[CandleTypePart.low] - currentInnerPrice) * directAddition
        innerPrices.push(currentInnerPrice)
      } else if (currentPhase === 3 && currentInnerPrice <= currentCandle[CandleTypePart.close]) {
        innerPrices.push(currentInnerPrice)
      } else {
        innerPrices.push(currentCandle[CandleTypePart.close])
      }
    }
  }
  return {prices: innerPrices, dateTimes: innerDateTime}
}

/**
 * Создание свечи с промежуточной ценой закрытия, имитация что свеча сейчас текущая и в процессе, для тестов
 */
export const createPartialCandle = (currentCandle: Candle, innerPrice: number, innerDateTime: string): Candle => {
  let open = currentCandle[CandleTypePart.open]
  let close = innerPrice
  let high = Math.max(open, close)
  let low = Math.min(open, close)
  let value = currentCandle[CandleTypePart.value]
  let volume = currentCandle[CandleTypePart.volume]
  let begin = currentCandle[CandleTypePart.begin]
  let end = innerDateTime
  return [open, close, high, low, value, volume, begin, end]
}

/**
 * Создание объекта соответствия индексов свечей М10 в массиве истории индексам свечей другого тф в массиве истории
 */
export const getAccordIndM10ToAnotherTF = async (infoTfM10: Candle[], infoTfAnother: Candle[], timeframe: TimeFrames) => {
  let accord: Record<number, number> = {}
  let startJ = 0//переменная начала итерации, нужна для оптимизации

  for (let i = 0; i < infoTfM10.length; i++) {
    for (let j = startJ; j < infoTfAnother.length; j++) {
      let dateTimeM10Start
      let dateTimeAnotherStart
      if (timeframe === TimeFrames.m60) {
        dateTimeM10Start = moment(infoTfM10[i][CandleTypePart.begin]).startOf('hour').format()
        dateTimeAnotherStart = moment(infoTfAnother[j][CandleTypePart.begin]).startOf('hour').format()
      } else if (timeframe === TimeFrames.day) {
        dateTimeM10Start = moment(infoTfM10[i][CandleTypePart.begin]).startOf('day').format()
        dateTimeAnotherStart = moment(infoTfAnother[j][CandleTypePart.begin]).startOf('day').format()
      } else if (timeframe === TimeFrames.week) {
        dateTimeM10Start = moment(infoTfM10[i][CandleTypePart.begin]).startOf('week').format()
        dateTimeAnotherStart = moment(infoTfAnother[j][CandleTypePart.begin]).startOf('week').format()
      } else if (timeframe === TimeFrames.month) {
        dateTimeM10Start = moment(infoTfM10[i][CandleTypePart.begin]).startOf('month').format()
        dateTimeAnotherStart = moment(infoTfAnother[j][CandleTypePart.begin]).startOf('month').format()
      } else {
        dateTimeM10Start = '0'
        dateTimeAnotherStart = '1'
      }
      let isBelong = dateTimeM10Start === dateTimeAnotherStart
      if (isBelong) {
        accord[i] = j
        if (j > 0) {
          startJ = j - 1
        }
        break
      }
    }

    //цикл очень долгий поэтому делаю обрыв потока выполнения цикла на некоторых итерациях, для не блокирования браузера
    if (i % 1000 === 0) {
      await delay(0)
    }

  }

  return accord
}

/**
 * Для имитации реал тайм режима по текущему состоянию на M10 создаем состояния на других ТФ
 */
export const getCurrentStateFromM10 = (infoTf: Candle[], accordIndM10ToAnotherTf: number, innerPriceLastCandle: number, innerDateTimeLastCandle: string): Candle[] => {
  let currentInfoTfFromFirstToFullCurrentCandle = infoTf.slice(0, accordIndM10ToAnotherTf + 1)
  let currentInfoAnotherTfFromFirstToPartCurrentCandle = currentInfoTfFromFirstToFullCurrentCandle.slice(0, currentInfoTfFromFirstToFullCurrentCandle.length - 1)
  let partialCurrentCandle = createPartialCandle(currentInfoTfFromFirstToFullCurrentCandle[currentInfoTfFromFirstToFullCurrentCandle.length - 1], innerPriceLastCandle, innerDateTimeLastCandle)
  currentInfoAnotherTfFromFirstToPartCurrentCandle.push([...partialCurrentCandle])
  return currentInfoAnotherTfFromFirstToPartCurrentCandle
}

/**
 * Функция замедления в асинхронных функциях, очень тяжелые функции делаю асинхронными и в них делаю паузы для разрыва потока выполнения, чтобы не блокировался браузер
 */
const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Имитация режима риалтайм для тестирования стратегий в реалистичных условиях на основании исторических данных
 */
export const testInImitationRealTimeMode = async (
  strategyForTesting: Strategies,
  infoTfM10: Candle[], infoTfM60: Candle[], infoTfDay: Candle[], infoTfWeek: Candle[], infoTfMonth: Candle[],
  timeframe: TimeFrames,
  settings: string[]
) => {

  //массив под готовые сделки
  const orders: Array<ISeriesOfTransaction> = []
  //информация об открытой серии сделок для функции стратегии
  let openedSeriesOfTransactions: ISeriesOfTransaction = {enters: [], exits: []}

  // массивы соответствия свечи в М10 истории свече в других ТФ {индекс М10:  индекс М60, }
  const accordIndM10ToM60 = await getAccordIndM10ToAnotherTF(infoTfM10, infoTfM60, TimeFrames.m60)
  const accordIndM10ToDay = await getAccordIndM10ToAnotherTF(infoTfM10, infoTfDay, TimeFrames.day)
  const accordIndM10ToWeek = await getAccordIndM10ToAnotherTF(infoTfM10, infoTfWeek, TimeFrames.week)
  const accordIndM10ToMonth = await getAccordIndM10ToAnotherTF(infoTfM10, infoTfMonth, TimeFrames.month)

  //имитация ежеминутного обновления онлайн данных, поэтому проходим по всем 10 минутным свечам и дробим каждую еще на 10 частей.
  //все остальные массивы по таймфреймам истории подгоняем под эти моменты т.е в момент последняя свеча в массиве это свеча
  //открытая ее собственной ценой открытия а закрытая промежуточной ценой.
  //прохождение по массиву реальных 10 минутных свечей
  for (let i = 0; i < infoTfM10.length; i++) {
    //todo: для америки пока не доступно, так как у америке на yahoo.finance нет m10, только м15 и м5. Проверить на какой срок предоставляет yahoo м15 и если этого достаточно для тестов, то сделать подмену м10 на м15 для америки.
    //бывает, что история на разных тф начинается с разной даты (например на week с начала следующей недели от указанной даты), этот период пропускаем
    if (!accordIndM10ToM60[i] || !accordIndM10ToDay[i] || !accordIndM10ToWeek[i] || !accordIndM10ToMonth[i]) {
      continue
    }
    //текущий массив с полноценной последней свечей
    let currentInfoTfM10FromFirstToFullCurrentCandle = infoTfM10.slice(0, i + 1)
    //дробные цены и dateTimes последней свечи текущего массива
    let {prices: innerPricesLastCandle, dateTimes: innerDateTimesLastCandle} = candleSeparation(currentInfoTfM10FromFirstToFullCurrentCandle[currentInfoTfM10FromFirstToFullCurrentCandle.length - 1])
    //прохождение по промежуточным состояниям 10 минутных свечей для имитации онлайн данных
    for (let j = 0; j < innerPricesLastCandle.length; j++) {
      let currentPrice = innerPricesLastCandle[j]
      let currentDateTime = innerDateTimesLastCandle[j]
      //удаляем последнюю свечу из текущего массива свечей М10 и заменяем ее на текущую частичную
      let currentInfoTfM10 = currentInfoTfM10FromFirstToFullCurrentCandle.slice(0, currentInfoTfM10FromFirstToFullCurrentCandle.length - 1)
      let partialCurrentCandleM10 = createPartialCandle(currentInfoTfM10FromFirstToFullCurrentCandle[currentInfoTfM10FromFirstToFullCurrentCandle.length - 1], innerPricesLastCandle[j], innerDateTimesLastCandle[j])
      currentInfoTfM10.push([...partialCurrentCandleM10])
      //формируем живое состояние остальных ТФ на данный текущий момент
      const currentInfoTfM60 = getCurrentStateFromM10(infoTfM60, accordIndM10ToM60[i], currentPrice, currentDateTime)
      const currentInfoTfDay = getCurrentStateFromM10(infoTfDay, accordIndM10ToDay[i], currentPrice, currentDateTime)
      const currentInfoTfWeek = getCurrentStateFromM10(infoTfWeek, accordIndM10ToWeek[i], currentPrice, currentDateTime)
      const currentInfoTfMonth = getCurrentStateFromM10(infoTfMonth, accordIndM10ToMonth[i], currentPrice, currentDateTime)

      //подготовка параметров для функции стратегии
      let params: IParamsStrategyFunction = {
        timeframe: timeframe,
        settings: settings,
        currentInfoTfM10: currentInfoTfM10,
        currentInfoTfM60: currentInfoTfM60,
        currentInfoTfDay: currentInfoTfDay,
        currentInfoTfWeek: currentInfoTfWeek,
        currentInfoTfMonth: currentInfoTfMonth,
      }
      //вызов функции стратегии
      let resultStrategy = StrategyObject[StrategyLabelFlip[strategyForTesting]].main(params, openedSeriesOfTransactions)
      //заполнение тестовых сделок
      if (resultStrategy.state === StateOfTrade.NEED_OPEN_SERIES) {
        openedSeriesOfTransactions.enters.push({
          price: resultStrategy.price,
          date: resultStrategy.date,
          value: resultStrategy.value,
          specificity: resultStrategy.specificity
        })
      } else if (resultStrategy.state === StateOfTrade.NEED_ADD_ENTER_IN_SERIES) {
        openedSeriesOfTransactions.enters.push({
          price: resultStrategy.price,
          date: resultStrategy.date,
          value: resultStrategy.value,
          specificity: resultStrategy.specificity
        })
      } else if (resultStrategy.state === StateOfTrade.NEED_ADD_EXIT_IN_SERIES) {
        openedSeriesOfTransactions.exits.push({
          price: resultStrategy.price,
          date: resultStrategy.date,
          value: resultStrategy.value,
          specificity: resultStrategy.specificity
        })
      } else if (resultStrategy.state === StateOfTrade.NEED_CLOSE_SERIES) {
        openedSeriesOfTransactions.exits.push({
          price: resultStrategy.price,
          date: resultStrategy.date,
          value: resultStrategy.value,
          specificity: resultStrategy.specificity
        })
        orders.push(openedSeriesOfTransactions)
        openedSeriesOfTransactions = {enters: [], exits: []}
      }
    }

    //цикл тестирования очень долгий поэтому делаю обрыв потока выполнения цикла на некоторых итерациях, для не блокирования браузера
    if (i % 100 === 0) {
      await delay(0)
    }

  }

  //если на конец истории последняя серия сделок осталась не закрыта, то закрываем ее искусственно последней датой в истории, цену ставим null, чтобы в отчете при расчете результатов стратегии понимать что эта сделка осталась открыта
  if (openedSeriesOfTransactions.enters.length !== 0) {
    const sumValueEnter = openedSeriesOfTransactions.enters.reduce((accum, item) => (accum + item.value), 0)
    const sumValueExit = openedSeriesOfTransactions.exits.reduce((accum, item) => (accum + item.value), 0)
    const valueClose = sumValueEnter - sumValueExit
    openedSeriesOfTransactions.exits.push({
      price: null,
      date: infoTfM10[infoTfM10.length - 1][CandleTypePart.end],
      value: valueClose,
      specificity: 'do not close'
    })
    orders.push(openedSeriesOfTransactions)
    openedSeriesOfTransactions = {enters: [], exits: []}
  }

  return orders
}

/**
 * Дополнение массива свечей с задержкой актуальной ценой
 */
export const addingActualPriceToCandlesWithDelay = (candles: Candle[], actualPrice: number, timeframe: TimeFrames, country: Countries): Candle[] => {
  //иногда биржа присылает пустой массив графиков по какому либо инструменту/таймфрейму, в этом случае возвращаем пустой массив, ничего не меняя в нем
  if (candles.length !== 0) {

    //сервер предоставляет свечи с московским временем, поэтому будем сравнивать тоже с московским
    const timeNow = dayjs().tz('Europe/Moscow')
    //время начала торговой сессии
    let timeStartSession
    if (country === Countries.RU) {
      timeStartSession = timeNow.set("hour", 10).set("minute", 0)
    } else {
      timeStartSession = timeNow.set("hour", 16).set("minute", 30)
    }
    //номер дня недели (0 -это воскресенье)
    const dayOfWeek = timeNow.day()

    //на тф м10 в любом случае нет актуальной свечи
    if (timeframe === TimeFrames.m10) {
      //если рынок еще не открылся или это выходной, значит текущая цена с прошлого периода
      if (timeNow < timeStartSession || dayOfWeek === 0 || dayOfWeek === 6) {
        //просто вклеиваем текущую котировку в последнюю свечу
        const lastCandle = candles[candles.length - 1]
        let candleAddedActualPrice = [...candles]
        const closeLastCandle = actualPrice
        const highLastCandle = lastCandle[CandleTypePart.high] >= actualPrice ? lastCandle[CandleTypePart.high] : actualPrice
        const lowLastCandle = lastCandle[CandleTypePart.low] <= actualPrice ? lastCandle[CandleTypePart.low] : actualPrice
        let newLastCandle: Candle = [...lastCandle]
        newLastCandle[CandleTypePart.close] = closeLastCandle
        newLastCandle[CandleTypePart.high] = highLastCandle
        newLastCandle[CandleTypePart.low] = lowLastCandle
        candleAddedActualPrice[candleAddedActualPrice.length - 1] = newLastCandle
        return candleAddedActualPrice
      }
      //если рынок работает значит текущая цена с нового периода, создаем последнюю свечу из текущей котировки
      else {
        let candleAddedActualPrice = [...candles]
        const openNewCandle = actualPrice
        const closeNewCandle = actualPrice
        const highNewCandle = actualPrice
        const lowNewCandle = actualPrice
        const valueNewCandle = 0
        const volumeNewCandle = 0
        const beginNewCandle = timeNow.format('YYYY-MM-DD HH:mm:ss')
        const endNewCandle = timeNow.format('YYYY-MM-DD HH:mm:ss')
        const newCandle: Candle = [openNewCandle, closeNewCandle, highNewCandle, lowNewCandle, valueNewCandle, volumeNewCandle, beginNewCandle, endNewCandle]
        candleAddedActualPrice.push(newCandle)
        return candleAddedActualPrice
      }
    } else {
      //для определения принадлежит ли текущая котировка последней свече нужно привести их к началу периода по тф и проверить совпадение
      let translateToStartPeriod: dayjs.OpUnitType = 'hour'
      if (timeframe === TimeFrames.month) {
        translateToStartPeriod = 'month'
      } else if (timeframe === TimeFrames.week) {
        translateToStartPeriod = 'week'
      } else if (timeframe === TimeFrames.day) {
        translateToStartPeriod = 'day'
      } else if (timeframe === TimeFrames.m60) {
        translateToStartPeriod = 'hour'
      }
      const lastCandle = candles[candles.length - 1]
      const dateLastCandle = lastCandle[CandleTypePart.begin]
      const dateLastCandleTranslateToStartPeriod = dayjs.tz(dateLastCandle, 'Europe/Moscow').startOf(translateToStartPeriod)
      const nowTranslateToStartPeriod = timeNow.startOf(translateToStartPeriod)

      //если текущий момент времени и последняя свеча принадлежит одному временному периоду, то последняя свеча текущая
      if (dateLastCandleTranslateToStartPeriod === nowTranslateToStartPeriod) {
        //просто вклеиваем текущую котировку в последнюю свечу
        let candleAddedActualPrice = [...candles]
        const closeLastCandle = actualPrice
        const highLastCandle = lastCandle[CandleTypePart.high] >= actualPrice ? lastCandle[CandleTypePart.high] : actualPrice
        const lowLastCandle = lastCandle[CandleTypePart.low] <= actualPrice ? lastCandle[CandleTypePart.low] : actualPrice
        let newLastCandle: Candle = [...lastCandle]
        newLastCandle[CandleTypePart.close] = closeLastCandle
        newLastCandle[CandleTypePart.high] = highLastCandle
        newLastCandle[CandleTypePart.low] = lowLastCandle
        candleAddedActualPrice[candleAddedActualPrice.length - 1] = newLastCandle
        return candleAddedActualPrice
      }
      //если текущий момент времени и последняя свеча принадлежат разным временным периодам, то...
      else {
        //и если рынок еще не открылся или это выходной, значит текущая цена с прошлого периода
        if (timeNow < timeStartSession || dayOfWeek === 0 || dayOfWeek === 6) {
          //просто вклеиваем текущую котировку в последнюю свечу
          let candleAddedActualPrice = [...candles]
          const closeLastCandle = actualPrice
          const highLastCandle = lastCandle[CandleTypePart.high] >= actualPrice ? lastCandle[CandleTypePart.high] : actualPrice
          const lowLastCandle = lastCandle[CandleTypePart.low] <= actualPrice ? lastCandle[CandleTypePart.low] : actualPrice
          let newLastCandle: Candle = [...lastCandle]
          newLastCandle[CandleTypePart.close] = closeLastCandle
          newLastCandle[CandleTypePart.high] = highLastCandle
          newLastCandle[CandleTypePart.low] = lowLastCandle
          candleAddedActualPrice[candleAddedActualPrice.length - 1] = newLastCandle
          return candleAddedActualPrice
        }
        //и если рынок уже открылся, значит текущая цена с нового периода
        else {
          //создаем последнюю свечу из текущей котировки
          let candleAddedActualPrice = [...candles]
          const openNewCandle = actualPrice
          const closeNewCandle = actualPrice
          const highNewCandle = actualPrice
          const lowNewCandle = actualPrice
          const valueNewCandle = 0
          const volumeNewCandle = 0
          const beginNewCandle = nowTranslateToStartPeriod.format('YYYY-MM-DD HH:mm:ss')
          const endNewCandle = timeNow.format('YYYY-MM-DD HH:mm:ss')
          const newCandle: Candle = [openNewCandle, closeNewCandle, highNewCandle, lowNewCandle, valueNewCandle, volumeNewCandle, beginNewCandle, endNewCandle]
          candleAddedActualPrice.push(newCandle)
          return candleAddedActualPrice
        }
      }
    }
  } else {
    return candles
  }
}

/**
 * Расчет одного значения скользящей средней по последней свече графика
 */
export const getMovingAverageValue = (candles: Candle[], period: number): number => {
  let value = 0
  if (candles.length >= period) {
    for (let i = candles.length - period; i < candles.length; i++) {
      value += candles[i][CandleTypePart.close]
    }
    value = value / period
  } else {
    for (let i = 0; i < candles.length; i++) {
      value += candles[i][CandleTypePart.close]
    }
    value = value / candles.length
  }
  return value
}

/**
 * Расчет скользящей средней
 */
export const getMovingAverage = (candles: Candle[], period: number): number[] => {
  const movAver: number[] = []

  let sum = 0
  candles.forEach((candle, index) => {
    const close = candle[CandleTypePart.close]
    if (index + 1 <= period) {
      sum += close
      movAver.push(sum / (index + 1))
    } else {
      sum += close
      sum -= candles[index - period][CandleTypePart.close]
      movAver.push(sum / period)
    }
  })

  return movAver
}

/**
 * Расчет скользящей средней не по свечам а просто по массиву значений
 */
export const getMovingAverageForNumArr = (values: number[], period: number): number[] => {
  const movAver: number[] = []

  let sum = 0
  values.forEach((value, index) => {
    if (index + 1 <= period) {
      sum += value
      movAver.push(sum / (index + 1))
    } else {
      sum += value
      sum -= values[index - period]
      movAver.push(sum / period)
    }
  })

  return movAver
}

/**
 * Расчет одного значения RSI по последней свече графика
 */
export const getRsiValue = (candles: Candle[], period: number): number => {
  let value = 50
  let closeUpAverageSum = 0
  let closeDownAverageSum = 0
  let closeUpAverage
  let closeDownAverage
  let startInd = 0
  if (candles.length >= period + 1) {
    startInd = candles.length - period
  } else {
    startInd = 1
  }
  for (let i = startInd; i < candles.length; i++) {
    const delta = candles[i][CandleTypePart.close] - candles[i - 1][CandleTypePart.close]
    if (delta > 0) {
      closeUpAverageSum += delta
    }
    if (delta < 0) {
      closeDownAverageSum += -delta
    }
  }
  closeUpAverage = closeUpAverageSum / period
  closeDownAverage = closeDownAverageSum / period
  if (closeUpAverage && closeDownAverage) {
    value = 100 - (100 / (1 + closeUpAverage / closeDownAverage))
  } else if (closeDownAverage === 0) {
    value = 100
  }
  return value
}

/**
 * Расчет RSI
 */
export const getRsi = (candles: Candle[], period: number): number[] => {
  const rsiArr: number[] = []

  let closeUpAverageSum = 0
  let closeDownAverageSum = 0
  candles.forEach((candle, index) => {
    if (index !== 0) {
      let rsi = 0
      let closeUpAverage
      let closeDownAverage

      const delta = candle[CandleTypePart.close] - candles[index - 1][CandleTypePart.close]
      if (index <= period) {
        if (delta > 0) {
          closeUpAverageSum += delta
        }
        if (delta < 0) {
          closeDownAverageSum += -delta
        }
        closeUpAverage = closeUpAverageSum / (index)
        closeDownAverage = closeDownAverageSum / (index)
        if (closeUpAverage && closeDownAverage) {
          rsi = 100 - (100 / (1 + closeUpAverage / closeDownAverage))
        } else if (closeDownAverage === 0) {
          rsi = 100
        }
        rsiArr.push(rsi)
      } else {
        const unnecessaryDelta = candles[index - period][CandleTypePart.close] - candles[index - period - 1][CandleTypePart.close]
        if (delta > 0 && unnecessaryDelta > 0) {
          closeUpAverageSum += delta
          closeUpAverageSum -= unnecessaryDelta
        }
        if (delta > 0 && unnecessaryDelta < 0) {
          closeUpAverageSum += delta
          closeDownAverageSum -= -unnecessaryDelta
        }
        if (delta > 0 && unnecessaryDelta === 0) {
          closeUpAverageSum += delta
        }
        if (delta < 0 && unnecessaryDelta < 0) {
          closeDownAverageSum += -delta
          closeDownAverageSum -= -unnecessaryDelta
        }
        if (delta < 0 && unnecessaryDelta > 0) {
          closeDownAverageSum += -delta
          closeUpAverageSum -= unnecessaryDelta
        }
        if (delta < 0 && unnecessaryDelta === 0) {
          closeDownAverageSum += -delta
        }
        if (delta === 0 && unnecessaryDelta > 0) {
          closeUpAverageSum -= unnecessaryDelta
        }
        if (delta === 0 && unnecessaryDelta < 0) {
          closeDownAverageSum -= -unnecessaryDelta
        }
        if (delta === 0 && unnecessaryDelta === 0) {
          //ничего не делать
        }
        closeUpAverage = closeUpAverageSum / period
        closeDownAverage = closeDownAverageSum / period
        if (closeUpAverage && closeDownAverage) {
          rsi = 100 - (100 / (1 + closeUpAverage / closeDownAverage))
        } else if (closeDownAverage === 0) {
          rsi = 100
        }
        rsiArr.push(rsi)
      }
    }
  })

  return rsiArr
}

/**
 * Расчет одного значения стохастика по последней свече графика
 */
export const getStochasticValue = (candles: Candle[], period: number): number => {
  let value = 50
  const lastClose = candles[candles.length - 1][CandleTypePart.close]
  let lowestPrice
  let highestPrice
  let startInd = 0
  if (candles.length >= period) {
    startInd = candles.length - period
  } else {
    startInd = 0
  }

  lowestPrice = candles[startInd][CandleTypePart.low]
  highestPrice = candles[startInd][CandleTypePart.high]
  for (let i = startInd; i < candles.length; i++) {
    if (candles[i][CandleTypePart.low] < lowestPrice) {
      lowestPrice = candles[i][CandleTypePart.low]
    }
    if (candles[i][CandleTypePart.high] > highestPrice) {
      highestPrice = candles[i][CandleTypePart.high]
    }
  }

  if (lowestPrice && highestPrice) {
    value = ((lastClose - lowestPrice) / (highestPrice - lowestPrice)) * 100
  }
  return value
}

/**
 * Расчет Stochastic
 */
export const getStochastic = (candles: Candle[], period: number): number[] => {
  const stochArr: number[] = []

  let lowestPrice = candles[0][CandleTypePart.low]
  let highestPrice = candles[0][CandleTypePart.high]
  candles.forEach((candle, index) => {
    if (index !== 0) {
      let value = 0
      const lastClose = candle[CandleTypePart.close]

      if (index + 1 <= period) {
        //экстремальные значения за период
        if (candle[CandleTypePart.low] < lowestPrice) {
          lowestPrice = candle[CandleTypePart.low]
        }
        if (candle[CandleTypePart.high] > highestPrice) {
          highestPrice = candle[CandleTypePart.high]
        }
        //расчет стохастика
        if (lowestPrice && highestPrice) {
          value = ((lastClose - lowestPrice) / (highestPrice - lowestPrice)) * 100
        }
        stochArr.push(value)
      } else {
        //удаление лишнего значения и нахождение нового без учета лишнего
        const unnecessaryLow = candles[index - period][CandleTypePart.low]
        const unnecessaryHigh = candles[index - period][CandleTypePart.high]
        if (lowestPrice >= unnecessaryLow || highestPrice <= unnecessaryHigh) {
          let newLow = candles[index - period + 1][CandleTypePart.low]
          let newHigh = candles[index - period + 1][CandleTypePart.high]
          for (let i = index - period + 1; i < index; i++) {
            if (candles[i][CandleTypePart.low] < newLow) {
              newLow = candles[i][CandleTypePart.low]
            }
            if (candles[i][CandleTypePart.high] > newHigh) {
              newHigh = candles[i][CandleTypePart.high]
            }
          }
          lowestPrice = newLow
          highestPrice = newHigh
        }
        //экстремальные значения за период
        if (candle[CandleTypePart.low] < lowestPrice) {
          lowestPrice = candle[CandleTypePart.low]
        }
        if (candle[CandleTypePart.high] > highestPrice) {
          highestPrice = candle[CandleTypePart.high]
        }
        //расчет стохастика
        if (lowestPrice && highestPrice) {
          value = ((lastClose - lowestPrice) / (highestPrice - lowestPrice)) * 100
        }
        stochArr.push(value)
      }
    }
  })

  return stochArr
}

/**
 * Расчет MACD
 */
export const getMacd = (fastMovAver: number[], slowMovAver: number[]): number[] => {
  const macdArr: number[] = []
  fastMovAver.forEach((fast, index) => {
    const macd = fast - slowMovAver[index]
    macdArr.push(macd)
  })

  return macdArr
}

/**
 * Расчет MACD Histogram
 */
export const getMacdHistogram = (macd: number[], macdSignal: number[]): number[] => {
  const macdHistogramArr: number[] = []

  macd.forEach((macdValue, index) => {
    const macdHistogram = macdValue - macdSignal[index]
    macdHistogramArr.push(macdHistogram)
  })

  return macdHistogramArr
}

/**
 * Расчет зигзага, последние несколько звеньев. Массив с конца, [0] - это текущая цена
 * @param candles массив свечей
 * @param delta настройка зигзага - отклонение на котором фиксируется излом кривой
 * @return массив узлов зигзага, каждый узел имеет цену и индекс свечи с массива свечей
 */
export const getZigZag = (candles: Candle[], delta: number): IZigZagNode[] => {
  let direction: 'u' | 'd' | null = null//текущее направление относительно последнего узла зигзага
  let extremeInd: number = candles.length - 1//индекс текущего экстремума в массиве свечей
  let extremePrice: number = candles[extremeInd][CandleTypePart.close]//цена текущего экстремума
  let nodeInd: number = candles.length - 1//индекс последнего зафиксированного узла в массиве свечей
  let nodePrice: number = candles[nodeInd][CandleTypePart.close]//цена последнего зафиксированного узла
  let nodes: IZigZagNode[] = []
  let firstUpExtremeInd: number = candles.length - 1
  let firstUpExtremePrice: number = candles[extremeInd][CandleTypePart.close]
  let firstDnExtremeInd: number = candles.length - 1
  let firstDnExtremePrice: number = candles[extremeInd][CandleTypePart.close]
  nodes.push({ind: nodeInd, price: nodePrice})
  for (let i = candles.length - 1; i >= 0; i--) {
    const currentPrice = candles[i][CandleTypePart.close]
    if (!direction) {//если самое начало цикла и направление еще не определено
      if (currentPrice < firstDnExtremePrice) {
        firstDnExtremePrice = currentPrice
        firstDnExtremeInd = i
        const difference = firstUpExtremePrice - currentPrice
        const percent = difference * 100 / firstUpExtremePrice
        if (percent >= delta) {
          direction = 'd'
          //это на случай если сначала немного вильнула вверх
          if (firstUpExtremeInd !== candles.length - 1) {
            nodePrice = firstUpExtremePrice
            nodeInd = firstUpExtremeInd
            nodes.push({ind: nodeInd, price: nodePrice})
          }
          extremePrice = currentPrice
          extremeInd = i
        }
      } else if (currentPrice > firstUpExtremePrice) {
        firstUpExtremePrice = currentPrice
        firstUpExtremeInd = i
        const difference = currentPrice - firstDnExtremePrice
        const percent = difference * 100 / firstDnExtremePrice
        if (percent >= delta) {
          direction = 'u'
          //это на случай если сначала немного вильнула вниз
          if (firstDnExtremeInd !== candles.length - 1) {
            nodePrice = firstDnExtremePrice
            nodeInd = firstDnExtremeInd
            nodes.push({ind: nodeInd, price: nodePrice})
          }
          extremePrice = currentPrice
          extremeInd = i
        }

      } else {
        continue
      }
    } else if (direction === 'u') {
      if (currentPrice >= extremePrice) {
        extremePrice = currentPrice
        extremeInd = i
      } else {
        const difference = extremePrice - currentPrice
        const percent = difference * 100 / extremePrice
        if (percent >= delta) {
          direction = 'd'
          nodePrice = extremePrice
          nodeInd = extremeInd
          nodes.push({ind: nodeInd, price: nodePrice})
          extremePrice = currentPrice
          extremeInd = i
        }
      }
    } else if (direction === 'd') {
      if (currentPrice <= extremePrice) {
        extremePrice = currentPrice
        extremeInd = i
      } else {
        const difference = currentPrice - extremePrice
        const percent = difference * 100 / extremePrice
        if (percent >= delta) {
          direction = 'u'
          nodePrice = extremePrice
          nodeInd = extremeInd
          nodes.push({ind: nodeInd, price: nodePrice})
          extremePrice = currentPrice
          extremeInd = i
        }
      }
    }

    //так как мне нужны только последние несколько волн зигзага, то выходим как только их получили
    if (nodes.length >= 7) {
      break
    }
  }

  //иногда бывает, что весь имеющийся исторический график это устойчивый тренд в одну сторону и зигзаг с установленным отклонением на котором фиксируется излом кривой выглядит как прямой отрезок без изломов, в этом случае массив узлов зигзага будет заполнен только одним начальным узлом, поэтому нужно принудительно заполнить еще один узел в виде точки начала графика
  if (nodes.length === 1) {
    nodes.push({ind: 0, price: candles[0][CandleTypePart.close]})
  }

  return nodes
}

/**
 * Проверка по зигзагу, что текущая линия зигзага направлена вниз, контекст восходящий или флетовый
 * и набран соответствующий направлению контекста потенциал для дальнейшего роста
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param differenceWaveZzToDetermineDirectionOfContext сколько процентов должна быть разница между восходящей и нисходящей волной, чтобы признать что контекст восходящий или нисходящий
 * @param sizeCorrectionToHavePotentialForUpContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при растущем контексте
 * @param sizeCorrectionToHavePotentialForFlatContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при флетовом контексте
 * @return boolean значение, что есть потенциал
 */
export const checkDirectionAndContextDirectionAndHavePotentialByZigzag = (
  zigzag: IZigZagNode[],
  differenceWaveZzToDetermineDirectionOfContext: number,
  sizeCorrectionToHavePotentialForUpContextOnZigzag: number,
  sizeCorrectionToHavePotentialForFlatContextOnZigzag: number,
): boolean => {
  //для проверки направления контекста и коррекции нужно минимум четыре узла зигзага
  if (zigzag.length >= 4) {
    if (zigzag[0].price >= zigzag[1].price) {//если текущая волна вверх, то условие не выполнено
      return false
    }
    let waveDown = zigzag[3].price - zigzag[2].price
    let waveUp = zigzag[1].price - zigzag[2].price
    let coeffContext = waveUp / waveDown
    if (coeffContext < ((100 - differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст нисходящий, то условие не выполнено
      return false
    }
    let waveCurrent = zigzag[1].price - zigzag[0].price
    let coeffCorrection = waveCurrent / waveUp
    if (coeffContext >= ((100 + differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст восходящий
      if (coeffCorrection > sizeCorrectionToHavePotentialForUpContextOnZigzag / 100) {//если коррекция достаточная
        return true
      } else {//если коррекция не достаточная
        return false
      }
    } else if (coeffContext >= ((100 - differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст флетовый
      if (coeffCorrection > sizeCorrectionToHavePotentialForFlatContextOnZigzag / 100) {//если коррекция достаточная
        return true
      } else {//если коррекция не достаточная
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Проверка по зигзагу, что текущее движение зигзага вниз
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @return boolean значение, что текущее движение зигзага вниз
 */
export const checkCurrentMoveOfZigzagIsDown = (zigzag: IZigZagNode[]): boolean => {
  if (zigzag[0].price < zigzag[1].price) {
    return true
  } else {
    return false
  }
}


/**
 * Получение нижней точки зигзага, за исключением указанной (обязательно донышко, т.к. при исключении нижнего донышка низом может оказаться вершинка)
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param exceptedNode точка, которая исключена из обработки
 * @return number, индекс нижней точки в zigzag
 */
export const getLowestNodeOfZigzag = (zigzag: IZigZagNode[], exceptedNode: number | undefined = undefined): number => {
  let lowestNode = 0
  //получение исходного узла-донышка для сравнения
  if (zigzag[0].price < zigzag[1].price) {//нулевой узел это донышко
    if (exceptedNode !== 0) {
      lowestNode = 0//если 0 это не исключенный узел то берем его
    } else {
      lowestNode = 2//если 0 это исключенный узел то берем следующее донышко
    }
  } else {//нулевой узел это вершинка, то ближайшее донышко это 1
    if (exceptedNode !== 1) {
      lowestNode = 1//если 1 это не исключенный узел то берем его
    } else {
      lowestNode = 3//если 1 это исключенный узел то берем следующее донышко
    }
  }
  for (let i = 0; i < zigzag.length; i++) {
    //пропускаем исключаемое звено
    if (exceptedNode && i === exceptedNode) {
      continue
    }
    //находим соседний существующий узел для того чтобы понять является ли итерируемый узел донышком
    const neighbour = (zigzag[i - 1]) ? i - 1 : i + 1
    //пропускаем вершинку
    if (zigzag[i].price > zigzag[neighbour].price) {
      continue
    }
    //находим нижнее звено
    if (zigzag[i].price < zigzag[lowestNode].price) {
      lowestNode = i
    }
  }
  return lowestNode
}

/**
 * Получение узла зигзага с максимальным уровнем цены между двумя точками
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param firstNode первая точка в любом порядке по зигзагу
 * @param secondNode вторая точка в любом порядке по зигзагу
 * @return number, индекс верхней точки в zigzag
 */
export const getHighestNodeBetweenTwoPointOfZigzag = (zigzag: IZigZagNode[], firstNode: number, secondNode: number): number => {
  const startPoint = (firstNode < secondNode) ? firstNode : secondNode
  const endPoint = (firstNode > secondNode) ? firstNode : secondNode
  let highestNode = startPoint + 1
  for (let i = startPoint + 1; i < endPoint; i++) {
    if (zigzag[i].price > zigzag[highestNode].price) {
      highestNode = i
    }
  }
  return highestNode
}

/**
 * Проверка по зигзагу, что после движения вниз была остановка двойным дном, текущее движение вниз и цена достаточно скорректировалась в сторону поддержки
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param maximumAllowableSizeOfSecondBottomOverrunOnLowerTfZigzagSetting максимально допустимый перебег второго дна на младшем зигзаге
 * @param sizeCorrectionToHavePotentialForUpContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при растущем контексте
 * @param sizeCorrectionToHavePotentialForFlatContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при флетовом контексте
 * @return boolean значение, что есть потенциал
 */
export const checkWasDoubleBottomAndPriceAboutBottomOnZigzag = (
  zigzag: IZigZagNode[],
  maximumAllowableSizeOfSecondBottomOverrunOnLowerTfZigzagSetting: number,
  differenceWaveZzToDetermineBreakoutToUpOfFlatOnLowerTfZigzagSetting: number,
  sizeCorrectionToHavePotentialForUpContextOnLowerTfZigzagSetting: number,
  sizeCorrectionToHavePotentialForFlatContextOnLowerTfZigzagSetting: number,
): boolean => {
  //для проверки нужно минимум пять узлов зигзага
  if (zigzag.length >= 5) {
    //если текущая волна вверх, то условие не выполнено
    if (!checkCurrentMoveOfZigzagIsDown(zigzag)) return false
    //получим зигзаг без последнего узла (текущей цены), т.к. в некоторых расчетах этот узел не должен участвовать
    const zigzagWithoutLastNode = zigzag.slice(1)
    //получим две нижних точки зигзага без участия текущей цены
    const lowestNode = getLowestNodeOfZigzag(zigzagWithoutLastNode) + 1
    const lowestNodeExceptLowest = getLowestNodeOfZigzag(zigzagWithoutLastNode, lowestNode - 1) + 1
    //первое по хронологии дно
    const firstBottom = Math.max(lowestNode, lowestNodeExceptLowest)
    //второе по хронологии дно
    const secondBottom = Math.min(lowestNode, lowestNodeExceptLowest)
    //определим узел зигзага с максимальным уровнем цены между донышками, на нем будет уровень сопротивления
    const maxNodeBetweenBottoms = getHighestNodeBetweenTwoPointOfZigzag(zigzag, lowestNode, lowestNodeExceptLowest)
    //определим узел зигзага с максимальным уровнем цены после второго донышка
    const maxNodeAfterSecondBottom = getHighestNodeBetweenTwoPointOfZigzag(zigzag, 0, secondBottom)
    //движение вверх от первого дна к максимальной точке
    const moveUpAfterFirstBottom = zigzag[maxNodeBetweenBottoms].price - zigzag[firstBottom].price
    //движение вниз от максимальной точки ко второму дну
    const moveDownBeforeSecondBottom = zigzag[maxNodeBetweenBottoms].price - zigzag[secondBottom].price
    //определим было ли двойное дно
    if (moveDownBeforeSecondBottom / moveUpAfterFirstBottom > 1 + maximumAllowableSizeOfSecondBottomOverrunOnLowerTfZigzagSetting / 100) {
      return false
    }
    //движение вверх от второго дна к максимальной точке после второго дна
    const moveUpAfterSecondBottom = zigzag[maxNodeAfterSecondBottom].price - zigzag[secondBottom].price
    //движение вниз от максимальной точки после второго дна к текущему уровню
    const moveDownAfterTopAfterSecondBottom = zigzag[maxNodeAfterSecondBottom].price - zigzag[0].price
    //определим, верхняя точка после второго дна пробила ли вверх коридор или осталась внутри коридора
    if (moveUpAfterSecondBottom / moveDownBeforeSecondBottom > 1 + differenceWaveZzToDetermineBreakoutToUpOfFlatOnLowerTfZigzagSetting / 100) {//если пробила вверх
      if (moveDownAfterTopAfterSecondBottom / moveUpAfterSecondBottom >= sizeCorrectionToHavePotentialForUpContextOnLowerTfZigzagSetting / 100) {//если коррекция достаточно глубокая
        return true
      } else {
        return false
      }
    } else {//если осталась в коридоре
      if (moveDownAfterTopAfterSecondBottom / moveUpAfterSecondBottom >= sizeCorrectionToHavePotentialForFlatContextOnLowerTfZigzagSetting / 100) {//если коррекция достаточно глубокая
        return true
      } else {
        return false
      }
    }
  } else {
    return false
  }
}

/**
 * Проверка по зигзагу, что текущая линия зигзага направлена вниз, контекст восходящий или флетовый
 * и коррекцией набран соответствующий типу контекста потенциал для дальнейшего роста и коррекция не слишком глубокая
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param differenceWaveZzToDetermineDirectionOfContext сколько процентов должна быть разница между восходящей и нисходящей волной, чтобы признать что контекст восходящий или нисходящий
 * @param sizeCorrectionToHavePotentialForUpContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при растущем контексте
 * @param sizeCorrectionToHavePotentialForFlatContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при флетовом контексте
 * @param maxSizeCorrectionToHavePotentialForUpContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при растущем контексте
 * @param maxSizeCorrectionToHavePotentialForFlatContextOnZigzag размер коррекции на зигзаге для понимания что есть потенциал для роста при флетовом контексте
 * @return boolean значение, что есть потенциал
 */
export const checkDirectionAndContextDirectionAndHavePotentialAndCorrectionNotTooMuchByZigzag = (
  zigzag: IZigZagNode[],
  differenceWaveZzToDetermineDirectionOfContext: number,
  sizeCorrectionToHavePotentialForUpContextOnZigzag: number,
  sizeCorrectionToHavePotentialForFlatContextOnZigzag: number,
  maxSizeCorrectionToHavePotentialForUpContextOnZigzag: number,
  maxSizeCorrectionToHavePotentialForFlatContextOnZigzag: number,
): boolean => {
  //для проверки направления контекста и коррекции нужно минимум четыре узла зигзага
  if (zigzag.length >= 4) {
    if (zigzag[0].price >= zigzag[1].price) {//если текущая волна вверх, то условие не выполнено
      return false
    }
    let waveDown = zigzag[3].price - zigzag[2].price
    let waveUp = zigzag[1].price - zigzag[2].price
    let coeffContext = waveUp / waveDown
    if (coeffContext < ((100 - differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст нисходящий, то условие не выполнено
      return false
    }
    let waveCurrent = zigzag[1].price - zigzag[0].price
    let coeffCorrection = waveCurrent / waveUp
    if (coeffContext >= ((100 + differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст восходящий
      if ((coeffCorrection > sizeCorrectionToHavePotentialForUpContextOnZigzag / 100) && (coeffCorrection < maxSizeCorrectionToHavePotentialForUpContextOnZigzag / 100)) {//если коррекция достаточная, но не больше максимально допустимой
        return true
      } else {//если коррекция не достаточная или больше максимально допустимой
        return false
      }
    } else if (coeffContext >= ((100 - differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст флетовый
      if ((coeffCorrection > sizeCorrectionToHavePotentialForFlatContextOnZigzag / 100) && (coeffCorrection < maxSizeCorrectionToHavePotentialForFlatContextOnZigzag / 100)) {//если коррекция достаточная, но не больше максимально допустимой
        return true
      } else {//если коррекция не достаточная или больше максимально допустимой
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Проверка по зигзагу, что цена выбрала ранее накопленный потенциал.
 * За базу берется нисходящая волна зигзага на которой набирался потенциал для входа,
 * цена должна пройти от нижней точки базовой волны определенную часть базовой волны в зависимости
 * от направления контекста в момент входа
 * @param candles график, на котором строился зигзаг
 * @param zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @param dateOpen дата открытия сделки
 * @param priceOpen суррогатная (рассчитанная из всех входов в серии) цена открытия сделки
 * @param sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting размер движения по тренду на зигзаге для понимания что потенциал роста выбран при растущем контексте
 * @param sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting размер движения по тренду на зигзаге для понимания что потенциал роста выбран при флетовом контексте
 * @param differenceWaveZzToDetermineDirectionOfContext сколько процентов должна быть разница между восходящей и нисходящей волной, чтобы признать что контекст восходящий или нисходящий
 * @return boolean значение, что есть потенциал
 */
export const checkSizeMoveUpToFinishPotentialAfterOrderByZigzag = (
  candles: Candle[],
  zigzag: IZigZagNode[],
  dateOpen: string,
  priceOpen: number,
  sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting: number,
  sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting: number,
  differenceWaveZzToDetermineDirectionOfContext: number,
): boolean => {
  if (zigzag.length >= 5) {

    //поиск звена зигзага на котором прошла сделка
    let nodeZzWithOrder
    for (let i = 0; i < zigzag.length; i++) {
      const dateOfNode = candles[zigzag[i].ind][CandleTypePart.end]
      if (dayjs(dateOpen) > dayjs(dateOfNode)) {
        nodeZzWithOrder = i
        break
      }
    }
    if (!nodeZzWithOrder) return false

    //нахождение базового элемента зигзага и его параметров - это звено направленное вниз на котором шел набор потенциала для сделки
    const nodeZzAsBaseElement = (zigzag[nodeZzWithOrder].price - zigzag[nodeZzWithOrder - 1].price) > 0 ? nodeZzWithOrder : nodeZzWithOrder + 1
    let bottomBaseElement = zigzag[nodeZzAsBaseElement - 1].price
    for (let i = zigzag[nodeZzAsBaseElement].ind; i <= zigzag[nodeZzAsBaseElement - 1].ind; i++) {
      if (candles[i][CandleTypePart.close] < bottomBaseElement) {
        bottomBaseElement = candles[i][CandleTypePart.close]
      }
    }
    if (priceOpen < bottomBaseElement) {
      bottomBaseElement = priceOpen
    }
    const sizeBaseElement = zigzag[nodeZzAsBaseElement].price - bottomBaseElement

    //определение направления контекста на момент заключения сделки
    const waveDown = zigzag[nodeZzAsBaseElement + 2].price - zigzag[nodeZzAsBaseElement + 1].price
    const waveUp = zigzag[nodeZzAsBaseElement].price - zigzag[nodeZzAsBaseElement + 1].price
    const coeffContext = waveUp / waveDown
    const currentPrice = candles[candles.length - 1][CandleTypePart.close]
    if (coeffContext >= ((100 + differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст восходящий
      //нахождение ожидаемого уровня цены при котором считаем что потенциал выбран и сравнение с текущей ценой
      const waitPrice = bottomBaseElement + sizeBaseElement * sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting / 100
      if (currentPrice >= waitPrice) {
        return true
      } else {
        return false
      }
    } else if (coeffContext >= ((100 - differenceWaveZzToDetermineDirectionOfContext) / 100)) {//если контекст флетовый
      //нахождение ожидаемого уровня цены при котором считаем что потенциал выбран и сравнение с текущей ценой
      const waitPrice = bottomBaseElement + sizeBaseElement * sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting / 100
      if (currentPrice >= waitPrice) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return true
  }
}

/**
 * Расчет открытой сделки-заменителя открытой серии сделок
 * @param openedSeriesOfTransactions информация об открытой серии сделок
 * @return сделка-заменитель
 */
export const calculateSurrogateTransaction = (openedSeriesOfTransactions: ISeriesOfTransaction): IEnter => {
  let sumEnter = 0
  let sumValue = 0
  openedSeriesOfTransactions.enters.forEach((enter) => {
    if (enter.price && enter.value) {
      sumEnter += enter.price * enter.value
      sumValue += enter.value
    }
  })
  openedSeriesOfTransactions.exits.forEach((exit) => {
    if (exit.price && exit.value) {
      sumEnter -= exit.price * exit.value
      sumValue -= exit.value
    }
  })
  const price = sumEnter / sumValue
  const value = sumValue
  const date = openedSeriesOfTransactions.enters[0].date
  return {price, date, value}
}

/**
 * Расчет закрытой сделки-заменителя закрытой серии сделок
 * @param openedSeriesOfTransactions информация об открытой серии сделок
 * @return сделка-заменитель
 */
export const calculateSurrogateCloseTransaction = (openedSeriesOfTransactions: ISeriesOfTransaction): IOrder => {
  let sumEnter = 0
  let sumExit = 0
  let sumValueEnters = 0
  let sumValueExits = 0
  openedSeriesOfTransactions.enters.forEach((enter) => {
    if (enter.price && enter.value) {
      sumValueEnters += enter.value
      sumEnter += enter.price * enter.value
    }
  })
  openedSeriesOfTransactions.exits.forEach((exit) => {
    if (exit.price && exit.value) {
      sumValueExits += exit.value
      sumExit += exit.price * exit.value
    }
  })
  const priceEnter = sumEnter / sumValueEnters
  const priceExit = sumExit / sumValueExits
  return {
    enter: {
      price: priceEnter,
      date: openedSeriesOfTransactions.enters[0].date,
      value: sumValueEnters
    },
    exit: {
      price: priceExit,
      date: openedSeriesOfTransactions.exits[openedSeriesOfTransactions.exits.length - 1].date,
      value: sumValueExits
    }
  }
}

/**
 * Проверка, что текущее движение на зигзаге вверх
 * @param  zigzag индикатор зигзаг в виде обратного массива узлов зигзага
 * @return boolean значение, что текущее движение вверх
 */
export const checkCurrentMoveIsUpByZigzag = (zigzag: IZigZagNode[]): boolean => {
  if (zigzag[0].price > zigzag[1].price) {
    return true
  } else {
    return false
  }
}

/**
 * Выбор таймфрейма для расчета точек Пивот в зависимости от тф на котором работаем
 * @param  tf таймфрейм на котором работаем
 * @return таймфрейм для расчета точек Пивот
 */
export const choiceTfForPivot = (tf: TimeFrames): TimeFrames => {
  let tfForPivot = TimeFrames.month
  switch (tf) {
    case TimeFrames.m10:
      tfForPivot = TimeFrames.day
      break
    case TimeFrames.m60:
      tfForPivot = TimeFrames.day
      break
    case TimeFrames.day:
      tfForPivot = TimeFrames.week
      break
    case TimeFrames.week:
      tfForPivot = TimeFrames.month
      break
    case TimeFrames.month:
      tfForPivot = TimeFrames.month
      break
  }
  return tfForPivot
}

/**
 * Определение максимальной и минимальной цены на графике и объема
 * @param chart - график
 * @param startIndex - опционально можно указать начало поиска, включительно
 * @param endIndex - опционально можно указать конец поиска, включительно
 * @return максимальная и минимальная цены и максимальный объем
 */
export const getLimitPrices = (chart: Candle[], startIndex?: number, endIndex?: number) => {
  const start = startIndex ? startIndex : 0
  const end = endIndex ? endIndex : chart.length - 1
  let highPrice = chart[start][CandleTypePart.high]
  let lowPrice = chart[start][CandleTypePart.low]
  let highVolume = chart[start][CandleTypePart.volume]
  for (let i = start; i <= end; i++) {
    if (chart[i][CandleTypePart.high] > highPrice) {
      highPrice = chart[i][CandleTypePart.high]
    }
    if (chart[i][CandleTypePart.low] < lowPrice) {
      lowPrice = chart[i][CandleTypePart.low]
    }
    if (chart[i][CandleTypePart.volume] > highVolume) {
      highVolume = chart[i][CandleTypePart.volume]
    }
  }

  return {highPrice, lowPrice, highVolume}
}

/**
 * Определение максимального и минимального значения на индикаторе MACD
 * @param macd - значения индикатора MACD
 * @param macdSignal - значения сигнальной линии индикатора MACD
 * @param macdHistogram - значения индикатора MACD Histogram
 * @return максимальное и минимальное значение
 */
export const getLimitValuesOnMacd = (macd: number[], macdSignal: number[], macdHistogram: number[]) => {
  let highValue = macd[0]
  let lowValue = macd[0]
  macd.forEach((macdVal, index) => {
    const macdValue = macdVal
    const macdSignalValue = macdSignal[index]
    const macdHistogramValue = macdHistogram[index]
    const maxValue = Math.max(macdValue, macdSignalValue, macdHistogramValue)
    const minValue = Math.min(macdValue, macdSignalValue, macdHistogramValue)
    if (highValue < maxValue) {
      highValue = maxValue
    }
    if (lowValue > minValue) {
      lowValue = minValue
    }
  })

  highValue = Math.round(highValue)
  lowValue = Math.round(lowValue)

  return {highValue, lowValue}
}

/**
 * Определение направления свечи
 * @param candle - свеча
 * @return направление свечи
 */
export const getDirectionOfCandle = (candle: Candle): DirectionsOfCandle => {
  let direction: DirectionsOfCandle
  const open = candle[CandleTypePart.open]
  const close = candle[CandleTypePart.close]
  if (open < close) {
    direction = DirectionsOfCandle.UP
  } else if (open > close) {
    direction = DirectionsOfCandle.DOWN
  } else {
    direction = DirectionsOfCandle.DOJI
  }
  return direction
}


