//типы данных на сайте investing.com
import {TimeFrames} from "./types";

//таймфреймы
export enum InvestingComTimeFrames {
  '1M' = '1M',
  '1W' = '1W',
  '1D' = '1D'
}

//трансляция местных таймфреймов в мои
export const translateInvestingComTimeFramesToMy: Record<InvestingComTimeFrames, TimeFrames> = {
  [InvestingComTimeFrames['1M']]: TimeFrames.month,
  [InvestingComTimeFrames['1W']]: TimeFrames.week,
  [InvestingComTimeFrames['1D']]: TimeFrames.day
}

//направление свечных моделей
export enum InvestingComModelsDirection {
  'Бычий разворот' = 'Бычий разворот',
  'Медвежий разворот' = 'Медвежий разворот',
  'Продолжение медвежьего тренда' = 'Продолжение медвежьего тренда',
  'Продолжение бычьего тренда' = 'Продолжение бычьего тренда'
}

//сила свечных моделей
export enum InvestingComModelsStrength {
  'Средняя' = 'Средняя',
  'Мин.' = 'Мин.',
  'Макс.' = 'Макс.',
}

//значения финансовых состояний
export enum FinancialState {
  'Слабо' = 'Слабо',
  'Справедл.' = 'Справедл.',
  'Хорошо' = 'Хорошо',
  'Отлично' = 'Отлично',
  'Наверно Превосходно но я не нашел его' = 'Наверно Превосходно но я не нашел его',
}

//соответствие значений Фин. состояния цветам
export const ColorOfFinancialState: Record<FinancialState, string> = {
  'Слабо': '#D9140033',
  'Справедл.': '#D9860033',
  'Хорошо': '#B5A31033',
  'Отлично': '#0EA60033',
  'Наверно Превосходно но я не нашел его': '#D9140033',
}

//соответствие значений Фин. состояния положению на шкале
export const PositionOfFinancialState: Record<FinancialState, string> = {
  'Слабо': '22px',
  'Справедл.': '66px',
  'Хорошо': '110px',
  'Отлично': '154px',
  'Наверно Превосходно но я не нашел его': '198px',
}

//значения Справедливая стоимость
export enum FairPrices {
  'Переоценено' = 'Переоценено',
  'Справедливо' = 'Справедливо',
  'Недооценено' = 'Недооценено',
}

//соответствие значений Фин. состояния цветам
export const ColorOfFairPrice: Record<FairPrices, string> = {
  'Переоценено': '#de3221',
  'Справедливо': '#d98600',
  'Недооценено': '#007c32',
}

//знаки значений
export enum DirectionValues {
  'positive' = 'positive',
  'negative' = 'negative',
}

//соответствие значений Фин. состояния цветам
export const ColorOfDirectionValues: Record<DirectionValues, string> = {
  'positive': '#007c32',
  'negative': '#d91400',
}

//значения Настроений
export enum Sentiment {
  'Покупать' = 'Покупать',
  'Активно покупать' = 'Активно покупать',
  'Нейтрально' = 'Нейтрально',
  'Продавать' = 'Продавать',
}

//соответствие значений Настроений цветам
export const ColorOfSentiment: Record<Sentiment, string> = {
  'Покупать': '#007c32',
  'Активно покупать': '#007c32',
  'Нейтрально': '#232526',
  'Продавать': '#db210e',
}

//значения классов, определяющих цвет записи ProTips
export enum ProTipsCssClasses {
  'text-positive-main' = 'text-positive-main',
  'text-negative-main' = 'text-negative-main',
  'doubter' = 'doubter',
}

//значения классов, определяющих направление прогноза пользователя
export enum ForecastCssClasses {
  'newSmallBullIcon2' = 'newSmallBullIcon2',
  'newSmallBearIcon2' = 'newSmallBearIcon2',
}

//соответствие значений классов ProTips цветам
export const ColorOfProTips: Record<ProTipsCssClasses, string> = {
  'text-positive-main': '#007c32',
  'text-negative-main': '#d91400',
  'doubter': '#181c21',
}

//соответствие значений классов направление прогноза пользователя цветам
export const ColorOfForecast: Record<ForecastCssClasses, string> = {
  'newSmallBullIcon2': '#007c32',
  'newSmallBearIcon2': '#d91400',
}

