import React, {useState} from 'react';
import styles from "./testing.module.scss";
import StrategiesWrap from "./strategiesWrap/StrategiesWrap";
import GetHistory from "./getHistory/GetHistory";
import {Countries} from "../../shared/types";
import flagRu from "../../assets/images/flag-ru.png";
import flagUs from "../../assets/images/flag-us.png";

const Testing = () => {

  const [country, setCountry] = useState<Countries>()

  //обработчик выбора страны (только намерение выбора), по акциям которой будем работать
  const switchCountryHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const imgTagCountry = event.target as HTMLElement
    const country = imgTagCountry.dataset['country'] as Countries
    if (country === Countries.RU) {
      setCountry(Countries.RU)
    } else if (country === Countries.US) {
      setCountry(Countries.US)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <p className={styles.title}>Тестирование стратегий</p>
          <div className={styles.country} onClick={switchCountryHandler}>
            <p className={
              country === Countries.RU ? styles.active : ''
            }>
              <img src={flagRu} alt="flag-ru" data-country={Countries.RU}/>
            </p>
            <p className={
              country === Countries.US ? styles.active : ''
            }>
              <img src={flagUs} alt="flag-us" data-country={Countries.US}/>
            </p>
          </div>
        </div>
        {
          country &&
          <div className={styles.table}>
            <GetHistory country={country}/>
            <StrategiesWrap country={country}/>
          </div>
        }
      </div>
    </div>
  );
};

export default Testing;