import React, {useEffect, useState} from 'react';
import styles from "./main.module.scss";
import {Button, message as messageAnt} from "antd";
import {Countries} from "../../shared/types";
import flagRu from '../../assets/images/flag-ru.png'
import flagUs from '../../assets/images/flag-us.png'
import GetData from "./getData/GetData";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {countrySlice} from "../../store/redusers/countrySlice";
import {useTickerList} from "../../hooks/tickerList.hook";
import ColorIndicator from "../../components/colorIndicator/ColorIndicator";
import {Color as ColorOfIndicator} from "../../components/colorIndicator/ColorIndicator";

//в Main выбираем страну и получаем соответствующий ей список тикеров
const Main = () => {

  const {loading, getTickers, error, clearError} = useTickerList()

  const country = useAppSelector(state => state.countryReduser.country)
  const tickerList = useAppSelector(state => state.countryReduser.tickerList)
  const {setCountry, setTickerList} = countrySlice.actions

  const dispatch = useAppDispatch()

  const [colorIndicator, setColorIndicator] = useState<ColorOfIndicator>(ColorOfIndicator.gray)//цвет индикатора результата загрузки списка тикеров
  const [btnStartIsClicked, setBtnStartIsClicked] = useState<boolean>(false);//состояние кнопки Старт, которая запускает весь процесс в зависимости от выбранной страны
  const [btnStartIsLoading, setBtnStartIsLoading] = useState<boolean>(false);//состояние кнопки Старт, которая запускает весь процесс в зависимости от выбранной страны

  //обработчик выбора страны (только намерение выбора), по акциям которой будем работать
  const switchCountryHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    setBtnStartIsClicked(false);
    setColorIndicator(ColorOfIndicator.gray)
    const imgTagCountry = event.target as HTMLElement
    const country = imgTagCountry.dataset['country'] as Countries
    if (country === Countries.RU) {
      dispatch(setCountry(Countries.RU))
    } else if (country === Countries.US) {
      dispatch(setCountry(Countries.US))
    }
  }

  const getTickerList = async (country: Countries) => {
    try {
      setColorIndicator(ColorOfIndicator.gray)
      const dataTickers = await getTickers(country)
      messageAnt.info(dataTickers.message)
      dispatch(setTickerList(dataTickers.info))
      setColorIndicator(ColorOfIndicator.green)
    } catch (e) {
      messageAnt.info(error);
      clearError()
      setColorIndicator(ColorOfIndicator.red)
    }
  }

  //получение списка тикеров с сайта ru.investing.com в зависимости от выбранной страны
  useEffect(() => {
    if (country) {
      const func = async () => {
        setBtnStartIsLoading(true)
        await getTickerList(country)
        setBtnStartIsLoading(false)
      }
      func()
    }
  }, [country])

  //кнопка запуска
  const btnHandler = async () => {
    if (country) {
      setBtnStartIsClicked(true);
    }
  }

  //принудительная загрузка списка тикеров на случай сбоя в автоматической загрузке
  const forcedDownloadTickers = () => {
    if (colorIndicator === ColorOfIndicator.red) {
      const areYouSure = window.confirm(`Принудительная загрузка списка тикеров. Продолжить?`)
      if (!areYouSure) {
        return
      }
      if (country) {
        const func = async () => {
          setBtnStartIsLoading(true)
          await getTickerList(country)
          setBtnStartIsLoading(false)
        }
        func()
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.start}>
            {
              country &&
              <div className={styles.indicators}>
                <ColorIndicator onClick={forcedDownloadTickers} color={colorIndicator}
                                description={'Индикатор загрузки списка тикеров'}/>
              </div>
            }
            <div className={styles.country} onClick={switchCountryHandler}>
              <p className={
                country === Countries.RU ? styles.active : ''
              }>
                <img src={flagRu} alt="flag-ru" data-country={Countries.RU}/>
              </p>
              <p className={
                country === Countries.US ? styles.active : ''
              }>
                <img src={flagUs} alt="flag-us" data-country={Countries.US}/>
              </p>
            </div>
            <Button onClick={btnHandler} type={'primary'}
                    disabled={!country || btnStartIsClicked}
                    loading={btnStartIsLoading}
                    style={{'width': '100px'}}
            > Старт </Button>
          </div>
        </div>
        {
          btnStartIsClicked && country && tickerList.length !== 0 ?
            <GetData/> :
            null
        }
      </div>
    </div>
  );
};

export default Main;