import React, {FC} from 'react';
import styles from './closer.module.scss'

type Props = {
  closeHandler: () => void
}

const Closer: FC<Props> = ({closeHandler}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content} onClick={closeHandler}>
      </div>
    </div>
  );
};

export default Closer;