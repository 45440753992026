import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  token: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    auth: (state, action) => {
      state.userId = action.payload.userId
      state.token = action.payload.token
    }
  }
})

export const { auth } = authSlice.actions

export default authSlice.reducer