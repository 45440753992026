import React from 'react';
import styles from "./positions.module.scss";
import {Candle, Countries, TimeFrames} from "../../../shared/types";

interface Props {
  active: boolean
  quotes: Record<string, Record<TimeFrames, Candle[]>> | undefined
  country: Countries
}

const Positions = ({active, quotes, country}: Props) => {

  //если сделка совершена, то возможно она должна попасть на страницу отслеживаемые
  //не понятно нужна ли вкладка Отслеживание если стратегии на выход из сделки тоже работают в Advisors

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.table}>
          Positions
        </div>
      </div>
    </div>
  );
};

export default Positions;