import React from 'react';
import styles from "./news.module.scss";
import {INewsAnalytics} from "../../../../../../shared/types";
import {v4 as uuidv4} from "uuid";
import news from '../../../../../../assets/images/news.png'

interface Props {
  active: boolean
  analytic: INewsAnalytics[]
}

const News = ({active, analytic}: Props) => {

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          {
            analytic && analytic.map((piece) => (
              <div key={uuidv4()} className={styles.piece}>
                <div className={styles.image}>
                  {
                    piece.img ?
                      <img src={piece.img} alt="news"/> :
                      <img src={news} alt="news"/>
                  }
                </div>
                <div className={styles.text}>
                  <a href={piece.link} target={'_blank'}>{piece.title}</a>
                  <p className={styles.descr}>{piece.descr}</p>
                  <p className={styles.date}>{piece.date}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default News;