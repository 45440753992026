import {useHttp} from "./http.hook";
import {Countries} from "../shared/types";

interface IDataGetAll {
  message: string,
  info: string[]
}

export const useTickerList = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение полного списка инструментов с ru.investing.com, по которым будем торговать
  const getTickers = async (country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/tickers/get-ticker-list', 'POST', {
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getTickers, error, clearError}
}