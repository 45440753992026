import {useHttp} from "./http.hook";
import {Countries, ExpertAnalyticsType} from "../shared/types";

interface IDataGetExpertAnalytics {
  message: string,
  info: ExpertAnalyticsType
}

export const useExpertAnalytics = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение всех экспертных мнений по тикеру
  const getExpert = async (country: Countries, tickerList: string[]) => {
    try {
      const data: IDataGetExpertAnalytics = await request('/api/analytics/get-expert', 'POST', {
        country: country,
        tickerList: tickerList
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getExpert, error, clearError}
}