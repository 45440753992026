import {useHttp} from "./http.hook";
import {Countries, IStrategyPositions, Strategies} from "../shared/types";

interface IDataSaveOpenedPositions {
  message: string,
}

interface IDataGetAll {
  message: string,
  info: IStrategyPositions[]
}

export const usePositions = () => {

  const {loading, request, error, clearError} = useHttp()

  //сохранение данных по открытым позициям для одной выбранной стратегии
  const saveOpenedPositions = async (country: Countries, strategy: Strategies, positions: string) => {
    try {
      const data: IDataSaveOpenedPositions = await request('/api/positions/save-one-strategy', 'POST', {
        strategy: strategy,
        positions: positions,
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //удаление данных по открытым позициям для одной выбранной стратегии если закрыта последняя сделка по стратегии
  const removeOpenedPositions = async (country: Countries, strategy: Strategies) => {
    try {
      const data: IDataSaveOpenedPositions = await request('/api/positions/remove-one-strategy', 'POST', {
        strategy: strategy,
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение всех открытых позиций по всем стратегиям
  const getAllPositions = async (country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/positions', 'POST', {
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, saveOpenedPositions, removeOpenedPositions, getAllPositions, error, clearError}
}