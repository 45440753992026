import {useHttp} from "./http.hook";
import {IStrategySettings, Strategies} from "../shared/types";

interface IDataGetAll {
  message: string,
  info: IStrategySettings[]
}

interface IDataSaveSettings {
  message: string,
}

export const useSettings = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение всех настроек по всем стратегиям
  const getAllSettings = async () => {
    try {
      const data: IDataGetAll = await request('/api/settings', 'GET')
      return data
    } catch (e) {
      throw e
    }
  }

  //сохранение настроек для одной выбранной стратегии
  const saveSettingsForStrategy = async (strategy: Strategies, enable: boolean, settings: string) => {
    try {
      const data: IDataSaveSettings = await request('/api/settings/save-one', 'POST', {
        strategy: strategy,
        enable: enable,
        settings: settings
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getAllSettings, saveSettingsForStrategy, error, clearError}
}