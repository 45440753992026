import {
  Candle, CandleTypePart,
  IParamsStrategyFunction, IResultStrategy, ISeriesOfTransaction, IStrategyClass,
  IZigZagNode,
  StateOfTrade,
  TimeFrames
} from "../shared/types";
import {
  checkCurrentMoveIsUpByZigzag,
  checkDirectionAndContextDirectionAndHavePotentialAndCorrectionNotTooMuchByZigzag,
  checkDirectionAndContextDirectionAndHavePotentialByZigzag,
  getZigZag
} from "../shared/lib/lib";
import dayjs from "dayjs";

//набор графиков нужных для стратегии, как три экрана, если нужен один или два, то начинать сохранять с lowTf
interface IKitCharts {
  lowTf: Candle[]
  midTf: Candle[]
  highTf: Candle[]
}

//набор всех графиков для стратегии
interface ICurrentCharts {
  m10: Candle[],
  m60: Candle[],
  day: Candle[],
  week: Candle[],
  month: Candle[]
}

export class StrategyThreeScreens implements IStrategyClass {

  //ЭТОТ МЕТОД СТРАТЕГИИ ДОДЕЛАН, НЕ ДОДЕЛАНЫ МЕТОДЫ ПОИСКА ВХОДОВ И ВЫХОДОВ

  //настройки стратегии, пока их должно быть строго 20
  public settings = {
    'MIN_DELTA_ON_ZIGZAG': 'мин дельта на зигзаге, ниже какого % колебания считаем шумом',
    'DIFFERENCE_WAVE_ZIGZAG_TO_DETERMINE_DIRECTION_OF_CONTEXT_ON_UPPER_TF_ZIGZAG': 'разница в процентах между волнами зигзага для определения что контекст восходящий на старшем экране',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_UP_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при растущем контексте на старшем экране',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_FLAT_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при флетовом контексте на старшем экране',
    'MAX_SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_UP_CONTEXT_ON_UPPER_TF_ZIGZAG': 'максимально допустимый размер коррекции достаточный для набора потенциала при растущем контексте на старшем экране',
    'MAX_SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_FLAT_CONTEXT_ON_UPPER_TF_ZIGZAG': 'максимально допустимый размер коррекции достаточный для набора потенциала при флетовом контексте на старшем экране',
    'SIZE_MOVE_UP_TO_FINISH_POTENTIAL_FOR_UP_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер движения вверх достаточный для израсходования потенциала при растущем контексте на старшем экране',
    'SIZE_MOVE_UP_TO_FINISH_POTENTIAL_FOR_FLAT_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер движения вверх достаточный для израсходования потенциала при флетовом контексте на старшем экране',
    'NONE_8': '',
    'NONE_9': '',
    'NONE_10': '',
    'NONE_11': '',
    'NONE_12': '',
    'NONE_13': '',
    'NONE_14': '',
    'NONE_15': '',
    'NONE_16': '',
    'NONE_17': '',
    'NONE_18': '',
    'NONE_19': '',
  }

  public main(params: IParamsStrategyFunction, openedSeriesOfTransactions: ISeriesOfTransaction): IResultStrategy {
    //данная стратегия сама прорабатывает все тф, поэтому не нужно ее прогонять по всем тф, обрабатываем только условно week, все остальные пропускаем
    if (params.timeframe !== TimeFrames.week) {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }

    //настройки стратегии
    const settings = params.settings
    //набор графиков
    const currentCharts: ICurrentCharts = {
      m10: params.currentInfoTfM10,
      m60: params.currentInfoTfM60,
      day: params.currentInfoTfDay,
      week: params.currentInfoTfWeek,
      month: params.currentInfoTfMonth
    }

    //проверка что массивы с графиками не пустые
    if (params.currentInfoTfM10.length === 0 || params.currentInfoTfM60.length === 0 || params.currentInfoTfDay.length === 0 || params.currentInfoTfWeek.length === 0 || params.currentInfoTfMonth.length === 0) {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }

    //тип действия, которое требуется от стратегии
    type ActionType = 'FIND_ENTER' | 'FIND_EXIT' | 'NONE'
    let actionType: ActionType
    //определяем, что должна сделать стратегия в данных обстоятельствах
    if (openedSeriesOfTransactions.enters.length === 0) {
      //если открытых позиций нет, то стратегии нужно искать вход
      actionType = "FIND_ENTER"
    } else if (openedSeriesOfTransactions.enters.length === 1) {
      //если открытая позиция есть, то стратегии нужно искать выход
      actionType = "FIND_EXIT"
    } else {
      actionType = "NONE"
    }

    //массив соответствия тф и delta зигзага, согласно стратегии по нему будем проходить от больших тф к маленьким пока не встретим подходящие условия для входа
    const arrZzTf = [
      {zz: 17, tf: TimeFrames.week}, {zz: 16, tf: TimeFrames.week}, {zz: 15, tf: TimeFrames.week},
      {zz: 14, tf: TimeFrames.week}, {zz: 13, tf: TimeFrames.week}, {zz: 12, tf: TimeFrames.week},
      {zz: 11, tf: TimeFrames.week}, {zz: 10, tf: TimeFrames.week}, {zz: 9, tf: TimeFrames.day},
      {zz: 8, tf: TimeFrames.day}, {zz: 7, tf: TimeFrames.day}, {zz: 6, tf: TimeFrames.day},
      {zz: 5, tf: TimeFrames.day}, {zz: 4, tf: TimeFrames.day}, {zz: 3, tf: TimeFrames.m60},
      {zz: 2, tf: TimeFrames.m60}, {zz: 1, tf: TimeFrames.m60},
      {zz: 0.6, tf: TimeFrames.m10}, {zz: 0.3, tf: TimeFrames.m10}
    ]

    //в зависимости от выявленного режима стратегии, отработать нужное действие
    if (actionType === "FIND_ENTER") {
      let {isFound, price, date, value, specificity} = this.findEnter(currentCharts, settings, arrZzTf)
      if (isFound && price && date && value && specificity) {
        return {
          state: StateOfTrade.NEED_OPEN_SERIES,
          price: price,
          date: date,
          value: value,
          specificity: specificity
        }
      } else {
        return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
      }
    } else if (actionType === "FIND_EXIT") {
      let {isFound, price, date, value, specificity} = this.findExit(currentCharts, settings, openedSeriesOfTransactions)
      if (isFound && price && date && value && specificity) {
        return {
          state: StateOfTrade.NEED_CLOSE_SERIES,
          price: price,
          date: date,
          value: value,
          specificity: specificity
        }
      } else {
        return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
      }
    } else {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }
  }

  public strategyDescription = 'Стратегия трех экранов.' +
    '\n' +
    '\n    Описание:' +
    '\n        На старшем тф есть растущий контекст, полноценная коррекция, текущее движение вверх и потенциал роста не израсходован, на среднем тф то же самое, ' +
    'на нижнем тф растущий контекст и достаточная коррекция. Выход по истечению потенциала на нижнем тф.' +
    '\n' +
    '\n    Критерии входа:' +
    '\n        * найден старший зигзаг, на нем есть не падающий контекст, полноценная коррекция в зависимости от контекста ' +
    'не менее X% от последней растущей волны и не менее расчетного значения для Y% всех коррекций подобного контекста и ' +
    'коррекция не больше максимально допустимой и текущее движение вверх и потенциал роста не израсходован;' +
    '\n        * найден средний зигзаг, на нем есть не падающий контекст, полноценная коррекция в зависимости от контекста ' +
    'не менее X% от последней растущей волны и не менее расчетного значения для Y% всех коррекций подобного контекста, и ' +
    'коррекция не больше максимально допустимой и текущее движение вверх и потенциал роста не израсходован;' +
    '\n        * найден младший зигзаг, на нем есть не падающий контекст, полноценная коррекция в зависимости от контекста ' +
    'не менее X% от последней растущей волны и не менее расчетного значения для Y% всех коррекций подобного контекста;' +
    '\n        * ;' +
    '\n        * ;' +
    '\n        * ;' +
    '\n' +
    '\n    Критерии выхода:' +
    '\n        * на младшем зигзаге растущая волна прошла Х% (в зависимости от контекста) от предыдущей волны;' +
    '\n        * если потенциал не реализовался, то выходим по достижению среднегодового среднерыночного дохода, ' +
    'пересчитанного на продолжительность сделки;' +
    '\n        * ;' +
    '\n        * '

  public singleVolumeInStrategyDescription = 'Для стратегии "Стратегия трех экранов":' +
    '\n    Единичный объем равен 1/1 разрешенного объема на один инструмент.'

  /**
   * Поиск сделки согласно алгоритму стратегии по текущему графику
   * @param currentCharts - набор графиков нужных стратегии
   * @param settings - массив настроек для стратегии
   * @param arrZzTf - массив соответствия тф и delta зигзага, согласно стратегии по нему будем проходить от больших тф к маленьким пока не встретим подходящие условия для входа
   */
  private findEnter = (currentCharts: ICurrentCharts, settings: string[], arrZzTf: { zz: number, tf: TimeFrames }[]): { isFound: true, price: number, date: string, value: number, specificity: string } | { isFound: false, price: undefined, date: undefined, value: undefined, specificity: undefined } => {

    //настройки стратегии из инпутов (названия и индекс в массиве смотреть в поле settings этого класса)
    // const minDeltaOnZigzagSetting = Number(settings[0])
    const minDeltaOnZigzagSetting = 1
    // const differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting = Number(settings[1])
    const differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting = 10
    // const sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting = Number(settings[2])
    const sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting = 38
    // const sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting = Number(settings[3])
    const sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting = 61
    // const maxSizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting = Number(settings[4])
    const maxSizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting = 100
    // const maxSizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting = Number(settings[5])
    const maxSizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting = 120
    // const sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting = Number(settings[6])
    const sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting = 50
    // const sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting = Number(settings[7])
    const sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting = 38

    //общий цикл прохода по всем таймфреймам и зигзагам
    for (let i = 0; i < arrZzTf.length; i++) {
      //если дошли до зигзага меньше минимально допустимого в настройках стратегии, выходим из поиска входа
      if (arrZzTf[i].zz < minDeltaOnZigzagSetting) {
        return {
          isFound: false,
          price: undefined,
          date: undefined,
          value: undefined,
          specificity: undefined
        }
      }

      //-----Поиск условий на старшем экране-----
      const zigzag = getZigZag(currentCharts[arrZzTf[i].tf], arrZzTf[i].zz)
      //проверка, что текущее движение вверх
      const currentMoveIsUp = checkCurrentMoveIsUpByZigzag(zigzag)
      if (!currentMoveIsUp) {
        continue
      }
      //зигзаг без последнего звена, так как метод checkDirectionAndContextDirectionAndHavePotentialByZigzag уже рассчитан на то, что последнее звено вниз, а не вверх
      const zigzagWithoutLastNode = zigzag.slice(1)
      //проверка что на старшем экране перед текущим движением вверх найдены не отрицательный контекст и коррекция больше минимально допустимой и меньше максимально допустимой
      const contextIsUpAndHavePotential = checkDirectionAndContextDirectionAndHavePotentialAndCorrectionNotTooMuchByZigzag(zigzagWithoutLastNode, differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting, maxSizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting, maxSizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting)
      if (!contextIsUpAndHavePotential) {
        continue
      }


      //-----Поиск условий на среднем экране-----
      let tf: TimeFrames | undefined //вспомогательная переменная
      let chartMiddleScreen: Candle[] = []
      //цикл прохода по всем таймфреймам и зигзагам ниже найденного на верхнем экране для проработки второго экрана
      for (let j = i + 1; j < arrZzTf.length; j++) {

        //если дошли до зигзага меньше минимально допустимого в настройках стратегии, выходим из поиска входа
        if (arrZzTf[j].zz < minDeltaOnZigzagSetting) {
          return {
            isFound: false,
            price: undefined,
            date: undefined,
            value: undefined,
            specificity: undefined
          }
        }

        //дата, начиная с которой будем брать часть графика для поиска условий на среднем экране, это последние два звена верхнего экрана
        const date = currentCharts[arrZzTf[i].tf][zigzag[2].ind][CandleTypePart.end]
        //отбор части графика среднего экрана, которая соответствует последним двум звеньям зигзага верхнего экрана
        if (tf !== arrZzTf[j].tf) {
          tf = arrZzTf[j].tf
          const fullChart = currentCharts[tf]
          for (let k = fullChart.length - 1; k >= 0; k--) {
            if (dayjs(fullChart[k][CandleTypePart.end]) < dayjs(date)) {
              chartMiddleScreen = fullChart.slice(k + 1)
              break
            }
          }
        }
        //если chartMiddleScreen.length === 0 т.е не заполнился, это значит что графика с таким мелким тф не хватило чтобы дойти до установленной даты, значит берем полный график
        if (chartMiddleScreen.length === 0) {
          chartMiddleScreen = currentCharts[arrZzTf[j].tf]
        }

        const zigzagMidScr = getZigZag(chartMiddleScreen, arrZzTf[j].zz)
        //проверка, что текущее движение вверх
        const currentMoveIsUpMidScr = checkCurrentMoveIsUpByZigzag(zigzagMidScr)
        if (!currentMoveIsUpMidScr) {
          continue
        }
        //зигзаг без последнего звена, так как метод checkDirectionAndContextDirectionAndHavePotentialByZigzag уже рассчитан на то, что последнее звено вниз, а не вверх
        const zigzagWithoutLastNodeMidScr = zigzagMidScr.slice(1)
        //проверка что на среднем экране перед текущим движением вверх найдены не отрицательный контекст и коррекция больше минимально допустимой и меньше максимально допустимой
        const contextIsUpAndHavePotentialMidScr = checkDirectionAndContextDirectionAndHavePotentialAndCorrectionNotTooMuchByZigzag(zigzagWithoutLastNodeMidScr, differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting, maxSizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting, maxSizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting)
        if (!contextIsUpAndHavePotentialMidScr) {
          continue
        }



        //todo: здесь и на верхнем экране еще не реализована проверка что потенциал не израсходован еще (ввести тоже коэфф для обоих контекстов), и расчет средних размеров коррекций






        //если все условия пройдены, то ситуация для входа в сделку подходящая
        const specificity = {
          highTf: arrZzTf[i].tf,
          highZz: arrZzTf[i].zz,
          midTf: arrZzTf[j].tf,
          midZz: arrZzTf[j].zz,
        }
        return {
          isFound: true,
          price: currentCharts.m60[currentCharts.m60.length - 1][CandleTypePart.close],
          date: currentCharts.m60[currentCharts.m60.length - 1][CandleTypePart.end],
          value: 1,
          specificity: JSON.stringify(specificity)
        }
      }
    }

    //ответ что условия для входа не найдены
    return {
      isFound: false,
      price: undefined,
      date: undefined,
      value: undefined,
      specificity: undefined
    }
  }

  /**
   * Поиск выхода из сделки согласно алгоритму стратегии по текущему графику
   * @param currentCharts - набор графиков нужных стратегии
   * @param settings - массив настроек для стратегии
   * @param openedSeriesOfTransactions - информация об открытой серии сделок, если они есть
   */
  private findExit = (currentCharts: ICurrentCharts, settings: string[], openedSeriesOfTransactions: ISeriesOfTransaction): { isFound: true, price: number, date: string, value: number, specificity: string } | { isFound: false, price: undefined, date: undefined, value: undefined, specificity: undefined } => {

    //todo: временный возврат
    return {
      isFound: false,
      price: undefined,
      date: undefined,
      value: undefined,
      specificity: undefined
    }
  }

}














