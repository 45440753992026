import {Countries, IRatingUser} from "../shared/types";
import {useHttp} from "./http.hook";

interface IDataSaveMembersRating {
  message: string,
}

interface IDataGetAllMembersRating {
  message: string,
  info: IRatingUser[]
}

export const useMembersRating = () => {

  const {loading, request, error, clearError} = useHttp()

  //сохранение рейтинга эффективных голосующих пользователей в БД
  const saveMembersRating = async (country: Countries, ratingList: IRatingUser[]) => {
    try {
      const data: IDataSaveMembersRating = await request('/api/ratings/save-members-rating', 'POST', {
        country: country,
        ratingList: ratingList,
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение всех рейтингов эффективных голосующих пользователей из БД
  const getAllMembersRating = async () => {
    try {
      const data: IDataGetAllMembersRating = await request('/api/ratings/get-all-members-rating', 'GET')
      return data
    } catch (e) {
      throw e
    }
  }

  //получение всех рейтингов голосующих пользователей c сайта investing.com
  const getAllMembersRatingFromSite = async (country: Countries, tickerList: string[]) => {
    try {
      const data: IDataGetAllMembersRating = await request('/api/ratings/get-all-members-rating-from-site', 'POST', {
        country: country,
        tickerList: tickerList
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {
    loading,
    saveMembersRating,
    getAllMembersRating,
    getAllMembersRatingFromSite,
    error,
    clearError
    }
}