import React, {useEffect, useState} from 'react';
import styles from "./getHistory.module.scss";
import {Button, DatePicker, DatePickerProps, message as messageAnt, Select, SelectProps} from "antd";
import {useHttp} from "../../../hooks/http.hook";
import {Countries, TimeFrames} from "../../../shared/types";
import {v4 as uuidv4} from 'uuid';
import {tickerNameList, tickerNameListAmerica} from "../../../shared/tickerNameList";

interface Props {
  country: Countries
}

const GetHistory = ({country}: Props) => {

  const {loading, request, error, clearError} = useHttp()

  const [tickerForGetHistory, setTickerForGetHistory] = useState<string>()//тикер выбранный в селекте для получения исторических данных
  const [timeframeForGetHistory, setTimeframeForGetHistory] = useState<string>()//таймфреймов выбранный в селекте для получения исторических данных
  const [optionsSelectTicker, setOptionsSelectTicker] = useState<SelectProps['options']>()//массив тикеров, доступных для выбора
  const [optionsSelectTimeframe, setOptionsSelectTimeframe] = useState<SelectProps['options']>()//массив таймфреймов, доступных для выбора
  const [dateFrom, setDateFrom] = useState<string>()//начало периода, для которого нужно получить данные
  const [dateTill, setDateTill] = useState<string>()//конец периода, для которого нужно получить данные
  const [shortInfo, setShortInfo] = useState<Record<TimeFrames, string> | undefined>()//краткая сводка об наличии истории на сервере по выбранному тикеру
  const [tickerListWithHistory, setTickerListWithHistory] = useState<string[] | undefined>()//список тикеров с уже загруженной историей
  const [btnDisabled, setBtnDisabled] = useState(true)//блокировка кнопки если не все необходимые данные выбраны

  //изначальная подгрузка списка тикеров с уже загруженной историей для наглядности
  useEffect(() => {
    getTickerListWithHistoryFunc(country)
  }, [country])
  const getTickerListWithHistoryFunc = async (country: Countries) => {
    const info = await getTickerListWithHistory(country)
    setTickerListWithHistory(info)
  }
  const getTickerListWithHistory = async (country: Countries) => {
    try {
      const data = await request('/api/history/ticker-list', 'POST', {
        country: country
      })
      messageAnt.info(data.message)
      return JSON.parse(data.info)
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Что-то пошло не так'
      messageAnt.info(message);
      clearError()
    }
  }

  //формирование списков для селекторов выбора
  useEffect(() => {
    const optionsTicker: SelectProps['options'] = []
    if (country === Countries.RU) {
      Object.keys(tickerNameList).forEach((ticker) => {
        optionsTicker.push({value: ticker, label: ticker})
      })
    } else if (country === Countries.US) {
      Object.keys(tickerNameListAmerica).forEach((ticker) => {
        optionsTicker.push({value: ticker, label: ticker})
      })
    }
    setOptionsSelectTicker(optionsTicker)
    const optionsTimeframe: SelectProps['options'] = []
    Object.keys(TimeFrames).forEach((ticker) => {
      optionsTimeframe.push({value: ticker, label: ticker})
    })
    setOptionsSelectTimeframe(optionsTimeframe)
  }, [tickerNameList, tickerNameListAmerica, TimeFrames, country])

  //подгрузка краткой сводки об уже существующей исторической информации по выбранному тикеру
  const getShortInfo = async (country: string, ticker: string) => {
    try {
      const data = await request('/api/history/short-info', 'POST', {
        country: country,
        ticker: ticker,
      })
      messageAnt.info(data.message)
      return JSON.parse(data.info)
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Что-то пошло не так'
      messageAnt.info(message);
      clearError()
    }
  }
  const getShortInfoLocalFunc = async (country: string, ticker: string | undefined) => {
    if (ticker) {
      const info = await getShortInfo(country, ticker)
      setShortInfo(info)
    } else {
      setShortInfo(undefined)
    }
  }
  useEffect(() => {
    getShortInfoLocalFunc(country, tickerForGetHistory)
  }, [tickerForGetHistory])

  //выбор инструмента для получения исторических данных
  const onChangeTicker = (value: string) => {
    setTickerForGetHistory(value)
  };
  //выбор таймфрейма для получения исторических данных
  const onChangeTimeframe = (value: string) => {
    setTimeframeForGetHistory(value)
  };
  //выбор начала периода, для которого нужно получить данные
  const dateFromChangeHandler: DatePickerProps['onChange'] = (date, dateString) => {
    setDateFrom(dateString)
  };
  //выбор конца периода, для которого нужно получить данные
  const dateTillChangeHandler: DatePickerProps['onChange'] = (date, dateString) => {
    setDateTill(dateString)
  };

  //запрос на получение сервером исторических данных
  const getHistoryHandler = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    try {
      const data = await request('/api/history', 'POST', {
        country: country,
        ticker: tickerForGetHistory,
        dateFrom: dateFrom,
        till: dateTill,
        interval: timeframeForGetHistory
      })
      messageAnt.info(data.message)
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Что-то пошло не так'
      messageAnt.info(message);
      clearError()
    }
    await getShortInfoLocalFunc(country, tickerForGetHistory)
  }

  //управление активностью кнопки запуска
  useEffect(() => {
    if (dateFrom && dateTill && tickerForGetHistory && timeframeForGetHistory) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [dateFrom, dateTill, tickerForGetHistory, timeframeForGetHistory])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <h5>Исторические данные</h5>
          <div className={[styles.tickerList, styles.blockWrap].join(' ')}>
            <p className={styles.title}>История уже получена для</p>
            <div>
              <p className={styles.line}>
                {
                  tickerListWithHistory ?
                    tickerListWithHistory.map(ticker => (
                      <span key={uuidv4()}>{ticker} </span>
                    )) :
                    null
                }
              </p>
            </div>
          </div>
          <div className={[styles.settings, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Настройки получения данных</p>
            <Select showSearch allowClear placeholder="Тикер" onChange={onChangeTicker}
                    options={optionsSelectTicker} style={{width: 165}}/>
            <DatePicker placeholder="Начальная дата" onChange={dateFromChangeHandler} style={{width: 165, height: 32}}/>
            <DatePicker placeholder="Конечная дата" onChange={dateTillChangeHandler} style={{width: 165, height: 32}}/>
            <Select showSearch allowClear placeholder="Таймфрейм" onChange={onChangeTimeframe}
                    options={optionsSelectTimeframe} style={{width: 165}}/>
            <Button title={'Загрузить историю на сервер'} type={'primary'} onClick={getHistoryHandler}
                    loading={loading} style={{width: 165}} disabled={btnDisabled}>Получить</Button>
          </div>
          {
            tickerForGetHistory ?
              <div className={[styles.received, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Уже загруженные на сервер
                  данные{tickerForGetHistory ? ' по ' + tickerForGetHistory + ':' : ':'}</p>
                <div>
                  {
                    shortInfo ?
                      Object.keys(shortInfo).map(tf => (
                        <p className={styles.line} key={uuidv4()}>
                          <span className={styles.bold}>{tf}:</span> {shortInfo[tf as keyof typeof shortInfo]}</p>
                      )) :
                      null
                  }
                </div>
                <p className={styles.tip}>Для тестов лучше получить все ТФ на одинаковый период</p>
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default GetHistory;