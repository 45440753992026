import {useHttp} from "./http.hook";
import {Countries} from "../shared/types";

interface IDataGetAll {
  message: string,
  info: Record<string, any>
}

export const useQuotes = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение последних котировок по всем инструментам с ru.investing.com - там только актуальные цены в данный момент
  const getAllLastQuotes = async (tickers: string[], country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/quotes/get-all-last-quotes', 'POST', {
        tickers: tickers,
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  //получение графиков по всем инструментам с московской биржи - там все данные с опозданием на 15 мин
  const getAllCharts = async (tickers: string[], timeFrames: string[], country: Countries) => {
    try {
      const data: IDataGetAll = await request('/api/quotes/get-all-charts', 'POST', {
        tickers: tickers,
        timeFrames: timeFrames,
        country: country
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getAllLastQuotes, getAllCharts, error, clearError}
}