import {useHttp} from "./http.hook";
import {Countries, NewsAnalyticsType} from "../shared/types";

interface IDataGetNewsAnalytics {
  message: string,
  info: NewsAnalyticsType
}

export const useNewsAnalytics = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение всех новостей по тикеру
  const getNews = async (country: Countries, tickerList: string[]) => {
    try {
      const data: IDataGetNewsAnalytics = await request('/api/analytics/get-news', 'POST', {
        country: country,
        tickerList: tickerList
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getNews, error, clearError}
}