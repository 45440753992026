import React, {useEffect, useRef, useState} from 'react';
import styles from "./chart.module.scss";
import {Candle, ICandlesAnalytics, TimeFrames} from "../../../../../../../shared/types";
import {
  colorOfLegendCanvas,
  colorOfLineCandlestickCanvas,
  colorOfMarkUnderCandleCandlestickCanvas,
  colorOfVolumeOnDojiCandle,
  colorOfVolumeOnDownCandle,
  colorOfVolumeOnUpCandle,
  countCandleOnCandlestickCanvas,
  formulaOfCandlestickCandleCanvas,
  heightCandlestickCanvas,
  heightCandlestickVolumeCanvas,
  lineWidthOfVolumeCandlestickCanvas,
  offsetChartFromLeftBorderCandlestickCanvas,
  widthCandlestickCanvas
} from "../../../../../../../shared/configCanvas";
import {
  buildCandle,
  buildMarkUnderCandleWithModels,
  buildVolumeCandle,
  scalePrice,
} from "../../../../../../../shared/lib/canvas";
import {getLimitPrices} from "../../../../../../../shared/lib/lib";
import {
  InvestingComTimeFrames,
  translateInvestingComTimeFramesToMy
} from "../../../../../../../shared/investingComConfig";

interface ICanvasSize {
  width: number
  height: number
}

interface Props {
  timeframe: TimeFrames
  chart: Candle[]
  analytics: ICandlesAnalytics[]
}

const Chart = ({timeframe, chart, analytics}: Props) => {

  const chartCanvas = useRef<HTMLCanvasElement>(null)
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null)
  const [canvasSize, setCanvasSize] = useState<ICanvasSize>({
    width: widthCandlestickCanvas,
    height: heightCandlestickCanvas + heightCandlestickVolumeCanvas
  })
  const [arrNumberOfCandlesWithModel, setArrNumberOfCandlesWithModel] = useState<number[]>()//массив номеров свечей с конца по которым есть модели по нужному таймфрейму

  //изначальное получение canvas графика и настройка
  useEffect(() => {
    if (chartCanvas.current) {
      const canvas = chartCanvas.current
      const context = canvas.getContext('2d')
      if (context) {
        //это решение вопроса размытой графики
        context.translate(0.5, 0.5);
        setCanvasContext(context)
      }
    }
  }, [chartCanvas])

  //подготовка массива номеров свечей с конца по которым есть модели по нужному таймфрейму
  useEffect(() => {
    const arr: number[] = []
    analytics.forEach((elem) => {
      if (translateInvestingComTimeFramesToMy[elem.timeframe as keyof typeof InvestingComTimeFrames] === timeframe) {
        let num: number
        if (elem.candlesAgo === 'Текущая') {
          num = 0
        } else {
          num = Number(elem.candlesAgo)
        }
        arr.push(num)
      }
    })
    setArrNumberOfCandlesWithModel(arr)
  }, [])

  //рисование графика
  useEffect(() => {
    if (canvasContext && arrNumberOfCandlesWithModel) {
      //очистка холста при перерендере
      canvasContext.clearRect(0, 0, canvasSize.width, canvasSize.height);
      canvasContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const indOfLastCandle = chart.length - 1
      const countCandleOnCandlestick = countCandleOnCandlestickCanvas
      const colorOfLineCandlestick = colorOfLineCandlestickCanvas
      const scaleXCandle = formulaOfCandlestickCandleCanvas
      const {highPrice, lowPrice, highVolume} = getLimitPrices(chart, indOfLastCandle - countCandleOnCandlestick + 1, indOfLastCandle)
      const scaleYChart = scalePrice(highPrice, lowPrice, canvasSize.height - heightCandlestickVolumeCanvas - 5, 0)
      const scaleYVolume = scalePrice(highVolume, 0, heightCandlestickVolumeCanvas - 5, 0)
      const startOfScaleY = lowPrice
      const offsetLeftBorder = offsetChartFromLeftBorderCandlestickCanvas
      const stepCandleOnScaleX = formulaOfCandlestickCandleCanvas.total
      const colorVolumeOnUpCandle = colorOfVolumeOnUpCandle
      const colorVolumeOnDownCandle = colorOfVolumeOnDownCandle
      const colorVolumeOnDojiCandle = colorOfVolumeOnDojiCandle
      const lineWidthOfVolume = lineWidthOfVolumeCandlestickCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfMarkUnderCandle = colorOfMarkUnderCandleCandlestickCanvas

      //-------рисование графики----------
      //график
      for (let i = indOfLastCandle - countCandleOnCandlestick + 1; i <= indOfLastCandle; i++) {
        const newInd = indOfLastCandle - i
        buildCandle(canvasContext, canvasSize.width, canvasSize.height, scaleXCandle, scaleYChart, startOfScaleY, offsetLeftBorder, 0, heightCandlestickVolumeCanvas, chart[i], newInd, colorOfLineCandlestick)
      }
      //индикатор Volume
      buildVolumeCandle(canvasContext, chart, scaleYVolume, canvasSize.height, canvasSize.width, 0, 0, stepCandleOnScaleX, offsetLeftBorder, colorVolumeOnUpCandle, colorVolumeOnDownCandle, colorVolumeOnDojiCandle, colorOfLegend, lineWidthOfVolume)
      //рисование меток над свечами, по которым есть свечные модели
      buildMarkUnderCandleWithModels(canvasContext, chart, countCandleOnCandlestick, arrNumberOfCandlesWithModel, startOfScaleY, scaleYChart, heightCandlestickVolumeCanvas, canvasSize.height, canvasSize.width, stepCandleOnScaleX, offsetLeftBorder, 0, colorOfMarkUnderCandle)

    }
  }, [canvasContext])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>{timeframe}</p>
        <div className={styles.wrap}>
          <canvas
            ref={chartCanvas} width={canvasSize.width} height={canvasSize.height}>
            Ваш браузер не поддерживается!
          </canvas>
        </div>
      </div>
    </div>
  );
};

export default Chart;