import {useCallback, useState} from 'react'

export const useHttp = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const request = useCallback(async (url: string, method: string = 'GET', dataBody: {} | null = null) => {
    setLoading(true)
    try {
      const headers = new Headers()
      let body: BodyInit | null
      if (dataBody) {
        body = JSON.stringify(dataBody)
        headers.set('Content-Type', 'application/json')
      }else{
        body = null
      }

      const response = await fetch(url, {method, body, headers})
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Что-то пошло не так')
      }

      return data

    } catch (e) {
      let message = e instanceof Error ? e.message : 'Что-то пошло не так'
      setError(message)
      throw e
    } finally {
      setLoading(false)
    }

  }, [])

  const clearError = () => setError(null)

  return {loading, request, error, clearError}
}