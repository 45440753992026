//МОСКОВСКАЯ БИРЖА

//список всех доступных для торговли инструментов на московской бирже
export const tickerNameList = {
  'ABIO': 'ABIO',
  'ABRD': 'ABRD',
  'ACKO': 'ACKO',
  'AFKS': 'AFKS',
  'AFLT': 'AFLT',
  'AGRO': 'AGRO',
  'AKRN': 'AKRN',
  'ALRS': 'ALRS',
  'AMEZ': 'AMEZ',
  'APTK': 'APTK',
  'AQUA': 'AQUA',
  'ARSA': 'ARSA',
  'ASSB': 'ASSB',
  'AVAN': 'AVAN',
  'BANE': 'BANE',
  'BELU': 'BELU',
  'BISVP': 'BISVP',
  'BLNG': 'BLNG',
  'BRZL': 'BRZL',
  'BSPB': 'BSPB',
  'CBOM': 'CBOM',
  'CHGZ': 'CHGZ',
  'CHKZ': 'CHKZ',
  'CHMF': 'CHMF',
  'CHMK': 'CHMK',
  'CIAN': 'CIAN',
  'CNTL': 'CNTL',
  'DIOD': 'DIOD',
  'DSKY': 'DSKY',
  'DVEC': 'DVEC',
  'DZRD': 'DZRD',
  'EELT': 'EELT',
  'ELFV': 'ELFV',
  'ELTZ': 'ELTZ',
  'ENPG': 'ENPG',
  'ETLN': 'ETLN',
  'FEES': 'FEES',
  'FESH': 'FESH',
  'FIVE': 'FIVE',
  'FIXP': 'FIXP',
  'FLOT': 'FLOT',
  'GAZA': 'GAZA',
  'GAZP': 'GAZP',
  'GAZS': 'GAZS',
  'GAZT': 'GAZT',
  'GCHE': 'GCHE',
  'GEMA': 'GEMA',
  'GEMC': 'GEMC',
  'GLTR': 'GLTR',
  'GMKN': 'GMKN',
  'GTRK': 'GTRK',
  'HHRU': 'HHRU',
  'HIMCP': 'HIMCP',
  'HYDR': 'HYDR',
  'IGST': 'IGST',
  'INGR': 'INGR',
  'IRAO': 'IRAO',
  'IRKT': 'IRKT',
  'JNOS': 'JNOS',
  'KAZT': 'KAZT',
  'KBSB': 'KBSB',
  'KCHE': 'KCHE',
  'KGKC': 'KGKC',
  'KLSB': 'KLSB',
  'KMAZ': 'KMAZ',
  'KMEZ': 'KMEZ',
  'KOGK': 'KOGK',
  'KRKN': 'KRKN',
  'KRKOP': 'KRKOP',
  'KROT': 'KROT',
  'KRSB': 'KRSB',
  'KUBE': 'KUBE',
  'KUZB': 'KUZB',
  'KZOS': 'KZOS',
  'LENT': 'LENT',
  'LIFE': 'LIFE',
  'LKOH': 'LKOH',
  'LNZL': 'LNZL',
  'LPSB': 'LPSB',
  'LSNG': 'LSNG',
  'LSRG': 'LSRG',
  'LVHK': 'LVHK',
  'MAGE': 'MAGE',
  'MAGN': 'MAGN',
  'MDMG': 'MDMG',
  'MFGSP': 'MFGSP',
  'MGNT': 'MGNT',
  'MGNZ': 'MGNZ',
  'MGTSP': 'MGTSP',
  'MISB': 'MISB',
  'MOEX': 'MOEX',
  'MRKC': 'MRKC',
  'MRKK': 'MRKK',
  'MRKP': 'MRKP',
  'MRKS': 'MRKS',
  'MRKU': 'MRKU',
  'MRKV': 'MRKV',
  'MRKY': 'MRKY',
  'MRKZ': 'MRKZ',
  'MRSB': 'MRSB',
  'MSNG': 'MSNG',
  'MSRS': 'MSRS',
  'MSTT': 'MSTT',
  'MTLR': 'MTLR',
  'MTSS': 'MTSS',
  'MVID': 'MVID',
  'NAUK': 'NAUK',
  'NFAZ': 'NFAZ',
  'NKHP': 'NKHP',
  'NKNC': 'NKNC',
  'NKSH': 'NKSH',
  'NLMK': 'NLMK',
  'NMTP': 'NMTP',
  'NNSB': 'NNSB',
  'NSVZ': 'NSVZ',
  'NVTK': 'NVTK',
  'OGKB': 'OGKB',
  'OKEY': 'OKEY',
  'OMZZP': 'OMZZP',
  'OZON': 'OZON',
  'PAZA': 'PAZA',
  'PHOR': 'PHOR',
  'PIKK': 'PIKK',
  'PLZL': 'PLZL',
  'PMSB': 'PMSB',
  'POLY': 'POLY',
  'POSI': 'POSI',
  'PRFN': 'PRFN',
  'PRMB': 'PRMB',
  'QIWI': 'QIWI',
  'RASP': 'RASP',
  'RBCM': 'RBCM',
  'RDRB': 'RDRB',
  'RENI': 'RENI',
  'RGSS': 'RGSS',
  'RKKE': 'RKKE',
  'RNFT': 'RNFT',
  'ROLO': 'ROLO',
  'ROSB': 'ROSB',
  'ROSN': 'ROSN',
  'ROST': 'ROST',
  'RTGZ': 'RTGZ',
  'RTKM': 'RTKM',
  'RTSB': 'RTSB',
  'RUAL': 'RUAL',
  'RUSI': 'RUSI',
  'RZSB': 'RZSB',
  'SAGO': 'SAGO',
  'SARE': 'SARE',
  'SBER': 'SBER',
  'SELG': 'SELG',
  'SFIN': 'SFIN',
  'SGZH': 'SGZH',
  'SIBN': 'SIBN',
  'SLEN': 'SLEN',
  'SMLT': 'SMLT',
  'SNGS': 'SNGS',
  'SPBE': 'SPBE',
  'STSB': 'STSB',
  'SVAV': 'SVAV',
  'SVCB': 'SVCB',
  'SVET': 'SVET',
  'TASB': 'TASB',
  'TATN': 'TATN',
  'TCSG': 'TCSG',
  'TGKA': 'TGKA',
  'TGKB': 'TGKB',
  'TGKN': 'TGKN',
  'TNSE': 'TNSE',
  'TORS': 'TORS',
  'TRMK': 'TRMK',
  'TRNFP': 'TRNFP',
  'TTLK': 'TTLK',
  'TUZA': 'TUZA',
  'UKUZ': 'UKUZ',
  'UNAC': 'UNAC',
  'UNKL': 'UNKL',
  'UPRO': 'UPRO',
  'URKZ': 'URKZ',
  'USBN': 'USBN',
  'UTAR': 'UTAR',
  'UWGN': 'UWGN',
  'VGSB': 'VGSB',
  'VJGZ': 'VJGZ',
  'VKCO': 'VKCO',
  'VLHZ': 'VLHZ',
  'VRSB': 'VRSB',
  'VSMO': 'VSMO',
  'VSYD': 'VSYD',
  'VTBR': 'VTBR',
  'WTCM': 'WTCM',
  'WUSH': 'WUSH',
  'YKEN': 'YKEN',
  'YNDX': 'YNDX',
  'YRSB': 'YRSB',
  'ZILL': 'ZILL',
  'ZVEZ': 'ZVEZ',
}

//список соответствия имен всех доступных для аналитики инструментов на московской бирже именам, которые на сайте ru.investing.com
// export const tickerNamesTranscript: Record<keyof typeof tickerNameList, string> = {
//   ABIO: 'Артген',
//   ABRD: 'Абрау-Дюрсо',
//   ACKO: 'Южурал-Аско',
//   AFKS: 'Система',
//   AFLT: 'Аэрофлот',
//   AGRO: 'РОС АГРО ПЛС',
//   AKRN: 'Акрон',
//   ALRS: 'АК АЛРОСА',
//   AMEZ: 'Ашинский Метзавод',
//   APTK: 'Аптечная сеть 36',
//   AQUA: 'Inarktika PAO',
//   ARSA: 'УК Арсагера',
//   ASSB: 'АЭСК',
//   AVAN: 'Банк Авангард',
//   BANE: 'Башнефть ПАО',
//   BELU: 'Novabev PAO',
//   BISVP: 'Башинформсвязь (прив.)',
//   BLNG: 'Белон',
//   BRZL: 'Бурятзолото',
//   BSPB: 'Банк Санкт-Петербург',
//   CBOM: 'МКБ',
//   CHGZ: 'RN Zapadnaya Sibir’ PAO',
//   CHKZ: 'ЧКПЗ',
//   CHMF: 'Северсталь',
//   CHMK: 'ЧМК',
//   CIAN: 'Cian DRC',
//   CNTL: 'Центральный телеграф',
//   DIOD: 'ДИОД OAO',
//   DSKY: 'Детский мир',
//   DVEC: 'ДЭК ОАО',
//   DZRD: 'ДЗРД',
//   EELT: 'Yevropyeyskaya Elyektrotyekhnika PAO',
//   ELFV: 'EL5 Energo PAO',
//   ELTZ: 'Электроцинк',
//   ENPG: 'ЭН+ Груп',
//   ETLN: 'Etalon Gr',
//   FEES: 'ФСК ЕЭС ОАО',
//   FESH: 'FESH',
//   FIVE: 'X5 Retail Group',
//   FIXP: 'Fix Price Group',
//   FLOT: 'FLOT',
//   GAZA: 'Газ',
//   GAZP: 'Газпром',
//   GAZS: 'Gaz Servis',
//   GAZT: 'ГАЗ-Тек',
//   GCHE: 'Группа Черкизово',
//   GEMA: 'ММТсБ',
//   GEMC: 'United Medical Group Cy Plc',
//   GLTR: 'GLTRDR',
//   GMKN: 'Норникель',
//   GTRK: 'Globaltruck Management PAO',
//   HHRU: 'HeadHunter Group',
//   HIMCP: 'Химпром (прив.)',
//   HYDR: 'HYDR',
//   IGST: 'Ижсталь',
//   INGR: 'Инград ',
//   IRAO: 'Интер РАО ЕЭС ОАО',
//   IRKT: 'Иркут',
//   JNOS: 'Славнефть-ЯНОС',
//   KAZT: 'КуйбышевАзот',
//   KBSB: 'TNS Energo Kuban’ PAO',
//   KCHE: 'Камчатскэнерго',
//   KGKC: 'КГК',
//   KLSB: 'Калужская сбытовая',
//   KMAZ: 'КАМАЗ',
//   KMEZ: 'Ковровский механический',
//   KOGK: 'Коршуновский ГОК',
//   KRKN: 'Саратовский НПЗ',
//   KRKOP: 'Красный котельщик (прив.)',
//   KROT: 'Красный Октябрь',
//   KRSB: 'Красноярскэнергосбыт',
//   KUBE: 'Кубаньэнерго',
//   KUZB: 'Банк Кузнецкий',
//   KZOS: 'Органический Синтез',
//   LENT: 'MK Lenta PAO',
//   LIFE: 'ФармСинтез',
//   LKOH: 'ЛУКОЙЛ',
//   LNZL: 'Лензолото',
//   LPSB: 'Липецкая ЭСК',
//   LSNG: 'Ленэнерго',
//   LSRG: 'Группа ЛСР',
//   LVHK: 'Левенгук OAO',
//   MAGE: 'Магаданэнерго',
//   MAGN: 'ММК',
//   MDMG: 'MD Medical Group Investments',
//   MFGSP: 'Славнефть-МНГ (прив.)',
//   MGNT: 'Магнит',
//   MGNZ: 'Соликамский магниевый',
//   MGTSP: 'МГТС (прив.)',
//   MISB: 'TNS Energo Mariy El PAO',
//   MOEX: 'Московская биржа',
//   MRKC: 'Rosseti Tsentr PAO',
//   MRKK: 'Rosseti Severnyi Kavkaz PAO',
//   MRKP: 'Россети Центр и Приволжье',
//   MRKS: 'Rosseti Sibir’ PAO',
//   MRKU: 'Rosseti Ural PAO',
//   MRKV: 'Rosseti Volga PAO',
//   MRKY: 'Rosseti Yug PAO',
//   MRKZ: 'Rosseti Severo Zapad PAO',
//   MRSB: 'Мордовэнергосбыт',
//   MSNG: 'Мосэнерго',
//   MSRS: 'МОЭСК ОАО',
//   MSTT: 'МОСТОТРЕСТ',
//   MTLR: 'Мечел',
//   MTSS: 'МТС',
//   MVID: 'М.видео',
//   NAUK: 'Наука НПО',
//   NFAZ: 'НефАЗ ОАО',
//   NKHP: 'НКХП ПАО',
//   NKNC: 'Нижнекамскнефтехим',
//   NKSH: 'Нижнекамскшина',
//   NLMK: 'НЛМК',
//   NMTP: 'НМТП',
//   NNSB: 'ТНС Энерго Нижний Новгород',
//   NSVZ: 'Наука-Связь',
//   NVTK: 'НОВАТЭК',
//   OGKB: 'ОГК-2 ОАО',
//   OKEY: 'OKey DRC',
//   OMZZP: 'ПАО "ОМЗ"',
//   OZON: 'Ozon Holdings',
//   PAZA: 'ПАЗ',
//   PHOR: 'ФосАгро',
//   PIKK: 'Группа Компаний ПИК',
//   PLZL: 'Полюс',
//   PMSB: 'Пермэнергосбыт',
//   POLY: 'POLY',
//   POSI: 'POSI',
//   PRFN: 'ЧЗПСН-Профнастил',
//   PRMB: 'Банк Приморье',
//   QIWI: 'QIWI',
//   RASP: 'Распадская',
//   RBCM: 'РБК ОАО',
//   RDRB: 'РосДорБанк',
//   RENI: 'RENI',
//   RGSS: 'Росгосстрах',
//   RKKE: 'РКК Энергия ОАО',
//   RNFT: 'РуссНефть',
//   ROLO: 'Русолово OAO',
//   ROSB: 'Росбанк',
//   ROSN: 'Роснефть',
//   ROST: 'Росинтер ОАО',
//   RTGZ: 'Газпром Ростов-на-Дону',
//   RTKM: 'Ростелеком',
//   RTSB: 'ТНС энерго Ростов-на-Дону',
//   RUAL: 'RUAL',
//   RUSI: 'ИК РУСС-ИНВЕСТ',
//   RZSB: 'РЭСК',
//   SAGO: 'Самараэнерго ОАО',
//   SARE: 'Саратовэнерго',
//   SBER: 'Сбербанк',
//   SELG: 'Селигдар',
//   SFIN: 'SFI PAO',
//   SGZH: 'Сегежа Групп',
//   SIBN: 'Газпром нефть',
//   SLEN: 'Сахалинэнерго',
//   SMLT: 'ГК Самолет',
//   SNGS: 'Сургутнефтегаз',
//   SPBE: 'SPBE',
//   STSB: 'Ставропольэнергосбыт',
//   SVAV: 'СОЛЛЕРС',
//   SVCB: 'Совкомбанк',
//   SVET: 'Svetofor Group PAO',
//   TASB: 'Тамбовэнергосбыт',
//   TATN: 'Татнефть',
//   TCSG: 'TKS Holding MKPAO',
//   TGKA: 'ТГК №1 ОАО',
//   TGKB: 'Territorial’naya Generiruyushchaya Kompaniya No2 P',
//   TGKN: 'Territorial’naya Generiruyushchaya Kompaniya No 14',
//   TNSE: 'ТНС энерго',
//   TORS: 'ТРК',
//   TRMK: 'ТМК',
//   TRNFP: 'Транснефть (прив.)',
//   TTLK: 'Таттелеком',
//   TUZA: 'ТЗА ОАО',
//   UKUZ: 'Южный Кузбасс ОАО',
//   UNAC: 'ОАК',
//   UNKL: 'Южно-Уральский никелевый',
//   UPRO: 'Юнипро',
//   URKZ: 'Уральская кузница',
//   USBN: 'Банк Уралсиб',
//   UTAR: 'Авиакомпания ЮТэйр',
//   UWGN: 'НПК ОВК',
//   VGSB: 'Волгоградэнергосбыт',
//   VJGZ: 'Var’yeganneftegaz PAO',
//   VKCO: 'VK DRC',
//   VLHZ: 'ВХЗ',
//   VRSB: 'Воронежская ЭСК',
//   VSMO: 'VSMO',
//   VSYD: 'Выборгский ССЗ',
//   VTBR: 'ВТБ',
//   WTCM: 'ЦМТ',
//   WUSH: 'Whoosh Holding PAO',
//   YKEN: 'Якутскэнерго',
//   YNDX: 'Яндекс',
//   YRSB: 'TNS Energo Yaroslavl PAO',
//   ZILL: 'ZILL',
//   ZVEZ: 'Звезда',
// }

//информация по торгуемым инструментам на московской бирже
// export const tickerInfoList: Record<keyof typeof tickerNameList, { name: string, cluster: string, haveFutures: boolean }> = {
//   ABIO: {name: 'Артген', cluster: 'ЗДРАВООХРАНЕНИЕ', haveFutures: false},
//   ABRD: {name: 'Абрау-Дюрсо', cluster: 'ПОТРЕБ', haveFutures: false},
//   ACKO: {name: 'Южурал-Аско', cluster: 'ФИНАНСЫ', haveFutures: false},
//   AFKS: {name: 'Система', cluster: 'ФИНАНСЫ', haveFutures: false},
//   AFLT: {name: 'Аэрофлот', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   AGRO: {name: 'РОС АГРО ПЛС', cluster: 'ПОТРЕБ', haveFutures: false},
//   AKRN: {name: 'Акрон', cluster: 'ХИМИЯ удобрения', haveFutures: false},
//   ALRS: {name: 'АК АЛРОСА', cluster: 'ГОРНОДОБЫВАЮЩИЕ', haveFutures: false},
//   AMEZ: {name: 'Ашинский Метзавод', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   APTK: {name: 'Аптечная сеть 36', cluster: 'РИТЕЙЛ', haveFutures: false},
//   AQUA: {name: 'Inarktika PAO', cluster: 'ПОТРЕБ', haveFutures: false},
//   ARSA: {name: 'УК Арсагера', cluster: 'ФИНАНСЫ', haveFutures: false},
//   ASSB: {name: 'АЭСК', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   AVAN: {name: 'Банк Авангард', cluster: 'БАНКИ', haveFutures: false},
//   BANE: {name: 'Башнефть ПАО', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   BELU: {name: 'Novabev PAO', cluster: 'ПОТРЕБ', haveFutures: false},
//   BISVP: {name: 'Башинформсвязь (прив.)', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   BLNG: {name: 'Белон', cluster: 'ГОРНОДОБЫВАЮЩИЕ', haveFutures: false},
//   BRZL: {name: 'Бурятзолото', cluster: 'ДРАГ.МЕТАЛЛЫ', haveFutures: false},
//   BSPB: {name: 'Банк Санкт-Петербург', cluster: 'БАНКИ', haveFutures: false},
//   CBOM: {name: 'МКБ', cluster: 'БАНКИ', haveFutures: false},
//   CHGZ: {name: 'RN Zapadnaya Sibir’ PAO', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   CHKZ: {name: 'ЧКПЗ', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   CHMF: {name: 'Северсталь', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   CHMK: {name: 'ЧМК', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   CIAN: {name: 'Cian DRC', cluster: 'ИНТЕРНЕТ', haveFutures: false},
//   CNTL: {name: 'Центральный телеграф', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   DIOD: {name: 'ДИОД OAO', cluster: 'ПОТРЕБ', haveFutures: false},
//   DSKY: {name: 'Детский мир', cluster: 'РИТЕЙЛ', haveFutures: false},
//   DVEC: {name: 'ДЭК ОАО', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   DZRD: {name: 'ДЗРД', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   EELT: {name: 'Yevropyeyskaya Elyektrotyekhnika PAO', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   ELFV: {name: 'EL5 Energo PAO', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   ELTZ: {name: 'Электроцинк', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   ENPG: {name: 'ЭН+ Груп', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   ETLN: {name: 'Etalon Gr', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   FEES: {name: 'ФСК ЕЭС ОАО', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   FESH: {name: 'FESH', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   FIVE: {name: 'X5 Retail Group', cluster: 'РИТЕЙЛ', haveFutures: false},
//   FIXP: {name: 'Fix Price Group', cluster: 'РИТЕЙЛ', haveFutures: false},
//   FLOT: {name: 'FLOT', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   GAZA: {name: 'Газ', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   GAZP: {name: 'Газпром', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   GAZS: {name: 'Gaz Servis', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   GAZT: {name: 'ГАЗ-Тек', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   GCHE: {name: 'Группа Черкизово', cluster: 'ПОТРЕБ', haveFutures: false},
//   GEMA: {name: 'ММТсБ', cluster: 'HIGH TECH', haveFutures: false},
//   GEMC: {name: 'United Medical Group Cy Plc', cluster: 'ПОТРЕБ', haveFutures: false},
//   GLTR: {name: 'GLTRDR', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   GMKN: {name: 'Норникель', cluster: 'МЕТАЛЛУРГИЯ цвет.', haveFutures: false},
//   GTRK: {name: 'Globaltruck Management PAO', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   HHRU: {name: 'HeadHunter Group', cluster: 'ИНТЕРНЕТ', haveFutures: false},
//   HIMCP: {name: 'Химпром (прив.)', cluster: 'ХИМИЯ разное', haveFutures: false},
//   HYDR: {name: 'HYDR', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   IGST: {name: 'Ижсталь', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   INGR: {name: 'Инград ', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   IRAO: {name: 'Интер РАО ЕЭС ОАО', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   IRKT: {name: 'Иркут', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   JNOS: {name: 'Славнефть-ЯНОС', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   KAZT: {name: 'КуйбышевАзот', cluster: 'ХИМИЯ удобрения', haveFutures: false},
//   KBSB: {name: 'TNS Energo Kuban’ PAO', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   KCHE: {name: 'Камчатскэнерго', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   KGKC: {name: 'КГК', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   KLSB: {name: 'Калужская сбытовая', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   KMAZ: {name: 'КАМАЗ', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   KMEZ: {name: 'Ковровский механический', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   KOGK: {name: 'Коршуновский ГОК', cluster: 'ГОРНОДОБЫВАЮЩИЕ', haveFutures: false},
//   KRKN: {name: 'Саратовский НПЗ', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   KRKOP: {name: 'Красный котельщик (прив.)', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   KROT: {name: 'Красный Октябрь', cluster: 'ПОТРЕБ', haveFutures: false},
//   KRSB: {name: 'Красноярскэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   KUBE: {name: 'Кубаньэнерго', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   KUZB: {name: 'Банк Кузнецкий', cluster: 'БАНКИ', haveFutures: false},
//   KZOS: {name: 'Органический Синтез', cluster: 'ХИМИЯ разное', haveFutures: false},
//   LENT: {name: 'MK Lenta PAO', cluster: 'РИТЕЙЛ', haveFutures: false},
//   LIFE: {name: 'ФармСинтез', cluster: 'ПОТРЕБ', haveFutures: false},
//   LKOH: {name: 'ЛУКОЙЛ', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   LNZL: {name: 'Лензолото', cluster: 'ДРАГ.МЕТАЛЛЫ', haveFutures: false},
//   LPSB: {name: 'Липецкая ЭСК', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   LSNG: {name: 'Ленэнерго', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   LSRG: {name: 'Группа ЛСР', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   LVHK: {name: 'Левенгук OAO', cluster: 'HIGH TECH', haveFutures: false},
//   MAGE: {name: 'Магаданэнерго', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   MAGN: {name: 'ММК', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   MDMG: {name: 'MD Medical Group Investments', cluster: 'ПОТРЕБ', haveFutures: false},
//   MFGSP: {name: 'Славнефть-МНГ (прив.)', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   MGNT: {name: 'Магнит', cluster: 'РИТЕЙЛ', haveFutures: false},
//   MGNZ: {name: 'Соликамский магниевый', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   MGTSP: {name: 'МГТС (прив.)', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   MISB: {name: 'TNS Energo Mariy El PAO', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   MOEX: {name: 'Московская биржа', cluster: 'ФИНАНСЫ', haveFutures: false},
//   MRKC: {name: 'Rosseti Tsentr PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKK: {name: 'Rosseti Severnyi Kavkaz PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKP: {name: 'Россети Центр и Приволжье', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKS: {name: 'Rosseti Sibir’ PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKU: {name: 'Rosseti Ural PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKV: {name: 'Rosseti Volga PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKY: {name: 'Rosseti Yug PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRKZ: {name: 'Rosseti Severo Zapad PAO', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MRSB: {name: 'Мордовэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   MSNG: {name: 'Мосэнерго', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   MSRS: {name: 'МОЭСК ОАО', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   MSTT: {name: 'МОСТОТРЕСТ', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   MTLR: {name: 'Мечел', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   MTSS: {name: 'МТС', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   MVID: {name: 'М.видео', cluster: 'РИТЕЙЛ', haveFutures: false},
//   NAUK: {name: 'Наука НПО', cluster: 'HIGH TECH', haveFutures: false},
//   NFAZ: {name: 'НефАЗ ОАО', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   NKHP: {name: 'НКХП ПАО', cluster: 'ПОТРЕБ', haveFutures: false},
//   NKNC: {name: 'Нижнекамскнефтехим', cluster: 'ХИМИЯ разное', haveFutures: false},
//   NKSH: {name: 'Нижнекамскшина', cluster: 'ХИМИЯ разное', haveFutures: false},
//   NLMK: {name: 'НЛМК', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   NMTP: {name: 'НМТП', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   NNSB: {name: 'ТНС Энерго Нижний Новгород', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   NSVZ: {name: 'Наука-Связь', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   NVTK: {name: 'НОВАТЭК', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   OGKB: {name: 'ОГК-2 ОАО', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   OKEY: {name: 'OKey DRC', cluster: 'РИТЕЙЛ', haveFutures: false},
//   OMZZP: {name: 'ПАО "ОМЗ"', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   OZON: {name: 'Ozon Holdings', cluster: 'РИТЕЙЛ', haveFutures: false},
//   PAZA: {name: 'ПАЗ', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   PHOR: {name: 'ФосАгро', cluster: 'ХИМИЯ удобрения', haveFutures: false},
//   PIKK: {name: 'Группа Компаний ПИК', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   PLZL: {name: 'Полюс', cluster: 'ДРАГ.МЕТАЛЛЫ', haveFutures: false},
//   PMSB: {name: 'Пермэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   POLY: {name: 'POLY', cluster: 'ДРАГ.МЕТАЛЛЫ', haveFutures: false},
//   POSI: {name: 'POSI', cluster: 'HIGH TECH', haveFutures: false},
//   PRFN: {name: 'ЧЗПСН-Профнастил', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   PRMB: {name: 'Банк Приморье', cluster: 'БАНКИ', haveFutures: false},
//   QIWI: {name: 'QIWI', cluster: 'ФИНАНСЫ', haveFutures: false},
//   RASP: {name: 'Распадская', cluster: 'ГОРНОДОБЫВАЮЩИЕ', haveFutures: false},
//   RBCM: {name: 'РБК ОАО', cluster: 'МЕДИА', haveFutures: false},
//   RDRB: {name: 'РосДорБанк', cluster: 'БАНКИ', haveFutures: false},
//   RENI: {name: 'RENI', cluster: 'ФИНАНСЫ', haveFutures: false},
//   RGSS: {name: 'Росгосстрах', cluster: 'ФИНАНСЫ', haveFutures: false},
//   RKKE: {name: 'РКК Энергия ОАО', cluster: 'HIGH TECH', haveFutures: false},
//   RNFT: {name: 'РуссНефть', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   ROLO: {name: 'Русолово OAO', cluster: 'МЕТАЛЛУРГИЯ цвет.', haveFutures: false},
//   ROSB: {name: 'Росбанк', cluster: 'БАНКИ', haveFutures: false},
//   ROSN: {name: 'Роснефть', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   ROST: {name: 'Росинтер ОАО', cluster: 'ПОТРЕБ', haveFutures: false},
//   RTGZ: {name: 'Газпром Ростов-на-Дону', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   RTKM: {name: 'Ростелеком', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   RTSB: {name: 'ТНС энерго Ростов-на-Дону', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   RUAL: {name: 'RUAL', cluster: 'МЕТАЛЛУРГИЯ цвет.', haveFutures: false},
//   RUSI: {name: 'ИК РУСС-ИНВЕСТ', cluster: 'ФИНАНСЫ', haveFutures: false},
//   RZSB: {name: 'РЭСК', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   SAGO: {name: 'Самараэнерго ОАО', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   SARE: {name: 'Саратовэнерго', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   SBER: {name: 'Сбербанк', cluster: 'БАНКИ', haveFutures: false},
//   SELG: {name: 'Селигдар', cluster: 'ДРАГ.МЕТАЛЛЫ', haveFutures: false},
//   SFIN: {name: 'SFI PAO', cluster: 'ФИНАНСЫ', haveFutures: false},
//   SGZH: {name: 'Сегежа Групп', cluster: 'ДРУГОЕ', haveFutures: false},
//   SIBN: {name: 'Газпром нефть', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   SLEN: {name: 'Сахалинэнерго', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   SMLT: {name: 'ГК Самолет', cluster: 'СТРОИТЕЛИ', haveFutures: false},
//   SNGS: {name: 'Сургутнефтегаз', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   SPBE: {name: 'SPBE', cluster: 'ФИНАНСЫ', haveFutures: false},
//   STSB: {name: 'Ставропольэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   SVAV: {name: 'СОЛЛЕРС', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   SVCB: {name: 'Совкомбанк', cluster: 'БАНКИ', haveFutures: false},
//   SVET: {name: 'Svetofor Group PAO', cluster: 'HIGH TECH', haveFutures: false},
//   TASB: {name: 'Тамбовэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   TATN: {name: 'Татнефть', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   TCSG: {name: 'TKS Holding MKPAO', cluster: 'БАНКИ', haveFutures: false},
//   TGKA: {name: 'ТГК №1 ОАО', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   TGKB: {name: 'Territorial’naya Generiruyushchaya Kompaniya No2 P', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   TGKN: {name: 'Territorial’naya Generiruyushchaya Kompaniya No 14', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   TNSE: {name: 'ТНС энерго', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   TORS: {name: 'ТРК', cluster: 'ЭЛЕКТРОСЕТИ', haveFutures: false},
//   TRMK: {name: 'ТМК', cluster: 'МЕТАЛЛУРГИЯ разное', haveFutures: false},
//   TRNFP: {name: 'Транснефть (прив.)', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   TTLK: {name: 'Таттелеком', cluster: 'ТЕЛЕКОМ', haveFutures: false},
//   TUZA: {name: 'ТЗА ОАО', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   UKUZ: {name: 'Южный Кузбасс ОАО', cluster: 'ГОРНОДОБЫВАЮЩИЕ', haveFutures: false},
//   UNAC: {name: 'ОАК', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   UNKL: {name: 'Южно-Уральский никелевый', cluster: 'МЕТАЛЛУРГИЯ цвет.', haveFutures: false},
//   UPRO: {name: 'Юнипро', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   URKZ: {name: 'Уральская кузница', cluster: 'МЕТАЛЛУРГИЯ черн.', haveFutures: false},
//   USBN: {name: 'Банк Уралсиб', cluster: 'БАНКИ', haveFutures: false},
//   UTAR: {name: 'Авиакомпания ЮТэйр', cluster: 'ТРАНСПОРТ', haveFutures: false},
//   UWGN: {name: 'НПК ОВК', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   VGSB: {name: 'Волгоградэнергосбыт', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   VJGZ: {name: 'Var’yeganneftegaz PAO', cluster: 'НЕФТЕГАЗ', haveFutures: false},
//   VKCO: {name: 'VK DRC', cluster: 'ИНТЕРНЕТ', haveFutures: false},
//   VLHZ: {name: 'ВХЗ', cluster: 'ХИМИЯ разное', haveFutures: false},
//   VRSB: {name: 'Воронежская ЭСК', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   VSMO: {name: 'VSMO', cluster: 'МЕТАЛЛУРГИЯ цвет.', haveFutures: false},
//   VSYD: {name: 'Выборгский ССЗ', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   VTBR: {name: 'ВТБ', cluster: 'БАНКИ', haveFutures: false},
//   WTCM: {name: 'ЦМТ', cluster: 'ТРЕТИЙ ЭШЕЛОН', haveFutures: false},
//   WUSH: {name: 'Whoosh Holding PAO', cluster: 'ДРУГОЕ', haveFutures: false},
//   YKEN: {name: 'Якутскэнерго', cluster: 'Э/ГЕНЕРАЦИЯ', haveFutures: false},
//   YNDX: {name: 'Яндекс', cluster: 'ИНТЕРНЕТ', haveFutures: false},
//   YRSB: {name: 'TNS Energo Yaroslavl PAO', cluster: 'ЭНЕРГОСБЫТ', haveFutures: false},
//   ZILL: {name: 'ZILL', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
//   ZVEZ: {name: 'Звезда', cluster: 'МАШИНОСТРОЕНИЕ', haveFutures: false},
// }

//АМЕРИКА

//список всех доступных для торговли инструментов на американской бирже
export const tickerNameListAmerica = {
  'A': 'A',
  'AAL': 'AAL',
  'AAPL': 'AAPL',
  'ABBV': 'ABBV',
  'ABNB': 'ABNB',
  'ABT': 'ABT',
  'ACGL': 'ACGL',
  'ACN': 'ACN',
  'ADBE': 'ADBE',
  'ADI': 'ADI',
  'ADM': 'ADM',
  'ADP': 'ADP',
  'ADSK': 'ADSK',
  'AEE': 'AEE',
  'AEP': 'AEP',
  'AES': 'AES',
  'AFL': 'AFL',
  'AIG': 'AIG',
  'AIZ': 'AIZ',
  'AJG': 'AJG',
  'AKAM': 'AKAM',
  'ALB': 'ALB',
  'ALGN': 'ALGN',
  'ALL': 'ALL',
  'ALLE': 'ALLE',
  'AMAT': 'AMAT',
  'AMCR': 'AMCR',
  'AMD': 'AMD',
  'AME': 'AME',
  'AMGN': 'AMGN',
  'AMP': 'AMP',
  'AMT': 'AMT',
  'AMZN': 'AMZN',
  'ANET': 'ANET',
  'ANSS': 'ANSS',
  'AON': 'AON',
  'AOS': 'AOS',
  'APA': 'APA',
  'APD': 'APD',
  'APH': 'APH',
  'APTV': 'APTV',
  'ARE': 'ARE',
  'ATO': 'ATO',
  'AVB': 'AVB',
  'AVGO': 'AVGO',
  'AVY': 'AVY',
  'AWK': 'AWK',
  'AXON': 'AXON',
  'AXP': 'AXP',
  'AZO': 'AZO',
  'BA': 'BA',
  'BAC': 'BAC',
  'BALL': 'BALL',
  'BAX': 'BAX',
  'BBWI': 'BBWI',
  'BBY': 'BBY',
  'BDX': 'BDX',
  'BEN': 'BEN',
  'BFb': 'BFb',
  'BG': 'BG',
  'BIIB': 'BIIB',
  'BIO': 'BIO',
  'BK': 'BK',
  'BKNG': 'BKNG',
  'BKR': 'BKR',
  'BLDR': 'BLDR',
  'BLK': 'BLK',
  'BMY': 'BMY',
  'BR': 'BR',
  'BRKb': 'BRKb',
  'BRO': 'BRO',
  'BSX': 'BSX',
  'BWA': 'BWA',
  'BX': 'BX',
  'BXP': 'BXP',
  'C': 'C',
  'CAG': 'CAG',
  'CAH': 'CAH',
  'CARR': 'CARR',
  'CAT': 'CAT',
  'CB': 'CB',
  'CBOE': 'CBOE',
  'CBRE': 'CBRE',
  'CCI': 'CCI',
  'CCL': 'CCL',
  'CDNS': 'CDNS',
  'CDW': 'CDW',
  'CE': 'CE',
  'CEG': 'CEG',
  'CF': 'CF',
  'CFG': 'CFG',
  'CHD': 'CHD',
  'CHRW': 'CHRW',
  'CHTR': 'CHTR',
  'CI': 'CI',
  'CINF': 'CINF',
  'CL': 'CL',
  'CLX': 'CLX',
  'CMA': 'CMA',
  'CMCSA': 'CMCSA',
  'CME': 'CME',
  'CMG': 'CMG',
  'CMI': 'CMI',
  'CMS': 'CMS',
  'CNC': 'CNC',
  'CNP': 'CNP',
  'COF': 'COF',
  'COO': 'COO',
  'COP': 'COP',
  'COR': 'COR',
  'COST': 'COST',
  'CPAY': 'CPAY',
  'CPB': 'CPB',
  'CPRT': 'CPRT',
  'CPT': 'CPT',
  'CRL': 'CRL',
  'CRM': 'CRM',
  'CSCO': 'CSCO',
  'CSGP': 'CSGP',
  'CSX': 'CSX',
  'CTAS': 'CTAS',
  'CTLT': 'CTLT',
  'CTRA': 'CTRA',
  'CTSH': 'CTSH',
  'CTVA': 'CTVA',
  'CVS': 'CVS',
  'CVX': 'CVX',
  'CZR': 'CZR',
  'D': 'D',
  'DAL': 'DAL',
  'DAY': 'DAY',
  'DD': 'DD',
  'DE': 'DE',
  'DECK': 'DECK',
  'DFS': 'DFS',
  'DG': 'DG',
  'DGX': 'DGX',
  'DHI': 'DHI',
  'DHR': 'DHR',
  'DIS': 'DIS',
  'DLR': 'DLR',
  'DLTR': 'DLTR',
  'DOC': 'DOC',
  'DOV': 'DOV',
  'DOW': 'DOW',
  'DPZ': 'DPZ',
  'DRI': 'DRI',
  'DTE': 'DTE',
  'DUK': 'DUK',
  'DVA': 'DVA',
  'DVN': 'DVN',
  'DXCM': 'DXCM',
  'EA': 'EA',
  'EBAY': 'EBAY',
  'ECL': 'ECL',
  'ED': 'ED',
  'EFX': 'EFX',
  'EG': 'EG',
  'EIX': 'EIX',
  'EL': 'EL',
  'ELV': 'ELV',
  'EMN': 'EMN',
  'EMR': 'EMR',
  'ENPH': 'ENPH',
  'EOG': 'EOG',
  'EPAM': 'EPAM',
  'EQIX': 'EQIX',
  'EQR': 'EQR',
  'EQT': 'EQT',
  'ES': 'ES',
  'ESS': 'ESS',
  'ETN': 'ETN',
  'ETR': 'ETR',
  'ETSY': 'ETSY',
  'EVRG': 'EVRG',
  'EW': 'EW',
  'EXC': 'EXC',
  'EXPD': 'EXPD',
  'EXPE': 'EXPE',
  'EXR': 'EXR',
  'F': 'F',
  'FANG': 'FANG',
  'FAST': 'FAST',
  'FCX': 'FCX',
  'FDS': 'FDS',
  'FDX': 'FDX',
  'FE': 'FE',
  'FFIV': 'FFIV',
  'FI': 'FI',
  'FICO': 'FICO',
  'FIS': 'FIS',
  'FITB': 'FITB',
  'FMC': 'FMC',
  'FOX': 'FOX',
  'FOXA': 'FOXA',
  'FRT': 'FRT',
  'FSLR': 'FSLR',
  'FTNT': 'FTNT',
  'FTV': 'FTV',
  'GD': 'GD',
  'GE': 'GE',
  'GEHC': 'GEHC',
  'GEN': 'GEN',
  'GILD': 'GILD',
  'GIS': 'GIS',
  'GL': 'GL',
  'GLW': 'GLW',
  'GM': 'GM',
  'GNRC': 'GNRC',
  'GOOG': 'GOOG',
  'GOOGL': 'GOOGL',
  'GPC': 'GPC',
  'GPN': 'GPN',
  'GRMN': 'GRMN',
  'GS': 'GS',
  'GWW': 'GWW',
  'HAL': 'HAL',
  'HAS': 'HAS',
  'HBAN': 'HBAN',
  'HCA': 'HCA',
  'HD': 'HD',
  'HES': 'HES',
  'HIG': 'HIG',
  'HII': 'HII',
  'HLT': 'HLT',
  'HOLX': 'HOLX',
  'HON': 'HON',
  'HPE': 'HPE',
  'HPQ': 'HPQ',
  'HRL': 'HRL',
  'HSIC': 'HSIC',
  'HST': 'HST',
  'HSY': 'HSY',
  'HUBB': 'HUBB',
  'HUM': 'HUM',
  'HWM': 'HWM',
  'IBM': 'IBM',
  'ICE': 'ICE',
  'IDXX': 'IDXX',
  'IEX': 'IEX',
  'IFF': 'IFF',
  'ILMN': 'ILMN',
  'INCY': 'INCY',
  'INTC': 'INTC',
  'INTU': 'INTU',
  'INVH': 'INVH',
  'IP': 'IP',
  'IPG': 'IPG',
  'IQV': 'IQV',
  'IR': 'IR',
  'IRM': 'IRM',
  'ISRG': 'ISRG',
  'IT': 'IT',
  'ITW': 'ITW',
  'IVZ': 'IVZ',
  'J': 'J',
  'JBHT': 'JBHT',
  'JBL': 'JBL',
  'JCI': 'JCI',
  'JKHY': 'JKHY',
  'JNJ': 'JNJ',
  'JNPR': 'JNPR',
  'JPM': 'JPM',
  'K': 'K',
  'KDP': 'KDP',
  'KEY': 'KEY',
  'KEYS': 'KEYS',
  'KHC': 'KHC',
  'KIM': 'KIM',
  'KLAC': 'KLAC',
  'KMB': 'KMB',
  'KMI': 'KMI',
  'KMX': 'KMX',
  'KO': 'KO',
  'KR': 'KR',
  'KVUE': 'KVUE',
  'L': 'L',
  'LDOS': 'LDOS',
  'LEN': 'LEN',
  'LH': 'LH',
  'LHX': 'LHX',
  'LIN': 'LIN',
  'LKQ': 'LKQ',
  'LLY': 'LLY',
  'LMT': 'LMT',
  'LNT': 'LNT',
  'LOW': 'LOW',
  'LRCX': 'LRCX',
  'LULU': 'LULU',
  'LUV': 'LUV',
  'LVS': 'LVS',
  'LW': 'LW',
  'LYB': 'LYB',
  'LYV': 'LYV',
  'MA': 'MA',
  'MAA': 'MAA',
  'MAR': 'MAR',
  'MAS': 'MAS',
  'MCD': 'MCD',
  'MCHP': 'MCHP',
  'MCK': 'MCK',
  'MCO': 'MCO',
  'MDLZ': 'MDLZ',
  'MDT': 'MDT',
  'MET': 'MET',
  'META': 'META',
  'MGM': 'MGM',
  'MHK': 'MHK',
  'MKC': 'MKC',
  'MKTX': 'MKTX',
  'MLM': 'MLM',
  'MMC': 'MMC',
  'MMM': 'MMM',
  'MNST': 'MNST',
  'MO': 'MO',
  'MOH': 'MOH',
  'MOS': 'MOS',
  'MPC': 'MPC',
  'MPWR': 'MPWR',
  'MRK': 'MRK',
  'MRNA': 'MRNA',
  'MRO': 'MRO',
  'MS': 'MS',
  'MSCI': 'MSCI',
  'MSFT': 'MSFT',
  'MSI': 'MSI',
  'MTB': 'MTB',
  'MTCH': 'MTCH',
  'MTD': 'MTD',
  'MU': 'MU',
  'NCLH': 'NCLH',
  'NDAQ': 'NDAQ',
  'NDSN': 'NDSN',
  'NEE': 'NEE',
  'NEM': 'NEM',
  'NFLX': 'NFLX',
  'NI': 'NI',
  'NKE': 'NKE',
  'NOC': 'NOC',
  'NOW': 'NOW',
  'NRG': 'NRG',
  'NSC': 'NSC',
  'NTAP': 'NTAP',
  'NTRS': 'NTRS',
  'NUE': 'NUE',
  'NVDA': 'NVDA',
  'NVR': 'NVR',
  'NWS': 'NWS',
  'NWSA': 'NWSA',
  'NXPI': 'NXPI',
  'O': 'O',
  'ODFL': 'ODFL',
  'OKE': 'OKE',
  'OMC': 'OMC',
  'ON': 'ON',
  'ORCL': 'ORCL',
  'ORLY': 'ORLY',
  'OTIS': 'OTIS',
  'OXY': 'OXY',
  'PANW': 'PANW',
  'PARA': 'PARA',
  'PAYC': 'PAYC',
  'PAYX': 'PAYX',
  'PCAR': 'PCAR',
  'PCG': 'PCG',
  'PEG': 'PEG',
  'PEP': 'PEP',
  'PFE': 'PFE',
  'PFG': 'PFG',
  'PG': 'PG',
  'PGR': 'PGR',
  'PH': 'PH',
  'PHM': 'PHM',
  'PKG': 'PKG',
  'PLD': 'PLD',
  'PM': 'PM',
  'PNC': 'PNC',
  'PNR': 'PNR',
  'PNW': 'PNW',
  'PODD': 'PODD',
  'POOL': 'POOL',
  'PPG': 'PPG',
  'PPL': 'PPL',
  'PRU': 'PRU',
  'PSA': 'PSA',
  'PSX': 'PSX',
  'PTC': 'PTC',
  'PWR': 'PWR',
  'PYPL': 'PYPL',
  'QCOM': 'QCOM',
  'QRVO': 'QRVO',
  'RCL': 'RCL',
  'REG': 'REG',
  'REGN': 'REGN',
  'RF': 'RF',
  'RHI': 'RHI',
  'RJF': 'RJF',
  'RL': 'RL',
  'RMD': 'RMD',
  'ROK': 'ROK',
  'ROL': 'ROL',
  'ROP': 'ROP',
  'ROST': 'ROST',
  'RSG': 'RSG',
  'RTX': 'RTX',
  'RVTY': 'RVTY',
  'SBAC': 'SBAC',
  'SBUX': 'SBUX',
  'SCHW': 'SCHW',
  'SHW': 'SHW',
  'SJM': 'SJM',
  'SLB': 'SLB',
  'SMCI': 'SMCI',
  'SNA': 'SNA',
  'SNPS': 'SNPS',
  'SO': 'SO',
  'SPG': 'SPG',
  'SPGI': 'SPGI',
  'SRE': 'SRE',
  'STE': 'STE',
  'STLD': 'STLD',
  'STT': 'STT',
  'STX': 'STX',
  'STZ': 'STZ',
  'SWK': 'SWK',
  'SWKS': 'SWKS',
  'SYF': 'SYF',
  'SYK': 'SYK',
  'SYY': 'SYY',
  'T': 'T',
  'TAP': 'TAP',
  'TDG': 'TDG',
  'TDY': 'TDY',
  'TECH': 'TECH',
  'TEL': 'TEL',
  'TER': 'TER',
  'TFC': 'TFC',
  'TFX': 'TFX',
  'TGT': 'TGT',
  'TJX': 'TJX',
  'TMO': 'TMO',
  'TMUS': 'TMUS',
  'TPR': 'TPR',
  'TRGP': 'TRGP',
  'TRMB': 'TRMB',
  'TROW': 'TROW',
  'TRV': 'TRV',
  'TSCO': 'TSCO',
  'TSLA': 'TSLA',
  'TSN': 'TSN',
  'TT': 'TT',
  'TTWO': 'TTWO',
  'TXN': 'TXN',
  'TXT': 'TXT',
  'TYL': 'TYL',
  'UAL': 'UAL',
  'UBER': 'UBER',
  'UDR': 'UDR',
  'UHS': 'UHS',
  'ULTA': 'ULTA',
  'UNH': 'UNH',
  'UNP': 'UNP',
  'UPS': 'UPS',
  'URI': 'URI',
  'USB': 'USB',
  'V': 'V',
  'VFC': 'VFC',
  'VICI': 'VICI',
  'VLO': 'VLO',
  'VLTO': 'VLTO',
  'VMC': 'VMC',
  'VRSK': 'VRSK',
  'VRSN': 'VRSN',
  'VRTX': 'VRTX',
  'VTR': 'VTR',
  'VTRS': 'VTRS',
  'VZ': 'VZ',
  'WAB': 'WAB',
  'WAT': 'WAT',
  'WBA': 'WBA',
  'WBD': 'WBD',
  'WDC': 'WDC',
  'WEC': 'WEC',
  'WELL': 'WELL',
  'WFC': 'WFC',
  'WM': 'WM',
  'WMB': 'WMB',
  'WMT': 'WMT',
  'WRB': 'WRB',
  'WRK': 'WRK',
  'WST': 'WST',
  'WTW': 'WTW',
  'WY': 'WY',
  'WYNN': 'WYNN',
  'XEL': 'XEL',
  'XOM': 'XOM',
  'XRAY': 'XRAY',
  'XYL': 'XYL',
  'YUM': 'YUM',
  'ZBH': 'ZBH',
  'ZBRA': 'ZBRA',
  'ZTS': 'ZTS',
}

//список соответствия имен всех доступных для аналитики инструментов на американской бирже именам, которые на сайте ru.investing.com
// export const tickerNamesTranscriptAmerica: Record<keyof typeof tickerNameListAmerica, string> = {
//   A: 'Agilent Technologies',
//   AAL: 'American Airlines',
//   AAPL: 'Apple',
//   ABBV: 'AbbVie',
//   ABNB: 'Airbnb',
//   ABT: 'Abbott Labs',
//   ACGL: 'Arch Capital',
//   ACN: 'Accenture',
//   ADBE: 'Adobe',
//   ADI: 'Analog Devices',
//   ADM: 'Archer-Daniels-Midland',
//   ADP: 'ADP',
//   ADSK: 'Autodesk',
//   AEE: 'Ameren',
//   AEP: 'American Electric Power',
//   AES: 'The AES',
//   AFL: 'Aflac',
//   AIG: 'AIG',
//   AIZ: 'Assurant',
//   AJG: 'Arthur J Gallagher',
//   AKAM: 'Akamai',
//   ALB: 'Albemarle',
//   ALGN: 'Align',
//   ALL: 'Allstate',
//   ALLE: 'Allegion PLC',
//   AMAT: 'Applied Materials',
//   AMCR: 'Amcor PLC',
//   AMD: 'AMD',
//   AME: 'Ametek',
//   AMGN: 'Amgen',
//   AMP: 'Ameriprise Financial',
//   AMT: 'American Tower',
//   AMZN: 'Amazon.com',
//   ANET: 'Arista Networks',
//   ANSS: 'ANSYS',
//   AON: 'Aon',
//   AOS: 'AO Smith',
//   APA: 'APA Corp',
//   APD: 'Air Products',
//   APH: 'Amphenol',
//   APTV: 'Aptiv',
//   ARE: 'Alexandria RE',
//   ATO: 'Atmos Energy',
//   AVB: 'AvalonBay',
//   AVGO: 'Broadcom',
//   AVY: 'Avery Dennison',
//   AWK: 'American Water Works',
//   AXON: 'Axon Enterprise',
//   AXP: 'American Express',
//   AZO: 'AutoZone',
//   BA: 'Boeing',
//   BAC: 'Bank of America',
//   BALL: 'Ball',
//   BAX: 'Baxter',
//   BBWI: 'Bath & Body Works',
//   BBY: 'Best Buy',
//   BDX: 'Becton Dickinson',
//   BEN: 'Franklin Resources',
//   BFb: 'Brown Forman',
//   BG: 'Bunge',
//   BIIB: 'Biogen',
//   BIO: 'Bio-Rad Labs',
//   BK: 'Bank of NY Mellon',
//   BKNG: 'Booking',
//   BKR: 'Baker Hughes',
//   BLDR: 'Builders FirstSource',
//   BLK: 'BlackRock',
//   BMY: 'Bristol-Myers Squibb',
//   BR: 'Broadridge',
//   BRKb: 'Berkshire Hathaway B',
//   BRO: 'Brown&Brown',
//   BSX: 'Boston Scientific',
//   BWA: 'BorgWarner',
//   BX: 'Blackstone',
//   BXP: 'Boston Properties',
//   C: 'Citigroup',
//   CAG: 'Conagra Brands',
//   CAH: 'Cardinal Health',
//   CARR: 'Carrier Global',
//   CAT: 'Caterpillar',
//   CB: 'Chubb',
//   CBOE: 'Cboe Global',
//   CBRE: 'CBRE A',
//   CCI: 'Crown Castle',
//   CCL: 'Carnival Corp',
//   CDNS: 'Cadence Design',
//   CDW: 'CDW Corp',
//   CE: 'Celanese',
//   CEG: 'Constellation Energy',
//   CF: 'CF Industries',
//   CFG: 'Citizens Financial Group Inc',
//   CHD: 'Church&Dwight',
//   CHRW: 'CH Robinson',
//   CHTR: 'Charter Communications',
//   CI: 'Cigna',
//   CINF: 'Cincinnati Financial',
//   CL: 'Colgate-Palmolive',
//   CLX: 'Clorox',
//   CMA: 'Comerica',
//   CMCSA: 'Comcast',
//   CME: 'CME Group',
//   CMG: 'Chipotle Mexican Grill',
//   CMI: 'Cummins',
//   CMS: 'CMS Energy',
//   CNC: 'Centene',
//   CNP: 'CenterPoint Energy',
//   COF: 'Capital One Financial',
//   COO: 'Cooper',
//   COP: 'ConocoPhillips',
//   COR: 'Cencora Inc',
//   COST: 'Costco',
//   CPAY: 'Corpay',
//   CPB: 'Campbell Soup',
//   CPRT: 'Copart',
//   CPT: 'Camden Property',
//   CRL: 'Charles River Laboratories',
//   CRM: 'Salesforce Inc',
//   CSCO: 'Cisco',
//   CSGP: 'CoStar',
//   CSX: 'CSX',
//   CTAS: 'Cintas',
//   CTLT: 'Catalent Inc',
//   CTRA: 'Coterra Energy',
//   CTSH: 'Cognizant A',
//   CTVA: 'Corteva',
//   CVS: 'CVS Health Corp',
//   CVX: 'Chevron',
//   CZR: 'Caesars',
//   D: 'Dominion Energy',
//   DAL: 'Delta Air Lines',
//   DAY: 'Dayforce',
//   DD: 'DuPont De Nemours',
//   DE: 'Deere&Company',
//   DECK: 'Deckers Outdoor',
//   DFS: 'Discover',
//   DG: 'Dollar General',
//   DGX: 'Quest Diagnostics',
//   DHI: 'DR Horton',
//   DHR: 'Danaher',
//   DIS: 'Walt Disney',
//   DLR: 'Digital',
//   DLTR: 'Dollar Tree',
//   DOC: 'Healthpeak Properties',
//   DOV: 'Dover',
//   DOW: 'Dow',
//   DPZ: 'Domino’s Pizza Inc',
//   DRI: 'Darden Restaurants',
//   DTE: 'DTE Energy',
//   DUK: 'Duke Energy',
//   DVA: 'DaVita',
//   DVN: 'Devon Energy',
//   DXCM: 'DexCom',
//   EA: 'Electronic Arts',
//   EBAY: 'eBay',
//   ECL: 'Ecolab',
//   ED: 'Consolidated Edison',
//   EFX: 'Equifax',
//   EG: 'Everest',
//   EIX: 'Edison',
//   EL: 'Estee Lauder',
//   ELV: 'Elevance Health',
//   EMN: 'Eastman Chemical',
//   EMR: 'Emerson',
//   ENPH: 'Enphase',
//   EOG: 'EOG Resources',
//   EPAM: 'EPAM Systems',
//   EQIX: 'Equinix',
//   EQR: 'Equity Residential',
//   EQT: 'EQT',
//   ES: 'Eversource Energy',
//   ESS: 'Essex Property',
//   ETN: 'Eaton',
//   ETR: 'Entergy',
//   ETSY: 'Etsy Inc',
//   EVRG: 'Evergy',
//   EW: 'Edwards Lifesciences',
//   EXC: 'Exelon',
//   EXPD: 'Expeditors Washington',
//   EXPE: 'Expedia',
//   EXR: 'Extra Space Storage',
//   F: 'Ford Motor',
//   FANG: 'Diamondback',
//   FAST: 'Fastenal',
//   FCX: 'Freeport-McMoran',
//   FDS: 'FactSet Research',
//   FDX: 'FedEx',
//   FE: 'FirstEnergy',
//   FFIV: 'F5 Networks',
//   FI: 'Fiserv',
//   FICO: 'Fair Isaac',
//   FIS: 'Fidelity National Info',
//   FITB: 'Fifth Third',
//   FMC: 'FMC',
//   FOX: 'Fox Corp B',
//   FOXA: 'Fox Corp A',
//   FRT: 'Federal Realty',
//   FSLR: 'First Solar',
//   FTNT: 'Fortinet',
//   FTV: 'Fortive',
//   GD: 'General Dynamics',
//   GE: 'General Electric',
//   GEHC: 'GE HealthCare',
//   GEN: 'Gen Digital',
//   GILD: 'Gilead',
//   GIS: 'General Mills',
//   GL: 'Globe Life',
//   GLW: 'Corning',
//   GM: 'General Motors',
//   GNRC: 'Generac',
//   GOOG: 'Alphabet C',
//   GOOGL: 'Alphabet A',
//   GPC: 'Genuine Parts',
//   GPN: 'Global Payments',
//   GRMN: 'Garmin',
//   GS: 'Goldman Sachs',
//   GWW: 'WW Grainger',
//   HAL: 'Halliburton',
//   HAS: 'Hasbro',
//   HBAN: 'Huntington Bancshares',
//   HCA: 'HCA',
//   HD: 'Home Depot',
//   HES: 'Hess',
//   HIG: 'Hartford',
//   HII: 'Huntington Ingalls Industries',
//   HLT: 'Hilton Worldwide',
//   HOLX: 'Hologic',
//   HON: 'Honeywell',
//   HPE: 'Hewlett Packard',
//   HPQ: 'HP Inc',
//   HRL: 'Hormel Foods',
//   HSIC: 'Henry Schein',
//   HST: 'Host Hotels Resorts',
//   HSY: 'Hershey Co',
//   HUBB: 'Hubbell',
//   HUM: 'Humana',
//   HWM: 'Howmet',
//   IBM: 'IBM',
//   ICE: 'ICE',
//   IDXX: 'IDEXX Labs',
//   IEX: 'IDEX',
//   IFF: 'IFF',
//   ILMN: 'Illumina',
//   INCY: 'Incyte',
//   INTC: 'Intel',
//   INTU: 'Intuit',
//   INVH: 'Invitation Homes',
//   IP: 'International Paper',
//   IPG: 'IPG',
//   IQV: 'IQVIA Holdings',
//   IR: 'Ingersoll Rand',
//   IRM: 'Iron Mountain',
//   ISRG: 'Intuitive Surgical',
//   IT: 'Gartner',
//   ITW: 'Illinois Tool Works',
//   IVZ: 'Invesco',
//   J: 'Jacobs Engineering',
//   JBHT: 'JB Hunt',
//   JBL: 'Jabil Circuit',
//   JCI: 'Johnson Controls',
//   JKHY: 'Jack Henry&Associates',
//   JNJ: 'J&J',
//   JNPR: 'Juniper',
//   JPM: 'JPMorgan',
//   K: 'Kellanova',
//   KDP: 'Keurig Dr Pepper',
//   KEY: 'KeyCorp',
//   KEYS: 'Keysight Technologies',
//   KHC: 'Kraft Heinz',
//   KIM: 'Kimco Realty',
//   KLAC: 'KLA Corp',
//   KMB: 'Kimberly-Clark',
//   KMI: 'Kinder Morgan',
//   KMX: 'CarMax',
//   KO: 'Coca-Cola',
//   KR: 'Kroger',
//   KVUE: 'Kenvue',
//   L: 'Loews',
//   LDOS: 'Leidos',
//   LEN: 'Lennar',
//   LH: 'Labcorp Holdings',
//   LHX: 'L3Harris Technologies',
//   LIN: 'Linde PLC',
//   LKQ: 'LKQ',
//   LLY: 'Eli Lilly',
//   LMT: 'Lockheed Martin',
//   LNT: 'Alliant Energy',
//   LOW: 'Lowe’s',
//   LRCX: 'Lam Research',
//   LULU: 'Lululemon Athletica',
//   LUV: 'Southwest Airlines',
//   LVS: 'Las Vegas Sands',
//   LW: 'Lamb Weston Holdings',
//   LYB: 'LyondellBasell Industries',
//   LYV: 'Live Nation Entertainment',
//   MA: 'Mastercard',
//   MAA: 'Mid-America Apartment',
//   MAR: 'Marriott Int',
//   MAS: 'Masco',
//   MCD: 'McDonald’s',
//   MCHP: 'Microchip',
//   MCK: 'McKesson',
//   MCO: 'Moody’s',
//   MDLZ: 'Mondelez',
//   MDT: 'Medtronic',
//   MET: 'MetLife',
//   META: 'Meta Platforms',
//   MGM: 'MGM',
//   MHK: 'Mohawk Industries',
//   MKC: 'McCormick&Co',
//   MKTX: 'MarketAxesss',
//   MLM: 'Martin Marietta Materials',
//   MMC: 'Marsh McLennan',
//   MMM: '3M',
//   MNST: 'Monster Beverage',
//   MO: 'Altria',
//   MOH: 'Molina Healthcare',
//   MOS: 'Mosaic',
//   MPC: 'Marathon Petroleum',
//   MPWR: 'Monolithic',
//   MRK: 'Merck&Co',
//   MRNA: 'Moderna',
//   MRO: 'Marathon Oil',
//   MS: 'Morgan Stanley',
//   MSCI: 'MSCI',
//   MSFT: 'Microsoft',
//   MSI: 'Motorola',
//   MTB: 'M&T Bank',
//   MTCH: 'Match Group',
//   MTD: 'Mettler-Toledo',
//   MU: 'Micron',
//   NCLH: 'Norwegian Cruise Line',
//   NDAQ: 'Nasdaq Inc',
//   NDSN: 'Nordson',
//   NEE: 'NextEra Energy',
//   NEM: 'Newmont Goldcorp',
//   NFLX: 'Netflix',
//   NI: 'NiSource',
//   NKE: 'Nike',
//   NOC: 'Northrop Grumman',
//   NOW: 'ServiceNow Inc',
//   NRG: 'NRG',
//   NSC: 'Norfolk Southern',
//   NTAP: 'NetApp',
//   NTRS: 'Northern Trust',
//   NUE: 'Nucor',
//   NVDA: 'NVIDIA',
//   NVR: 'NVR',
//   NWS: 'News Corp',
//   NWSA: 'News Corp A',
//   NXPI: 'NXP',
//   O: 'Realty Income',
//   ODFL: 'Old Dominion Freight Line',
//   OKE: 'ONEOK',
//   OMC: 'Omnicom',
//   ON: 'ON Semiconductor',
//   ORCL: 'Oracle',
//   ORLY: 'O’Reilly Automotive',
//   OTIS: 'Otis Worldwide',
//   OXY: 'Occidental',
//   PANW: 'Palo Alto Networks',
//   PARA: 'Paramount Global B',
//   PAYC: 'Paycom Soft',
//   PAYX: 'Paychex',
//   PCAR: 'PACCAR',
//   PCG: 'PG E',
//   PEG: 'Public Service Enterprise',
//   PEP: 'PepsiCo',
//   PFE: 'Pfizer',
//   PFG: 'Principal Financial',
//   PG: 'P&G',
//   PGR: 'Progressive',
//   PH: 'Parker-Hannifin',
//   PHM: 'PulteGroup',
//   PKG: 'Packaging America',
//   PLD: 'Prologis',
//   PM: 'Philip Morris',
//   PNC: 'PNC Financial',
//   PNR: 'Pentair',
//   PNW: 'Pinnacle West',
//   PODD: 'Insulet',
//   POOL: 'Pool',
//   PPG: 'PPG Industries',
//   PPL: 'PPL',
//   PRU: 'Prudential Financial',
//   PSA: 'Public Storage',
//   PSX: 'Phillips 66',
//   PTC: 'PTC',
//   PWR: 'Quanta Services',
//   PYPL: 'PayPal',
//   QCOM: 'Qualcomm',
//   QRVO: 'Qorvo Inc',
//   RCL: 'Royal Caribbean Cruises',
//   REG: 'Regency Centers',
//   REGN: 'Regeneron Pharma',
//   RF: 'Regions Financial',
//   RHI: 'Robert Half',
//   RJF: 'Raymond James Financial',
//   RL: 'Ralph Lauren A',
//   RMD: 'ResMed',
//   ROK: 'Rockwell Automation',
//   ROL: 'Rollins',
//   ROP: 'Roper Technologies',
//   ROST: 'Ross Stores',
//   RSG: 'Republic Services',
//   RTX: 'Rtx Corp',
//   RVTY: 'Revvity',
//   SBAC: 'SBA Communications',
//   SBUX: 'Starbucks',
//   SCHW: 'Charles Schwab',
//   SHW: 'Sherwin-Williams',
//   SJM: 'JM Smucker',
//   SLB: 'Schlumberger',
//   SMCI: 'Super Micro Computer',
//   SNA: 'Snap-On',
//   SNPS: 'Synopsys',
//   SO: 'Southern',
//   SPG: 'Simon Property',
//   SPGI: 'S&P Global',
//   SRE: 'Sempra Energy',
//   STE: 'STERIS',
//   STLD: 'Steel Dynamics',
//   STT: 'State Street',
//   STX: 'Seagate',
//   STZ: 'Constellation Brands A',
//   SWK: 'Stanley Black Decker',
//   SWKS: 'Skyworks',
//   SYF: 'Synchrony Financial',
//   SYK: 'Stryker',
//   SYY: 'Sysco',
//   T: 'AT&T',
//   TAP: 'Molson Coors Brewing B',
//   TDG: 'Transdigm',
//   TDY: 'Teledyne Technologies',
//   TECH: 'Bio-Techne',
//   TEL: 'TE Connectivity',
//   TER: 'Teradyne',
//   TFC: 'Truist Financial Corp',
//   TFX: 'Teleflex',
//   TGT: 'Target',
//   TJX: 'TJX',
//   TMO: 'Thermo Fisher Scientific',
//   TMUS: 'T-Mobile US',
//   TPR: 'Tapestry',
//   TRGP: 'Targa Resources',
//   TRMB: 'Trimble',
//   TROW: 'T Rowe',
//   TRV: 'Travelers',
//   TSCO: 'Tractor Supply',
//   TSLA: 'Tesla',
//   TSN: 'Tyson Foods',
//   TT: 'Trane Technologies',
//   TTWO: 'Take-Two',
//   TXN: 'Texas Instruments',
//   TXT: 'Textron',
//   TYL: 'Tyler Technologies',
//   UAL: 'United Airlines Holdings',
//   UBER: 'Uber Tech',
//   UDR: 'UDR',
//   UHS: 'Universal Health Services',
//   ULTA: 'Ulta Beauty',
//   UNH: 'UnitedHealth',
//   UNP: 'Union Pacific',
//   UPS: 'United Parcel Service',
//   URI: 'United Rentals',
//   USB: 'U.S. Bancorp',
//   V: 'Visa A',
//   VFC: 'VF',
//   VICI: 'VICI Properties',
//   VLO: 'Valero Energy',
//   VLTO: 'Veralto',
//   VMC: 'Vulcan Materials',
//   VRSK: 'Verisk',
//   VRSN: 'VeriSign',
//   VRTX: 'Vertex',
//   VTR: 'Ventas',
//   VTRS: 'Viatris',
//   VZ: 'Verizon',
//   WAB: 'Westinghouse Air Brake',
//   WAT: 'Waters',
//   WBA: 'Walgreens Boots',
//   WBD: 'Warner Bros Discovery',
//   WDC: 'Western Digital',
//   WEC: 'WEC Energy',
//   WELL: 'Welltower',
//   WFC: 'Wells Fargo&Co',
//   WM: 'Waste Management',
//   WMB: 'Williams',
//   WMT: 'Walmart',
//   WRB: 'WR Berkley',
//   WRK: 'WestRock Co',
//   WST: 'West Pharmaceutical Services',
//   WTW: 'Willis Towers Watson',
//   WY: 'Weyerhaeuser',
//   WYNN: 'Wynn Resorts',
//   XEL: 'Xcel Energy',
//   XOM: 'Exxon Mobil',
//   XRAY: 'Dentsply',
//   XYL: 'Xylem',
//   YUM: 'Yum! Brands',
//   ZBH: 'Zimmer Biomet',
//   ZBRA: 'Zebra',
//   ZTS: 'Zoetis Inc',
// }

//информация по торгуемым инструментам на американской бирже
// export const tickerInfoListAmerica: Record<keyof typeof tickerNameListAmerica, { name: string, cluster: string, haveFutures: boolean }> = {
//   ABIO: {name: 'Артген', cluster: 'ЗДРАВООХРАНЕНИЕ', haveFutures: false},
//   ABRD: {name: 'Абрау-Дюрсо', cluster: 'ПОТРЕБ', haveFutures: false},
//   ACKO: {name: 'Южурал-Аско', cluster: 'ФИНАНСЫ', haveFutures: false},
// }
