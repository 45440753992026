import React, {useEffect, useState} from 'react';
import styles from "./company.module.scss";
import {useTickerRating} from "../../../hooks/tickerRating.hook";
import {Countries, IRatingTicker, namesOfTypesOfRating, TypesOfRating} from "../../../shared/types";
import {Button, Input, message as messageAnt, Select, SelectProps, Checkbox, DatePicker} from "antd";
import type {CheckboxProps, DatePickerProps} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {useAppSelector} from "../../../store/store";
import dayjs from "dayjs";
import type {Dayjs} from 'dayjs';

const Company = () => {

  const country = useAppSelector(state => state.countryReduser.country)
  const tickerList = useAppSelector(state => state.countryReduser.tickerList)

  const {loading: loadingTickerRating, saveTickerRating, getAllTickerRatings, removeTickerRating, saveDateExpRating, error: errorRating, clearError: clearErrorRating} = useTickerRating()

  const [optionsSelectTicker, setOptionsSelectTicker] = useState<SelectProps['options']>()//массив тикеров, доступных для выбора
  const [optionsSelectTypeOfRating, setOptionsSelectTypeOfRating] = useState<SelectProps['options']>()//массив типов рейтингов, доступных для выбора
  const [tickerForAdding, setTickerForAdding] = useState<string>('')
  const [ratingForAdding, setRatingForAdding] = useState<string>('')
  const [techForAdding, setTechForAdding] = useState<boolean>(false)
  const [typeOfRatingToSetDateExp, setTypeOfRatingToSetDateExp] = useState<TypesOfRating | undefined>()
  const [dateToSetDateExp, setDateToSetDateExp] = useState<dayjs.Dayjs | null>(null)
  const [ratingList, setRatingList] = useState<IRatingTicker[]>([])
  const [needUpdateRatingList, setNeedUpdateRatingList] = useState(true)
  const [saveTickerBtnDisabled, setSaveTickerBtnDisabled] = useState(true)//блокировка кнопки добавления в список рейтинга если не все необходимые данные выбраны
  const [setDateExpBtnDisabled, setSetDateExpBtnDisabled] = useState(true)//блокировка кнопки настройки даты экспирации рейтинга если не все необходимые данные выбраны

  //получение данных по рейтингам тикеров из БД
  const getAllRatings = async (country: Countries) => {
    try {
      const data = await getAllTickerRatings(country)
      messageAnt.info(data.message)
      const ratings = data.info
      return ratings
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
      return undefined
    }
  }

  //изначальное получение данных по рейтингам тикеров из БД и при смене страны и маркера о необходимости обновления
  useEffect(() => {
    if (country && needUpdateRatingList) {
      const get = async () => {
        const allTickerRatings = await getAllRatings(country)
        if (allTickerRatings) {
          setRatingList(allTickerRatings)
        }
        setNeedUpdateRatingList(false)
      }
      get()
    }
  }, [country, needUpdateRatingList])

  //функция для сохранение рейтинга по тикеру в БД
  const saveRating = async (country: Countries, ticker: string, fund: string, tech: boolean) => {
    try {
      const data = await saveTickerRating(country, ticker, fund, tech)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
    }
  }

  //управление активностью кнопки добавления в список рейтинга
  useEffect(() => {
    if (tickerForAdding && ratingForAdding) {
      setSaveTickerBtnDisabled(false)
    } else {
      setSaveTickerBtnDisabled(true)
    }
  }, [tickerForAdding, ratingForAdding])

  //управление активностью кнопки установки даты экспирации рейтинга
  useEffect(() => {
    if (typeOfRatingToSetDateExp && dateToSetDateExp) {
      setSetDateExpBtnDisabled(false)
    } else {
      setSetDateExpBtnDisabled(true)
    }
  }, [typeOfRatingToSetDateExp, dateToSetDateExp])

  //формирование списков для селекторов выбора
  useEffect(() => {
    const optionsTicker: SelectProps['options'] = []
    tickerList.forEach((ticker) => {
      optionsTicker.push({value: ticker, label: ticker})
    })
    setOptionsSelectTicker(optionsTicker)

    const optionsTypeOfRating: SelectProps['options'] = []
    Object.keys(TypesOfRating).forEach((type) => {
      optionsTypeOfRating.push({value: type, label: namesOfTypesOfRating[type as keyof typeof TypesOfRating]})
    })
    setOptionsSelectTypeOfRating(optionsTypeOfRating)
  }, [tickerList])

  //выбор инструмента для добавления в список рейтинга
  const onChangeTicker = (value: string) => {
    setTickerForAdding(value)
  };

  //ввод числового значения рейтинга для добавления в список рейтинга
  const onChangeRating = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRatingForAdding(e.target.value)
  };

  //ввод значения прошел ли тикер долгосрочный тех отбор для добавления в список рейтинга
  const onChangeTech: CheckboxProps['onChange'] = (e) => {
    setTechForAdding(e.target.checked)
  };

  //добавление тикера в список рейтингов
  const saveTickerHandler = async () => {
    if (country && tickerForAdding && ratingForAdding) {
      await saveRating(country, tickerForAdding, ratingForAdding, techForAdding)
      setNeedUpdateRatingList(true)
      setTickerForAdding('')
      setRatingForAdding('')
      setTechForAdding(false)
    }
  }

  //функция для удаления рейтинга по тикеру из БД
  const removeRating = async (country: Countries, ticker: string) => {
    try {
      const data = await removeTickerRating(country, ticker)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
    }
  }

  //удаление тикера из локального списка рейтингов
  const deleteTickerHandler = async (index: number) => {
    const areYouSure = window.confirm(`Вы уверены, что хотите удалить рейтинг по ${ratingList[index].ticker}?`)
    if (!areYouSure) {
      return
    }
    //что будем удалять
    const ticker = ratingList[index].ticker
    if (country && ticker) {
      await removeRating(country, ticker)
      setNeedUpdateRatingList(true)
    }
  }

  //выбор типа рейтинга для установки даты экспирации
  const onChangeTypeOfRatingToSetDateExp = (value: string) => {
    setTypeOfRatingToSetDateExp(value as TypesOfRating)
  };

  //выбор даты для установки даты экспирации
  const onChangeDateExp: DatePickerProps['onChange'] = (date, dateString) => {
    setDateToSetDateExp(date);
  };

  //функция для сохранение даты экспирации для рейтинга в БД
  const saveDateExpOfRating = async (country: Countries, typeOfRating: TypesOfRating, dateExpirationOfRating: string) => {
    try {
      const data = await saveDateExpRating(country, typeOfRating, dateExpirationOfRating)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorRating);
      clearErrorRating()
    }
  }

  //установка даты экспирации для списка рейтинга
  const saveDateExpHandler = async () => {
    if (country && typeOfRatingToSetDateExp && dateToSetDateExp) {
      const dateString = dateToSetDateExp.format('YYYY-MM-DD')
      await saveDateExpOfRating(country, typeOfRatingToSetDateExp, dateString)
      setTypeOfRatingToSetDateExp(undefined)
      setDateToSetDateExp(null)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <h5>Фундаментальный и долгосрочный тех. отбор акций</h5>
          {
            !country &&
            <p className={styles.noCountry}>Не выбрана страна на главной странице!</p>
          }
          {
            country &&
            <div>
              <div className={[styles.dateOfExpiration, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Установка даты экспирации рейтингов</p>
                <Select showSearch allowClear placeholder="Тип рейтинга" onChange={onChangeTypeOfRatingToSetDateExp}
                        options={optionsSelectTypeOfRating} style={{width: 165}} value={typeOfRatingToSetDateExp}/>
                <DatePicker placeholder="Дата экспирации" onChange={onChangeDateExp} style={{width: 165}}
                            value={dateToSetDateExp}/>
                <Button title={'Установить дату экспирации рейтинга'} type={'primary'} onClick={saveDateExpHandler}
                        style={{width: 165}} disabled={setDateExpBtnDisabled}>Установить</Button>
              </div>
              <div className={[styles.addToRating, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Добавление тикера в рейтинг</p>
                <Select showSearch allowClear placeholder="Тикер" onChange={onChangeTicker}
                        options={optionsSelectTicker} style={{width: 165}} value={tickerForAdding}/>
                <Input placeholder="Фунд рейтинг" onChange={onChangeRating} style={{width: 165}}
                       value={ratingForAdding}/>
                <Checkbox onChange={onChangeTech} style={{width: 152}} checked={techForAdding}>Долгоср техн
                  OK</Checkbox>
                <Button title={'Добавить тикер в рейтинг'} type={'primary'} onClick={saveTickerHandler}
                        style={{width: 165}} disabled={saveTickerBtnDisabled}>Добавить</Button>
              </div>
              {
                ratingList ?
                  <div className={[styles.received, styles.blockWrap].join(' ')}>
                    <p className={styles.title}>Список тикеров попавших в рейтинг как наиболее сильные</p>
                    <div className={styles.headTable}>
                      <p className={styles.ticker}>Инструмент</p>
                      <p className={styles.value}>Фунд рейтинг</p>
                      <p className={styles.tech}>Техн долгоср</p>
                    </div>
                    <div className={styles.bodyTable}>
                      {
                        ratingList.map((ratingElem, index) => (
                          <div className={styles.lineTable} key={uuidv4()}>
                            <p className={styles.ticker}>{ratingElem.ticker}</p>
                            <p className={styles.value}>{ratingElem.rating}</p>
                            <p className={styles.tech}>{
                              ratingElem.tech ?
                                <span className={styles.green}></span> :
                                <span className={styles.red}></span>
                            }</p>
                            <p className={styles.delete}>
                          <span onClick={() => deleteTickerHandler(index)}>
                            <FontAwesomeIcon icon={faXmark} style={{color: "#990000"}}/>
                          </span>
                            </p>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Company;