import React, {useEffect, useRef, useState} from 'react';
import styles from "./chart.module.scss";
import {
  colorOfAxisCanvas, colorOfControlLevelRsiCanvas, colorOfControlLevelStochasticCanvas,
  colorOfCurrentPriceCanvas,
  colorOfLegendCanvas,
  colorOfLevelPivotCanvas, colorOfLineCandlestickCanvas,
  colorOfLongMovAverCanvas, colorOfMacdCanvas, colorOfMacdHistogramCanvas,
  colorOfMiddleMovAverCanvas,
  colorOfResistPivotCanvas, colorOfRsiCanvas,
  colorOfShortMovAverCanvas, colorOfSignalMacdCanvas, colorOfStochasticCanvas, colorOfStochasticMovingCanvas,
  colorOfSupportPivotCanvas,
  colorOfVerticalMarkerCanvas, colorOfVolumeOnDojiCandle,
  colorOfVolumeOnDownCandle,
  colorOfVolumeOnUpCandle,
  colorOfZigzagCanvas, formulaOfCandleCanvas, heightCandlestickVolumeCanvas,
  heightChartCanvas,
  heightIndicatorsCanvas,
  heightTimeScaleChartCanvas,
  heightTimeScaleIndicatorsCanvas, lineWidthOfMacdCanvas,
  lineWidthOfMovAverCanvas,
  lineWidthOfPivotCanvas, lineWidthOfRsiCanvas,
  lineWidthOfScaleChartCanvas, lineWidthOfStochasticCanvas,
  lineWidthOfVerticalMarkerCanvas,
  lineWidthOfVolumeCanvas,
  lineWidthOfZigzagCanvas,
  offsetChartFromLeftBorderCanvas, periodOfFastMaMacdCanvas,
  periodOfLongMovAverCanvas,
  periodOfMiddleMovAverCanvas, periodOfRsiCanvas,
  periodOfShortMovAverCanvas, periodOfSignalMacdCanvas, periodOfSlowMaMacdCanvas, periodOfStochasticCanvas,
  widthChartCanvas,
  widthPriceScaleChartCanvas
} from "../../../../../../../shared/configCanvas";
import {Candle, CandleTypePart, TimeFrames} from "../../../../../../../shared/types";
import {
  choiceTfForPivot,
  getLimitPrices, getLimitValuesOnMacd, getMacd, getMacdHistogram,
  getMovingAverage, getMovingAverageForNumArr,
  getRsiValue,
  getStochasticValue
} from "../../../../../../../shared/lib/lib";
import {
  buildCandle, buildLegend, buildMacd, buildMovingAverage, buildPivot, buildRsi, buildStochastic,
  buildVerticalMarker, buildVolume, buildXAxis, buildXAxisOscillator, buildYAxis, buildYAxisOscillator, buildZigzag,
  scalePrice,
  startOfScalePrice
} from "../../../../../../../shared/lib/canvas";

interface ICanvasSize {
  width: number
  height: number
}

interface Props {
  active: boolean
  quotes: Record<TimeFrames, Candle[]>
  tfByRecomendsStrategy: TimeFrames
  zzByRecomendsStrategy: number
}

const Chart = ({active, quotes, tfByRecomendsStrategy, zzByRecomendsStrategy}: Props) => {

  //элемент графика старшего таймфрейма
  const wrapCanvas = useRef<HTMLDivElement>(null)
  const chartCanvas = useRef<HTMLCanvasElement>(null)
  const volumeCanvas = useRef<HTMLCanvasElement>(null)
  const rsiCanvas = useRef<HTMLCanvasElement>(null)
  const stochasticCanvas = useRef<HTMLCanvasElement>(null)
  const macdCanvas = useRef<HTMLCanvasElement>(null)
  const [canvasSize, setCanvasSize] = useState<ICanvasSize>({
    width: widthChartCanvas,
    height: heightChartCanvas
  })
  const [indicatorsCanvasSize, setIndicatorsCanvasSize] = useState<ICanvasSize>({
    width: widthChartCanvas,
    height: heightIndicatorsCanvas
  })
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null)
  const [canvasVolumeContext, setCanvasVolumeContext] = useState<CanvasRenderingContext2D | null>(null)
  const [canvasRsiContext, setCanvasRsiContext] = useState<CanvasRenderingContext2D | null>(null)
  const [canvasStochasticContext, setCanvasStochasticContext] = useState<CanvasRenderingContext2D | null>(null)
  const [canvasMacdContext, setCanvasMacdContext] = useState<CanvasRenderingContext2D | null>(null)
  const [chart, setChart] = useState<Candle[]>(quotes[tfByRecomendsStrategy])
  const [zigzagParam, setZigzagParam] = useState<number>(zzByRecomendsStrategy)
  const [tf, setTf] = useState<TimeFrames>(tfByRecomendsStrategy)
  const [zigzagIsActive, setZigzagIsActive] = useState(true)
  const [movingAverageIsActive, setMovingAverageIsActive] = useState(true)
  const [pivotIsActive, setPivotIsActive] = useState(true)
  const [volumeIsActive, setVolumeIsActive] = useState(true)
  const [rsiIsActive, setRsiIsActive] = useState(true)
  const [stochasticIsActive, setStochasticIsActive] = useState(true)
  const [macdIsActive, setMacdIsActive] = useState(true)
  const [modeIsActive, setModeIsActive] = useState(true)
  const [coordOfClick, setCoordOfClick] = useState<{ x: number, y: number }>()

  //снятие координаты с места клика по canvas для отрисовки вертикальной метки
  const getCursorPositionClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (event.target) {
      if (modeIsActive) {
        const canvas = event.target as HTMLCanvasElement
        const rect = canvas.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top
        setCoordOfClick({x, y})
      }
    }
  }

  //изначальное получение canvas графика и настройка
  useEffect(() => {
    if (chartCanvas.current) {
      const canvas = chartCanvas.current
      const context = canvas.getContext('2d')
      if (context) {
        //это решение вопроса размытой графики
        context.translate(0.5, 0.5);
        setCanvasContext(context)
      }
    }
  }, [chartCanvas])

  //изначальное получение canvas индикатора Volume
  useEffect(() => {
    if (volumeCanvas.current) {
      const canvasVolume = volumeCanvas.current
      const contextVolume = canvasVolume.getContext('2d')
      if (contextVolume) {
        //это решение вопроса размытой графики
        contextVolume.translate(0.5, 0.5);
        setCanvasVolumeContext(contextVolume)
      }
    }
  }, [volumeCanvas, volumeIsActive])

  //изначальное получение canvas индикатора RSI
  useEffect(() => {
    if (rsiCanvas.current) {
      const canvasRsi = rsiCanvas.current
      const contextRsi = canvasRsi.getContext('2d')
      if (contextRsi) {
        //это решение вопроса размытой графики
        contextRsi.translate(0.5, 0.5);
        setCanvasRsiContext(contextRsi)
      }
    }
  }, [rsiCanvas, rsiIsActive])

  //изначальное получение canvas индикатора Stochastic
  useEffect(() => {
    if (stochasticCanvas.current) {
      const canvasStochastic = stochasticCanvas.current
      const contextStochastic = canvasStochastic.getContext('2d')
      if (contextStochastic) {
        //это решение вопроса размытой графики
        contextStochastic.translate(0.5, 0.5);
        setCanvasStochasticContext(contextStochastic)
      }
    }
  }, [stochasticCanvas, stochasticIsActive])

  //изначальное получение canvas индикатора Macd
  useEffect(() => {
    if (macdCanvas.current) {
      const canvasMacd = macdCanvas.current
      const contextMacd = canvasMacd.getContext('2d')
      if (contextMacd) {
        //это решение вопроса размытой графики
        contextMacd.translate(0.5, 0.5);
        setCanvasMacdContext(contextMacd)
      }
    }
  }, [macdCanvas, macdIsActive])

  //изначальная прокрутка скролла на графике на начало графика
  useEffect(() => {
    const canvas = wrapCanvas.current
    if (canvas) {
      setTimeout(() => {
        canvas.scrollLeft = canvas.scrollWidth;
      }, 100)
    }
  }, [wrapCanvas, active])

  //рисование графика
  useEffect(() => {
    if (canvasContext) {
      //очистка холста при перерендере
      canvasContext.clearRect(0, 0, canvasSize.width, canvasSize.height);
      canvasContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const offsetLeftBorder = offsetChartFromLeftBorderCanvas
      const indOfLastCandle = chart.length - 1
      const widthPriceScale = widthPriceScaleChartCanvas
      const heightTimeScale = heightTimeScaleChartCanvas
      const lineWidthOfScale = lineWidthOfScaleChartCanvas
      const lineWidthOfZigzag = lineWidthOfZigzagCanvas
      const colorOfAxis = colorOfAxisCanvas
      const colorOfZigzag = colorOfZigzagCanvas
      const lineWidthOfMovAver = lineWidthOfMovAverCanvas
      const colorOfShortMovAver = colorOfShortMovAverCanvas
      const periodOfShortMovAver = periodOfShortMovAverCanvas
      const colorOfMiddleMovAver = colorOfMiddleMovAverCanvas
      const periodOfMiddleMovAver = periodOfMiddleMovAverCanvas
      const colorOfLongMovAver = colorOfLongMovAverCanvas
      const periodOfLongMovAver = periodOfLongMovAverCanvas
      const colorOfCurrentPrice = colorOfCurrentPriceCanvas
      const lineWidthOfPivot = lineWidthOfPivotCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfLevelPivot = colorOfLevelPivotCanvas
      const colorOfResistPivot = colorOfResistPivotCanvas
      const colorOfSupportPivot = colorOfSupportPivotCanvas
      const colorOfVerticalMarker = colorOfVerticalMarkerCanvas
      const lineWidthOfVerticalMarker = lineWidthOfVerticalMarkerCanvas
      const colorOfLineCandlestick = colorOfLineCandlestickCanvas
      const currentPrice = chart[indOfLastCandle][CandleTypePart.close]
      const {highPrice, lowPrice} = getLimitPrices(chart)
      const scaleXCandle = formulaOfCandleCanvas
      const stepCandleOnScaleX = scaleXCandle.total
      const scaleY = scalePrice(highPrice, lowPrice, canvasSize.height, heightTimeScale)
      const startOfScaleY = startOfScalePrice(chart)
      const tfForPivot = choiceTfForPivot(tf)

      //-------рисование графики----------
      //график
      chart.forEach((candle, index) => {
        const newInd = indOfLastCandle - index
        buildCandle(canvasContext, canvasSize.width, canvasSize.height, scaleXCandle, scaleY, startOfScaleY, offsetLeftBorder, widthPriceScale, heightTimeScale, candle, newInd, colorOfLineCandlestick)
      })
      //рисование вертикального маркера по клику мыши
      if (coordOfClick) {
        buildVerticalMarker(canvasContext, coordOfClick.x, canvasSize.height, colorOfVerticalMarker, lineWidthOfVerticalMarker)
      }
      //оси
      buildYAxis(canvasContext, canvasSize.width, canvasSize.height, scaleY, startOfScaleY, widthPriceScale, heightTimeScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, currentPrice, highPrice, lowPrice)
      buildXAxis(canvasContext, canvasSize.width, canvasSize.height, heightTimeScale, widthPriceScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, scaleXCandle, chart, offsetLeftBorder)
      //зигзаг
      if (zigzagIsActive) {
        buildZigzag(canvasContext, chart, zigzagParam, startOfScaleY, scaleY, canvasSize.height, canvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorOfZigzag, lineWidthOfZigzag)
      }
      //скользящие средние
      if (movingAverageIsActive) {
        buildMovingAverage(canvasContext, chart, periodOfShortMovAver, startOfScaleY, scaleY, canvasSize.height, canvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorOfShortMovAver, lineWidthOfMovAver)
        buildMovingAverage(canvasContext, chart, periodOfMiddleMovAver, startOfScaleY, scaleY, canvasSize.height, canvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorOfMiddleMovAver, lineWidthOfMovAver)
        buildMovingAverage(canvasContext, chart, periodOfLongMovAver, startOfScaleY, scaleY, canvasSize.height, canvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorOfLongMovAver, lineWidthOfMovAver)
      }
      //точки разворота (пивот)
      if (pivotIsActive) {
        buildPivot(canvasContext, quotes[tfForPivot], startOfScaleY, scaleY, canvasSize.height, canvasSize.width, heightTimeScale, widthPriceScale, scaleXCandle, offsetLeftBorder, colorOfLevelPivot, colorOfResistPivot, colorOfSupportPivot, lineWidthOfPivot)
      }
    }
  }, [canvasContext, zigzagIsActive, movingAverageIsActive, pivotIsActive, coordOfClick])

  //рисование индикатора Volume для графика старшего таймфрейма
  useEffect(() => {
    if (canvasVolumeContext) {
      //очистка холста при перерендере
      canvasVolumeContext.clearRect(0, 0, indicatorsCanvasSize.width, indicatorsCanvasSize.height);
      canvasVolumeContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const offsetLeftBorder = offsetChartFromLeftBorderCanvas
      const indOfLastCandle = chart.length - 1
      const widthPriceScale = widthPriceScaleChartCanvas
      const heightTimeScale = heightTimeScaleIndicatorsCanvas
      const lineWidthOfScale = lineWidthOfScaleChartCanvas
      const lineWidthOfVolume = lineWidthOfVolumeCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfAxis = colorOfAxisCanvas
      const colorOfCurrentPrice = colorOfCurrentPriceCanvas
      const colorVolumeOnUpCandle = colorOfVolumeOnUpCandle
      const colorVolumeOnDownCandle = colorOfVolumeOnDownCandle
      const colorVolumeOnDojiCandle = colorOfVolumeOnDojiCandle
      const colorOfVerticalMarker = colorOfVerticalMarkerCanvas
      const lineWidthOfVerticalMarker = lineWidthOfVerticalMarkerCanvas
      const {highVolume} = getLimitPrices(chart)
      const scaleYVolume = scalePrice(highVolume, 0, heightIndicatorsCanvas, heightTimeScale)
      const stepCandleOnScaleX = formulaOfCandleCanvas.total

      //-------рисование графики----------
      const currentVolume = chart[indOfLastCandle][CandleTypePart.volume]
      buildVolume(canvasVolumeContext, chart, scaleYVolume, heightIndicatorsCanvas, indicatorsCanvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorVolumeOnUpCandle, colorVolumeOnDownCandle, colorVolumeOnDojiCandle, colorOfLegend, lineWidthOfVolume)
      buildYAxisOscillator(canvasVolumeContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, scaleYVolume, widthPriceScale, heightTimeScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, currentVolume, highVolume, 0)
      buildXAxisOscillator(canvasVolumeContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, heightTimeScale, widthPriceScale, lineWidthOfScale, colorOfAxis)

      //рисование вертикального маркера по клику мыши
      if (coordOfClick) {
        buildVerticalMarker(canvasVolumeContext, coordOfClick.x, indicatorsCanvasSize.height, colorOfVerticalMarker, lineWidthOfVerticalMarker)
      }

    }
  }, [canvasVolumeContext, volumeIsActive, coordOfClick])

  //рисование индикатора RSI для графика старшего таймфрейма
  useEffect(() => {
    if (canvasRsiContext) {
      //очистка холста при перерендере
      canvasRsiContext.clearRect(0, 0, indicatorsCanvasSize.width, indicatorsCanvasSize.height);
      canvasRsiContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const offsetLeftBorder = offsetChartFromLeftBorderCanvas
      const widthPriceScale = widthPriceScaleChartCanvas
      const heightTimeScale = heightTimeScaleIndicatorsCanvas
      const lineWidthOfScale = lineWidthOfScaleChartCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfAxis = colorOfAxisCanvas
      const colorOfCurrentPrice = colorOfCurrentPriceCanvas
      const periodOfRsi = periodOfRsiCanvas
      const colorRsi = colorOfRsiCanvas
      const colorControlLevelRsi = colorOfControlLevelRsiCanvas
      const lineWidthRsi = lineWidthOfRsiCanvas
      const colorOfVerticalMarker = colorOfVerticalMarkerCanvas
      const lineWidthOfVerticalMarker = lineWidthOfVerticalMarkerCanvas
      const scaleYFrom0To100 = scalePrice(100, 0, heightIndicatorsCanvas, heightTimeScale)
      const stepCandleOnScaleX = formulaOfCandleCanvas.total

      //-------рисование графики----------
      const currentRsi = Math.round(getRsiValue(chart, periodOfRsi))
      buildRsi(canvasRsiContext, chart, periodOfRsi, scaleYFrom0To100, heightIndicatorsCanvas, indicatorsCanvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorRsi, colorOfLegend, colorControlLevelRsi, lineWidthRsi)
      buildYAxisOscillator(canvasRsiContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, scaleYFrom0To100, widthPriceScale, heightTimeScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, currentRsi, 100, 0)
      buildXAxisOscillator(canvasRsiContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, heightTimeScale, widthPriceScale, lineWidthOfScale, colorOfAxis)

      //рисование вертикального маркера по клику мыши
      if (coordOfClick) {
        buildVerticalMarker(canvasRsiContext, coordOfClick.x, indicatorsCanvasSize.height, colorOfVerticalMarker, lineWidthOfVerticalMarker)
      }
    }
  }, [canvasRsiContext, rsiIsActive, coordOfClick])

  //рисование индикатора Stochastic для графика старшего таймфрейма
  useEffect(() => {
    if (canvasStochasticContext) {
      //очистка холста при перерендере
      canvasStochasticContext.clearRect(0, 0, indicatorsCanvasSize.width, indicatorsCanvasSize.height);
      canvasStochasticContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const offsetLeftBorder = offsetChartFromLeftBorderCanvas
      const widthPriceScale = widthPriceScaleChartCanvas
      const heightTimeScale = heightTimeScaleIndicatorsCanvas
      const lineWidthOfScale = lineWidthOfScaleChartCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfAxis = colorOfAxisCanvas
      const colorOfCurrentPrice = colorOfCurrentPriceCanvas
      const periodOfStochastic = periodOfStochasticCanvas
      const colorStochastic = colorOfStochasticCanvas
      const colorStochasticMoving = colorOfStochasticMovingCanvas
      const colorControlLevelStochastic = colorOfControlLevelStochasticCanvas
      const lineWidthStochastic = lineWidthOfStochasticCanvas
      const colorOfVerticalMarker = colorOfVerticalMarkerCanvas
      const lineWidthOfVerticalMarker = lineWidthOfVerticalMarkerCanvas
      const scaleYFrom0To100 = scalePrice(100, 0, heightIndicatorsCanvas, heightTimeScale)
      const stepCandleOnScaleX = formulaOfCandleCanvas.total

      //-------рисование графики----------
      const currentStochastic = Math.round(getStochasticValue(chart, periodOfStochastic))
      buildStochastic(canvasStochasticContext, chart, periodOfStochastic, scaleYFrom0To100, heightIndicatorsCanvas, indicatorsCanvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorStochastic, colorStochasticMoving, colorOfLegend, colorControlLevelStochastic, lineWidthStochastic)
      buildYAxisOscillator(canvasStochasticContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, scaleYFrom0To100, widthPriceScale, heightTimeScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, currentStochastic, 100, 0)
      buildXAxisOscillator(canvasStochasticContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, heightTimeScale, widthPriceScale, lineWidthOfScale, colorOfAxis)

      //рисование вертикального маркера по клику мыши
      if (coordOfClick) {
        buildVerticalMarker(canvasStochasticContext, coordOfClick.x, indicatorsCanvasSize.height, colorOfVerticalMarker, lineWidthOfVerticalMarker)
      }
    }
  }, [canvasStochasticContext, stochasticIsActive, coordOfClick])

  //рисование индикатора Macd для графика старшего таймфрейма
  useEffect(() => {
    if (canvasMacdContext) {
      //очистка холста при перерендере
      canvasMacdContext.clearRect(0, 0, indicatorsCanvasSize.width, indicatorsCanvasSize.height);
      canvasMacdContext.beginPath();
      //константы для графики и различные переменные для смещения и трансформирования размеров графики под размер холста
      const offsetLeftBorder = offsetChartFromLeftBorderCanvas
      const widthPriceScale = widthPriceScaleChartCanvas
      const heightTimeScale = heightTimeScaleIndicatorsCanvas
      const lineWidthOfScale = lineWidthOfScaleChartCanvas
      const colorOfLegend = colorOfLegendCanvas
      const colorOfAxis = colorOfAxisCanvas
      const colorOfCurrentPrice = colorOfCurrentPriceCanvas
      const periodFastMaMacd = periodOfFastMaMacdCanvas
      const periodSlowMaMacd = periodOfSlowMaMacdCanvas
      const periodSignalMacd = periodOfSignalMacdCanvas
      const colorMacd = colorOfMacdCanvas
      const colorSignalMacd = colorOfSignalMacdCanvas
      const colorMacdHistogram = colorOfMacdHistogramCanvas
      const lineWidthMacd = lineWidthOfMacdCanvas
      const colorOfVerticalMarker = colorOfVerticalMarkerCanvas
      const lineWidthOfVerticalMarker = lineWidthOfVerticalMarkerCanvas
      const stepCandleOnScaleX = formulaOfCandleCanvas.total

      //-------рисование графики----------
      const fastMovAver = getMovingAverage(chart, periodFastMaMacd)
      const slowMovAver = getMovingAverage(chart, periodSlowMaMacd)
      const macd = getMacd(fastMovAver, slowMovAver)
      const macdSignal = getMovingAverageForNumArr(macd, periodSignalMacd)
      const macdHistogram = getMacdHistogram(macd, macdSignal)
      const currentMacd = Math.round(macd[macd.length - 1])
      const {highValue, lowValue} = getLimitValuesOnMacd(macd, macdSignal, macdHistogram)
      const scaleY = scalePrice(highValue, lowValue, heightIndicatorsCanvas, heightTimeScale)
      buildMacd(canvasMacdContext, macd, macdSignal, macdHistogram, scaleY, heightIndicatorsCanvas, indicatorsCanvasSize.width, heightTimeScale, widthPriceScale, stepCandleOnScaleX, offsetLeftBorder, colorMacd, colorSignalMacd, colorMacdHistogram, colorOfLegend, lineWidthMacd, lowValue)
      buildYAxisOscillator(canvasMacdContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, scaleY, widthPriceScale, heightTimeScale, lineWidthOfScale, colorOfAxis, colorOfCurrentPrice, currentMacd, highValue, lowValue, 0, lowValue)
      buildXAxisOscillator(canvasMacdContext, indicatorsCanvasSize.width, heightIndicatorsCanvas, heightTimeScale, widthPriceScale, lineWidthOfScale, colorOfAxis)

      //рисование вертикального маркера по клику мыши
      if (coordOfClick) {
        buildVerticalMarker(canvasMacdContext, coordOfClick.x, indicatorsCanvasSize.height, colorOfVerticalMarker, lineWidthOfVerticalMarker)
      }
    }
  }, [canvasMacdContext, macdIsActive, coordOfClick])

  //управление активность индикаторов специальными кнопками
  const toggleZigzag = () => {
    const prevState = zigzagIsActive
    setZigzagIsActive(!prevState)
  }
  const toggleMovAver = () => {
    const prevState = movingAverageIsActive
    setMovingAverageIsActive(!prevState)
  }
  const togglePivot = () => {
    const prevState = pivotIsActive
    setPivotIsActive(!prevState)
  }
  const toggleVolume = () => {
    const prevState = volumeIsActive
    setVolumeIsActive(!prevState)
  }
  const toggleRsi = () => {
    const prevState = rsiIsActive
    setRsiIsActive(!prevState)
  }
  const toggleStochastic = () => {
    const prevState = stochasticIsActive
    setStochasticIsActive(!prevState)
  }
  const toggleMacd = () => {
    const prevState = macdIsActive
    setMacdIsActive(!prevState)
  }
  const toggleMode = () => {
    const prevState = modeIsActive
    if (prevState) {
      //при выключении режима вертикальной метки затираю линию
      setCoordOfClick(undefined)
    }
    setModeIsActive(!prevState)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.buttons}>
          <span onClick={toggleZigzag} className={zigzagIsActive ? styles.active : ''}>Zz</span>
          <span onClick={toggleMovAver} className={movingAverageIsActive ? styles.active : ''}>Ma</span>
          <span onClick={togglePivot} className={pivotIsActive ? styles.active : ''}>Pv</span>
          <span onClick={toggleVolume} className={volumeIsActive ? styles.active : ''}>Vm</span>
          <span onClick={toggleRsi} className={rsiIsActive ? styles.active : ''}>Rs</span>
          <span onClick={toggleStochastic} className={stochasticIsActive ? styles.active : ''}>St</span>
          <span onClick={toggleMacd} className={macdIsActive ? styles.active : ''}>Md</span>
          <span onClick={toggleMode} className={[styles.mode, modeIsActive ? styles.active : ''].join(' ')}>Mode</span>
        </div>
        <div className={styles.wrap}>
          <div ref={wrapCanvas} className={[styles.block, styles.chart].join(' ')}>
            <div className={styles.canvasWrap}>
              <p className={styles.legend}>{tf}</p>
              <canvas
                onClick={(event: React.MouseEvent<HTMLCanvasElement>) => getCursorPositionClick(event)}
                ref={chartCanvas} width={canvasSize.width} height={canvasSize.height}>
                Ваш браузер не поддерживается!
              </canvas>
            </div>
            {
              volumeIsActive &&
              <div className={styles.canvasWrap}>
                <p className={[styles.legend, styles.indicator].join(' ')}>{'Volume'}</p>
                <canvas ref={volumeCanvas} width={indicatorsCanvasSize.width}
                        height={indicatorsCanvasSize.height}>
                  Ваш браузер не поддерживается!
                </canvas>
              </div>
            }
            {
              rsiIsActive &&
              <div className={styles.canvasWrap}>
                <p className={[styles.legend, styles.indicator].join(' ')}>{'RSI (14)'}</p>
                <canvas ref={rsiCanvas} width={indicatorsCanvasSize.width}
                        height={indicatorsCanvasSize.height}>
                  Ваш браузер не поддерживается!
                </canvas>
              </div>
            }
            {
              stochasticIsActive &&
              <div className={styles.canvasWrap}>
                <p className={[styles.legend, styles.indicator].join(' ')}>{'Stoch (14)'}</p>
                <canvas ref={stochasticCanvas} width={indicatorsCanvasSize.width}
                        height={indicatorsCanvasSize.height}>
                  Ваш браузер не поддерживается!
                </canvas>
              </div>
            }
            {
              macdIsActive &&
              <div className={styles.canvasWrap}>
                <p className={[styles.legend, styles.indicator].join(' ')}>{'MACD (12, 26, 9)'}</p>
                <canvas ref={macdCanvas} width={indicatorsCanvasSize.width}
                        height={indicatorsCanvasSize.height}>
                  Ваш браузер не поддерживается!
                </canvas>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;