import {useHttp} from "./http.hook";
import {Countries, IAnalytics} from "../shared/types";

interface IDataGetAllAnalytics {
  message: string,
  info: IAnalytics
}

export const useAllAnalytics = () => {

  const {loading, request, error, clearError} = useHttp()

  //получение всей аналитики
  const getAllAnalytics = async (country: Countries, tickerList: string[]) => {
    try {
      const data: IDataGetAllAnalytics = await request('/api/analytics/get-all-analytics', 'POST', {
        country: country,
        tickerList: tickerList
      })
      return data
    } catch (e) {
      throw e
    }
  }

  return {loading, getAllAnalytics, error, clearError}
}