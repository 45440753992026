import React, {ChangeEvent, Fragment, useEffect, useState} from 'react';
import styles from "./advisors.module.scss";
import {Button, Checkbox, Input, message as messageAnt, Select, SelectProps} from "antd";
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import {
  Candle,
  Countries,
  IAnalytics,
  IElemAllStrategyTransform,
  IInfoAboutAdviceToAnalyticsPopup,
  IParamsStrategyFunction, IRatingUser,
  ISeriesOfTransaction,
  IToOrder,
  OpenedPositions,
  RecommendationLabel,
  StateOfTrade,
  StateOfTradeLabel,
  Strategies,
  StrategyLabel,
  StrategyLabelFlip,
  StrategyObject,
  TimeFrames,
  TimeFramesLabel,
  TradeAction
} from "../../../shared/types";
import {v4 as uuidv4} from "uuid";
import {useSettings} from "../../../hooks/settings.hook";
import {transformAllPositions, transformAllSettings} from "../../../shared/lib/lib";
import dayjs from "dayjs";
import {usePositions} from "../../../hooks/positions.hook";
import {useRequest} from "../../../hooks/request.hook";
import {beep} from "../../../services/sound";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import soundOn from '../../../assets/images/soundOn.png'
import soundOff from '../../../assets/images/soundOff.png'
import advisorsOn from '../../../assets/images/on.png'
import advisorsOff from '../../../assets/images/off.png'
import {appSlice} from "../../../store/redusers/appSlice";
import PopupAnalytics from "./popupAnalytics/PopupAnalytics";
import {useAnalytics} from "../../../hooks/analytics.hook";
import ColorIndicator, {Color as ColorOfIndicator} from "../../../components/colorIndicator/ColorIndicator";
import {useMembersRating} from "../../../hooks/membersRating.hook";

interface Props {
  active: boolean
  quotes: Record<string, Record<TimeFrames, Candle[]>> | undefined
  country: Countries
}

const Advisors = ({active, quotes, country}: Props) => {

  const {loading: loadingSettings, getAllSettings, saveSettingsForStrategy, error: errorSettings, clearError: clearErrorSettings} = useSettings()
  const {loading: loadingPositions, saveOpenedPositions, removeOpenedPositions, getAllPositions, error: errorPositions, clearError: clearErrorPositions} = usePositions()
  const {loading: loadingRequest, saveEnteredRequest, getAllEnteredRequests, removeEnteredRequest, error: errorRequest, clearError: clearErrorRequest} = useRequest()
  const {getAnalytics, loading: loadingAnalytics, error: errorAnalytics, clearError: clearErrorAnalytics} = useAnalytics()
  const {loading: loadingMembersRating, getAllMembersRating, error: errorMembersRating, clearError: clearErrorMembersRating} = useMembersRating()

  const dispatch = useAppDispatch()
  const {allowAdvisorsSound, onOffAdvisors} = appSlice.actions

  const isAllowSound = useAppSelector(state => state.appReduser.allowSound)
  const isAllowAdvisorsSound = useAppSelector(state => state.appReduser.allowAdvisorsSound)
  const isOnAdvisors = useAppSelector(state => state.appReduser.onOffAdvisors)

  const [stateSoundTrigger, setStateSoundTrigger] = useState(isAllowAdvisorsSound)
  const [stateOnAdvisorsTrigger, setStateOnAdvisorsTrigger] = useState(isOnAdvisors)
  const [allSettings, setAllSettings] = useState<Record<Strategies, IElemAllStrategyTransform> | undefined>()
  const [optionsSelectStrategyForEnable, setOptionsSelectStrategyForEnable] = useState<SelectProps['options']>()
  const [strategyForEnable, setStrategyForEnable] = useState<string | undefined>()
  const [strategyIsEnable, setStrategyIsEnable] = useState<boolean | undefined>(false)
  const [optionsSelectStrategyForSetting, setOptionsSelectStrategyForSetting] = useState<SelectProps['options']>()
  const [strategyForSetting, setStrategyForSetting] = useState<Strategies | undefined>()
  const [saveSettingsBtnDisabled, setSaveSettingsBtnDisabled] = useState(true)
  const [recomends, setRecomends] = useState<Record<TradeAction, Partial<Record<Strategies, [string[]]>>>>()
  const [toOrderList, setToOrderList] = useState<IToOrder[]>([])
  const [openedPositions, setOpenedPositions] = useState<typeof OpenedPositions>()
  const [btnRequestLoading, setBtnRequestLoading] = useState(false)
  const [btnOrderSendLoading, setBtnOrderSendLoading] = useState(false)
  const [btnOrderCancelLoading, setBtnOrderCancelLoading] = useState(false)
  const [analyticsIsShow, setAnalyticsIsShow] = useState(false)//отображается ли попап с аналитикой
  const [tickerListToGetExternalAnalytics, setTickerListToGetExternalAnalytics] = useState<string[]>()//список тикеров по которым нужно получить внешнюю аналитику, только новые тикеры, чтобы по тем которым уже получили не получать заново
  const [analytics, setAnalytics] = useState<IAnalytics>()//объект внешней аналитики для попапа
  const [analyticsIsProcessing, setAnalyticsIsProcessing] = useState<boolean>(false)//программный индикатор, что запущен процесс получения аналитики, нужен для временной блокировки повторного запроса после обновления котировок если один уже запущен
  const [infoAboutAdviceToAnalyticsPopup, setInfoAboutAdviceToAnalyticsPopup] = useState<IInfoAboutAdviceToAnalyticsPopup>()//объект содержащий информацию об кликнутом совете от стратегии, который передается в попап с аналитикой
  const [colorIndicatorAnalytics, setColorIndicatorAnalytics] = useState<ColorOfIndicator>(ColorOfIndicator.gray)//цвет индикатора результата загрузки аналитических данных
  const [membersRatingList, setMembersRatingList] = useState<IRatingUser[]>()//рейтинги прогнозирующих пользователей

  //поля тонких настроек актуальных для выбранной стратегии
  const [setting0, setSetting0] = useState<string>('')
  const [setting1, setSetting1] = useState<string>('')
  const [setting2, setSetting2] = useState<string>('')
  const [setting3, setSetting3] = useState<string>('')
  const [setting4, setSetting4] = useState<string>('')
  const [setting5, setSetting5] = useState<string>('')
  const [setting6, setSetting6] = useState<string>('')
  const [setting7, setSetting7] = useState<string>('')
  const [setting8, setSetting8] = useState<string>('')
  const [setting9, setSetting9] = useState<string>('')
  const [setting10, setSetting10] = useState<string>('')
  const [setting11, setSetting11] = useState<string>('')
  const [setting12, setSetting12] = useState<string>('')
  const [setting13, setSetting13] = useState<string>('')
  const [setting14, setSetting14] = useState<string>('')
  const [setting15, setSetting15] = useState<string>('')
  const [setting16, setSetting16] = useState<string>('')
  const [setting17, setSetting17] = useState<string>('')
  const [setting18, setSetting18] = useState<string>('')
  const [setting19, setSetting19] = useState<string>('')

  //получение данных по рейтингам пользователей из БД
  const getAllMembersRatings = async () => {
    try {
      const data = await getAllMembersRating()
      messageAnt.info(data.message)
      const ratings = data.info
      return ratings
    } catch (e) {
      messageAnt.info(errorMembersRating);
      clearErrorMembersRating()
      return undefined
    }
  }

  //изначальное получение данных по рейтингам пользователей из БД
  useEffect(() => {
    const get = async () => {
      const allMembersRatings = await getAllMembersRatings()
      if (allMembersRatings) {
        setMembersRatingList(allMembersRatings)
      }
    }
    get()
  }, [])

  //проверка нужны ли звуковые уведомления
  useEffect(() => {
    let needBeep = false

    //звуковое уведомление если есть сигналы на покупку, они могут быть отключены если выключен индикатор isAllowAdvisorsSound
    if (isAllowSound && isAllowAdvisorsSound && recomends?.BUY && Object.keys(recomends.BUY).length > 0) {
      needBeep = true
    }

    //проверка, есть ли сигналы на добавление в серию сделок
    let haveNeedAddEnterInSeries = false
    if (recomends?.BUY && Object.keys(recomends.BUY).length > 0) {
      Object.keys(Strategies).forEach((strategy) => {
        recomends[TradeAction.BUY][strategy as keyof typeof Strategies]?.forEach((recomendation) => {
          if (recomendation[2] === StateOfTrade.NEED_ADD_ENTER_IN_SERIES) {
            haveNeedAddEnterInSeries = true
          }
        })
      })
    }

    //звуковое уведомление если есть любые сигналы на продажу или сигнал на добавление в серию сделок, они включены всегда, тк как они важные
    if (
      isAllowSound &&
      (
        (recomends?.SELL && Object.keys(recomends.SELL).length > 0) ||
        (haveNeedAddEnterInSeries)
      )
    ) {
      needBeep = true
    }

    //если есть причины для сигнала, производим сигнал
    if (needBeep) {
      beep(isAllowSound)
    }
  }, [isAllowSound, isAllowAdvisorsSound, recomends])

  //включение/выключение звуков уведомлений советов
  const switchSoundHandler = () => {
    const stateSound = stateSoundTrigger
    setStateSoundTrigger(!stateSound)
    dispatch(allowAdvisorsSound(!stateSound))
  }

  //включение/выключение работы советников
  const switchAdvisorsHandler = () => {
    const stateAdvisor = stateOnAdvisorsTrigger
    setStateOnAdvisorsTrigger(!stateAdvisor)
    dispatch(onOffAdvisors(!stateAdvisor))
  }

  //формирование списка для селектора выбора стратегии для подключения/отключения
  useEffect(() => {
    const optionsStrategy: SelectProps['options'] = []
    Object.entries(StrategyLabel).forEach(([key, value]) => {
      optionsStrategy.push({value: value, label: value})
    })
    setOptionsSelectStrategyForEnable(optionsStrategy)
  }, [StrategyLabel])

  //выбор стратегии для подключения/отключения
  const onChangeStrategyForEnable = (value: string) => {
    setStrategyForEnable(value as Strategies)
  };

  //установка флага, подключена ли стратегия в работу, информация из БД
  useEffect(() => {
    if (allSettings && strategyForEnable) {
      const isEnable = allSettings[StrategyLabelFlip[strategyForEnable]]?.enable
      setStrategyIsEnable(isEnable)
    }
  }, [allSettings, strategyForEnable])

  //сохранение настроек стратегии в БД
  const saveSettings = async (strategy: Strategies, enable: boolean, settings: string) => {
    try {
      const data = await saveSettingsForStrategy(strategy, enable, settings)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorSettings);
      clearErrorSettings()
    }
  }

  //получение точных настроек для всех стратегий из БД
  const getSettings = async () => {
    try {
      const data = await getAllSettings()
      messageAnt.info(data.message)
      const settings = transformAllSettings(data.info)
      return settings
    } catch (e) {
      messageAnt.info(errorSettings);
      clearErrorSettings()
      return undefined
    }
  }

  //изначальное получение настроек для стратегий с сервера
  useEffect(() => {
    const get = async () => {
      const settings = await getSettings()
      setAllSettings(settings)
    }
    get()
  }, [])

  //подключение/отключение выбранной стратегии в работу
  const onChangeEnableStrategy = async (e: CheckboxChangeEvent) => {
    if (strategyForEnable) {
      const enable = e.target.checked
      setStrategyIsEnable(enable)
      const strategy = StrategyLabelFlip[strategyForEnable]
      const settingsString = (allSettings && allSettings[StrategyLabelFlip[strategyForEnable]] && allSettings[StrategyLabelFlip[strategyForEnable]]?.settings) ? allSettings[StrategyLabelFlip[strategyForEnable]].settings : ''
      await saveSettings(strategy, enable, settingsString)
      const settingsNew = await getSettings()
      setAllSettings(settingsNew)
    }
  };

  //формирование списка для селектора выбора стратегии для настройки стратегии. В списке будут только подключенные в работу стратегии.
  useEffect(() => {
    const optionsStrategy: SelectProps['options'] = []
    if (allSettings) {
      Object.keys(allSettings).forEach((sett) => {
        if (allSettings[sett as keyof typeof allSettings].enable) {
          optionsStrategy.push({
            value: StrategyLabel[sett as keyof typeof StrategyLabel],
            label: StrategyLabel[sett as keyof typeof StrategyLabel]
          })
        }
      })
    }
    setOptionsSelectStrategyForSetting(optionsStrategy)
  }, [StrategyLabel, allSettings])

  //выбор стратегии для настройки из списка активных
  const onChangeStrategyForSetting = (value: string) => {
    setStrategyForSetting(value as Strategies)
  };

  //изначальное заполнение полей настроек выбранной для настройки стратегии
  useEffect(() => {
    if (allSettings && strategyForSetting) {
      let sett0 = ''
      let sett1 = ''
      let sett2 = ''
      let sett3 = ''
      let sett4 = ''
      let sett5 = ''
      let sett6 = ''
      let sett7 = ''
      let sett8 = ''
      let sett9 = ''
      let sett10 = ''
      let sett11 = ''
      let sett12 = ''
      let sett13 = ''
      let sett14 = ''
      let sett15 = ''
      let sett16 = ''
      let sett17 = ''
      let sett18 = ''
      let sett19 = ''
      if (allSettings[StrategyLabelFlip[strategyForSetting]].settings !== '') {
        sett0 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[0]]
        sett1 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[1]]
        sett2 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[2]]
        sett3 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[3]]
        sett4 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[4]]
        sett5 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[5]]
        sett6 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[6]]
        sett7 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[7]]
        sett8 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[8]]
        sett9 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[9]]
        sett10 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[10]]
        sett11 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[11]]
        sett12 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[12]]
        sett13 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[13]]
        sett14 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[14]]
        sett15 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[15]]
        sett16 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[16]]
        sett17 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[17]]
        sett18 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[18]]
        sett19 = JSON.parse(allSettings[StrategyLabelFlip[strategyForSetting]].settings)[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[19]]
      }
      setSetting0(sett0)
      setSetting1(sett1)
      setSetting2(sett2)
      setSetting3(sett3)
      setSetting4(sett4)
      setSetting5(sett5)
      setSetting6(sett6)
      setSetting7(sett7)
      setSetting8(sett8)
      setSetting9(sett9)
      setSetting10(sett10)
      setSetting11(sett11)
      setSetting12(sett12)
      setSetting13(sett13)
      setSetting14(sett14)
      setSetting15(sett15)
      setSetting16(sett16)
      setSetting17(sett17)
      setSetting18(sett18)
      setSetting19(sett19)
    }
  }, [allSettings, strategyForSetting])

  //изменение настроек у выбранной для настройки стратегии
  const changeSetting0Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting0(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting1Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting1(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting2Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting2(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting3Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting3(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting4Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting4(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting5Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting5(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting6Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting6(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting7Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting7(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting8Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting8(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting9Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting9(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting10Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting10(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting11Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting11(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting12Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting12(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting13Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting13(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting14Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting14(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting15Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting15(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting16Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting16(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting17Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting17(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting18Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting18(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }
  const changeSetting19Handler = (e: ChangeEvent<HTMLInputElement>) => {
    setSetting19(e.target.value)
    setSaveSettingsBtnDisabled(false)
  }

  //сохранение изменений настроек для выбранной для настройки стратегии
  const saveSettingsHandler = async () => {
    if (strategyForSetting && allSettings) {
      const enable = allSettings[StrategyLabelFlip[strategyForSetting]].enable
      const strategy = StrategyLabelFlip[strategyForSetting]
      const settingsString = JSON.stringify({
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[0]]: setting0,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[1]]: setting1,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[2]]: setting2,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[3]]: setting3,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[4]]: setting4,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[5]]: setting5,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[6]]: setting6,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[7]]: setting7,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[8]]: setting8,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[9]]: setting9,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[10]]: setting10,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[11]]: setting11,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[12]]: setting12,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[13]]: setting13,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[14]]: setting14,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[15]]: setting15,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[16]]: setting16,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[17]]: setting17,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[18]]: setting18,
        [Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[19]]: setting19,
      })
      await saveSettings(strategy, enable, settingsString)
      const settingsNew = await getSettings()
      setAllSettings(settingsNew)
    }
  }

  //получение данных по открытым позициям для всех стратегий, таймфреймов и инструментов из БД
  const getOpenedPositions = async (country: Countries) => {
    try {
      const data = await getAllPositions(country)
      messageAnt.info(data.message)
      const positions = transformAllPositions(data.info)
      return positions
    } catch (e) {
      messageAnt.info(errorPositions);
      clearErrorPositions()
      return undefined
    }
  }

  //изначальное получение данных по открытым позициям из БД
  useEffect(() => {
    const get = async () => {
      const openedPositions = await getOpenedPositions(country)
      setOpenedPositions(openedPositions)
    }
    get()
  }, [])

  //функция для сохранение данных по открытым позициям в БД для одной стратегии в которой были изменения по позициям по любому тикеру и тф
  const savePositions = async (country: Countries, strategy: Strategies, positions: string) => {
    if (!positions) {
      //если последняя позиция по стратегии закрыта, то очищаем документ по этой стратегии в БД
      try {
        const data = await removeOpenedPositions(country, strategy)
        messageAnt.info(data.message)
      } catch (e) {
        messageAnt.info(errorPositions);
        clearErrorPositions()
      }
    } else {
      try {
        const data = await saveOpenedPositions(country, strategy, positions)
        messageAnt.info(data.message)
      } catch (e) {
        messageAnt.info(errorPositions);
        clearErrorPositions()
      }
    }
  }

  //получение данных по выставленным заявкам  из БД
  const getEnteredRequests = async (country: Countries) => {
    try {
      const data = await getAllEnteredRequests(country)
      messageAnt.info(data.message)
      return data.info
    } catch (e) {
      messageAnt.info(errorRequest);
      clearErrorRequest()
      return []
    }
  }

  //изначальное получение данных по выставленным заявкам из БД
  useEffect(() => {
    const get = async () => {
      const enteredRequests = await getEnteredRequests(country)
      setToOrderList(enteredRequests)
    }
    get()
  }, [])

  //функция для сохранение данных по выставленной заявке в БД
  const saveRequest = async (country: Countries, requestData: IToOrder) => {
    try {
      const data = await saveEnteredRequest(country, requestData)
      messageAnt.info(data.message)
    } catch (e) {
      messageAnt.info(errorRequest);
      clearErrorRequest()
    }
  }

  //оформление заявок
  const registerRequestHandler = async (request: IToOrder) => {
    setBtnRequestLoading(true)
    //проверка, что это не попытка повторно добавить заявку в список
    let dublicate = toOrderList.find((order) => {
      if (
        order.strategy === request.strategy &&
        order.ticker === request.ticker &&
        order.timeframe === request.timeframe
      ) {
        return true
      }
    })

    if (!dublicate) {
      const newToOrderList = [...toOrderList]
      newToOrderList.push(request)
      try {
        //сохранение обновленной информации по выставленным заявкам
        await saveRequest(country, request)
        setToOrderList(newToOrderList)
      } catch (e) {

      } finally {
        setBtnRequestLoading(false)
      }
    }
  }

  //всплывающее окно с аналитикой по инструменту, по которому рекомендована покупка
  const showAnalytics = async (request: IInfoAboutAdviceToAnalyticsPopup) => {
    setInfoAboutAdviceToAnalyticsPopup(request)
    setAnalyticsIsShow(true)
  }

  //закрытие окна с аналитикой
  const closeAnalytics = async () => {
    setAnalyticsIsShow(false)
  }

  //оформление сделок
  const registerOrderHandler = async ({strategy, ticker, timeframe, price, date, actionType, action, value, specificity}: IToOrder) => {
    setBtnOrderSendLoading(true)
    let data
    if (actionType === TradeAction.BUY && action === StateOfTrade.NEED_OPEN_SERIES) {
      data = {
        enters: [
          {
            price: price,
            date: dayjs(date).set("second", 0).format('YYYY-MM-DD HH:mm:ss'),
            value: value,
            specificity: specificity
          }
        ],
        exits: []
      }
    } else if (actionType === TradeAction.BUY && action === StateOfTrade.NEED_ADD_ENTER_IN_SERIES && openedPositions) {
      const exits = [...JSON.parse(JSON.stringify(openedPositions[strategy as Strategies][ticker][timeframe as TimeFrames].exits))]
      const enters = [...JSON.parse(JSON.stringify(openedPositions[strategy as Strategies][ticker][timeframe as TimeFrames].enters))]
      enters.push({
        price: price,
        date: dayjs(date).set("second", 0).format('YYYY-MM-DD HH:mm:ss'),
        value: value,
        specificity: specificity
      })
      data = {enters: enters, exits: exits}
    } else if (actionType === TradeAction.SELL && action === StateOfTrade.NEED_ADD_EXIT_IN_SERIES && openedPositions) {
      const enters = [...JSON.parse(JSON.stringify(openedPositions[strategy as Strategies][ticker][timeframe as TimeFrames].enters))]
      const exits = [...JSON.parse(JSON.stringify(openedPositions[strategy as Strategies][ticker][timeframe as TimeFrames].exits))]
      exits.push({
        price: price,
        date: dayjs(date).set("second", 0).format('YYYY-MM-DD HH:mm:ss'),
        value: value,
        specificity: specificity
      })
      data = {enters: enters, exits: exits}
    } else if (actionType === TradeAction.SELL && action === StateOfTrade.NEED_CLOSE_SERIES) {
      data = null
    }

    let newOpenedPositions
    if (openedPositions) {
      newOpenedPositions = JSON.parse(JSON.stringify(openedPositions))
      if (data) {
        if (newOpenedPositions[strategy]) {
          if (newOpenedPositions[strategy][ticker]) {
            if (newOpenedPositions[strategy][ticker][timeframe]) {
              newOpenedPositions[strategy][ticker][timeframe] = data
            } else {
              newOpenedPositions[strategy][ticker][timeframe] = data
            }
          } else {
            newOpenedPositions[strategy][ticker] = {[timeframe]: data}
          }
        } else {
          newOpenedPositions[strategy] = {[ticker]: {[timeframe]: data}}
        }
      } else {
        delete newOpenedPositions[strategy][ticker][timeframe]
        if (Object.keys(newOpenedPositions[strategy][ticker]).length === 0) {
          delete newOpenedPositions[strategy][ticker]
          if (Object.keys(newOpenedPositions[strategy]).length === 0) {
            delete newOpenedPositions[strategy]
          }
        }
      }
    } else {
      newOpenedPositions = {[strategy]: {[ticker]: {[timeframe]: data}}}
    }
    try {
      //сохранение обновленной информации по открытым позициям в БД
      await savePositions(country, strategy as Strategies, JSON.stringify(newOpenedPositions[strategy]))
      setOpenedPositions(newOpenedPositions)
    } catch (e) {

    }

    //убираем заявку из списка, по которой оформили сделку
    const deleteIndex = toOrderList.findIndex((order) => {
      if (
        order.strategy === strategy &&
        order.ticker === ticker &&
        order.timeframe === timeframe
      ) {
        return true
      }
    })
    if (deleteIndex !== -1) {
      const newToOrderList = [...toOrderList]
      const deletedOrders = newToOrderList.splice(deleteIndex, 1)
      await removeEnteredRequest(country, deletedOrders[0])
      setToOrderList(newToOrderList)
    }
    setBtnOrderSendLoading(false)
  }

  //убираем из списка заявку, которую отменили
  const cancelOrderHandler = async (strategy: Strategies, ticker: string, timeframe: TimeFrames) => {
    setBtnOrderCancelLoading(true)
    const deleteIndex = toOrderList.findIndex((order) => {
      if (
        order.strategy === strategy &&
        order.ticker === ticker &&
        order.timeframe === timeframe
      ) {
        return true
      }
    })
    if (deleteIndex !== -1) {
      const newToOrderList = [...toOrderList]
      const deletedOrders = newToOrderList.splice(deleteIndex, 1)
      await removeEnteredRequest(country, deletedOrders[0])
      setToOrderList(newToOrderList)
    }
    setBtnOrderCancelLoading(false)
  }

  //изменение значения цены в поле оформления сделки
  const toOrderChangeHandler = async (toOrderListIndex: number, newValue: string) => {
    if (!isNaN(Number(newValue))) {
      const newToOrderList = [...toOrderList]
      newToOrderList[toOrderListIndex].price = newValue
      setToOrderList(newToOrderList)
    }
  }

  //запуск активных стратегий после обновления онлайн графиков
  useEffect(() => {
    if (quotes && allSettings && openedPositions) {
      //временный накопитель всех торговых возможностей в данный момент, для отображения
      let recomendations: Record<TradeAction, Partial<Record<Strategies, [string[]]>>> = {
        [TradeAction.BUY]: {},
        [TradeAction.SELL]: {}
      }
      //проход по всем активным стратегиям
      Object.keys(Strategies).forEach((strategy) => {
        if (allSettings[strategy as keyof typeof Strategies].enable && allSettings[strategy as keyof typeof Strategies].settings) {
          const sett0 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[0]]
          const sett1 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[1]]
          const sett2 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[2]]
          const sett3 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[3]]
          const sett4 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[4]]
          const sett5 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[5]]
          const sett6 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[6]]
          const sett7 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[7]]
          const sett8 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[8]]
          const sett9 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[9]]
          const sett10 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[10]]
          const sett11 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[11]]
          const sett12 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[12]]
          const sett13 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[13]]
          const sett14 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[14]]
          const sett15 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[15]]
          const sett16 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[16]]
          const sett17 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[17]]
          const sett18 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[18]]
          const sett19 = JSON.parse(allSettings[strategy as keyof typeof Strategies].settings)[Object.keys(StrategyObject[strategy as keyof typeof Strategies].settings)[19]]
          let settings = [sett0, sett1, sett2, sett3, sett4, sett5, sett6, sett7, sett8, sett9, sett10, sett11, sett12, sett13, sett14, sett15, sett16, sett17, sett18, sett19]
          //проход по всем инструментам
          Object.keys(quotes).forEach((ticker) => {
            //проверка: данный инструмент уже куплен на любом ТФ и любой стратегии?
            let tickerIsAlreadyBuy = false
            {
              let strategyKeyArr = Object.keys(Strategies)
              let timeframeKeyArr = Object.keys(TimeFrames)
              for (let i = 0; i < strategyKeyArr.length - 1; i++) {
                let strategy = Strategies[strategyKeyArr[i] as keyof typeof Strategies]
                for (let j = 0; j < timeframeKeyArr.length - 1; j++) {
                  let timeframe = TimeFrames[timeframeKeyArr[j] as keyof typeof TimeFrames]
                  if (
                    openedPositions &&
                    openedPositions[strategy as keyof typeof Strategies] &&
                    openedPositions[strategy as keyof typeof Strategies][ticker] &&
                    openedPositions[strategy as keyof typeof Strategies][ticker][timeframe as keyof typeof TimeFrames] &&
                    openedPositions[strategy as keyof typeof Strategies][ticker][timeframe as keyof typeof TimeFrames].enters.length !== 0
                  ) {
                    tickerIsAlreadyBuy = true
                    break
                  }
                }
                if (tickerIsAlreadyBuy) {
                  break
                }
              }
            }

            //проход по всем таймфреймам
            Object.keys(TimeFrames).forEach((timeframe) => {
              //пропускаем таймфрейм Month, т.к. на этом таймфрейме автоматически не работаю, он только для ручного стратегического анализа по компаниям
              if (timeframe !== TimeFrames.month) {
                //подготовка параметров для функции стратегии
                let params: IParamsStrategyFunction = {
                  timeframe: TimeFrames[timeframe as keyof typeof TimeFrames],
                  settings: settings,
                  currentInfoTfM10: quotes[ticker][TimeFrames.m10],
                  currentInfoTfM60: quotes[ticker][TimeFrames.m60],
                  currentInfoTfDay: quotes[ticker][TimeFrames.day],
                  currentInfoTfWeek: quotes[ticker][TimeFrames.week],
                  currentInfoTfMonth: quotes[ticker][TimeFrames.month],
                }
                //информация об открытой серии сделок на этом инструменте этом тф и этой стратегии для функции стратегии
                let openedSeriesOfTransactions: ISeriesOfTransaction
                if (
                  openedPositions &&
                  openedPositions[strategy as keyof typeof Strategies] &&
                  openedPositions[strategy as keyof typeof Strategies][ticker] &&
                  openedPositions[strategy as keyof typeof Strategies][ticker][timeframe as keyof typeof TimeFrames]
                ) {
                  openedSeriesOfTransactions = openedPositions[strategy as keyof typeof Strategies][ticker][timeframe as keyof typeof TimeFrames]
                } else {
                  openedSeriesOfTransactions = {enters: [], exits: []}
                }

                //работаем по стратегии только если инструмент не куплен вообще или если куплен, то только если на этой стратегии и этом тф для поиска выхода или добавления к серии покупок
                if (!tickerIsAlreadyBuy || (tickerIsAlreadyBuy && openedSeriesOfTransactions.enters.length !== 0)) {

                  //вызов функции стратегии
                  let resultStrategy = StrategyObject[strategy as keyof typeof StrategyObject].main(params, openedSeriesOfTransactions)

                  //обработка сигналов от стратегий и заполнение торговых рекомендаций
                  if (resultStrategy.state === StateOfTrade.NEED_OPEN_SERIES) {
                    //возможность входа найдена
                    if (recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]) {
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    } else {
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies] = [[]]
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.splice(0, 1)
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    }
                  } else if (resultStrategy.state === StateOfTrade.NEED_ADD_ENTER_IN_SERIES) {
                    //возможность входа найдена
                    if (recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]) {
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    } else {
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies] = [[]]
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.splice(0, 1)
                      recomendations[TradeAction.BUY][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    }
                  } else if (resultStrategy.state === StateOfTrade.NEED_ADD_EXIT_IN_SERIES) {
                    //возможность выхода найдена
                    if (recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]) {
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    } else {
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies] = [[]]
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.splice(0, 1)
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    }
                  } else if (resultStrategy.state === StateOfTrade.NEED_CLOSE_SERIES) {
                    //возможность выхода найдена
                    if (recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]) {
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    } else {
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies] = [[]]
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.splice(0, 1)
                      recomendations[TradeAction.SELL][strategy as keyof typeof Strategies]?.push([ticker, timeframe, resultStrategy.state, resultStrategy.value.toString(), resultStrategy.specificity, resultStrategy.price.toString(), resultStrategy.date])
                    }
                  }
                }
              }
            })
          })
        }
      })

      //удаление из списка рекомендаций сигналов, по которым уже выставлены заявки
      toOrderList.forEach((order) => {
        recomendations.BUY[order.strategy]?.forEach((recom, index) => {
          if (recom[0] === order.ticker && recom[1] === order.timeframe) {
            recomendations.BUY[order.strategy]?.splice(index, 1)
          }
        })
        //@ts-ignore
        if (!recomendations.BUY[order.strategy] || recomendations.BUY[order.strategy]?.length === 0) {
          delete recomendations.BUY[order.strategy]
        }
        recomendations.SELL[order.strategy]?.forEach((recom, index) => {
          if (recom[0] === order.ticker && recom[1] === order.timeframe) {
            recomendations.SELL[order.strategy]?.splice(index, 1)
          }
        })
        //@ts-ignore
        if (!recomendations.SELL[order.strategy] || recomendations.SELL[order.strategy]?.length === 0) {
          delete recomendations.SELL[order.strategy]
        }
      })

      //окончательный список рекомендаций
      setRecomends(JSON.parse(JSON.stringify(recomendations)))
    }
  }, [quotes, allSettings, openedPositions])

  //список тикеров по которым нужно получить внешнюю аналитику
  useEffect(() => {
    if (recomends && recomends.BUY) {
      let justNewTickers: string[] = []
      //список тикеров по которым уже получили аналитику
      const tickerListInAnalytics = analytics ? Object.keys(analytics.news) : []
      //список тикеров по которым есть рекомендации
      const tickerListInRecomends: string[] = []
      Object.keys(Strategies).forEach((strategy) => {
        if (recomends.BUY[strategy as keyof typeof Strategies]) {
          //массив всех рекомендаций на покупку по стратегии
          const recs = recomends.BUY[strategy as keyof typeof Strategies]
          recs?.forEach((rec) => {
            const tick = rec[0]
            if (!tickerListInRecomends.includes(tick)) {
              tickerListInRecomends.push(tick)
            }
          })
        }
      })
      //создание массива тикеров которые есть в рекомендациях на покупку и по которым еще не получена аналитика
      tickerListInRecomends.forEach((tickerInRecomends) => {
        if (!tickerListInAnalytics.includes(tickerInRecomends)) {
          justNewTickers.push(tickerInRecomends)
        }
      })
      setTickerListToGetExternalAnalytics(justNewTickers)
    }
  }, [recomends])

  //функция получения внешней аналитики по тикерам
  const getAnalyticsFunc = async (country: Countries, tickerList: string[]) => {
    //todo: Временно аналитика реализована только для Америки
    if (country === Countries.US) {
      try {
        setColorIndicatorAnalytics(ColorOfIndicator.gray)
        const data = await getAnalytics(country, tickerList)
        messageAnt.info(data.message)
        setColorIndicatorAnalytics(ColorOfIndicator.green)
        return data.info
      } catch (e) {
        messageAnt.info(errorAnalytics);
        clearErrorAnalytics()
        setColorIndicatorAnalytics(ColorOfIndicator.red)
        return undefined
      }
    } else {
      return
    }
  }

  //получение внешней аналитики для попапа с аналитикой по тикерам которые получили рекомендацию к покупке от стратегий и по которым аналитика еще не получена
  useEffect(() => {
    if (!analyticsIsProcessing) {
      if (tickerListToGetExternalAnalytics && tickerListToGetExternalAnalytics.length !== 0) {
        const get = async () => {
          setAnalyticsIsProcessing(true)
          const analyticsForNewTickers = await getAnalyticsFunc(country, tickerListToGetExternalAnalytics)
          if (analyticsForNewTickers) {
            //суммируем уже имеющуюся аналитику с аналитикой по новым инструментам
            const sumAnalitics = sumAnaliticsForOldAndNewTickers(analytics, analyticsForNewTickers)
            setAnalytics(sumAnalitics)
          }
          setAnalyticsIsProcessing(false)
        }
        get()
      }
    }
  }, [tickerListToGetExternalAnalytics])

  //принудительная загрузка аналитики на случай сбоя в автоматической загрузке
  const forcedDownloadAnalytics = () => {
    if (colorIndicatorAnalytics === ColorOfIndicator.red && tickerListToGetExternalAnalytics && tickerListToGetExternalAnalytics.length !== 0) {
      const areYouSure = window.confirm(`Принудительная загрузка аналитики. Продолжить?`)
      if (!areYouSure) {
        return
      }
      if (!analyticsIsProcessing) {
        const get = async () => {
          setAnalyticsIsProcessing(true)
          const analyticsForNewTickers = await getAnalyticsFunc(country, tickerListToGetExternalAnalytics)
          if (analyticsForNewTickers) {
            //суммируем уже имеющуюся аналитику с аналитикой по новым инструментам
            const sumAnalitics = sumAnaliticsForOldAndNewTickers(analytics, analyticsForNewTickers)
            setAnalytics(sumAnalitics)
          }
          setAnalyticsIsProcessing(false)
        }
        get()
      }
    }
  }

  //суммирование уже имеющейся аналитики с аналитикой по новым инструментам
  const sumAnaliticsForOldAndNewTickers = (analytics: IAnalytics | undefined, analyticsForNewTickers: IAnalytics): IAnalytics => {
    if (!analytics) {
      return analyticsForNewTickers
    } else {
      let totalAnalytics: IAnalytics = JSON.parse(JSON.stringify(analytics))
      const newTickers = Object.keys(analyticsForNewTickers.news)
      newTickers.forEach((newTicker) => {
        const oldTickers = Object.keys(analytics.news)
        if (!oldTickers.includes(newTicker)) {
          totalAnalytics.news[newTicker] = [...analyticsForNewTickers.news[newTicker]]
          totalAnalytics.expert[newTicker] = {...analyticsForNewTickers.expert[newTicker]}
          totalAnalytics.candles[newTicker] = [...analyticsForNewTickers.candles[newTicker]]
        }
      })
      return totalAnalytics
    }
  }

  let tick: string = ''

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.indicators}>
          <ColorIndicator onClick={forcedDownloadAnalytics} color={colorIndicatorAnalytics}
                          description={'Индикатор загрузки аналитических данных'}/>
        </div>
        <div className={styles.table}>
          <h5>Работа по торговым стратегиям</h5>
          <div className={[styles.switchSound, !isAllowSound ? styles.warning : ''].join(' ')}
               onClick={switchSoundHandler}>
            {
              stateSoundTrigger ?
                <img src={soundOn} alt="" title={'Включены все звуки уведомлений от стратегий'}/>
                :
                <img src={soundOff} alt="" title={'Тихий режим: включены только важные уведомления'}/>
            }
          </div>
          <div className={styles.switchOnAdvisors}
               onClick={switchAdvisorsHandler}>
            {
              stateOnAdvisorsTrigger ?
                <img src={advisorsOn} alt="" title={'Советники Включены'}/>
                :
                <img src={advisorsOff} alt="" title={'Советники Отключены'}/>
            }
          </div>
          <div className={[styles.enable, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Подключение стратегий</p>
            <Select showSearch allowClear placeholder="К подключению" onChange={onChangeStrategyForEnable}
                    options={optionsSelectStrategyForEnable} style={{width: 499}}/>
            {
              strategyForEnable &&
              <Checkbox onChange={onChangeEnableStrategy} checked={strategyIsEnable}>Стратегия подключена</Checkbox>
            }
          </div>
          <div className={[styles.activeStrategy, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Настройки активных стратегий</p>
            <Select showSearch allowClear placeholder="Активные" onChange={onChangeStrategyForSetting}
                    options={optionsSelectStrategyForSetting} style={{width: 499}}/>
            {
              strategyForSetting
              &&
              <div className={styles.settingsWrap}>
                <div className={styles.settings}>
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[0]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[0]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[0]]}
                        value={setting0}
                        onChange={changeSetting0Handler}
                        style={{width: 499}}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[1]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[1]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[1]]}
                        value={setting1}
                        onChange={changeSetting1Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[2]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[2]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[2]]}
                        value={setting2}
                        onChange={changeSetting2Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[3]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[3]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[3]]}
                        value={setting3}
                        onChange={changeSetting3Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[4]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[4]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[4]]}
                        value={setting4}
                        onChange={changeSetting4Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[5]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[5]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[5]]}
                        value={setting5}
                        onChange={changeSetting5Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[6]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[6]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[6]]}
                        value={setting6}
                        onChange={changeSetting6Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[7]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[7]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[7]]}
                        value={setting7}
                        onChange={changeSetting7Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[8]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[8]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[8]]}
                        value={setting8}
                        onChange={changeSetting8Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[9]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[9]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[9]]}
                        value={setting9}
                        onChange={changeSetting9Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[10]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[10]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[10]]}
                        value={setting10}
                        onChange={changeSetting10Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[11]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[11]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[11]]}
                        value={setting11}
                        onChange={changeSetting11Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[12]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[12]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[12]]}
                        value={setting12}
                        onChange={changeSetting12Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[13]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[13]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[13]]}
                        value={setting13}
                        onChange={changeSetting13Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[14]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[14]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[14]]}
                        value={setting14}
                        onChange={changeSetting14Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[15]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[15]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[15]]}
                        value={setting15}
                        onChange={changeSetting15Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[16]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[16]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[16]]}
                        value={setting16}
                        onChange={changeSetting16Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[17]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[17]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[17]]}
                        value={setting17}
                        onChange={changeSetting17Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[18]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[18]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[18]]}
                        value={setting18}
                        onChange={changeSetting18Handler}
                      />
                    </div>
                  }
                  {
                    StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[19]] &&
                    <div>
                      <label
                        htmlFor="">{StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[19]]}</label>
                      <Input
                        placeholder={StrategyObject[StrategyLabelFlip[strategyForSetting]].settings[Object.keys(StrategyObject[StrategyLabelFlip[strategyForSetting]].settings)[19]]}
                        value={setting19}
                        onChange={changeSetting19Handler}
                      />
                    </div>
                  }
                </div>
                <Button title={'Сохранить'} type={'primary'} onClick={saveSettingsHandler}
                        style={{width: 150}} disabled={saveSettingsBtnDisabled}>Сохранить</Button>
              </div>
            }
          </div>
          <div className={[styles.toOrderWrap, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Список выставленных заявок</p>
            {
              toOrderList ?
                <div className={styles.toOrdWrap}>
                  {toOrderList.map((toOrder, index) => (
                    <div className={styles.toOrder} key={toOrder.strategy + toOrder.ticker + toOrder.timeframe}>
                      <p className={styles.strategyTitle}>стратегия
                        - <span>{StrategyLabel[toOrder.strategy as keyof typeof Strategies]}</span>
                      </p>
                      <p className={styles.tickerTitle}>{toOrder.ticker}</p>
                      <p className={styles.otherTitle}>таймфрейм
                        - {TimeFramesLabel[toOrder.timeframe as TimeFrames]}</p>
                      <p className={styles.otherTitle}>действие
                        - {RecommendationLabel[toOrder.actionType]} / {StateOfTradeLabel[toOrder.action as StateOfTrade]}</p>
                      <p className={styles.otherTitle}>особенности - {toOrder.specificity}</p>
                      <p className={styles.otherTitle}>условный объем - {toOrder.value}</p>
                      <p className={styles.otherTitle}>цена - <input type="text" value={toOrder.price}
                                                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => toOrderChangeHandler(index, e.target.value)}/>
                      </p>
                      <Button size={'small'} loading={btnOrderSendLoading}
                              onClick={() => registerOrderHandler({
                                strategy: toOrder.strategy,
                                ticker: toOrder.ticker,
                                timeframe: toOrder.timeframe,
                                price: toOrder.price,
                                date: toOrder.date,
                                actionType: toOrder.actionType,
                                action: toOrder.action,
                                value: toOrder.value,
                                specificity: toOrder.specificity
                              })}>оформить сделку</Button>
                      <Button size={'small'} loading={btnOrderCancelLoading}
                              onClick={() => cancelOrderHandler(
                                toOrder.strategy,
                                toOrder.ticker,
                                toOrder.timeframe,
                              )}>отменить заявку</Button>
                    </div>
                  ))}
                </div>
                : null
            }
          </div>
          <div className={[styles.recomendationsWrap, styles.blockWrap].join(' ')}>
            <p className={styles.title}>Торговые рекомендации</p>
            {
              recomends && quotes ?
                <div className={styles.recomendWrap}>
                  {
                    <div>
                      <p className={[styles.directionTitle, styles.sell].join(' ')}>на продажу</p>
                      {Object.keys(recomends.SELL).map((strategy) => (
                        <Fragment key={uuidv4()}>
                          <p
                            className={styles.strategyTitle}
                            title={StrategyObject[strategy as keyof typeof StrategyObject].strategyDescription}>
                            стратегия - <span>{StrategyLabel[strategy as keyof typeof Strategies]}</span>
                          </p>
                          {recomends.SELL[strategy as keyof typeof Strategies]?.map((elem) => {
                            const price = elem[5]
                            const date = elem[6]
                            if (tick !== elem[0]) {
                              tick = elem[0]
                              return <Fragment key={uuidv4()}>
                                <p className={styles.tickerTitle}>{elem[0]}</p>
                                <p className={styles.otherTitle}>таймфрейм
                                  - {TimeFramesLabel[elem[1] as TimeFrames]}</p>
                                <p className={styles.otherTitle}>действие
                                  - {RecommendationLabel.SELL} / {StateOfTradeLabel[elem[2] as StateOfTrade]}</p>
                                <p className={styles.otherTitle}>особенности - {elem[4]}</p>
                                <p className={styles.otherTitle}
                                   title={StrategyObject[strategy as keyof typeof StrategyObject].singleVolumeInStrategyDescription}>
                                  условный объем - {elem[3]}
                                </p>
                                <p className={styles.otherTitle}>цена - {price}</p>
                                <Button size={'small'} loading={btnRequestLoading}
                                        onClick={() => registerRequestHandler({
                                          strategy: strategy as Strategies,
                                          ticker: elem[0],
                                          timeframe: elem[1] as TimeFrames,
                                          price: price.toString(),
                                          date: date,
                                          actionType: TradeAction.SELL,
                                          action: elem[2] as StateOfTrade,
                                          value: Number(elem[3]),
                                          specificity: elem[4]
                                        })}
                                >оформить заявку</Button>
                              </Fragment>
                            } else {
                              return <Fragment key={uuidv4()}>
                                <p className={styles.otherTitle}>таймфрейм
                                  - {TimeFramesLabel[elem[1] as TimeFrames]}</p>
                                <p className={styles.otherTitle}>действие
                                  - {RecommendationLabel.SELL} / {StateOfTradeLabel[elem[2] as StateOfTrade]}</p>
                                <p className={styles.otherTitle}>особенности - {elem[4]}</p>
                                <p className={styles.otherTitle}
                                   title={StrategyObject[strategy as keyof typeof StrategyObject].singleVolumeInStrategyDescription}>
                                  условный объем - {elem[3]}
                                </p>
                                <p className={styles.otherTitle}>цена - {price}</p>
                                <Button size={'small'} loading={btnRequestLoading}
                                        onClick={() => registerRequestHandler({
                                          strategy: strategy as Strategies,
                                          ticker: elem[0],
                                          timeframe: elem[1] as TimeFrames,
                                          price: price.toString(),
                                          date: date,
                                          actionType: TradeAction.SELL,
                                          action: elem[2] as StateOfTrade,
                                          value: Number(elem[3]),
                                          specificity: elem[4]
                                        })}
                                >оформить заявку</Button>
                              </Fragment>
                            }
                          })}
                        </Fragment>
                      ))}
                    </div>
                  }
                  {
                    <div>
                      <p className={[styles.directionTitle, styles.buy].join(' ')}>на покупку</p>
                      {Object.keys(recomends.BUY).map((strategy) => (
                        <Fragment key={uuidv4()}>
                          <p
                            className={styles.strategyTitle}
                            title={StrategyObject[strategy as keyof typeof StrategyObject].strategyDescription}>
                            стратегия - <span>{StrategyLabel[strategy as keyof typeof Strategies]}</span>
                          </p>
                          {recomends.BUY[strategy as keyof typeof Strategies]?.map((elem, index) => {
                            const price = elem[5]
                            const date = elem[6]
                            if (tick !== elem[0]) {
                              tick = elem[0]
                              return <Fragment key={uuidv4()}>
                                <p className={styles.tickerTitle}>
                                  {elem[0]}
                                </p>
                                <p className={styles.otherTitle}>таймфрейм
                                  - {TimeFramesLabel[elem[1] as TimeFrames]}</p>
                                <p className={styles.otherTitle}>действие
                                  - {RecommendationLabel.BUY} / {StateOfTradeLabel[elem[2] as StateOfTrade]}</p>
                                <p className={styles.otherTitle}>особенности - {elem[4]}</p>
                                <p className={styles.otherTitle}
                                   title={StrategyObject[strategy as keyof typeof StrategyObject].singleVolumeInStrategyDescription}>
                                  условный объем - {elem[3]}
                                </p>
                                <p className={styles.otherTitle}>цена - {price}</p>
                                <Button size={'small'}
                                        onClick={() => showAnalytics({
                                          ticker: elem[0],
                                          specificity: elem[4]
                                        })}
                                        disabled={!analytics || !analytics.news[elem[0]]}
                                >посмотреть аналитику</Button>
                                <Button size={'small'} loading={btnRequestLoading}
                                        onClick={() => registerRequestHandler({
                                          strategy: strategy as Strategies,
                                          ticker: elem[0],
                                          timeframe: elem[1] as TimeFrames,
                                          price: price.toString(),
                                          date: date,
                                          actionType: TradeAction.BUY,
                                          action: elem[2] as StateOfTrade,
                                          value: Number(elem[3]),
                                          specificity: elem[4]
                                        })}
                                >оформить заявку</Button>
                              </Fragment>
                            } else {
                              return <Fragment key={uuidv4()}>
                                <p className={styles.otherTitle}>таймфрейм
                                  - {TimeFramesLabel[elem[1] as TimeFrames]}</p>
                                <p className={styles.otherTitle}>действие
                                  - {RecommendationLabel.BUY} / {StateOfTradeLabel[elem[2] as StateOfTrade]}</p>
                                <p className={styles.otherTitle}>особенности - {elem[4]}</p>
                                <p className={styles.otherTitle}
                                   title={StrategyObject[strategy as keyof typeof StrategyObject].singleVolumeInStrategyDescription}>
                                  условный объем - {elem[3]}
                                </p>
                                <p className={styles.otherTitle}>цена - {price}</p>
                                <Button size={'small'}
                                        onClick={() => showAnalytics({
                                          ticker: elem[0],
                                          specificity: elem[4]
                                        })}
                                        disabled={!analytics || !analytics.news[elem[0]]}
                                >посмотреть аналитику</Button>
                                <Button size={'small'} loading={btnRequestLoading}
                                        onClick={() => registerRequestHandler({
                                          strategy: strategy as Strategies,
                                          ticker: elem[0],
                                          timeframe: elem[1] as TimeFrames,
                                          price: price.toString(),
                                          date: date,
                                          actionType: TradeAction.BUY,
                                          action: elem[2] as StateOfTrade,
                                          value: Number(elem[3]),
                                          specificity: elem[4]
                                        })}
                                >оформить заявку</Button>
                              </Fragment>
                            }
                          })}
                        </Fragment>
                      ))}
                    </div>
                  }
                </div>
                :
                null
            }
          </div>
          {
            infoAboutAdviceToAnalyticsPopup && analytics && quotes && analyticsIsShow && membersRatingList &&
            <PopupAnalytics closeHandler={closeAnalytics}
                            country={country}
                            infoAboutAdvice={infoAboutAdviceToAnalyticsPopup}
                            analytics={analytics}
                            membersRating={membersRatingList}
                            quotes={quotes}/>
          }
        </div>
      </div>
    </div>
  );
};

export default Advisors;