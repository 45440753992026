import {buildMarkUnderCandleWithModels, IWidthCandleOnCanvas} from "./lib/canvas";
//для canvas во всплывашке с аналитикой на вкладке с графиками
export const widthChartCanvas = 540
export const heightChartCanvas = 440
export const heightIndicatorsCanvas = 105
export const offsetChartFromLeftBorderCanvas = 5
export const widthPriceScaleChartCanvas = 30
export const heightTimeScaleChartCanvas = 15
export const heightTimeScaleIndicatorsCanvas = 5
export const formulaOfCandleCanvas: IWidthCandleOnCanvas = {bodyWidth: 1, sideWidth: 1, distance: 1, total: 4}
export const lineWidthOfScaleChartCanvas = 1
export const lineWidthOfVolumeCanvas = 1
export const colorOfLegendCanvas = 'black'
export const colorOfVolumeOnUpCandle = 'green'
export const colorOfVolumeOnDownCandle = 'red'
export const colorOfVolumeOnDojiCandle = 'gray'
export const colorOfUpCandle = 'green'
export const colorOfDownCandle = 'red'
export const colorOfDojiCandle = 'gray'
export const colorOfAxisCanvas = 'black'
export const colorOfCurrentPriceCanvas = 'blue'
export const lineWidthOfZigzagCanvas = 0.8
export const colorOfZigzagCanvas = '#351c75'
export const lineWidthOfMovAverCanvas = 0.4
export const colorOfShortMovAverCanvas = 'red'
export const periodOfShortMovAverCanvas = 8
export const colorOfMiddleMovAverCanvas = 'green'
export const periodOfMiddleMovAverCanvas = 21
export const colorOfLongMovAverCanvas = 'blue'
export const periodOfLongMovAverCanvas = 55
export const lineWidthOfPivotCanvas = 1
export const colorOfLevelPivotCanvas = '#6d9eeb'
export const colorOfResistPivotCanvas = '#e06666'
export const colorOfSupportPivotCanvas = '#93c47d'
export const periodOfRsiCanvas = 14
export const colorOfRsiCanvas = '#c284c2'
export const colorOfControlLevelRsiCanvas = '#b6b2b6'
export const lineWidthOfRsiCanvas = 1
export const periodOfStochasticCanvas = 14
export const colorOfStochasticCanvas = '#7c7cff'
export const colorOfStochasticMovingCanvas = '#ff9999'
export const colorOfControlLevelStochasticCanvas = '#b6b2b6'
export const lineWidthOfStochasticCanvas = 1
export const periodOfFastMaMacdCanvas = 12
export const periodOfSlowMaMacdCanvas = 26
export const periodOfSignalMacdCanvas = 9
export const colorOfMacdCanvas = '#9c9cff'
export const colorOfSignalMacdCanvas = '#ff9595'
export const colorOfMacdHistogramCanvas = '#ffa1a1'
export const lineWidthOfMacdCanvas = 1
export const colorOfVerticalMarkerCanvas = '#b6b2b6'
export const lineWidthOfVerticalMarkerCanvas = 0.3
//для canvas во всплывашке с аналитикой на вкладке со свечными моделями
export const widthCandlestickCanvas = 145
export const heightCandlestickCanvas = 145
export const heightCandlestickVolumeCanvas = 70
export const countCandleOnCandlestickCanvas = 20
export const offsetChartFromLeftBorderCandlestickCanvas = 10
export const formulaOfCandlestickCandleCanvas: IWidthCandleOnCanvas = {
  bodyWidth: 1,
  sideWidth: 2,
  distance: 2,
  total: 7
}
export const colorOfLineCandlestickCanvas = 'black'
export const colorOfMarkUnderCandleCandlestickCanvas = 'green'
export const lineWidthOfVolumeCandlestickCanvas = 1

