import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  allowSound: false,//глобальное разрешение звуков в браузере
  allowAdvisorsSound: true,//пользовательское разрешение звука уведомлений торговых советов
  onOffAdvisors: true,//выключатель работы советника
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    allowSound: (state, action) => {
      state.allowSound = action.payload
    },
    allowAdvisorsSound: (state, action) => {
      state.allowAdvisorsSound = action.payload
    },
    onOffAdvisors: (state, action) => {
      state.onOffAdvisors = action.payload
    }
  }
})

export const { allowSound, allowAdvisorsSound, onOffAdvisors} = appSlice.actions

export default appSlice.reducer