import React, {useEffect, useMemo, useState} from 'react';
import styles from "./candlestick.module.scss";
import {Candle, ICandlesAnalytics, TimeFrames} from "../../../../../../shared/types";
import {v4 as uuidv4} from "uuid";
import downReturn from '../../../../../../assets/images/downReturn.png'
import upReturn from '../../../../../../assets/images/upReturn.png'
import proceedDown from '../../../../../../assets/images/proceedDown.png'
import proceedUp from '../../../../../../assets/images/proceedUp.png'
import starDark from '../../../../../../assets/images/star-dark.png'
import starLight from '../../../../../../assets/images/star-light.png'
import {candlestick} from "../../../../../../shared/candlestickConfig";
import Chart from "./chart/Chart";
import {
  InvestingComModelsDirection,
  InvestingComModelsStrength,
  InvestingComTimeFrames
} from "../../../../../../shared/investingComConfig";

interface Props {
  active: boolean
  analytic: ICandlesAnalytics[]
  specificityOfRecomendsByStrategy: string
  quotes: Record<TimeFrames, Candle[]>
}

const Candlestick = ({active, analytic, specificityOfRecomendsByStrategy, quotes}: Props) => {

  const specificityOfRecomendsByStrategyHighTf: TimeFrames = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).highTf, [specificityOfRecomendsByStrategy])
  const specificityOfRecomendsByStrategyLowTf: TimeFrames = useMemo(() => JSON.parse(specificityOfRecomendsByStrategy).lowTf, [specificityOfRecomendsByStrategy])

  const [monthIsActive, setMonthIsActive] = useState(false)
  const [weekIsActive, setWeekIsActive] = useState(false)
  const [dayIsActive, setDayIsActive] = useState(false)
  const [analyticFiltered, setAnalyticFiltered] = useState<ICandlesAnalytics[]>()
  const [indexOfVisibleDescription, setIndexOfVisibleDescription] = useState<number | undefined>()//это для анимации открывания описания модели
  const [indexOfHiddenDescription, setIndexOfHiddenDescription] = useState<number | undefined>()//это для анимации скрывания описания модели

  //какие нужно отображать таймфреймы, в зависимости от старшего тф из рекомендации по стратегии
  useEffect(() => {
    if (specificityOfRecomendsByStrategyHighTf === TimeFrames.week) {
      setMonthIsActive(true)
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.day) {
      setMonthIsActive(true)
      setWeekIsActive(true)
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.m60) {
      setMonthIsActive(true)
      setWeekIsActive(true)
      setDayIsActive(true)
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.m10) {
      setMonthIsActive(true)
      setWeekIsActive(true)
      setDayIsActive(true)
    }
  }, [specificityOfRecomendsByStrategyHighTf])

  //переработка списка с аналитикой, сортировка по тф, фильтр только не дальше 10 свечей назад и, фильтр только выше старшего тф из рекомендации по стратегии
  useEffect(() => {
    //отбор только не старых моделей
    const analyticWithoutOld: ICandlesAnalytics[] = []
    analytic.forEach((model) => {
      const candlesAgo = model.candlesAgo
      let candlesAgoNum: number
      if (candlesAgo === 'Текущая') {
        analyticWithoutOld.push(model)
      } else {
        candlesAgoNum = Number(candlesAgo)
        if (candlesAgoNum <= 10) {
          analyticWithoutOld.push(model)
        }
      }
    })
    //отбор только выше старшего тф из рекомендации по стратегии
    const analyticOnlyHigher: ICandlesAnalytics[] = []
    const necessaryTfArr: string[] = []
    if (specificityOfRecomendsByStrategyHighTf === TimeFrames.week) {
      necessaryTfArr.push(InvestingComTimeFrames["1M"])
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.day) {
      necessaryTfArr.push(InvestingComTimeFrames["1M"])
      necessaryTfArr.push(InvestingComTimeFrames["1W"])
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.m60) {
      necessaryTfArr.push(InvestingComTimeFrames["1M"])
      necessaryTfArr.push(InvestingComTimeFrames["1W"])
      necessaryTfArr.push(InvestingComTimeFrames["1D"])
    } else if (specificityOfRecomendsByStrategyHighTf === TimeFrames.m10) {
      necessaryTfArr.push(InvestingComTimeFrames["1M"])
      necessaryTfArr.push(InvestingComTimeFrames["1W"])
      necessaryTfArr.push(InvestingComTimeFrames["1D"])
    }
    analyticWithoutOld.forEach((model) => {
      const tf = model.timeframe
      if (necessaryTfArr.includes(tf)) {
        analyticOnlyHigher.push(model)
      }
    })
    //сортировка массива по тф и внутри тф по сроку давности модели
    analyticOnlyHigher.sort((a, b) => {
      if (a.timeframe === InvestingComTimeFrames['1M']) {
        if (b.timeframe === InvestingComTimeFrames['1M']) {
          if (a.candlesAgo === 'Текущая') {
            return -1
          } else if (b.candlesAgo === 'Текущая') {
            return 1
          } else {
            if (Number(a.candlesAgo) < Number(b.candlesAgo)) return -1;
            if (Number(a.candlesAgo) === Number(b.candlesAgo)) return 0;
            if (Number(a.candlesAgo) > Number(b.candlesAgo)) return 1;
          }
        }
        return -1
      }
      if (a.timeframe === InvestingComTimeFrames['1D']) {
        if (b.timeframe === InvestingComTimeFrames['1D']) {
          if (a.candlesAgo === 'Текущая') {
            return -1
          } else if (b.candlesAgo === 'Текущая') {
            return 1
          } else {
            if (Number(a.candlesAgo) < Number(b.candlesAgo)) return -1;
            if (Number(a.candlesAgo) === Number(b.candlesAgo)) return 0;
            if (Number(a.candlesAgo) > Number(b.candlesAgo)) return 1;
          }
        }
        return 1
      }
      if (
        a.timeframe === InvestingComTimeFrames['1W'] &&
        b.timeframe === InvestingComTimeFrames['1M']
      ) {
        return 1
      }
      if (
        a.timeframe === InvestingComTimeFrames['1W'] &&
        b.timeframe === InvestingComTimeFrames['1D']
      ) {
        return -1
      }
      if (
        a.timeframe === InvestingComTimeFrames['1W'] &&
        b.timeframe === InvestingComTimeFrames['1W']
      ) {
        if (a.candlesAgo === 'Текущая') {
          return -1
        } else if (b.candlesAgo === 'Текущая') {
          return 1
        } else {
          if (Number(a.candlesAgo) < Number(b.candlesAgo)) return -1;
          if (Number(a.candlesAgo) === Number(b.candlesAgo)) return 0;
          if (Number(a.candlesAgo) > Number(b.candlesAgo)) return 1;
        }
      }
      return 1
    })
    setAnalyticFiltered(analyticOnlyHigher)
  }, [specificityOfRecomendsByStrategyHighTf])

  //отображение и скрытие описания моделей при клике по ним
  const setVisibleDescription = (index: number) => {
    const prevValue = indexOfVisibleDescription
    if (prevValue === index) {
      setIndexOfVisibleDescription(undefined)
      setIndexOfHiddenDescription(prevValue)
    } else {
      setIndexOfVisibleDescription(index)
      setIndexOfHiddenDescription(prevValue)
    }
  }

  return (
    <div className={[styles.container, active ? styles.active : ''].join(' ')}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <div className={styles.charts}>
            <div className={styles.chart}>
              {monthIsActive && analyticFiltered &&
              <Chart timeframe={TimeFrames.month} chart={quotes[TimeFrames.month]} analytics={analyticFiltered}/>}
            </div>
            <div className={styles.divider}>

            </div>
            <div className={styles.chart}>
              {weekIsActive && analyticFiltered &&
              <Chart timeframe={TimeFrames.week} chart={quotes[TimeFrames.week]} analytics={analyticFiltered}/>}
            </div>
            <div className={styles.divider}>

            </div>
            <div className={styles.chart}>
              {dayIsActive && analyticFiltered &&
              <Chart timeframe={TimeFrames.day} chart={quotes[TimeFrames.day]} analytics={analyticFiltered}/>}
            </div>
          </div>
          <div className={styles.models}>
            <p className={styles.title}>Список недавних свечных моделей</p>
            <div className={styles.headTable}>
              <p className={styles.model}>Модель</p>
              <p className={styles.timeframe}>Таймфрейм</p>
              <p className={styles.reliability}>Надежность</p>
              <p className={styles.candlesAgo}>Х свечей назад</p>
            </div>
            {
              analyticFiltered &&
              <div className={styles.bodyTable}>
                {
                  analyticFiltered.map((model, index) => {
                    const modelInfo = candlestick[model.name as keyof typeof candlestick]
                    const name = model.name
                    const description = modelInfo?.description
                    const image = modelInfo?.image
                    return (
                      <div className={styles.lineTable} key={uuidv4()}>
                        <div className={styles.main} onClick={() => setVisibleDescription(index)}>
                          <p className={styles.direction} title={model.returnDirection}>
                            {
                              model.returnDirection === InvestingComModelsDirection["Бычий разворот"] &&
                              <img src={upReturn} alt="downReturn"/>
                            }
                            {
                              model.returnDirection === InvestingComModelsDirection["Медвежий разворот"] &&
                              <img src={downReturn} alt="downReturn"/>
                            }
                            {
                              model.returnDirection === InvestingComModelsDirection["Продолжение бычьего тренда"] &&
                              <img src={proceedUp} alt="downReturn"/>
                            }
                            {
                              model.returnDirection === InvestingComModelsDirection["Продолжение медвежьего тренда"] &&
                              <img src={proceedDown} alt="downReturn"/>
                            }
                          </p>
                          <p className={styles.name} title={model.name}>{model.name}</p>
                          <p className={styles.timeframe}>{model.timeframe}</p>
                          <p className={styles.reliability} title={model.reliability}>
                            {
                              model.reliability === InvestingComModelsStrength["Макс."] &&
                              <>
                                <img src={starDark} alt="starDark"/>
                                <img src={starDark} alt="starDark"/>
                                <img src={starDark} alt="starDark"/>
                              </>
                            }
                            {
                              model.reliability === InvestingComModelsStrength['Средняя'] &&
                              <>
                                <img src={starDark} alt="starDark"/>
                                <img src={starDark} alt="starDark"/>
                                <img src={starLight} alt="starLight"/>
                              </>
                            }
                            {
                              model.reliability === InvestingComModelsStrength["Мин."] &&
                              <>
                                <img src={starDark} alt="starDark"/>
                                <img src={starLight} alt="starLight"/>
                                <img src={starLight} alt="starLight"/>
                              </>
                            }
                          </p>
                          <p className={styles.candlesAgo}>{model.candlesAgo}</p>
                        </div>
                        <div
                          className={[styles.hidden, index === indexOfVisibleDescription ? styles.appearing : '', index === indexOfHiddenDescription ? styles.disappearing : ''].join(' ')}>
                          {
                            modelInfo &&
                            <div className={styles.wrap}>
                              <div className={styles.left}>
                                <img src={require(`../../../../../../assets/images/candleModels/${image}.webp`)}
                                     alt="candlestick"/>
                              </div>
                              <div className={styles.right}>
                                <p className={styles.name}>{name}</p>
                                <p className={styles.description}>{description}</p>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candlestick;