import {
  Candle, CandleTypePart,
  IParamsStrategyFunction,
  IResultStrategy,
  ISeriesOfTransaction,
  IStrategyClass, StateOfTrade,
  TimeFrames
} from "../shared/types";
import {
  calculateSurrogateTransaction,
  checkDirectionAndContextDirectionAndHavePotentialByZigzag,
  checkWasDoubleBottomAndPriceAboutBottomOnZigzag,
  checkSizeMoveUpToFinishPotentialAfterOrderByZigzag,
  getZigZag,
  priceDueForProfitOverAverageMarketIncome
} from "../shared/lib/lib";
import dayjs from "dayjs";

//набор всех графиков для стратегии
interface ICurrentCharts {
  m10: Candle[],
  m60: Candle[],
  day: Candle[],
  week: Candle[],
  month: Candle[]
}

export class StrategyPositiveContextAndStopCorrectionByDoubleBottom implements IStrategyClass {

  //настройки стратегии, пока их должно быть строго 10
  public settings = {
    'DIFFERENCE_WAVE_ZIGZAG_TO_DETERMINE_DIRECTION_OF_CONTEXT_ON_UPPER_TF_ZIGZAG': 'разница в процентах между волнами зигзага для определения что контекст восходящий на старшем зигзаге',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_UP_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при растущем контексте на старшем зигзаге',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_FLAT_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при флетовом контексте на старшем зигзаге',
    'DIFFERENCE_WAVE_ZIGZAG_TO_DETERMINE_ENOUGH_OF_WAVE_DOWN_ON_LOWER_TF_ZIGZAG': 'разница в процентах между волнами зигзага для определения что последняя нисходящая волна обновила минимум на младшем зигзаге',
    'SIZE_MOVE_UP_TO_FINISH_POTENTIAL_FOR_UP_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер движения вверх достаточный для израсходования потенциала при растущем контексте на старшем зигзаге',
    'SIZE_MOVE_UP_TO_FINISH_POTENTIAL_FOR_FLAT_CONTEXT_ON_UPPER_TF_ZIGZAG': 'размер движения вверх достаточный для израсходования потенциала при флетовом контексте на старшем зигзаге',
    'MIN_DELTA_ON_ZIGZAG': 'мин дельта на зигзаге, ниже какого % колебания считаем шумом',
    'MAXIMUM_ALLOWABLE_SIZE_OF_SECOND_BOTTOM_OVERRUN_ON_LOWER_TF_ZIGZAG': 'максимально допустимый перебег второго дна на младшем зигзаге',
    'DIFFERENCE_WAVE_ZIGZAG_TO_DETERMINE_BREAKOUT_TO_UP_OF_FLAT_ON_LOWER_TF_ZIGZAG': 'разница в процентах между волнами зигзага для определения что был пробой наверх из коридора на младшем зигзаге',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_UP_CONTEXT_ON_LOWER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при растущем контексте т.е если был выход вверх из коридора остановки на младшем зигзаге',
    'SIZE_CORRECTION_TO_HAVE_POTENTIAL_FOR_FLAT_CONTEXT_ON_LOWER_TF_ZIGZAG': 'размер коррекции достаточный для набора потенциала при флетовом контексте т.е если цена оставалась внутри коридора остановки на младшем зигзаге',
    'NONE_11': '',
    'NONE_12': '',
    'NONE_13': '',
    'NONE_14': '',
    'NONE_15': '',
    'NONE_16': '',
    'NONE_17': '',
    'NONE_18': '',
    'NONE_19': '',
  }

  public main(params: IParamsStrategyFunction, openedSeriesOfTransactions: ISeriesOfTransaction): IResultStrategy {

    //данная стратегия сама прорабатывает все тф, поэтому не нужно ее прогонять по всем тф, обрабатываем только условно week, все остальные пропускаем
    if (params.timeframe !== TimeFrames.week) {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }

    //настройки стратегии
    const settings = params.settings
    //набор графиков
    const currentCharts: ICurrentCharts = {
      m10: params.currentInfoTfM10,
      m60: params.currentInfoTfM60,
      day: params.currentInfoTfDay,
      week: params.currentInfoTfWeek,
      month: params.currentInfoTfMonth
    }

    //проверка что массивы с графиками не пустые
    if (params.currentInfoTfM10.length === 0 || params.currentInfoTfM60.length === 0 || params.currentInfoTfDay.length === 0 || params.currentInfoTfWeek.length === 0 || params.currentInfoTfMonth.length === 0) {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }

    //тип действия, которое требуется от стратегии
    type ActionType = 'FIND_ENTER' | 'FIND_ADD_ENTER_OR_EXIT' | 'FIND_EXIT' | 'NONE'
    let actionType: ActionType
    //определяем, что должна сделать стратегия в данных обстоятельствах
    if (openedSeriesOfTransactions.enters.length === 0) {
      //если открытых позиций нет, то стратегии нужно искать вход
      actionType = "FIND_ENTER"
    } else if (openedSeriesOfTransactions.enters.length < 3) {
      //если открытых позиций одна или две, то стратегия может искать как вход, чтобы пополнить серию так и выход, чтобы серию завершить
      actionType = "FIND_ADD_ENTER_OR_EXIT"
    } else if (openedSeriesOfTransactions.enters.length === 3) {
      //если открытых позиций три, то стратегии нужно искать выход, так как в стратегии предусмотрено по манименеджменту не более трех открытых позиций одновременно
      actionType = "FIND_EXIT"
    } else {
      actionType = "NONE"
    }

    //массив соответствия тф и delta зигзага, согласно стратегии по нему будем проходить от больших тф к маленьким пока не встретим подходящие условия для входа
    const arrZzTf = [
      {zz: 17, tf: TimeFrames.week}, {zz: 16, tf: TimeFrames.week}, {zz: 15, tf: TimeFrames.week},
      {zz: 14, tf: TimeFrames.week}, {zz: 13, tf: TimeFrames.week}, {zz: 12, tf: TimeFrames.week},
      {zz: 11, tf: TimeFrames.week}, {zz: 10, tf: TimeFrames.week}, {zz: 9, tf: TimeFrames.day},
      {zz: 8, tf: TimeFrames.day}, {zz: 7, tf: TimeFrames.day}, {zz: 6, tf: TimeFrames.day},
      {zz: 5, tf: TimeFrames.day}, {zz: 4, tf: TimeFrames.day}, {zz: 3, tf: TimeFrames.m60},
      {zz: 2, tf: TimeFrames.m60}, {zz: 1, tf: TimeFrames.m60},
      {zz: 0.6, tf: TimeFrames.m10}, {zz: 0.3, tf: TimeFrames.m10}
    ]

    //в зависимости от выявленного режима стратегии, отработать нужное действие
    if (actionType === "FIND_ENTER") {
      let {isFound, price, date, value, specificity} = this.findEnter(currentCharts, settings, arrZzTf)
      if (isFound && price && date && value && specificity) {
        return {
          state: StateOfTrade.NEED_OPEN_SERIES,
          price: price,
          date: date,
          value: value,
          specificity: specificity
        }
      } else {
        return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
      }
    } else if (actionType === "FIND_ADD_ENTER_OR_EXIT") {
      //сначала поискать выход, возможно создались условия для закрытия серии сделок
      let {isFound: isFoundExit, price: priceExit, date: dateExit, value: valueExit, specificity: specificityExit} = this.findExit(currentCharts, settings, openedSeriesOfTransactions)
      if (isFoundExit && priceExit && dateExit && valueExit && specificityExit) {
        return {
          state: StateOfTrade.NEED_CLOSE_SERIES,
          price: priceExit,
          date: dateExit,
          value: valueExit,
          specificity: specificityExit
        }
      }
      //если выход не найден, то поискать вход на добавку в серии
      let {isFound: isFoundAddEnter, price: priceAddEnter, date: dateAddEnter, value: valueAddEnter, specificity: specificityAddEnter} = this.findAddEnter(currentCharts, settings, openedSeriesOfTransactions, arrZzTf)
      if (isFoundAddEnter && priceAddEnter && dateAddEnter && valueAddEnter && specificityAddEnter) {
        return {
          state: StateOfTrade.NEED_ADD_ENTER_IN_SERIES,
          price: priceAddEnter,
          date: dateAddEnter,
          value: valueAddEnter,
          specificity: specificityAddEnter
        }
      } else {
        return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
      }
    } else if (actionType === "FIND_EXIT") {
      let {isFound, price, date, value, specificity} = this.findExit(currentCharts, settings, openedSeriesOfTransactions)
      if (isFound && price && date && value && specificity) {
        return {
          state: StateOfTrade.NEED_CLOSE_SERIES,
          price: price,
          date: date,
          value: value,
          specificity: specificity
        }
      } else {
        return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
      }
    } else {
      return {state: StateOfTrade.NONE, price: undefined, date: undefined, value: undefined, specificity: undefined}
    }
  }

  public strategyDescription = 'Не отрицательный контекст и остановка коррекции двойным дном с нахождением в зоне остановки.' +
    '\n' +
    '\n    Описание:' +
    '\n        Находим волну с растущим контекстом и достаточной коррекцией, коррекция - это мелкий тренд, на нем ждем остановку ' +
    'двойным дном как остановку нисходящего тренда и на его локальных падениях заходим и до трех раз можно усреднять. ' +
    'Выход по истечению потенциала, это Х% от предыдущей волны.' +
    '\n' +
    '\n    Критерии входа:' +
    '\n        * найден старший зигзаг, на нем есть не падающий контекст и полноценная достаточная коррекция в зависимости от контекста;' +
    '\n        * последняя (нисходящая) волна старшего зигзага разбита на младший зигзаг и на нем есть нисходящее движение и остановка двойным дном, последнее движение вниз с образованием достаточной коррекции;' +
    '\n        * ;' +
    '\n        * ;' +
    '\n        * ;' +
    '\n' +
    '\n    Критерии выхода:' +
    '\n        * на старшем зигзаге растущая волна прошла Х% (в зависимости от контекста) от предыдущей волны;' +
    '\n        * если потенциал не реализовался, то выходим по достижению среднегодового среднерыночного дохода, ' +
    'пересчитанного на продолжительность сделки;' +
    '\n        * ;' +
    '\n        * '

  public singleVolumeInStrategyDescription = 'Для стратегии "Не отрицательный контекст и остановка коррекции флетом":' +
    '\n    Единичный объем равен 1/3 разрешенного объема на один инструмент.'

  /**
   * Поиск сделки согласно алгоритму стратегии по текущему графику
   * @param currentCharts - набор графиков нужных стратегии
   * @param settings - массив настроек для стратегии
   * @param arrZzTf - массив соответствия тф и delta зигзага, согласно стратегии по нему будем проходить от больших тф к маленьким пока не встретим подходящие условия для входа
   */
  private findEnter(currentCharts: ICurrentCharts, settings: string[], arrZzTf: { zz: number, tf: TimeFrames }[]): { isFound: true, price: number, date: string, value: number, specificity: string } | { isFound: false, price: undefined, date: undefined, value: undefined, specificity: undefined } {

    //настройки стратегии из инпутов (названия и индекс в массиве смотреть в поле settings этого класса)
    const differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting = Number(settings[0])
    const sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting = Number(settings[1])
    const sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting = Number(settings[2])
    const minDeltaOnZigzagSetting = Number(settings[6])
    const maximumAllowableSizeOfSecondBottomOverrunOnLowerTfZigzagSetting = Number(settings[7])
    const differenceWaveZzToDetermineBreakoutToUpOfFlatOnLowerTfZigzagSetting = Number(settings[8])
    const sizeCorrectionToHavePotentialForUpContextOnLowerTfZigzagSetting = Number(settings[9])
    const sizeCorrectionToHavePotentialForFlatContextOnLowerTfZigzagSetting = Number(settings[10])

    //общий цикл прохода по всем таймфреймам и зигзагам
    for (let i = 0; i < arrZzTf.length; i++) {
      //если дошли до зигзага меньше минимально допустимого в настройках стратегии, выходим из поиска входа
      if (arrZzTf[i].zz < minDeltaOnZigzagSetting) {
        return {
          isFound: false,
          price: undefined,
          date: undefined,
          value: undefined,
          specificity: undefined
        }
      }

      //-----Поиск условий на старшем экране----- (поиск на каком тф и delta ZZ будут найдены не отрицательный контекст и хорошая коррекция, до первого найденного тф и zz)
      const zigzag1 = getZigZag(currentCharts[arrZzTf[i].tf], arrZzTf[i].zz)
      const condition1 = checkDirectionAndContextDirectionAndHavePotentialByZigzag(zigzag1, differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForUpContextOnUpperTfZigzagSetting, sizeCorrectionToHavePotentialForFlatContextOnUpperTfZigzagSetting)
      //если подходящие условия для старшего экрана на этом тф не найдены, то переходим к следующем тф
      if (!condition1) {
        continue
      }

      //-----Поиск условий на нижнем экране-----
      //дата, начиная с которой берем часть графика для разбиения на более мелкие звенья зигзага
      const date = currentCharts[arrZzTf[i].tf][zigzag1[1].ind][CandleTypePart.end]
      let tf: TimeFrames | undefined //вспомогательная переменная
      let chartLowScreen: Candle[] = []
      //цикл прохода по всем таймфреймам и зигзагам ниже найденного на верхнем экране для проработки нижнего экрана
      for (let j = i + 1; j < arrZzTf.length; j++) {

        //если дошли до зигзага меньше минимально допустимого в настройках стратегии, выходим из цикла поиска на втором экране, чтобы перейти к следующей итерации поиска на верхнем экране
        if (arrZzTf[j].zz < minDeltaOnZigzagSetting) {
          break
        }

        //отбор части графика, которая соответствует последнему нисходящему звену найденного зигзага
        if (tf !== arrZzTf[j].tf) {
          chartLowScreen = []
          tf = arrZzTf[j].tf
          const fullChart = currentCharts[tf]
          for (let k = fullChart.length - 1; k >= 0; k--) {
            if (dayjs(fullChart[k][CandleTypePart.end]) < dayjs(date)) {
              chartLowScreen = fullChart.slice(k + 1)
              break
            }
          }
        }

        //если chartLowScreen.length === 0 т.е не заполнился, это значит что графика с таким мелким тф не хватило чтобы дойти до начала звена старшего зигзага, а более мелких тф тем более не хватит
        if (chartLowScreen.length === 0) {
          break
        }

        //построение зигзага на той части графика, которая соответствует последнему нисходящему звену найденного для верхнего экрана зигзага и если это снова одно звено, то переходим к более мелкому зигзагу
        const zigzag2 = getZigZag(chartLowScreen, arrZzTf[j].zz)
        let condition2 = false
        if (zigzag2.length < 3) {
          continue
        } else {
          //проверка условий на нижнем тф
          condition2 = checkWasDoubleBottomAndPriceAboutBottomOnZigzag(zigzag2, maximumAllowableSizeOfSecondBottomOverrunOnLowerTfZigzagSetting, differenceWaveZzToDetermineBreakoutToUpOfFlatOnLowerTfZigzagSetting, sizeCorrectionToHavePotentialForUpContextOnLowerTfZigzagSetting, sizeCorrectionToHavePotentialForFlatContextOnLowerTfZigzagSetting)
        }
        //если на нижнем экране найдены подходящие условия, то сообщаем о готовом сигнале на вход
        if (condition2) {
          const specificity = {
            highTf: arrZzTf[i].tf,
            highZz: arrZzTf[i].zz,
            lowTf: arrZzTf[j].tf,
            lowZz: arrZzTf[j].zz,
          }
          return {
            isFound: true,
            price: currentCharts.m60[currentCharts.m60.length - 1][CandleTypePart.close],
            date: currentCharts.m60[currentCharts.m60.length - 1][CandleTypePart.end],
            value: 1,
            specificity: JSON.stringify(specificity)
          }
        } else {
          break
        }
      }
    }

    //если после прохода по всем тф и zz условия не найдены, то выходим из поиска входа
    return {
      isFound: false,
      price: undefined,
      date: undefined,
      value: undefined,
      specificity: undefined
    }
  }

  /**
   * Поиск добавления сделки в серию сделок согласно алгоритму стратегии по текущему графику
   * @param currentCharts - набор графиков нужных стратегии
   * @param settings - массив настроек для стратегии
   * @param openedSeriesOfTransactions - информация об открытой серии сделок, если они есть
   * @param arrZzTf - массив соответствия тф и delta зигзага, согласно стратегии по нему будем проходить от больших тф к маленьким пока не встретим подходящие условия для входа
   */
  private findAddEnter(currentCharts: ICurrentCharts, settings: string[], openedSeriesOfTransactions: ISeriesOfTransaction, arrZzTf: { zz: number, tf: TimeFrames }[]): { isFound: true, price: number, date: string, value: number, specificity: string } | { isFound: false, price: undefined, date: undefined, value: undefined, specificity: undefined } {

    //настройки стратегии
    const differenceWaveZzToDetermineEnoughOfWaveDownOnLowerTfZigzagSetting = Number(settings[3])
    const minDeltaOnZigzagSetting = Number(settings[6])

    //некоторые параметры старшего экрана, которые были на момент открытия серии сделок
    const highTfAtMomentOfOpenSeries: TimeFrames = JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highTf
    const highZzAtMomentOfOpenSeries: number = JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highZz
    const highZigzag = getZigZag(currentCharts[highTfAtMomentOfOpenSeries], highZzAtMomentOfOpenSeries)
    const dateAtMomentOfOpenSeries: string = openedSeriesOfTransactions.enters[0].date

    //проверить что сейчас другое звено младшего зигзага, не то на котором была открыта последняя сделка в серии сделок
    const lowTfAtMomentOfOpenLastTransactionInSeries: TimeFrames = JSON.parse(openedSeriesOfTransactions.enters[openedSeriesOfTransactions.enters.length - 1].specificity).lowTf
    const lowZzAtMomentOfOpenLastTransactionInSeries: number = JSON.parse(openedSeriesOfTransactions.enters[openedSeriesOfTransactions.enters.length - 1].specificity).lowZz
    const lowZigzag = getZigZag(currentCharts[lowTfAtMomentOfOpenLastTransactionInSeries], lowZzAtMomentOfOpenLastTransactionInSeries)
    const dateAtMomentOfOpenLastTransactionInSeries: string = openedSeriesOfTransactions.enters[openedSeriesOfTransactions.enters.length - 1].date
    if (dayjs(currentCharts[lowTfAtMomentOfOpenLastTransactionInSeries][lowZigzag[1].ind][CandleTypePart.end]) < dayjs(dateAtMomentOfOpenLastTransactionInSeries)) {
      return {
        isFound: false,
        price: undefined,
        date: undefined,
        value: undefined,
        specificity: undefined
      }
    }

    //период с момента открытия серии сделок (с начала звена старшего зигзага) нужно разбить на новый младший зигзаг, возможно он будет крупнее, чем на последней сделке серии, чтобы было больше одного звена, проверить что последнее текущее звено вниз и обновило минимум более чем на Х% предыдущей волны зигзага
    let newLowTf: TimeFrames | undefined
    let newLowZz: number | undefined
    //дата узла зигзага старшего экрана после которого был сделан вход в серию сделок
    let date = ''
    for (let i = 1; i < highZigzag.length; i++) {
      if (dayjs(currentCharts[highTfAtMomentOfOpenSeries][highZigzag[i].ind][CandleTypePart.end]) < dayjs(dateAtMomentOfOpenSeries)) {
        date = currentCharts[highTfAtMomentOfOpenSeries][highZigzag[i].ind][CandleTypePart.end]
        break
      }
    }

    let tf: TimeFrames | undefined
    let chartLowScreen: Candle[] = []
    for (let i = 0; i < arrZzTf.length; i++) {
      if (arrZzTf[i].zz >= minDeltaOnZigzagSetting) {
        //пропуск итераций с zz большим чем уже найденный старший зигзаг, который нужно раздробить на более мелкие
        if (arrZzTf[i].zz > highZzAtMomentOfOpenSeries) {
          continue
        }
        //отбор части графика, после даты открытия серии сделок (с начала звена старшего зигзага)
        if (tf !== arrZzTf[i].tf) {
          chartLowScreen = []
          tf = arrZzTf[i].tf
          const fullChart = currentCharts[tf]
          for (let j = fullChart.length - 1; j >= 0; j--) {
            if (dayjs(fullChart[j][CandleTypePart.end]) < dayjs(date)) {
              chartLowScreen = fullChart.slice(j + 1)
              break
            }
          }
        }

        //если chartLowScreen.length === 0 т.е не заполнился, это значит что графика с таким мелким тф не хватило чтобы дойти до начала звена старшего зигзага, а более мелких тф тем более не хватит
        if (chartLowScreen.length === 0) {
          break
        }

        //построение младшего зигзага на той части графика, которая соответствует последнему нисходящему звену старшего зигзага и если это снова одно звено, то переходим к более мелкому зигзагу
        const zigzag = getZigZag(chartLowScreen, arrZzTf[i].zz)
        if (zigzag.length < 3) {
          continue
        } else {
          //если последнее звено микро зигзага направлено вверх, то условия для входа не найдены
          if (zigzag[0].price >= zigzag[1].price) {
            return {
              isFound: false,
              price: undefined,
              date: undefined,
              value: undefined,
              specificity: undefined
            }
          }
          //если последняя линия микро зигзага идущая вниз обновила минимум менее чем на Х% предыдущей волны зигзага, то условия для входа не найдены
          if (((zigzag[1].price - zigzag[0].price) / (zigzag[1].price - zigzag[2].price)) < ((100 + differenceWaveZzToDetermineEnoughOfWaveDownOnLowerTfZigzagSetting) / 100)) {
            return {
              isFound: false,
              price: undefined,
              date: undefined,
              value: undefined,
              specificity: undefined
            }
          }
          //если текущая цена не ниже цен предыдущих входов в серии, то условия для входа не найдены
          const numOfLastEnter = openedSeriesOfTransactions.enters.length - 1
          const priceOfLastEnterInSeries: number | null = openedSeriesOfTransactions.enters[numOfLastEnter].price
          if (priceOfLastEnterInSeries && zigzag[0].price >= priceOfLastEnterInSeries) {
            return {
              isFound: false,
              price: undefined,
              date: undefined,
              value: undefined,
              specificity: undefined
            }
          }

          //условия для входа по микро зигзагу найдены
          newLowTf = arrZzTf[i].tf
          newLowZz = arrZzTf[i].zz
          break
        }
      }
    }
    //если микро зигзаг не найден или последнее текущее звено не вниз или не обновило минимум более чем на Х% предыдущей волны зигзага, то условия для входа не найдены
    if (!newLowTf && !newLowZz) {
      return {
        isFound: false,
        price: undefined,
        date: undefined,
        value: undefined,
        specificity: undefined
      }
    }

    //все условия пройдены - ситуация для добавления в серию сделок подходящая
    const specificity = {
      highTf: highTfAtMomentOfOpenSeries,
      highZz: highZzAtMomentOfOpenSeries,
      lowTf: newLowTf,
      lowZz: newLowZz,
    }
    return {
      isFound: true,
      price: currentCharts.m10[currentCharts.m10.length - 1][CandleTypePart.close],
      date: currentCharts.m10[currentCharts.m10.length - 1][CandleTypePart.end],
      value: 1,
      specificity: JSON.stringify(specificity)
    }
  }

  /**
   * Поиск выхода из сделки согласно алгоритму стратегии по текущему графику
   * @param currentCharts - набор графиков нужных стратегии
   * @param settings - массив настроек для стратегии
   * @param openedSeriesOfTransactions - информация об открытой серии сделок, если они есть
   */
  private findExit(currentCharts: ICurrentCharts, settings: string[], openedSeriesOfTransactions: ISeriesOfTransaction): { isFound: true, price: number, date: string, value: number, specificity: string } | { isFound: false, price: undefined, date: undefined, value: undefined, specificity: undefined } {

    //настройки стратегии
    const differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting = Number(settings[0])
    const sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting = Number(settings[4])
    const sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting = Number(settings[5])

    //текущая цена, дата, серия сделок синтетически преобразованная в одну и минимальная цена для выхода
    const currentPrice = currentCharts.m10[currentCharts.m10.length - 1][CandleTypePart.close]
    const currentDate = currentCharts.m10[currentCharts.m10.length - 1][CandleTypePart.end]
    const surrogateTransaction = calculateSurrogateTransaction(openedSeriesOfTransactions)
    const minPriceForExit = priceDueForProfitOverAverageMarketIncome(surrogateTransaction.price, surrogateTransaction.date, currentPrice, currentDate)

    //если цена не достигла цены необходимой для минимально допустимого дохода для закрытия - среднегодовая среднерыночная доходность в расчете на продолжительность нахождения в сделке, то выходим из поиска
    if (currentPrice < minPriceForExit) {
      return {
        isFound: false,
        price: undefined,
        date: undefined,
        value: undefined,
        specificity: undefined
      }
    }

    //построение верхнего зигзага по параметрам, которые были при открытии серии сделок
    const tfAtMomentOfOpenSeries: TimeFrames = JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highTf
    const zzAtMomentOfOpenSeries: number = JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highZz
    const zigzag = getZigZag(currentCharts[tfAtMomentOfOpenSeries], zzAtMomentOfOpenSeries)
    const dateAtMomentOfOpenSeries: string = openedSeriesOfTransactions.enters[0].date

    //поиск на каком звене этого зигзага была открыта серия сделок (после какого узла)
    let numNodeZzAtMomentOfOpenSeries = 0
    for (let i = 1; i < zigzag.length; i++) {
      if (dayjs(currentCharts[tfAtMomentOfOpenSeries][zigzag[i].ind][CandleTypePart.end]) < dayjs(dateAtMomentOfOpenSeries)) {
        numNodeZzAtMomentOfOpenSeries = i
        break
      }
    }

    //если серия сделок открыта более чем на третьем узле текущего зигзага (0 - это тоже "более чем на третьем узле" это значит не хватило рассчитанного зигзага, он до 7 звена рассчитывается для оптимизации программы), значит потенциал по стратегии не реализовался, было маленькое движение вверх и потом зафлетовало или пошло вниз, т.е. выходим по реализации среднерыночной доходности
    if (numNodeZzAtMomentOfOpenSeries > 3 || numNodeZzAtMomentOfOpenSeries === 0) {
      const specificity = {
        tf: JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highTf,
        date: openedSeriesOfTransactions.enters[0].date,
        price: openedSeriesOfTransactions.enters[0].price,
      }
      return {
        isFound: true,
        price: currentPrice,
        date: currentDate,
        value: surrogateTransaction.value,
        specificity: JSON.stringify(specificity)
      }
    }

    //если серия сделок открыта не более чем на втором узле текущего зигзага значит еще идет рост цены согласно потенциалу по стратегии, т.е. ждем пока реализуется потенциал
    if (numNodeZzAtMomentOfOpenSeries <= 3) {
      //если потенциал реализован, возвращаем сигнал о закрытии серии сделок
      if (checkSizeMoveUpToFinishPotentialAfterOrderByZigzag(currentCharts[tfAtMomentOfOpenSeries], zigzag, surrogateTransaction.date, surrogateTransaction.price, sizeMoveUpToFinishPotentialForUpContextOnUpperTfZigzagSetting, sizeMoveUpToFinishPotentialForFlatContextOnUpperTfZigzagSetting, differenceWaveZzToDetermineDirectionOfContextOnUpperTfZigzagSetting)) {
        const specificity = {
          tf: JSON.parse(openedSeriesOfTransactions.enters[0].specificity).highTf,
          date: openedSeriesOfTransactions.enters[0].date,
          price: openedSeriesOfTransactions.enters[0].price,
        }
        return {
          isFound: true,
          price: currentPrice,
          date: currentDate,
          value: surrogateTransaction.value,
          specificity: JSON.stringify(specificity)
        }
      } else {//если потенциал еще не реализован, продолжаем ждать
        return {
          isFound: false,
          price: undefined,
          date: undefined,
          value: undefined,
          specificity: undefined
        }
      }
    }

    //возврат, что выход не найден на случай каких либо не учтенных условий
    return {
      isFound: false,
      price: undefined,
      date: undefined,
      value: undefined,
      specificity: undefined
    }
  }
}
