import React from 'react';
import styles from "./colorIndicator.module.scss";

export enum Color {
  'gray' = 'gray',
  'green' = 'green',
  'red' = 'red',
}

interface Props {
  color: Color
  description: string
  onClick: () => void
}

const ColorIndicator = ({color, description, onClick}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          className={[styles.indicator, styles[color]].join(' ')} title={description}
          onClick={onClick}
        >

        </div>
      </div>
    </div>
  );
};

export default ColorIndicator;