import React, {useState} from 'react';
import styles from "./popupAnalytics.module.scss";
import Closer from "../../../../components/UI/closer/Closer";
import {Tabs} from "antd";
import News from "./tabs/news/News";
import Expert from "./tabs/expert/Expert";
import Charts from "./tabs/charts/Charts";
import Candlestick from "./tabs/candlestick/Candlestick";
import {
  Candle,
  Countries,
  IAnalytics,
  IInfoAboutAdviceToAnalyticsPopup,
  IRatingUser,
  TimeFrames
} from "../../../../shared/types";

interface Props {
  closeHandler: () => void
  country: Countries
  infoAboutAdvice: IInfoAboutAdviceToAnalyticsPopup
  analytics: IAnalytics
  membersRating: IRatingUser[]
  quotes: Record<string, Record<TimeFrames, Candle[]>>
}

const PopupAnalytics = ({closeHandler, country, infoAboutAdvice, analytics, membersRating, quotes}: Props) => {

  const [newsIsActive, setNewsIsActive] = useState(true) //активность вкладки Новости
  const [expertIsActive, setExpertIsActive] = useState(false) //активность вкладки Эксперты
  const [chartsIsActive, setChartsIsActive] = useState(false) //активность вкладки Графики
  const [candlestickIsActive, setCandlestickIsActive] = useState(false) //активность вкладки Свечные модели

  //работа табов
  const itemsTab = [
    {key: 'news', label: 'Новости',},
    {key: 'analytics', label: 'Аналитика',},
    {key: 'charts', label: 'Графики',},
    {key: 'candlestick', label: 'Свечные модели',},
  ];
  const changeTabHandler = (key: string) => {
    if (key === 'news') {
      if (!newsIsActive) {
        setNewsIsActive(true)
        setExpertIsActive(false)
        setChartsIsActive(false)
        setCandlestickIsActive(false)
      }
    } else if (key === 'analytics') {
      if (!expertIsActive) {
        setExpertIsActive(true)
        setNewsIsActive(false)
        setChartsIsActive(false)
        setCandlestickIsActive(false)
      }
    } else if (key === 'charts') {
      if (!chartsIsActive) {
        setChartsIsActive(true)
        setNewsIsActive(false)
        setExpertIsActive(false)
        setCandlestickIsActive(false)
      }
    } else if (key === 'candlestick') {
      if (!candlestickIsActive) {
        setCandlestickIsActive(true)
        setChartsIsActive(false)
        setNewsIsActive(false)
        setExpertIsActive(false)
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <Closer closeHandler={closeHandler}/>
          <p className={styles.title}>Аналитика по инструменту {infoAboutAdvice.ticker}</p>
          <Tabs defaultActiveKey="1" items={itemsTab} onChange={changeTabHandler}/>
          <News
            active={newsIsActive}
            analytic={analytics.news[infoAboutAdvice.ticker]}
          />
          <Expert
            active={expertIsActive}
            analytic={analytics.expert[infoAboutAdvice.ticker]}
            membersRating={membersRating}
          />
          <Charts
            active={chartsIsActive}
            specificityOfRecomendsByStrategy={infoAboutAdvice.specificity}
            quotes={quotes[infoAboutAdvice.ticker]}
          />
          <Candlestick
            active={candlestickIsActive}
            analytic={analytics.candles[infoAboutAdvice.ticker]}
            specificityOfRecomendsByStrategy={infoAboutAdvice.specificity}
            quotes={quotes[infoAboutAdvice.ticker]}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupAnalytics;