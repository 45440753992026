import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Countries} from "../../shared/types";

interface IState {
  country: Countries | null
  tickerList: string[]
}

const initialState: IState = {
  country: null,
  tickerList: [],
}

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<Countries | null>) => {
      state.country = action.payload
    },
    setTickerList: (state, action: PayloadAction<string[]>) => {
      state.tickerList = action.payload
    },
  }
})

export const {setCountry, setTickerList} = countrySlice.actions

export default countrySlice.reducer