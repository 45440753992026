import React, {useEffect, useState} from 'react';
import styles from "./positionsWrap.module.scss";
import {usePositions} from "../../../hooks/positions.hook";
import {Countries, OpenedPositions, Strategies, StrategyLabel, TimeFrames} from "../../../shared/types";
import {message as messageAnt} from "antd";
import {transformAllPositions} from "../../../shared/lib/lib";
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {useAppSelector} from "../../../store/store";

interface PositionsForRender {
  strategy: string
  ticker: string
  timeFrame: string
  value: string
}

const PositionsWrap = () => {

  const country = useAppSelector(state => state.countryReduser.country)
  const tickerList = useAppSelector(state => state.countryReduser.tickerList)

  const {loading: loadingPositions, saveOpenedPositions, removeOpenedPositions, getAllPositions, error: errorPositions, clearError: clearErrorPositions} = usePositions()

  const [openedPositions, setOpenedPositions] = useState<typeof OpenedPositions>()
  //список открытых позиций, подготовленный для рендера
  const [openedPositionsForRender, setOpenedPositionsForRender] = useState<Array<PositionsForRender>>()

  //функция преобразование Record с открытыми позициями в обычный массив строк
  const getOpenedPositionsList = (openedPositions: typeof OpenedPositions): Array<PositionsForRender> => {
    const arr: Array<PositionsForRender> = []
    if (openedPositions) {
      Object.keys(Strategies).forEach(strategy => {
        tickerList.forEach(ticker => {
          Object.keys(TimeFrames).forEach(timeFrame => {
            if (
              openedPositions &&
              openedPositions[strategy as keyof typeof Strategies] &&
              openedPositions[strategy as keyof typeof Strategies][ticker] &&
              openedPositions[strategy as keyof typeof Strategies][ticker][timeFrame as keyof typeof TimeFrames]
            ) {
              const enters = openedPositions[strategy as keyof typeof Strategies][ticker][timeFrame as keyof typeof TimeFrames].enters
              const exits = openedPositions[strategy as keyof typeof Strategies][ticker][timeFrame as keyof typeof TimeFrames].exits
              let sumValue = 0
              enters.forEach(enter => {
                sumValue += enter.value
              })
              exits.forEach(enter => {
                sumValue -= enter.value
              })
              arr.push({
                strategy: strategy,
                ticker: ticker,
                timeFrame: timeFrame,
                value: sumValue.toString()
              })
            }
          })
        })
      })
    }
    return arr
  }

  //преобразование Record с открытыми позициями в обычный массив строк, для рендера
  useEffect(() => {
    if (openedPositions) {
      const openedPositionsForRender = getOpenedPositionsList(openedPositions)
      setOpenedPositionsForRender(openedPositionsForRender)
    }
  }, [openedPositions])

  //получение данных по открытым позициям для всех стратегий, таймфреймов и инструментов из БД
  const getOpenedPositions = async (country: Countries) => {
    try {
      const data = await getAllPositions(country)
      messageAnt.info(data.message)
      const positions = transformAllPositions(data.info)
      return positions
    } catch (e) {
      messageAnt.info(errorPositions);
      clearErrorPositions()
      return undefined
    }
  }

  //изначальное получение данных по открытым позициям из БД
  useEffect(() => {
    if (country) {
      const get = async () => {
        const openedPositions = await getOpenedPositions(country)
        setOpenedPositions(openedPositions)
      }
      get()
    }
  }, [country])

  //функция для сохранение данных по открытым позициям в БД для одной стратегии в которой были изменения по позициям по любому тикеру и тф
  const savePositions = async (country: Countries, strategy: Strategies, positions: string) => {
    if (!positions) {
      //если последняя позиция по стратегии закрыта, то очищаем документ по этой стратегии в БД
      try {
        const data = await removeOpenedPositions(country, strategy)
        messageAnt.info(data.message)
      } catch (e) {
        messageAnt.info(errorPositions);
        clearErrorPositions()
      }
    } else {
      try {
        const data = await saveOpenedPositions(country, strategy, positions)
        messageAnt.info(data.message)
      } catch (e) {
        messageAnt.info(errorPositions);
        clearErrorPositions()
      }
    }
  }

  const deletePositionHandler = async (index: number) => {
    if (openedPositions && openedPositionsForRender && country) {

      const areYouSure = window.confirm(`Вы уверены, что хотите удалить информацию об открытой позиции по ${openedPositionsForRender[index].ticker}?`)
      if (!areYouSure) {
        return
      }

      //что будем удалять
      const strategy = openedPositionsForRender[index].strategy
      const ticker = openedPositionsForRender[index].ticker
      const timeframe = openedPositionsForRender[index].timeFrame

      let newOpenedPositions
      newOpenedPositions = JSON.parse(JSON.stringify(openedPositions))
      delete newOpenedPositions[strategy][ticker][timeframe]
      if (Object.keys(newOpenedPositions[strategy][ticker]).length === 0) {
        delete newOpenedPositions[strategy][ticker]
        if (Object.keys(newOpenedPositions[strategy]).length === 0) {
          delete newOpenedPositions[strategy]
        }
      }
      try {
        //сохранение обновленной информации по открытым позициям в БД
        await savePositions(country, strategy as Strategies, JSON.stringify(newOpenedPositions[strategy]))
        setOpenedPositions(newOpenedPositions)
      } catch (e) {

      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.wrap}>
          <h5>Открытые позиции</h5>
          {
            !country &&
            <p className={styles.noCountry}>Не выбрана страна на главной странице!</p>
          }
          {
            country && openedPositionsForRender ?
              <div className={[styles.received, styles.blockWrap].join(' ')}>
                <p className={styles.title}>Открытые позиции по всем инструментам и стратегиям</p>
                <div className={styles.headTable}>
                  <p className={styles.strategy}>Стратегия</p>
                  <p className={styles.ticker}>Инструмент</p>
                  <p className={styles.timeFrame}>Тайм фрейм</p>
                  <p className={styles.value}>Объем</p>
                </div>
                <div className={styles.bodyTable}>
                  {
                    openedPositionsForRender.map((position, index) => (
                      <div className={styles.lineTable} key={uuidv4()}>
                        <p
                          className={styles.strategy}
                          title={StrategyLabel[position.strategy as keyof typeof Strategies]}
                        >
                          {StrategyLabel[position.strategy as keyof typeof Strategies]}
                        </p>
                        <p className={styles.ticker}>{position.ticker}</p>
                        <p className={styles.timeFrame}>{position.timeFrame}</p>
                        <p className={styles.value}>{position.value}</p>
                        <p className={styles.delete}>
                          <span onClick={() => deletePositionHandler(index)}>
                            <FontAwesomeIcon icon={faXmark} style={{color: "#990000"}}/>
                          </span>
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default PositionsWrap;